import { memo, useMemo } from 'react';
import { Descriptions, Image, Space, Spin, Table, Typography } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { PageTitle } from '../../components';
import { AppRoutes } from '../../helpers';
import { CategoryTypeEnum, ProductTypeEnum } from '../../graphql/type.interface';
import { useAdminProductQuery } from '../../graphql/queries/adminProduct.generated';

const ProductDetail = memo(({ productType }: { productType: ProductTypeEnum }) => {
  const navigate = useNavigate();
  const isVehicle = productType === ProductTypeEnum.VEHICLE;

  const { id } = useParams() as {
    id: string;
  };
  if (!id) navigate(-1);

  const { data: productData, loading: productLoading } = useAdminProductQuery({
    skip: !id,
    variables: { id },
    fetchPolicy: 'cache-and-network',
  });

  const detailData = useMemo(() => productData?.adminProduct, [productData?.adminProduct]);
  const tableData = useMemo(
    () => detailData?.productDevices?.map((device, i) => ({ ...device, key: i + 1 })),
    [detailData?.productDevices],
  );

  return (
    <div>
      <Spin spinning={productLoading}>
        <Descriptions
          title={
            <PageTitle
              link={AppRoutes.productManagement}
              title={`Thông tin chi tiết ${isVehicle ? 'thiết bị' : 'phụ tùng'}`}
            />
          }
          column={1}
          labelStyle={{ width: '150px' }}>
          <Descriptions.Item label="Ảnh sản phẩm">
            <Image.PreviewGroup>
              {detailData?.avatar?.fullThumbUrl ? (
                <Image width={50} height={50} className="rounded-xl" src={detailData.avatar.fullThumbUrl} />
              ) : (
                'Không có hình ảnh'
              )}
            </Image.PreviewGroup>
          </Descriptions.Item>
          <Descriptions.Item label="Ảnh mô tả">
            <Image.PreviewGroup>
              {detailData?.descriptionImages.length !== 0
                ? detailData?.descriptionImages.map((avatar, i) => (
                    <Image
                      key={`avt-${i}`}
                      width={50}
                      height={50}
                      className="rounded-xl"
                      src={avatar.fullThumbUrl ?? ''}
                    />
                  ))
                : 'Không có hình ảnh'}
            </Image.PreviewGroup>
          </Descriptions.Item>
          <Descriptions.Item label="Tên sản phẩm">{detailData?.name}</Descriptions.Item>
          {!isVehicle ? (
            <>
              <Descriptions.Item label="Ký hiệu/model">{detailData?.serialNumber}</Descriptions.Item>
              <Descriptions.Item label="Serial/Part-number">{detailData?.partNumber}</Descriptions.Item>
            </>
          ) : (
            <>
              <Descriptions.Item label="Biển số">{detailData?.vehicleRegistrationPlate}</Descriptions.Item>
              <Descriptions.Item label="Số thứ tự">{detailData?.ordinalNumber}</Descriptions.Item>
              <Descriptions.Item label="Chủng loại máy">
                {detailData?.productType?.type === CategoryTypeEnum.VEHICLE_TYPE && detailData.productType.name}
              </Descriptions.Item>
              <Descriptions.Item label="Hãng sản xuất">{detailData?.manufacturer?.name}</Descriptions.Item>
              <Descriptions.Item label="Model">{detailData?.model?.name}</Descriptions.Item>
              <Descriptions.Item label="Số Serial">{detailData?.serialNumber}</Descriptions.Item>
              <Descriptions.Item label="Số VIN">{detailData?.vinNumber}</Descriptions.Item>
            </>
          )}
          <Descriptions.Item label="Tình trạng">
            {detailData?.isNew ? 'Mới' : `Đã qua sử dụng (${detailData?.operatingNumber} ${detailData?.operatingUnit})`}
          </Descriptions.Item>
          <Descriptions.Item label="Xuất xứ">{detailData?.origin?.name}</Descriptions.Item>
          <Descriptions.Item label="Năm sản xuất">{detailData?.yearOfManufacture}</Descriptions.Item>
          <Descriptions.Item label="Chi tiết">{detailData?.detail}</Descriptions.Item>
          {!isVehicle && (
            <Descriptions.Item label="Dùng cho bộ phận">{detailData?.partOfProduct?.name}</Descriptions.Item>
          )}
          <Descriptions.Item label="Giá sản phẩm">{detailData?.unitPrice} VND</Descriptions.Item>
          <Descriptions.Item label="Tồn kho">
            {detailData?.quantity} {detailData?.productUnit?.name}
          </Descriptions.Item>
        </Descriptions>
        {!isVehicle && (
          <Space direction="vertical" size="large" className="w-full">
            <Typography.Text>Lắp cho các thiết bị</Typography.Text>
            <Table
              columns={[
                { title: 'STT', dataIndex: 'key' },
                { title: 'Chủng loại máy', dataIndex: 'vehicleType', render: (v) => v?.name },
                { title: 'Hãng sản xuất', dataIndex: 'manufacturer', render: (v) => v?.name },
                { title: 'Model', dataIndex: 'model', render: (v) => v?.name },
              ]}
              dataSource={tableData}
              pagination={false}
              bordered
            />
          </Space>
        )}
      </Spin>
    </div>
  );
});

export default ProductDetail;
