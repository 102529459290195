import React from 'react';

import { AppRoutes } from '../../helpers';
import { MasterForm } from '../../components';
import { CategoryTypeEnum } from '../../graphql/type.interface';

type Props = {
  isDetail?: boolean;
};

export default function FormProblem({ isDetail = false }: Props) {
  return (
    <MasterForm
      isDetail={isDetail}
      title="Hiện tượng hư hỏng"
      backTo={AppRoutes.problemManagement}
      typeCategory={CategoryTypeEnum.PROBLEM}
    />
  );
}
