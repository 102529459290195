import type { TablePaginationConfig } from 'antd';

export const DefaultPagination: TablePaginationConfig = {
  position: ['bottomRight'],
  size: 'small',
  locale: { items_per_page: ' / trang' },
  defaultPageSize: 10,
  showQuickJumper: false,
  showSizeChanger: false,
  showTotal: (total, range) => {
    return `${range[0]}-${range[1]} của ${total}`;
  },
};
