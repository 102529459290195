import React, { useMemo, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, UploadFile, notification } from 'antd';
import dayjs from 'dayjs';
import { RcFile } from 'antd/es/upload';

import { useAdminCreateProductVehicleMutation } from '../../../graphql/mutations/adminCreateProductVehicle.generated';
import { useAdminUpdateProductVehicleMutation } from '../../../graphql/mutations/adminUpdateProductVehicle.generated';
import { useAdminCreateProductAccessaryMutation } from '../../../graphql/mutations/adminCreateProductAccessary.generated';
import { useAdminUpdateAccessaryMutation } from '../../../graphql/mutations/adminUpdateAccessary.generated';
import { useAdminProductQuery } from '../../../graphql/queries/adminProduct.generated';
import { CategoryTypeEnum, ProductTypeEnum } from '../../../graphql/type.interface';
import { useUploadImageMutation } from '../../../services';
import { AccessaryInput } from '../modal-select-device';
import { Messages } from '../../../constants';

import { useProdDropdown } from './useProdDropdown';

interface ModalSelectDevice {
  vehicleTypesData: any[];
  modelsData: any[];
  manufacturersData: any[];
}

export function useProdForm({ isEdit, productType }: { isEdit: boolean; productType: ProductTypeEnum }) {
  const isVehicle = productType === ProductTypeEnum.VEHICLE;
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [modalForm] = Form.useForm();

  const { id } = useParams() as {
    id: string;
  };

  const { data: productData, loading: productLoading } = useAdminProductQuery({
    skip: !id,
    variables: { id },
    fetchPolicy: 'cache-and-network',
  });

  const initValues = useMemo(() => {
    const prodById = productData?.adminProduct;
    return prodById
      ? {
          id: prodById.id,
          // avatarId: Object.assign(
          //   {},
          //   {
          //     uid: prodById?.avatar?.id,
          //     name: prodById?.avatar?.name,
          //     status: 'done' as const,
          //     url: prodById?.avatar?.fullThumbUrl ?? prodById?.avatar?.fullOriginalUrl ?? undefined,
          //   },
          // ),
          avatarId: [
            {
              uid: prodById?.avatar?.id,
              name: prodById?.avatar?.name,
              status: 'done' as const,
              url: prodById?.avatar?.fullThumbUrl ?? prodById?.avatar?.fullOriginalUrl ?? undefined,
            },
          ],
          descriptionImageIds: prodById.descriptionImages.map((avt) => ({
            uid: avt.id,
            name: avt.name,
            status: 'done' as const,
            url: avt.fullThumbUrl ?? avt.fullOriginalUrl ?? undefined,
          })),
          name: prodById.name,
          vehicleRegistrationPlate: prodById.vehicleRegistrationPlate,
          ordinalNumber: prodById.ordinalNumber,
          manufacturerId: prodById.manufacturer?.id,
          modelId: prodById.model?.id,
          serialNumber: prodById.serialNumber,
          partNumber: prodById.partNumber,
          originId: prodById.origin?.id,
          isNew: prodById.isNew,
          operatingNumber: prodById.operatingNumber,
          operatingUnit: prodById.operatingUnit,
          detail: prodById.detail,
          isFixedCost: prodById.isFixedCost,
          unitPrice: prodById.unitPrice,
          quantity: prodById.quantity,
          isActive: prodById.isActive,
          vehicleTypeId: prodById.productType?.type === CategoryTypeEnum.VEHICLE_TYPE && prodById.productType?.id,
          productUnitId: prodById.productUnit?.id,
          vinNumber: prodById.vinNumber,
          yearOfManufacture: prodById.yearOfManufacture && dayjs().year(prodById.yearOfManufacture),
          partId: prodById.partOfProduct?.id,
          productDevices: prodById.productDevices.map((device) => ({
            manufacturerId: device.manufacturer.id,
            modelId: device.model.id,
            vehicleTypeId: device.vehicleType.id,
          })),
        }
      : {
          descriptionImageIds: [],
          isNew: true,
          isFixedCost: true,
          isActive: true,
        };
  }, [productData?.adminProduct]);

  const { mutateAsync, isLoading: imgLoading } = useUploadImageMutation({
    onError(error) {
      notification.error({ message: Messages.upload.fail('ảnh'), description: error?.message });
    },
  });

  const [createProductVehicle, { loading: vehicleCreating }] = useAdminCreateProductVehicleMutation({
    onError(err) {
      notification.error({ message: Messages.create.fail('thiết bị'), description: err.message });
    },
    onCompleted() {
      notification.success({ message: Messages.create.success('thiết bị') });
      navigate(-1);
    },
  });

  const [updateProductVehicle, { loading: vehicleUpdating }] = useAdminUpdateProductVehicleMutation({
    onError: (err) => notification.error({ message: Messages.update.fail('thiết bị'), description: err?.message }),
    onCompleted: () => {
      notification.success({ message: Messages.update.success('thiết bị') });
      navigate(-1);
    },
  });

  const [createProductAccessary, { loading: accessaryCreating }] = useAdminCreateProductAccessaryMutation({
    onError(err) {
      notification.error({ message: Messages.create.fail('phụ tùng'), description: err.message });
    },
    onCompleted() {
      notification.success({ message: Messages.create.success('phụ tùng') });
      navigate(-1);
    },
  });

  const [updateProductAccessary, { loading: accessaryUpdating }] = useAdminUpdateAccessaryMutation({
    onError: (err) => notification.error({ message: Messages.update.fail('phụ tùng'), description: err?.message }),
    onCompleted: () => {
      notification.success({ message: Messages.update.success('phụ tùng') });
      navigate(-1);
    },
  });

  const dropdownParams = {
    vehicleTypes: true,
    manufacturers: true,
    models: true,
    origins: true,
    productUnits: true,
  };

  const { data: dropdownData, loading: dropDownLoading } = useProdDropdown(
    isVehicle
      ? dropdownParams
      : {
          ...dropdownParams,
          partOfNumbers: true,
        },
  );

  const productDevices = Form.useWatch('productDevices', form);

  const [modalSelectDeviceData, setModalSelectDeviceData] = React.useState<ModalSelectDevice>();

  const handleSubmitModal = useCallback(
    (values: AccessaryInput) => {
      if (values) {
        const productDevicesPrev: AccessaryInput[] = [];
        (productDevices || []).forEach((item: AccessaryInput) => {
          if (item?.manufacturerId && item?.modelId && item?.vehicleTypeId) productDevicesPrev.push(item);
        });
        form.setFieldsValue({
          productDevices: [...productDevicesPrev, values],
        });
        setModalSelectDeviceData(undefined);
        modalForm.resetFields();
      }
    },
    [productDevices, form, modalForm],
  );

  const onFinish = useCallback(
    async (prod: any) => {
      const handleUploadImage = async (img: UploadFile) => {
        const formData = new FormData();
        formData.append('file', img.originFileObj as RcFile);
        return mutateAsync(formData).then((v: any) => v.id);
      };
      const handleUploadImages = async (imgs: UploadFile[]) => {
        const uploadData = imgs.filter((img) => img.status !== 'done');
        return Promise.all(
          uploadData.map((data) => {
            const formData = new FormData();
            formData.append('file', data.originFileObj as RcFile);
            return mutateAsync(formData);
          }),
        ).then((values: any) => values.map((v: any) => v.id));
      };
      try {
        const avtId =
          prod.avatarId[0].status !== 'done' ? await handleUploadImage(prod.avatarId[0]) : prod.avatarId[0].uid;
        const descUploadIds = await handleUploadImages(prod.descriptionImageIds);
        const descIds = prod.descriptionImageIds
          .filter((i: any) => i.status === 'done')
          .map((i: any) => i.uid)
          .concat(descUploadIds);
        const productInput = {
          avatarId: avtId,
          descriptionImageIds: descIds,
          detail: prod.detail,
          isActive: prod.isActive,
          isFixedCost: prod.isFixedCost,
          isNew: prod.isNew,
          name: prod.name,
          operatingNumber: prod?.operatingNumber,
          operatingUnit: prod?.operatingUnit,
          originId: prod?.originId,
          quantity: prod.quantity,
          type: productType,
          unitPrice: prod.unitPrice,
          productUnitId: prod.productUnitId,
        };
        if (isVehicle) {
          const vehicleInput = {
            ...productInput,
            manufacturerId: prod.manufacturerId,
            modelId: prod.modelId,
            ordinalNumber: prod?.ordinalNumber,
            serialNumber: prod?.serialNumber,
            vehicleRegistrationPlate: prod?.vehicleRegistrationPlate,
            vehicleTypeId: prod.vehicleTypeId,
            vinNumber: prod.vinNumber,
            yearOfManufacture: dayjs(prod.yearOfManufacture).year(),
          };
          isEdit
            ? updateProductVehicle({
                variables: {
                  input: { ...vehicleInput, id },
                },
              })
            : createProductVehicle({
                variables: {
                  input: vehicleInput,
                },
              });
        } else {
          const accessaryInput = {
            ...productInput,
            partId: prod.partId,
            serialNumber: prod.serialNumber,
            partNumber: prod.partNumber,
            productDevices: prod.productDevices,
          };
          isEdit
            ? updateProductAccessary({ variables: { input: { ...accessaryInput, id } } })
            : createProductAccessary({
                variables: {
                  input: accessaryInput,
                },
              });
        }
      } finally {
        // do something
      }
    },
    [
      createProductAccessary,
      createProductVehicle,
      id,
      isEdit,
      isVehicle,
      mutateAsync,
      productType,
      updateProductAccessary,
      updateProductVehicle,
    ],
  );

  return {
    form,
    modalForm,
    dropdownData,
    initValues,
    modalSelectDeviceData,
    fetching: dropDownLoading || productLoading,
    updating: vehicleCreating || vehicleUpdating || accessaryCreating || accessaryUpdating,
    imgLoading,
    onFinish,
    navigate,
    handleSubmitModal,
    setModalSelectDeviceData,
  };
}
