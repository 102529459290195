import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminGetUserBookingsQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Array<Types.Scalars['JSONObject']> | Types.Scalars['JSONObject']>;
  isActive?: Types.InputMaybe<Types.StatusEnum>;
  isApproved?: Types.InputMaybe<Types.Scalars['Boolean']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  search?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.SortInput>;
  userId: Types.Scalars['String'];
}>;

export type AdminGetUserBookingsQueryResponse = { __typename?: 'Query' } & {
  adminGetUserBookings: { __typename?: 'BookingConnection' } & {
    items?: Types.Maybe<
      Array<
        { __typename?: 'BookingEntity' } & Pick<Types.BookingEntity, 'code' | 'createdAt' | 'id' | 'status'> & {
            quotationAccepted?: Types.Maybe<{ __typename?: 'QuotationEntity' } & Pick<Types.QuotationEntity, 'total'>>;
            settlementAccepted?: Types.Maybe<
              { __typename?: 'SettlementEntity' } & Pick<Types.SettlementEntity, 'total'>
            >;
            technician?: Types.Maybe<{ __typename?: 'PartnerEntity' } & Pick<Types.PartnerEntity, 'fullname'>>;
            vehicle: { __typename?: 'VehicleEntity' } & Pick<Types.VehicleEntity, 'name'>;
            partner: { __typename?: 'PartnerEntity' } & Pick<Types.PartnerEntity, 'fullname'>;
          }
      >
    >;
    meta: { __typename?: 'BasePaginationMeta' } & Pick<
      Types.BasePaginationMeta,
      'currentPage' | 'itemCount' | 'itemsPerPage' | 'totalItems' | 'totalPages'
    >;
  };
};

export const AdminGetUserBookingsDocument = gql`
  query adminGetUserBookings(
    $filters: [JSONObject!]
    $isActive: StatusEnum
    $isApproved: Boolean
    $limit: Int
    $page: Int
    $search: String
    $sort: SortInput
    $userId: String!
  ) {
    adminGetUserBookings(
      filters: $filters
      isActive: $isActive
      isApproved: $isApproved
      limit: $limit
      page: $page
      search: $search
      sort: $sort
      userId: $userId
    ) {
      items {
        code
        createdAt
        id
        quotationAccepted {
          total
        }
        settlementAccepted {
          total
        }
        status
        technician {
          fullname
        }
        vehicle {
          name
        }
        partner {
          fullname
        }
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;
export function useAdminGetUserBookingsQuery(
  baseOptions: Apollo.QueryHookOptions<AdminGetUserBookingsQueryResponse, AdminGetUserBookingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetUserBookingsQueryResponse, AdminGetUserBookingsQueryVariables>(
    AdminGetUserBookingsDocument,
    options,
  );
}
export function useAdminGetUserBookingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminGetUserBookingsQueryResponse, AdminGetUserBookingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetUserBookingsQueryResponse, AdminGetUserBookingsQueryVariables>(
    AdminGetUserBookingsDocument,
    options,
  );
}
export type AdminGetUserBookingsQueryHookResult = ReturnType<typeof useAdminGetUserBookingsQuery>;
export type AdminGetUserBookingsLazyQueryHookResult = ReturnType<typeof useAdminGetUserBookingsLazyQuery>;
export type AdminGetUserBookingsQueryResult = Apollo.QueryResult<
  AdminGetUserBookingsQueryResponse,
  AdminGetUserBookingsQueryVariables
>;
