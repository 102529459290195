import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminUpdateNewsMutationVariables = Types.Exact<{
  input: Types.UpdateNewsInput;
}>;

export type AdminUpdateNewsMutationResponse = { __typename?: 'Mutation' } & {
  adminUpdateNews: { __typename?: 'NewsEntity' } & Pick<
    Types.NewsEntity,
    'body' | 'description' | 'id' | 'isActive' | 'mediaId' | 'title'
  > & {
      media: { __typename?: 'Media' } & Pick<
        Types.Media,
        'fullOriginalUrl' | 'fullThumbUrl' | 'id' | 'name' | 'originalUrl' | 'videoUrl'
      >;
    };
};

export const AdminUpdateNewsDocument = gql`
  mutation adminUpdateNews($input: UpdateNewsInput!) {
    adminUpdateNews(input: $input) {
      body
      description
      id
      isActive
      media {
        fullOriginalUrl
        fullThumbUrl
        id
        name
        originalUrl
        videoUrl
      }
      mediaId
      title
    }
  }
`;
export function useAdminUpdateNewsMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminUpdateNewsMutationResponse, AdminUpdateNewsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminUpdateNewsMutationResponse, AdminUpdateNewsMutationVariables>(
    AdminUpdateNewsDocument,
    options,
  );
}
export type AdminUpdateNewsMutationHookResult = ReturnType<typeof useAdminUpdateNewsMutation>;
export type AdminUpdateNewsMutationResult = Apollo.MutationResult<AdminUpdateNewsMutationResponse>;
export type AdminUpdateNewsMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateNewsMutationResponse,
  AdminUpdateNewsMutationVariables
>;
