import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminGetUserOrdersQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Array<Types.Scalars['JSONObject']> | Types.Scalars['JSONObject']>;
  isActive?: Types.InputMaybe<Types.StatusEnum>;
  isApproved?: Types.InputMaybe<Types.Scalars['Boolean']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  search?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.SortInput>;
  userId: Types.Scalars['String'];
}>;

export type AdminGetUserOrdersQueryResponse = { __typename?: 'Query' } & {
  adminGetUserOrders: { __typename?: 'OrderConnection' } & {
    items?: Types.Maybe<
      Array<
        { __typename?: 'OrderEntity' } & Pick<
          Types.OrderEntity,
          'code' | 'createdAt' | 'id' | 'note' | 'status' | 'total'
        > & {
            address?: Types.Maybe<
              { __typename?: 'OrderAddressEntity' } & Pick<
                Types.OrderAddressEntity,
                'addressDetail' | 'addressName' | 'mapAddress'
              >
            >;
            partner?: Types.Maybe<{ __typename?: 'OrderPartner' } & Pick<Types.OrderPartner, 'id' | 'fullname'>>;
            product: Array<
              { __typename?: 'OrderProductEntity' } & Pick<
                Types.OrderProductEntity,
                'avatarId' | 'id' | 'name' | 'quantity' | 'total' | 'type' | 'unitPrice'
              > & { avatar?: Types.Maybe<{ __typename?: 'Media' } & Pick<Types.Media, 'fullThumbUrl'>> }
            >;
          }
      >
    >;
    meta: { __typename?: 'BasePaginationMeta' } & Pick<
      Types.BasePaginationMeta,
      'currentPage' | 'itemCount' | 'itemsPerPage' | 'totalItems' | 'totalPages'
    >;
  };
};

export const AdminGetUserOrdersDocument = gql`
  query adminGetUserOrders(
    $filters: [JSONObject!]
    $isActive: StatusEnum
    $isApproved: Boolean
    $limit: Int
    $page: Int
    $search: String
    $sort: SortInput
    $userId: String!
  ) {
    adminGetUserOrders(
      filters: $filters
      isActive: $isActive
      isApproved: $isApproved
      limit: $limit
      page: $page
      search: $search
      sort: $sort
      userId: $userId
    ) {
      items {
        address {
          addressDetail
          addressName
          mapAddress
        }
        code
        createdAt
        id
        note
        partner {
          id
          fullname
        }
        product {
          avatarId
          avatar {
            fullThumbUrl
          }
          id
          name
          quantity
          total
          type
          unitPrice
        }
        status
        total
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;
export function useAdminGetUserOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<AdminGetUserOrdersQueryResponse, AdminGetUserOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetUserOrdersQueryResponse, AdminGetUserOrdersQueryVariables>(
    AdminGetUserOrdersDocument,
    options,
  );
}
export function useAdminGetUserOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminGetUserOrdersQueryResponse, AdminGetUserOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetUserOrdersQueryResponse, AdminGetUserOrdersQueryVariables>(
    AdminGetUserOrdersDocument,
    options,
  );
}
export type AdminGetUserOrdersQueryHookResult = ReturnType<typeof useAdminGetUserOrdersQuery>;
export type AdminGetUserOrdersLazyQueryHookResult = ReturnType<typeof useAdminGetUserOrdersLazyQuery>;
export type AdminGetUserOrdersQueryResult = Apollo.QueryResult<
  AdminGetUserOrdersQueryResponse,
  AdminGetUserOrdersQueryVariables
>;
