import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminQuotationQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type AdminQuotationQueryResponse = { __typename?: 'Query' } & {
  adminQuotation: { __typename?: 'QuotationEntity' } & Pick<
    Types.QuotationEntity,
    | 'diagnosisFee'
    | 'diagnosisNote'
    | 'estimatedCompleteAt'
    | 'id'
    | 'operatingNumber'
    | 'operatingUnit'
    | 'repairFee'
    | 'transportFee'
    | 'status'
    | 'total'
  > & {
      accessories: Array<
        { __typename?: 'QuotationAccessoryEntity' } & Pick<
          Types.QuotationAccessoryEntity,
          'id' | 'name' | 'quantity' | 'unit' | 'unitPrice'
        >
      >;
      additionalFees: Array<
        { __typename?: 'QuotationAdditionalFeeEntity' } & Pick<
          Types.QuotationAdditionalFeeEntity,
          'amount' | 'id' | 'name'
        >
      >;
      booking: { __typename?: 'BookingEntity' } & Pick<Types.BookingEntity, 'code' | 'mapAddress'> & {
          partner: { __typename?: 'PartnerEntity' } & Pick<
            Types.PartnerEntity,
            'fullname' | 'hotline' | 'mapAddress' | 'phone'
          >;
          technician?: Types.Maybe<{ __typename?: 'PartnerEntity' } & Pick<Types.PartnerEntity, 'fullname'>>;
          vehicle: { __typename?: 'VehicleEntity' } & Pick<
            Types.VehicleEntity,
            'mapAddress' | 'name' | 'operatingNumber' | 'operatingUnit' | 'vehicleRegistrationPlate' | 'vinNumber'
          >;
        };
      diagnostics: Array<
        { __typename?: 'QuotationDiagnosticEntity' } & Pick<
          Types.QuotationDiagnosticEntity,
          'id' | 'diagnosticCode' | 'expense'
        >
      >;
      user: { __typename?: 'UserEntity' } & Pick<Types.UserEntity, 'address' | 'fullname' | 'phone'>;
    };
};

export const AdminQuotationDocument = gql`
  query adminQuotation($id: String!) {
    adminQuotation(id: $id) {
      accessories {
        id
        name
        quantity
        unit
        unitPrice
      }
      additionalFees {
        amount
        id
        name
      }
      booking {
        code
        mapAddress
        partner {
          fullname
          hotline
          mapAddress
          phone
        }
        technician {
          fullname
        }
        vehicle {
          mapAddress
          name
          operatingNumber
          operatingUnit
          vehicleRegistrationPlate
          vinNumber
        }
      }
      diagnosisFee
      diagnosisNote
      diagnostics {
        id
        diagnosticCode
        expense
      }
      estimatedCompleteAt
      id
      operatingNumber
      operatingUnit
      repairFee
      transportFee
      status
      total
      user {
        address
        fullname
        phone
      }
    }
  }
`;
export function useAdminQuotationQuery(
  baseOptions: Apollo.QueryHookOptions<AdminQuotationQueryResponse, AdminQuotationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminQuotationQueryResponse, AdminQuotationQueryVariables>(AdminQuotationDocument, options);
}
export function useAdminQuotationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminQuotationQueryResponse, AdminQuotationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminQuotationQueryResponse, AdminQuotationQueryVariables>(
    AdminQuotationDocument,
    options,
  );
}
export type AdminQuotationQueryHookResult = ReturnType<typeof useAdminQuotationQuery>;
export type AdminQuotationLazyQueryHookResult = ReturnType<typeof useAdminQuotationLazyQuery>;
export type AdminQuotationQueryResult = Apollo.QueryResult<AdminQuotationQueryResponse, AdminQuotationQueryVariables>;
