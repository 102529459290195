import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
import { MediaFragmentFragmentDoc } from '../fragments/media.fragment.generated';
import { CategoryFragmentFragmentDoc } from '../fragments/category.fragment.generated';

const defaultOptions = {} as const;
export type AdminProductQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type AdminProductQueryResponse = { __typename?: 'Query' } & {
  adminProduct: { __typename?: 'ProductEntity' } & Pick<
    Types.ProductEntity,
    | 'avatarId'
    | 'createdAt'
    | 'deletedAt'
    | 'descriptionImageIds'
    | 'detail'
    | 'id'
    | 'isActive'
    | 'isFixedCost'
    | 'isNew'
    | 'name'
    | 'operatingNumber'
    | 'operatingUnit'
    | 'ordinalNumber'
    | 'partNumber'
    | 'partnerId'
    | 'quantity'
    | 'serialNumber'
    | 'type'
    | 'unitPrice'
    | 'updatedAt'
    | 'vehicleRegistrationPlate'
    | 'vinNumber'
    | 'yearOfManufacture'
  > & {
      avatar?: Types.Maybe<
        { __typename?: 'Media' } & Pick<
          Types.Media,
          | 'id'
          | 'fullOriginalUrl'
          | 'fullThumbUrl'
          | 'createdAt'
          | 'fileSize'
          | 'name'
          | 'originalUrl'
          | 'ownerId'
          | 'thumbUrl'
          | 'type'
        >
      >;
      descriptionImages: Array<
        { __typename?: 'Media' } & Pick<
          Types.Media,
          | 'id'
          | 'fullOriginalUrl'
          | 'fullThumbUrl'
          | 'createdAt'
          | 'fileSize'
          | 'name'
          | 'originalUrl'
          | 'ownerId'
          | 'thumbUrl'
          | 'type'
        >
      >;
      manufacturer?: Types.Maybe<
        { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>
      >;
      model?: Types.Maybe<
        { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>
      >;
      origin?: Types.Maybe<
        { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>
      >;
      partOfProduct?: Types.Maybe<
        { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>
      >;
      partner?: Types.Maybe<{ __typename?: 'PartnerEntity' } & Pick<Types.PartnerEntity, 'fullname'>>;
      productDevices: Array<
        { __typename?: 'ProductDevice' } & {
          manufacturer: { __typename?: 'CategoryEntity' } & Pick<
            Types.CategoryEntity,
            'id' | 'name' | 'type' | 'isActive'
          >;
          model: { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>;
          vehicleType: { __typename?: 'CategoryEntity' } & Pick<
            Types.CategoryEntity,
            'id' | 'name' | 'type' | 'isActive'
          >;
        }
      >;
      productType?: Types.Maybe<
        { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>
      >;
      productUnit?: Types.Maybe<
        { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>
      >;
    };
};

export const AdminProductDocument = gql`
  query adminProduct($id: String!) {
    adminProduct(id: $id) {
      avatarId
      avatar {
        ...MediaFragment
      }
      createdAt
      deletedAt
      descriptionImageIds
      descriptionImages {
        ...MediaFragment
      }
      detail
      id
      isActive
      isFixedCost
      isNew
      manufacturer {
        ...CategoryFragment
      }
      model {
        ...CategoryFragment
      }
      name
      operatingNumber
      operatingUnit
      ordinalNumber
      origin {
        ...CategoryFragment
      }
      partNumber
      partOfProduct {
        ...CategoryFragment
      }
      partner {
        fullname
      }
      partnerId
      productDevices {
        manufacturer {
          ...CategoryFragment
        }
        model {
          ...CategoryFragment
        }
        vehicleType {
          ...CategoryFragment
        }
      }
      productType {
        ...CategoryFragment
      }
      productUnit {
        ...CategoryFragment
      }
      quantity
      serialNumber
      type
      unitPrice
      updatedAt
      vehicleRegistrationPlate
      vinNumber
      yearOfManufacture
    }
  }
  ${MediaFragmentFragmentDoc}
  ${CategoryFragmentFragmentDoc}
`;
export function useAdminProductQuery(
  baseOptions: Apollo.QueryHookOptions<AdminProductQueryResponse, AdminProductQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminProductQueryResponse, AdminProductQueryVariables>(AdminProductDocument, options);
}
export function useAdminProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminProductQueryResponse, AdminProductQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminProductQueryResponse, AdminProductQueryVariables>(AdminProductDocument, options);
}
export type AdminProductQueryHookResult = ReturnType<typeof useAdminProductQuery>;
export type AdminProductLazyQueryHookResult = ReturnType<typeof useAdminProductLazyQuery>;
export type AdminProductQueryResult = Apollo.QueryResult<AdminProductQueryResponse, AdminProductQueryVariables>;
