import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminGetDiscountCodeQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type AdminGetDiscountCodeQueryResponse = { __typename?: 'Query' } & {
  adminGetDiscountCode: { __typename?: 'DiscountCodeEntity' } & Pick<
    Types.DiscountCodeEntity,
    | 'createdAt'
    | 'endDate'
    | 'id'
    | 'isActive'
    | 'limit'
    | 'limitPerAccount'
    | 'minOrderValue'
    | 'name'
    | 'startDate'
    | 'unit'
    | 'value'
    | 'adminId'
  > & {
      partner?: Types.Maybe<{ __typename?: 'PartnerEntity' } & Pick<Types.PartnerEntity, 'fullname'>>;
      admin?: Types.Maybe<{ __typename?: 'AdminEntity' } & Pick<Types.AdminEntity, 'fullname'>>;
      products?: Types.Maybe<
        Array<
          { __typename?: 'ProductEntity' } & Pick<
            Types.ProductEntity,
            | 'avatarId'
            | 'detail'
            | 'id'
            | 'isActive'
            | 'isFixedCost'
            | 'isNew'
            | 'name'
            | 'operatingNumber'
            | 'operatingUnit'
            | 'ordinalNumber'
            | 'partnerId'
            | 'quantity'
            | 'serialNumber'
            | 'type'
            | 'unitPrice'
            | 'vehicleRegistrationPlate'
            | 'vinNumber'
            | 'yearOfManufacture'
          > & { avatar?: Types.Maybe<{ __typename?: 'Media' } & Pick<Types.Media, 'fullThumbUrl'>> }
        >
      >;
    };
};

export const AdminGetDiscountCodeDocument = gql`
  query adminGetDiscountCode($id: String!) {
    adminGetDiscountCode(id: $id) {
      createdAt
      endDate
      id
      isActive
      limit
      limitPerAccount
      minOrderValue
      name
      startDate
      unit
      value
      partner {
        fullname
      }
      adminId
      admin {
        fullname
      }
      products {
        avatarId
        avatar {
          fullThumbUrl
        }
        detail
        id
        isActive
        isFixedCost
        isNew
        name
        operatingNumber
        operatingUnit
        ordinalNumber
        partnerId
        quantity
        serialNumber
        type
        unitPrice
        vehicleRegistrationPlate
        vinNumber
        yearOfManufacture
      }
    }
  }
`;
export function useAdminGetDiscountCodeQuery(
  baseOptions: Apollo.QueryHookOptions<AdminGetDiscountCodeQueryResponse, AdminGetDiscountCodeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetDiscountCodeQueryResponse, AdminGetDiscountCodeQueryVariables>(
    AdminGetDiscountCodeDocument,
    options,
  );
}
export function useAdminGetDiscountCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminGetDiscountCodeQueryResponse, AdminGetDiscountCodeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetDiscountCodeQueryResponse, AdminGetDiscountCodeQueryVariables>(
    AdminGetDiscountCodeDocument,
    options,
  );
}
export type AdminGetDiscountCodeQueryHookResult = ReturnType<typeof useAdminGetDiscountCodeQuery>;
export type AdminGetDiscountCodeLazyQueryHookResult = ReturnType<typeof useAdminGetDiscountCodeLazyQuery>;
export type AdminGetDiscountCodeQueryResult = Apollo.QueryResult<
  AdminGetDiscountCodeQueryResponse,
  AdminGetDiscountCodeQueryVariables
>;
