import { memo } from 'react';
import { Button, Col, Form, Input, Popconfirm, Row, Switch } from 'antd';
import { Link } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/es/table';

import { useInstructionCate } from '../hooks/use-instruction-cate';
import ActionGroup from '../../../components/group-action';
import { AppRoutes } from '../../../helpers';
import { MasterTable, PageTitle } from '../../../components';
import { serialColumnTable } from '../../../utils';

export const InstructionCateList = memo(() => {
  const { params, tableData, totalItems, fetching, updating, setParams, handleFilter, handleDelete, onEditStatus } =
    useInstructionCate({});
  const columns: ColumnType<any>[] = [
    {
      title: 'STT',
      dataIndex: 'index',
      align: 'center',
      render: (_, __, index) => serialColumnTable(index, { page: params.page, limit: params.limit }),
      width: 80,
    },
    {
      title: `Tên danh mục`,
      dataIndex: 'name',
      ellipsis: true,
    },
    {
      title: `Mô tả`,
      dataIndex: 'description',
      ellipsis: true,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'isActive',
      render: (isActive: boolean, rec: any) => (
        <Popconfirm
          title={`Bạn có chắc muốn thay đổi trạng thái không?`}
          okText="Đồng ý"
          cancelText="Huỷ bỏ"
          placement="topLeft"
          okButtonProps={{ loading: updating }}
          onConfirm={() => onEditStatus(rec)}>
          <Switch checked={isActive} size="default" />
        </Popconfirm>
      ),
      align: 'center',
      width: 90,
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'id',
      render: (id: string) => (
        <ActionGroup
          urlDetail={AppRoutes.instructionCateDetailId}
          urlEdit={AppRoutes.instructionCateEditId}
          id={id}
          onDelete={handleDelete}
        />
      ),
      align: 'center',
      width: 170,
    },
  ];
  return (
    <div>
      <PageTitle title={`Danh sách Danh mục hướng dẫn`} />
      <div>
        <Form size="small" onFinish={handleFilter}>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item name="search" normalize={(e) => e.trimStart()}>
                <Input placeholder="Tìm kiếm theo tên..." addonBefore={<SearchOutlined />} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Áp dụng
                </Button>
              </Form.Item>
            </Col>
            <Col offset={10} span={2} className="flex space-x-4">
              <Link to={AppRoutes.instructionCateCreate}>
                <Button type="primary">Tạo mới</Button>
              </Link>
            </Col>
          </Row>
        </Form>
      </div>
      <div>
        <MasterTable
          filter={params}
          setFilter={setParams}
          loading={fetching}
          columns={columns}
          data={tableData}
          total={totalItems}
        />
      </div>
    </div>
  );
});
