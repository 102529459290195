import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminGetGuideQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type AdminGetGuideQueryResponse = { __typename?: 'Query' } & {
  adminGetGuide: { __typename?: 'GuideEntity' } & Pick<Types.GuideEntity, 'description' | 'id' | 'isActive' | 'name'>;
};

export const AdminGetGuideDocument = gql`
  query adminGetGuide($id: String!) {
    adminGetGuide(id: $id) {
      description
      id
      isActive
      name
    }
  }
`;
export function useAdminGetGuideQuery(
  baseOptions: Apollo.QueryHookOptions<AdminGetGuideQueryResponse, AdminGetGuideQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetGuideQueryResponse, AdminGetGuideQueryVariables>(AdminGetGuideDocument, options);
}
export function useAdminGetGuideLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminGetGuideQueryResponse, AdminGetGuideQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetGuideQueryResponse, AdminGetGuideQueryVariables>(AdminGetGuideDocument, options);
}
export type AdminGetGuideQueryHookResult = ReturnType<typeof useAdminGetGuideQuery>;
export type AdminGetGuideLazyQueryHookResult = ReturnType<typeof useAdminGetGuideLazyQuery>;
export type AdminGetGuideQueryResult = Apollo.QueryResult<AdminGetGuideQueryResponse, AdminGetGuideQueryVariables>;
