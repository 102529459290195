import React, { memo, useCallback, useMemo, useState } from 'react';
import { Rate, Tabs } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { MasterTable, PageTitle } from '../../components';
import { serialColumnTable } from '../../utils';
import ActionGroup from '../../components/group-action';
import { AppRoutes } from '../../helpers';
import { useAdminGetReviewsQuery } from '../../graphql/queries/adminGetReviews.generated';
import { ReviewTypeEnum } from '../../graphql/type.interface';

import { FormReviewFilter } from './components';

export const ReviewTabs = [
  {
    key: ReviewTypeEnum.CLIENT_TECHNICIAN,
    label: 'KTV',
  },
  {
    key: ReviewTypeEnum.CLIENT_AGENCY,
    label: 'Đại lý',
  },
  {
    key: ReviewTypeEnum.TECHNICIAN_CLIENT,
    label: 'Khách hàng',
  },
];

const Review = memo(() => {
  const [activeTab, setActiveTab] = useState(ReviewTabs[0].key);
  const [filter, setFilter] = useState({
    limit: 10,
    page: 1,
    type: activeTab,
  });

  const { data, loading, refetch } = useAdminGetReviewsQuery({ variables: filter, fetchPolicy: 'cache-and-network' });
  const reviews = useMemo(() => data?.adminGetReviews?.items, [data]);

  const handleFilter = useCallback(
    (values: any) => {
      setFilter({
        ...filter,
        ...values,
        page: 1,
      });
    },
    [filter],
  );

  const handleChangeTab = useCallback(
    (key: string) => {
      const newTab = key as ReviewTypeEnum;
      setActiveTab(newTab);
      refetch({ ...filter, type: newTab, page: 1 });
    },
    [filter, refetch],
  );

  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: 'STT',
        dataIndex: 'key',
        align: 'center',
        width: '5%',
        render: (_, __, index) => serialColumnTable(index, filter),
      },
      {
        title: 'Nội dung đánh giá ',
        dataIndex: 'comment',
        align: 'center',
        ellipsis: true,
      },
      {
        title: 'Số sao',
        dataIndex: 'star',
        align: 'center',
        render: (value) => <Rate allowHalf defaultValue={value} disabled />,
      },
      {
        title: 'Người đánh giá ',
        dataIndex: 'userAssessor',
        align: 'center',
        ellipsis: true,
        render: (user, record) => {
          if (activeTab === ReviewTypeEnum.TECHNICIAN_CLIENT) {
            return record?.partnerAssessor?.fullname;
          }
          return user?.fullname;
        },
      },
      {
        title: 'Tùy chọn',
        dataIndex: 'id',
        align: 'center',
        width: '7%',
        render: (id) => <ActionGroup urlDetail={AppRoutes.reviewManagementDetailId} id={id} />,
      },
    ],
    [activeTab, filter],
  );

  return (
    <div>
      <PageTitle title={'Danh sách Đánh giá'} />
      <FormReviewFilter onFinish={handleFilter} />
      <Tabs type="card" items={ReviewTabs} onChange={handleChangeTab} activeKey={activeTab} size="small" />
      <MasterTable
        columns={columns}
        data={reviews ?? []}
        loading={loading}
        total={data?.adminGetReviews?.meta?.totalItems}
        filter={filter}
        setFilter={setFilter}
      />
    </div>
  );
});

export default Review;
