import React, { memo, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Rate, Spin } from 'antd';

import { PageTitle } from '../../components';
import { AppRoutes } from '../../helpers';
import { useAdminGetReviewQuery } from '../../graphql/queries/adminGetReview.generated';
import { ReviewTypeEnum } from '../../graphql/type.interface';

import { ReviewTabs } from '.';

const ReviewDetail = memo(() => {
  const { id = '' } = useParams();

  const { data, loading } = useAdminGetReviewQuery({ variables: { id } });
  const review = useMemo(() => data?.adminGetReview, [data]);

  if (!review) return null;
  return (
    <Spin spinning={loading}>
      <PageTitle title={'Thông tin chi tiết đánh giá'} link={AppRoutes.reviewManagement} />

      <div className="flex items-center h-[36px] my-12px">
        <div className="basis-2/12">Phân loại:</div>
        <div className="basis-8/12">{ReviewTabs.find((it) => it?.key === review?.type)?.label}</div>
      </div>
      <div className="flex items-center h-[36px] my-12px">
        <div className="basis-2/12">Nội dung :</div>
        <div className="basis-8/12">{review?.comment}</div>
      </div>
      <div className="flex items-center h-[36px] my-12px">
        <div className="basis-2/12">Số sao :</div>
        <div className="basis-8/12">
          <Rate allowHalf defaultValue={review?.star} disabled />
        </div>
      </div>
      <div className="flex items-center h-[36px] my-12px">
        <div className="basis-2/12">Người đánh giá:</div>
        <div className="basis-8/12">
          {review?.type === ReviewTypeEnum.TECHNICIAN_CLIENT
            ? review?.partnerAssessor?.fullname
            : review?.userAssessor?.fullname}
        </div>
      </div>
    </Spin>
  );
});

export default ReviewDetail;
