import { memo, useCallback, useMemo, useEffect, useRef } from 'react';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  UploadFile,
  notification,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { debounce } from 'debounce';
import dayjs from 'dayjs';

import { AppRoutes, validationMessages } from '../../helpers';
import { RegexConstant } from '../../helpers/regex';
import { UploadImage, UploadImageRef } from '../../components/upload-image';
import { PageTitle } from '../../components/page-title';
import {
  CheckTechnicianExistedQueryVariables,
  useCheckTechnicianExistedLazyQuery,
} from '../../graphql/queries/checkTechnicianExisted.generated';
import { useCategoriesQuery } from '../../graphql/queries/categories.generated';
import { CategoryEntity, CategoryTypeEnum, PartnerTypeEnum, StatusEnum } from '../../graphql/type.interface';
import { notificationMessages } from '../../helpers/notification-messages';
import SearchAddress, { Address } from '../../components/seach-address';
import { useAdminCreateTechnicianMutation } from '../../graphql/mutations/adminCreateTechnician.generated';
import { useAdminAgenciesQuery } from '../../graphql/queries/adminAgencies.generated';
import { useAdminTechnicianQuery } from '../../graphql/queries/adminTechnician.generated';
import { useAdminUpdateTechnicianMutation } from '../../graphql/mutations/adminUpdateTechnician.generated';
import { trimObj } from '../../utils';

type TechnicianSubmit = {
  avatarId: string;
  birthday: string;
  parentId: string;
  addressMoreInfo: string;
  email: string;
  fullname: string;
  phone: string;
  password: string;
  address: Address;
  description?: string;
  id: string;
  citizenId: string;
  bank: string;
  cardNumber: string;
  qualifications: string[];
  level?: string;
  suggestionPoint?: number;
};

const FormTechnician = memo(({ isEdit }: { isEdit?: boolean }) => {
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const [form] = Form.useForm();
  const ref = useRef<UploadImageRef>(null);

  const param = {
    limit: 1000,
    filters: null,
    isActive: StatusEnum.ACTIVE,
    search: '',
    isApproved: null,
  };
  const { data: rs, loading } = useAdminTechnicianQuery({ variables: { id }, skip: !id });
  const data = useMemo(() => (rs ? rs?.adminTechnician : undefined), [rs]);

  const { loading: loadingAgencies, data: selectAgenciesData } = useAdminAgenciesQuery({
    variables: param,
  });

  const agencies = useMemo(() => selectAgenciesData?.adminAgencies?.items ?? [], [selectAgenciesData]);

  const { data: dataLevel, loading: loadingLevel } = useCategoriesQuery({
    variables: { ...param, type: CategoryTypeEnum.LEVEL },
    fetchPolicy: 'cache-and-network',
  });

  const levels = useMemo(
    () =>
      dataLevel?.categories?.items
        ? dataLevel?.categories?.items.map((item) => ({ label: item.name, value: item.id }))
        : [],
    [dataLevel],
  );

  const { data: dataQualifications } = useCategoriesQuery({
    variables: { ...param, type: CategoryTypeEnum.QUALIFICATION },
    fetchPolicy: 'cache-and-network',
  });
  const qualifications = useMemo(() => dataQualifications?.categories?.items || [], [dataQualifications]);

  const { data: dataEducation, loading: loadingEducation } = useCategoriesQuery({
    variables: { ...param, type: CategoryTypeEnum.EDUCATION },
    fetchPolicy: 'cache-and-network',
  });
  const educations = useMemo(
    () =>
      dataEducation?.categories?.items
        ? dataEducation?.categories?.items.map((item) => ({ label: item.name, value: item.id }))
        : [],
    [dataEducation],
  );

  const [technicianExisted] = useCheckTechnicianExistedLazyQuery();
  const [createTechnician, { loading: creating }] = useAdminCreateTechnicianMutation({
    onError(err) {
      notification.error({
        message: notificationMessages.create.fail,
        description: err.message,
      });
    },
    onCompleted() {
      notification.success({ message: notificationMessages.create.success });
      navigate(-1);
    },
  });
  const [updateTechnician, { loading: updatting }] = useAdminUpdateTechnicianMutation({
    onError(err) {
      notification.error({
        message: notificationMessages.update.fail,
        description: err.message,
      });
    },
    onCompleted() {
      notification.success({ message: notificationMessages.update.success });
      navigate(-1);
    },
  });
  const onFinish = useCallback(
    async (values: TechnicianSubmit) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const avatarId = await ref?.current?.upload();

      const { id, address, password: _pw, phone, ...rest } = trimObj(values);
      if (isEdit) {
        updateTechnician({
          variables: {
            input: { ...rest, id, ...address, parentId: rest.parentId ?? undefined, avatarId },
          },
        });
      } else {
        createTechnician({
          variables: { input: { ...rest, phone, ...address, avatarId } },
        });
      }
    },
    [createTechnician, updateTechnician, isEdit],
  );

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        birthday: data?.birthday ? dayjs(data?.birthday) : null,
        qualifications: (data?.qualifications || []).map((qualification) => qualification.id),
        avatarId: data?.avatar?.id,
        education: data?.education?.id,
        level: data?.level?.id,
        address: {
          mapAddress: data?.mapAddress,
          latitude: data?.latitude,
          longitude: data?.longitude,
        },
      });
      return;
    }
  }, [data, form]);

  const debouncedTechnicianExisted = debounce(
    async (fieldName: string, value: any) => {
      const variables: CheckTechnicianExistedQueryVariables = { [fieldName]: value };
      const res = await technicianExisted({ variables });
      if (res.data?.checkTechnicianExisted) {
        const messageErrUsed =
          fieldName === 'email'
            ? validationMessages.email.used
            : fieldName === 'phone'
            ? validationMessages.phoneNumber.used
            : validationMessages.nationalIdentityNumber.used;
        throw new Error(messageErrUsed);
      }
    },
    500, // 500ms delay before making API call
  );

  const validateField = async (rule: any, value: any, fieldName: string) => {
    if (!value) {
      const messageErrRequired =
        fieldName === 'email'
          ? validationMessages.email.required
          : fieldName === 'phone'
          ? validationMessages.phoneNumber.required
          : validationMessages.nationalIdentityNumber.required;
      throw new Error(messageErrRequired);
    }
    if (fieldName === 'email' && !RegexConstant.EMAIL.test(value)) {
      throw new Error(validationMessages.email.notValid);
    }
    if (fieldName === 'phone' && !RegexConstant.PHONE.test(value)) {
      throw new Error(validationMessages.phoneNumber.notValid);
    }
    if (fieldName === 'citizenId' && !RegexConstant.CITIZEN_ID.test(value)) {
      throw new Error(validationMessages.citizenId.notValid);
    }
    debouncedTechnicianExisted(fieldName, value);
  };
  const isLoading = useMemo(() => creating || updatting || loading, [creating, loading, updatting]);

  return (
    <>
      <PageTitle
        link={AppRoutes.technicianManagement}
        title={isEdit ? 'Cập nhật thông tin KTV' : 'Thêm mới Kỹ Thuật viên'}
      />
      <div>
        <Form
          labelCol={{ span: 5 }}
          labelAlign="left"
          wrapperCol={{ span: 15 }}
          size="small"
          onFinish={onFinish}
          initialValues={{
            password: 'Callme@2023',
          }}
          autoComplete="off"
          form={form}>
          <span className="text-20px font-semibold">Thông tin cá nhân</span>
          <Form.Item label="Ảnh đại diện" name="avatarId">
            <UploadImage
              ref={ref}
              files={
                data?.avatar?.fullThumbUrl
                  ? ([
                      {
                        uid: data?.avatar?.id,
                        name: data?.avatar?.name,
                        status: 'done',
                        url: data?.avatar?.fullThumbUrl,
                      },
                    ] as UploadFile[])
                  : undefined
              }
            />
          </Form.Item>
          <Form.Item
            label={
              <span>
                Họ và tên<span className="text-red"> *</span>
              </span>
            }
            name="fullname"
            normalize={(e) => e.trimStart()}
            rules={[{ required: true, message: validationMessages.firstAndLastNameRequired }]}>
            <Input placeholder="Nhập họ tên của KTV" maxLength={255} />
          </Form.Item>
          <Form.Item
            label={
              <span>
                Ngày sinh<span className="text-red"> *</span>
              </span>
            }
            name="birthday"
            rules={[{ required: true, message: validationMessages.birthDay }]}>
            <DatePicker
              placeholder="DD/MM/YYYY"
              format="DD/MM/YYYY"
              className="w-full"
              disabledDate={(current) => current && current > dayjs()}
            />
          </Form.Item>
          <Form.Item
            label={
              <span>
                Email<span className="text-red"> *</span>
              </span>
            }
            name="email"
            required
            normalize={(e) => e.trimStart()}
            rules={[{ validator: (rule, value) => validateField(rule, value, 'email') }]}>
            <Input maxLength={255} placeholder={validationMessages.email.placeholder} />
          </Form.Item>
          <Form.Item
            label={
              <span>
                Số điện thoại<span className="text-red"> *</span>
              </span>
            }
            name="phone"
            required
            normalize={(text: string) => text.replace(/[^0-9]/g, '')}
            rules={[{ validator: (rule, value) => validateField(rule, value, 'phone') }]}>
            <Input maxLength={10} placeholder={validationMessages.phoneNumber.placeholder} className="w-full" />
          </Form.Item>
          <Form.Item label="Mật khẩu mặc định" name="password">
            <Input disabled />
          </Form.Item>
          <Form.Item label={<p>Điểm đề xuất bởi call me</p>} name="suggestionPoint">
            <InputNumber
              className="w-full"
              placeholder={`Điểm càng cao sẽ quyết định vị trí xuất hiện trong danh sách ktv/agency khi người dùng chọn đơn vị sửa chữa`}
              maxLength={255}
            />
          </Form.Item>
          <Form.Item
            name="address"
            label={
              <span>
                Địa chỉ<span className="text-red"> *</span>
              </span>
            }
            required
            rules={[
              {
                validator: async (_, values: string[]) => {
                  if (!values) {
                    return Promise.reject(validationMessages.address.required);
                  }
                },
              },
            ]}>
            <SearchAddress
              address={{
                mapAddress: data?.mapAddress,
                latitude: data?.latitude,
                longitude: data?.longitude,
              }}
              loading={isLoading}
            />
          </Form.Item>

          <Form.Item
            label={
              <span>
                Địa chỉ cụ thể<span className="text-red"> *</span>
              </span>
            }
            name="addressMoreInfo"
            normalize={(e) => e.trimStart()}
            rules={[
              {
                required: true,
                message: validationMessages.address.specific,
              },
            ]}>
            <Input maxLength={255} placeholder={validationMessages.address.placeholder} />
          </Form.Item>
          <Form.Item
            label={
              <span>
                Đại lý trực thuộc<span className="text-red"> *</span>
              </span>
            }
            name="parentId"
            rules={[
              {
                required: data == null || data.type !== PartnerTypeEnum.FREELANCER_TECHNICIAN,
                message: validationMessages.agency.required,
              },
            ]}>
            <Select
              disabled={data?.type === PartnerTypeEnum.FREELANCER_TECHNICIAN}
              placeholder={validationMessages.agency.placeholder}
              showSearch
              loading={loadingAgencies}
              defaultActiveFirstOption={false}
              options={(agencies ?? []).map((it) => ({
                label: it.fullname,
                value: it.id,
              }))}
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item
            label={
              <span>
                Chuyên môn<span className="text-red"> *</span>
              </span>
            }
            name="qualifications"
            rules={[
              {
                required: true,
                message: validationMessages.expertiseSkill,
              },
            ]}>
            <Checkbox.Group>
              <Row>
                {qualifications.map((item: CategoryEntity) => (
                  <Col span={8} key={item.id}>
                    <Checkbox value={item.id}>{item.name}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item
            label={
              <span>
                Level<span className="text-red"> *</span>
              </span>
            }
            name="level"
            rules={[
              {
                required: true,
                message: validationMessages.level.required,
              },
            ]}>
            <Select placeholder={validationMessages.level.placeholder} options={levels} loading={loadingLevel} />
          </Form.Item>
          <Form.Item label="Trình độ học vấn" name="education">
            <Select placeholder="Chọn trình độ học vấn" options={educations} loading={loadingEducation} />
          </Form.Item>
          <Form.Item
            label={
              <span>
                Số CMND/CCCD<span className="text-red"> *</span>
              </span>
            }
            name="citizenId"
            required
            normalize={(text: string) => text.replace(/[^0-9]/g, '')}
            rules={[
              {
                validator: (rule, value) => validateField(rule, value, 'citizenId'),
              },
            ]}>
            <Input
              minLength={9}
              maxLength={12}
              className="w-full"
              placeholder={validationMessages.citizenId.placeholder}
            />
          </Form.Item>
          <span className="text-20px font-semibold">Thông tin ngân hàng</span>
          <Form.Item
            label={
              <span>
                Ngân hàng <span className="text-error">*</span>
              </span>
            }
            name="bank"
            normalize={(e) => e.trimStart()}
            rules={[{ required: true, message: validationMessages.required }]}>
            <Input maxLength={255} placeholder={validationMessages.bank.placeholder} />
          </Form.Item>
          <Form.Item
            label={
              <span>
                Số thẻ <span className="text-error">*</span>
              </span>
            }
            name="cardNumber"
            normalize={(text: string) => text.replace(/[^0-9]/g, '')}
            rules={[
              { required: true, message: validationMessages.required },
              {
                pattern: RegexConstant.BANK_CARD_NUMBER,
                message: validationMessages.bank.cardNumberNotValid,
              },
            ]}>
            <Input minLength={10} maxLength={19} placeholder={validationMessages.cardNumber.placeholder} />
          </Form.Item>
          <Form.Item name="id">
            <Input className="hidden" />
          </Form.Item>
          <Row>
            <Col span={20} className="flex justify-end space-x-4">
              <Button className="w-20" type="default" onClick={() => navigate(-1)}>
                Huỷ
              </Button>
              <Button className="w-20" type="primary" htmlType="submit" loading={isLoading} disabled={isLoading}>
                Lưu
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
});
export default FormTechnician;
