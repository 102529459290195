import React, { memo } from 'react';

import { MasterDataTable } from '../../components';
import { CategoryTypeEnum } from '../../graphql/type.interface';
import { AppRoutes } from '../../helpers';
const EducationManagement = memo(() => {
  return (
    <MasterDataTable
      type={CategoryTypeEnum.EDUCATION}
      title="Trình độ học vấn"
      urlCreate={AppRoutes.educationCreate}
      urlEdit={AppRoutes.educationEditId}
    />
  );
});

export default EducationManagement;
