import { Col, Descriptions, Row, Spin, Image } from 'antd';
import React, { memo, useMemo } from 'react';
import dayjs from 'dayjs';

import { useAdminGetVehicleByIdQuery } from '../../graphql/queries/adminGetVehicleById.generated';
import { useAdminGetNewestMaintenanceByVehicleIdQuery } from '../../graphql/queries/adminGetNewestMaintenanceByVehicleId.generated';
import { DATE_FORMAT } from '../../constants';

type DetailVehiclePropType = {
  id: string;
};

const DetailVehicle = memo(({ id }: DetailVehiclePropType) => {
  const { data: vehicleData, loading: vehicleLoading } = useAdminGetVehicleByIdQuery({
    skip: !id,
    variables: { id },
  });
  const { data: newestMaintenanceData, loading: newestMaintenanceLoading } =
    useAdminGetNewestMaintenanceByVehicleIdQuery({
      skip: !id,
      variables: { id },
    });

  const detailVehicleData = useMemo(() => vehicleData?.adminGetVehicleById, [vehicleData]);
  const detailNewestMaintenanceData = useMemo(
    () => newestMaintenanceData?.adminGetNewestMaintenanceByVehicleId,
    [newestMaintenanceData],
  );

  return (
    <Spin spinning={vehicleLoading && newestMaintenanceLoading}>
      <Row>
        <Col span={12}>
          <Descriptions column={1} labelStyle={{ width: '150px' }}>
            <Descriptions.Item label="Ảnh đại diện">
              <Image width={200} src={detailVehicleData?.avatar?.fullOriginalUrl as string} />
            </Descriptions.Item>
            <Descriptions.Item label="Tên máy">{detailVehicleData?.name}</Descriptions.Item>
            <Descriptions.Item label="Biển số">{detailVehicleData?.vehicleRegistrationPlate}</Descriptions.Item>
            <Descriptions.Item label="Chủng loại máy">{detailVehicleData?.vehicleType?.name}</Descriptions.Item>
            <Descriptions.Item label="Hãng sản xuất">{detailVehicleData?.manufacturer?.name}</Descriptions.Item>
            <Descriptions.Item label="Model">{detailVehicleData?.model?.name}</Descriptions.Item>
            <Descriptions.Item label="Số seri">{detailVehicleData?.serialNumber}</Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={12}>
          <Descriptions column={1} labelStyle={{ width: '220px' }}>
            <Descriptions.Item label="Số VIN">{detailVehicleData?.vinNumber}</Descriptions.Item>
            <Descriptions.Item label="Xuất xứ">{detailVehicleData?.origin?.name}</Descriptions.Item>
            <Descriptions.Item label="Năm sản xuất">{detailVehicleData?.yearOfManufacture}</Descriptions.Item>
            <Descriptions.Item label="Số giờ vận hành">{detailVehicleData?.operatingNumber}</Descriptions.Item>
            <Descriptions.Item label="Ngày nhận xe">
              {dayjs(detailVehicleData?.createdAt).format(DATE_FORMAT)}
            </Descriptions.Item>
            <Descriptions.Item label="Ngày bảo dưỡng gần nhất">
              {dayjs(detailNewestMaintenanceData?.createdAt).format(DATE_FORMAT)}
            </Descriptions.Item>
            <Descriptions.Item label="Địa điểm đặt máy">{detailVehicleData?.mapAddress}</Descriptions.Item>
            <Descriptions.Item label="Chi tiết">Số seri</Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </Spin>
  );
});

export default DetailVehicle;
