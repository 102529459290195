import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminGetNewsByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type AdminGetNewsByIdQueryResponse = { __typename?: 'Query' } & {
  adminGetNewsById: { __typename?: 'NewsEntity' } & Pick<
    Types.NewsEntity,
    'body' | 'id' | 'isActive' | 'description' | 'mediaId' | 'title'
  > & {
      media: { __typename?: 'Media' } & Pick<
        Types.Media,
        'fullOriginalUrl' | 'fullThumbUrl' | 'id' | 'name' | 'originalUrl' | 'thumbUrl' | 'type' | 'videoUrl'
      >;
    };
};

export const AdminGetNewsByIdDocument = gql`
  query adminGetNewsById($id: String!) {
    adminGetNewsById(id: $id) {
      body
      id
      isActive
      description
      media {
        fullOriginalUrl
        fullThumbUrl
        id
        name
        originalUrl
        thumbUrl
        type
        videoUrl
      }
      mediaId
      title
    }
  }
`;
export function useAdminGetNewsByIdQuery(
  baseOptions: Apollo.QueryHookOptions<AdminGetNewsByIdQueryResponse, AdminGetNewsByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetNewsByIdQueryResponse, AdminGetNewsByIdQueryVariables>(
    AdminGetNewsByIdDocument,
    options,
  );
}
export function useAdminGetNewsByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminGetNewsByIdQueryResponse, AdminGetNewsByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetNewsByIdQueryResponse, AdminGetNewsByIdQueryVariables>(
    AdminGetNewsByIdDocument,
    options,
  );
}
export type AdminGetNewsByIdQueryHookResult = ReturnType<typeof useAdminGetNewsByIdQuery>;
export type AdminGetNewsByIdLazyQueryHookResult = ReturnType<typeof useAdminGetNewsByIdLazyQuery>;
export type AdminGetNewsByIdQueryResult = Apollo.QueryResult<
  AdminGetNewsByIdQueryResponse,
  AdminGetNewsByIdQueryVariables
>;
