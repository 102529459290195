import { useState, useCallback, useMemo } from 'react';
import { notification } from 'antd';

import { ALL_PARAMS, LIST_PARAMS, Messages } from '../../../constants';
import { useAdminGetGuidesQuery } from '../../../graphql/queries/adminGetGuides.generated';
import { useAdminUpdateGuideStatusMutation } from '../../../graphql/mutations/adminUpdateGuideStatus.generated';
import { useAdminDeleteGuideMutation } from '../../../graphql/mutations/adminDeleteGuide.generated';
import { StatusEnum } from '../../../graphql/type.interface';

export function useInstructionCate({ getAll = false }: { getAll?: boolean }) {
  const [params, setParams] = useState(getAll ? { ...ALL_PARAMS, isActive: StatusEnum.ACTIVE } : LIST_PARAMS);

  const {
    data: guidesData,
    loading: guidesLoading,
    refetch,
  } = useAdminGetGuidesQuery({
    variables: params,
    fetchPolicy: 'cache-and-network',
  });

  const tableData = useMemo(() => guidesData?.adminGetGuides.items ?? [], [guidesData?.adminGetGuides.items]);

  const [updateStatus, { loading: updating }] = useAdminUpdateGuideStatusMutation({
    onError(error) {
      notification.error({
        message: Messages.update.fail('trạng thái danh mục hướng dẫn'),
        description: error?.message,
      });
    },
    onCompleted() {
      notification.success({
        message: Messages.update.success('trạng thái danh mục hướng dẫn'),
      });
      refetch();
    },
  });

  const [removeGuide] = useAdminDeleteGuideMutation({
    onError(error) {
      notification.error({
        message: Messages.delete.fail('danh mục hướng dẫn'),
        description: error?.message,
      });
    },
    onCompleted() {
      notification.success({
        message: Messages.delete.success('danh mục hướng dẫn'),
      });
      refetch();
    },
  });

  const handleFilter = useCallback(
    (values: any) => {
      setParams({ ...params, page: 1, ...values });
    },
    [params],
  );

  const handleDelete = useCallback(
    (id: string) => {
      removeGuide({ variables: { input: { id } } });
    },
    [removeGuide],
  );

  const onEditStatus = useCallback(
    (data: any) => updateStatus({ variables: { input: { id: data.id, isActive: !data.isActive } } }),
    [updateStatus],
  );

  return {
    params,
    tableData,
    totalItems: guidesData?.adminGetGuides.meta.totalItems,
    fetching: guidesLoading,
    updating,
    setParams,
    handleFilter,
    handleDelete,
    onEditStatus,
  };
}
