import { memo, useCallback, useMemo, useState } from 'react';
import { Button, Col, Form, Input, notification, Popconfirm, Row, Select, Spin, Switch, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';

import { PageTitle } from '../../components';
import { AppRoutes } from '../../helpers';
import { DefaultPagination } from '../../utils/pagination';
import ActionGroup from '../../components/group-action';
import { useAdminAgenciesQuery } from '../../graphql/queries/adminAgencies.generated';
import { useAdminUpdateAgencyStatusMutation } from '../../graphql/mutations/adminUpdateAgencyStatus.generated';
import { useAdminRemoveAgencyMutation } from '../../graphql/mutations/adminRemoveAgency.generated';
import { LIST_PARAMS } from '../../constants';
import { downloadExcel, serialColumnTable, showNotification } from '../../utils';
import { useAdminExportAgenciesLazyQuery } from '../../graphql/queries/adminExportAgencies.generated';

type RowData = {
  id: string;
  name: string;
  email: string;
  address: string;
  phone: string;
  hottline: string;
  isActive: boolean;
};
type Filter = {
  search?: string;
  isActive?: boolean;
};

const DealerManagement = memo(() => {
  const navigate = useNavigate();

  const [filter, setFilter] = useState(LIST_PARAMS);

  const { data, loading, refetch } = useAdminAgenciesQuery({ variables: filter, fetchPolicy: 'cache-and-network' });
  const agencies = useMemo(
    () => (data?.adminAgencies?.items ? data?.adminAgencies?.items.map((item) => ({ ...item, key: item.id })) : []),
    [data?.adminAgencies?.items],
  );

  const [updateAngencyStatus, { loading: updating }] = useAdminUpdateAgencyStatusMutation({
    onError(err) {
      notification.error({ message: err.message });
    },
    onCompleted() {
      refetch(filter);
    },
  });

  const [removeAgency, { loading: removing }] = useAdminRemoveAgencyMutation({
    onError(err) {
      notification.error({ message: err.message });
    },
    onCompleted() {
      notification.success({ message: 'Xóa đại lý thành công!' });
      refetch(filter);
    },
  });

  const handleFilter = useCallback(
    (values: Filter) => {
      setFilter({ ...filter, page: 1, ...values });
    },
    [filter],
  );

  const handleDelete = useCallback(
    (id: string) => {
      removeAgency({ variables: { id } });
    },
    [removeAgency],
  );

  const onEditStatus = useCallback(
    (data: RowData) => {
      const { id, isActive } = data;
      updateAngencyStatus({ variables: { input: { id, isActive: !isActive } } });
    },
    [updateAngencyStatus],
  );

  const onChangePage = useCallback(
    (newPage: number, pageSize: number) => {
      setFilter({ ...filter, page: newPage, limit: pageSize });
    },
    [filter],
  );

  const [adminExportAgenciesMutation, { loading: exporting }] = useAdminExportAgenciesLazyQuery({
    onCompleted(data) {
      downloadExcel(data?.adminExportAgencies, 'Danh-Sach-Dai-Ly');
    },
    onError(error) {
      showNotification('error', 'Tải xuống thất bại', error?.message);
    },
  });

  const handleExport = useCallback(() => {
    adminExportAgenciesMutation();
  }, [adminExportAgenciesMutation]);

  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      key: 'key',
      dataIndex: 'key',
      align: 'center',
      width: 80,
      render: (_, __, index) => serialColumnTable(index, filter),
    },
    {
      title: `Tên đại lý`,
      dataIndex: 'fullname',
      key: 'fullname',
      ellipsis: true,
    },
    {
      title: `Email`,
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
    },
    {
      title: `Số điện thoại`,
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
      width: 110,
    },
    {
      title: `Hotline`,
      dataIndex: 'hotline',
      key: 'hotline',
      width: 110,
      align: 'center',
    },
    {
      title: `Địa chỉ cụ thể`,
      dataIndex: 'addressMoreInfo',
      key: 'addressMoreInfo',
      ellipsis: true,
    },
    {
      title: 'Trạng thái',
      key: 'isActive',
      render: (row) => {
        return (
          <Popconfirm
            title={`Bạn có chắc muốn cập nhật trạng thái Đại lý ?`}
            okText="Đồng ý"
            cancelText="Huỷ bỏ"
            placement="topLeft"
            onConfirm={() => onEditStatus(row)}>
            <Switch defaultChecked checked={row?.isActive} size="default" />
          </Popconfirm>
        );
      },
      align: 'center',
      width: 90,
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'id',
      render: (id: string) => (
        <ActionGroup
          urlDetail={AppRoutes.agencyDetailId}
          urlEdit={AppRoutes.agencyEditId}
          id={id}
          onDelete={handleDelete}
        />
      ),
      align: 'center',
      width: 220,
    },
  ];

  return (
    <Spin spinning={exporting}>
      <PageTitle title={'Danh sách Đại lý'} />
      <div>
        <Form size="small" onFinish={handleFilter}>
          <Row gutter={12}>
            <Col span={8}>
              <Form.Item name="search" normalize={(e) => e.trimStart()}>
                <Input
                  placeholder="Tìm kiếm theo tên, sdt, địa chỉ, email, hotline ..."
                  addonBefore={<SearchOutlined />}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="isActive">
                <Select
                  placeholder="Trạng thái"
                  options={[
                    { label: 'Tất cả', value: null },
                    { label: 'Kích hoạt', value: 'ACTIVE' },
                    { label: 'Vô hiệu', value: 'INACTIVE' },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Áp dụng
                </Button>
              </Form.Item>
            </Col>
            <Col offset={6} span={2}>
              <Form.Item>
                <Button type="primary" onClick={handleExport}>
                  Tải xuống
                </Button>
              </Form.Item>
            </Col>
            <Col span={2} className="flex space-x-4">
              <Button type="primary" onClick={() => navigate(AppRoutes.agencyCreate)}>
                Tạo mới
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      <div>
        <Table
          size="small"
          loading={loading || removing || updating}
          bordered
          columns={columns}
          dataSource={agencies}
          pagination={{
            ...DefaultPagination,
            onChange: onChangePage,
            current: Number(filter?.page),
            total: data?.adminAgencies?.meta?.totalItems,
          }}
          scroll={{ y: 'calc(100vh - 320px)' }}
        />
      </div>
    </Spin>
  );
});

export default DealerManagement;
