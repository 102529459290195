import { AppRoutes } from '../../helpers';

export const tabRouters = {
  routes: [
    {
      path: AppRoutes.dashboard,
      name: 'Trang chủ',
    },
    {
      path: AppRoutes.agencyManagement,
      name: 'Quản lý Đại lý',
    },
    {
      path: AppRoutes.technicianManagement,
      name: 'Quản lý Kỹ thuật viên',
    },
    {
      path: AppRoutes.customerManagement,
      name: 'Quản lý Khách hàng',
    },
    {
      path: AppRoutes.requestRepairManagement,
      name: 'Quản lý Yêu cầu sửa chữa',
    },
    {
      path: AppRoutes.repairServiceManagement,
      name: 'Quản lý Dịch vụ sửa chữa',
      children: [
        {
          path: AppRoutes.problemManagement,
          name: 'Quản lý Hiện tượng hư hỏng',
        },
      ],
    },
    {
      path: AppRoutes.maintenanceManagement,
      name: 'Quản lý Bảo hành, Bảo dưỡng',
      children: [
        {
          path: AppRoutes.listMaintenanceManagement,
          name: 'Danh sách yêu cầu bảo dưỡng',
        },
        {
          path: AppRoutes.vehicleTypeManagement,
          name: 'Hạng mục và vật tư bảo dưỡng',
        },
      ],
    },
    {
      path: AppRoutes.productCategoryManagement,
      name: 'Quản lý Sản phẩm',
      children: [
        {
          path: AppRoutes.productManufacturerManagement,
          name: 'Quản lý Hãng sản xuất',
        },
        {
          path: AppRoutes.productModelManagement,
          name: 'Quản lý Model',
        },
        {
          path: AppRoutes.productOriginManagement,
          name: 'Quản lý Xuất xứ',
        },
        {
          path: AppRoutes.productManagement,
          name: 'Quản lý Sản phẩm',
        },
        {
          path: AppRoutes.vehicleManagement,
          name: 'Quản lý Chủng loại',
        },
        {
          path: AppRoutes.partOfProductManagement,
          name: 'Quản lý Bộ phận thiết bị',
        },
        {
          path: AppRoutes.productUnitManagement,
          name: 'Quản lý Đơn vị tồn kho',
        },
      ],
    },
    {
      path: AppRoutes.categoryManagement,
      name: 'Quản lý Danh mục',
      children: [
        {
          path: AppRoutes.educationManagement,
          name: 'Quản lý Trình độ học vấn',
        },
        {
          path: AppRoutes.levelManagement,
          name: 'Quản lý Level',
        },
        {
          path: AppRoutes.qualificationManagement,
          name: 'Quản lý Trình độ chuyên môn',
        },
        {
          path: AppRoutes.cancelRequestByUserManagement,
          name: 'Lý do hủy YCSC của người dùng',
        },
        {
          path: AppRoutes.cancelRequestByPartnerManagement,
          name: 'Lý do hủy YCSC của đối tác',
        },
        {
          path: AppRoutes.cancelQuotationManagement,
          name: 'Lý do hủy báo giá',
        },
        {
          path: AppRoutes.cancelOrderByUserManagement,
          name: 'Lý do hủy đơn hàng của người mua',
        },
        {
          path: AppRoutes.cancelOrderByPartnerManagement,
          name: 'Lý do hủy đơn hàng của người bán',
        },
      ],
    },
    {
      path: AppRoutes.discountManagement,
      name: 'Quản lý Khuyến mãi, Mã giảm giá',
    },
    {
      path: AppRoutes.orderCategoryManagement,
      name: 'Quản lý Đơn hàng',
      children: [
        {
          path: AppRoutes.productQuotationManagement,
          name: 'Báo giá',
        },
        {
          path: AppRoutes.orderManagement,
          name: 'Đơn hàng',
        },
      ],
    },
    {
      path: AppRoutes.pricingManagement,
      name: 'Quản lý Bảng giá',
    },
    {
      path: AppRoutes.quotationManagement,
      name: 'Quản lý Báo giá, Quyết toán',
    },
    {
      path: AppRoutes.invoiceManagement,
      name: 'Quản lý Hóa đơn',
    },
    {
      path: AppRoutes.reviewManagement,
      name: 'Quản lý Đánh giá',
    },
    {
      path: AppRoutes.notificationManagement,
      name: 'Quản lý Thông báo',
    },
    {
      path: AppRoutes.newsManagement,
      name: 'Quản lý Tin tức',
    },
    {
      path: AppRoutes.courseManagement,
      name: 'Quản lý Khóa học',
    },
    {
      path: AppRoutes.documentKey,
      name: 'Quản lý Tài liệu tham khảo',
      children: [
        {
          path: AppRoutes.referenceManagement,
          name: 'Danh mục tài liệu tham khảo',
        },
        {
          path: AppRoutes.documentManagement,
          name: 'Tài liệu tham khảo',
        },
      ],
    },
    {
      path: AppRoutes.insKey,
      name: 'Quản lý Hướng dẫn',
      children: [
        {
          path: AppRoutes.instructCateManagement,
          name: 'Danh mục hướng dẫn',
        },
        {
          path: AppRoutes.instructionManagement,
          name: 'Hướng dẫn',
        },
      ],
    },
    {
      path: AppRoutes.surveyManagement,
      name: 'Quản lý Khảo sát',
    },
    {
      path: AppRoutes.feedbackManagement,
      name: 'Quản lý Phản hồi về chất lượng dịch vụ',
    },
    {
      path: AppRoutes.setting,
      name: 'Cài đặt',
      children: [
        {
          path: AppRoutes.accountInfo,
          name: 'Tài khoản',
        },
        {
          path: AppRoutes.stallManagement,
          name: 'Gian hàng',
        },
      ],
    },
    {
      path: AppRoutes.report,
      name: 'Báo cáo',
    },
  ],
};
