import { ReactNode, memo } from 'react';

type Props = {
  title?: string | ReactNode;
  children?: ReactNode;
};

export const SectionWrapper = memo(({ title, children }: Props) => {
  return (
    <div className="w-full h-fit p-20px bg-white">
      {title && <h3 className="text-16px font-semibold uppercase mb-20px">{title}</h3>}
      <div className="w-full flex flex-col space-y-20px">{children}</div>
    </div>
  );
});
