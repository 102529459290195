import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminRemoveAgencyMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>;
}>;

export type AdminRemoveAgencyMutationResponse = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'adminRemoveAgency'>;

export const AdminRemoveAgencyDocument = gql`
  mutation adminRemoveAgency($id: ID) {
    adminRemoveAgency(id: $id)
  }
`;
export function useAdminRemoveAgencyMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminRemoveAgencyMutationResponse, AdminRemoveAgencyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminRemoveAgencyMutationResponse, AdminRemoveAgencyMutationVariables>(
    AdminRemoveAgencyDocument,
    options,
  );
}
export type AdminRemoveAgencyMutationHookResult = ReturnType<typeof useAdminRemoveAgencyMutation>;
export type AdminRemoveAgencyMutationResult = Apollo.MutationResult<AdminRemoveAgencyMutationResponse>;
export type AdminRemoveAgencyMutationOptions = Apollo.BaseMutationOptions<
  AdminRemoveAgencyMutationResponse,
  AdminRemoveAgencyMutationVariables
>;
