import { memo } from 'react';
import { Button, Checkbox, Col, Form, Input, Row, Switch } from 'antd';

import { AppRoutes } from '../../helpers';
import { InputNumberFormat, PageTitle, ProgressLoading } from '../../components';
import { Messages } from '../../constants';

import { useFormQuotationPrice } from './use-form-quotation-price';

type Props = {
  isDetail?: boolean;
  isCreate?: boolean;
};

const FormPricing = memo(({ isDetail, isCreate }: Props) => {
  const { form, initialValues, fetching, loading, navigate, onFinish } = useFormQuotationPrice();

  if (fetching) return <ProgressLoading />;

  return (
    <div>
      <PageTitle
        title={
          !isDetail && !isCreate
            ? 'Chỉnh sửa thông tin bảng giá'
            : !isDetail && isCreate
            ? 'Thêm mới bảng giá'
            : 'Thông tin chi tiết bảng giá'
        }
        link={AppRoutes.pricingManagement}
      />
      <div>
        <Form
          form={form}
          labelCol={{ span: 5 }}
          labelAlign="left"
          wrapperCol={{ span: 15 }}
          size="small"
          initialValues={initialValues}
          disabled={isDetail || loading}
          onFinish={onFinish}
          autoComplete="off">
          <Form.Item name="id">
            <Input className="hidden" />
          </Form.Item>
          <Form.Item name="fixable" valuePropName="checked" label=" " colon={false}>
            <Checkbox defaultChecked={initialValues.fixable}>
              Cho phép KTV chỉnh sửa giá và thời gian dự kiến hoàn thành
            </Checkbox>
          </Form.Item>
          <Form.Item
            label={
              <span>
                Loại xe<span className="text-red"> *</span>
              </span>
            }
            name="vehicleType"
            normalize={(e) => e.trimStart()}
            rules={[{ required: true, message: Messages.required('Loại xe') }]}>
            <Input placeholder={`Nhập thông tin loại xe`} maxLength={255} />
          </Form.Item>
          <Form.Item
            label={
              <span>
                Tên phụ tùng<span className="text-red"> *</span>
              </span>
            }
            name="accessoriesName"
            normalize={(e) => e.trimStart()}
            rules={[{ required: true, message: Messages.required('Tên phụ tùng') }]}>
            <Input placeholder={`Nhập thông tin phụ tùng`} maxLength={255} />
          </Form.Item>
          <Form.Item
            label={
              <span>
                Mã chẩn đoán<span className="text-red"> *</span>
              </span>
            }
            name="diagnosticCode"
            rules={[{ required: true, message: Messages.required('Mã chẩn đoán') }]}>
            <Input placeholder={`Nhập thông tin mã chẩn đoán`} maxLength={255} />
          </Form.Item>
          <Form.Item
            label={
              <span>
                Mã công việc<span className="text-red"> *</span>
              </span>
            }
            name="workingCode"
            rules={[{ required: true, message: Messages.required('Mã công việc') }]}>
            <Input placeholder={`Nhập thông tin mã công việc`} maxLength={255} />
          </Form.Item>
          <Form.Item
            label={
              <span>
                Chi phí (VND)<span className="text-red"> *</span>
              </span>
            }
            name="expense"
            rules={[{ required: true, message: Messages.required('Chi phí') }]}>
            <InputNumberFormat style={{ width: 300 }} placeholder={`Nhập chi phí`} min={0} />
          </Form.Item>
          <Form.Item
            label={
              <span>
                Giờ công (giờ)<span className="text-red"> *</span>
              </span>
            }
            name="workingHour"
            rules={[{ required: true, message: Messages.required('Giờ công') }]}>
            <InputNumberFormat style={{ width: 300 }} placeholder={`Nhập giờ công`} min={0} />
          </Form.Item>
          <Form.Item label={'Trạng thái'} name="isActive">
            <Switch defaultChecked={initialValues.isActive} />
          </Form.Item>
          {!isDetail && (
            <Row>
              <Col span={20} className="flex justify-end space-x-4">
                <Button className="w-20" type="default" onClick={() => navigate(-1)}>
                  Huỷ
                </Button>
                <Button className="px-2 py-8" type="primary" htmlType="submit" loading={loading}>
                  Lưu
                </Button>
              </Col>
            </Row>
          )}
        </Form>
      </div>
    </div>
  );
});

export default FormPricing;
