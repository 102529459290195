import { Spin, Tabs } from 'antd';
import { memo, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { AppRoutes } from '../../helpers';
import { PageTitle } from '../../components';
import { useAdminGetSurveyQuery } from '../../graphql/queries/adminGetSurvey.generated';
import { QuestionEntity, SurveyEntity } from '../../graphql/type.interface';

import { FormReplySurvey } from './components';
import { FormSurvey } from './form-survey';

import './style.css';

type Props = {
  isDetail?: boolean;
};

export const SurveyDetail = memo(({ isDetail }: Props) => {
  const { id = '' } = useParams();

  const { data, loading } = useAdminGetSurveyQuery({ variables: { id }, fetchPolicy: 'cache-and-network', skip: !id });
  const survey = useMemo(() => data?.adminGetSurvey, [data]);

  const [tabActive, setTabActive] = useState('1');

  const onChange = useCallback((key: string) => {
    setTabActive(key);
  }, []);

  const tabs = useMemo(
    () => [
      {
        key: '1',
        label: 'Câu hỏi',
        children: <FormSurvey survey={survey as SurveyEntity} isDetail={isDetail} />,
      },
      {
        key: '2',
        label: 'Câu trả lời',
        children: <FormReplySurvey surveyId={survey?.id as string} questions={survey?.questions as QuestionEntity[]} />,
      },
    ],
    [isDetail, survey],
  );

  return (
    <Spin spinning={loading}>
      <PageTitle title={survey?.name as string} link={AppRoutes.surveyManagement} />
      <Tabs items={tabs} activeKey={tabActive} type="card" onChange={onChange} size="small" />
    </Spin>
  );
});
