import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Select } from 'antd';
import type { SelectProps } from 'antd';

import { useSearchPlacesAutocompleteLazyQuery } from '../../graphql/queries/searchPlacesAutocomplete.generated';
import { useGetPlaceDetailLazyQuery } from '../../graphql/queries/getPlaceDetail.generated';

export type Address = { latitude: number; longitude: number; mapAddress: string };

type Props = {
  address?: any;
  loading?: boolean;
  onChange?: (value: Address) => void;
};

const SearchAddress = memo(({ loading, address = { latitude: 0, longitude: 0, mapAddress: '' }, onChange }: Props) => {
  const searchTemp = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [options, setOptions] = useState<SelectProps['options']>([]);
  const [value, setValue] = useState<any>(undefined);

  const [searchAsync, { loading: searching }] = useSearchPlacesAutocompleteLazyQuery({});
  const [getPlaceAsync, { loading: gettingPlace }] = useGetPlaceDetailLazyQuery({});

  const handleChange = useCallback(
    async (place_id: string, options: any) => {
      setValue(options?.label);
      const data = await getPlaceAsync({ variables: { place_id } });
      const place = data?.data?.getPlaceDetail;
      if (!place) return;
      const { lat, lng, address } = place;
      onChange?.({ longitude: lng, latitude: lat, mapAddress: address });
    },
    [getPlaceAsync, onChange],
  );

  const handleSearch = useCallback(
    (keyword: string) => {
      if (!keyword) return;
      if (searchTemp.current) {
        clearTimeout(searchTemp.current);
        searchTemp.current = null;
      }
      searchTemp.current = setTimeout(async () => {
        const rs = await searchAsync({ variables: { input: { keyword } } });
        const data = rs?.data?.searchPlacesAutocomplete;
        setOptions(data?.map((item) => ({ label: item.address, value: item.place_id })));
      }, 300);
    },
    [searchAsync],
  );

  useEffect(() => {
    if (address && address?.mapAddress && !loading) {
      setValue(address?.mapAddress);
    }
  }, [address, loading]);

  return (
    <div>
      <Select
        showSearch
        loading={searching || gettingPlace}
        value={value}
        placeholder="Nhập địa chỉ"
        defaultActiveFirstOption={false}
        onSearch={handleSearch}
        onChange={handleChange}
        filterOption={false}
        options={options}
      />
    </div>
  );
});

export default SearchAddress;
