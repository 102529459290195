import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, DatePicker, Form, Input, Radio, Row, Switch, Table, notification } from 'antd';
import { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { AppRoutes } from '../../helpers';
import { InputNumberFormat, PageTitle, ProgressLoading } from '../../components';
import { DiscountCodeUnitEnum, ProductEntity } from '../../graphql/type.interface';
import { useAdminCreateDiscountCodeMutation } from '../../graphql/mutations/adminCreateDiscountCode.generated';
import { useAdminUpdateDiscountCodeMutation } from '../../graphql/mutations/adminUpdateDiscountCode.generated';
import { DefaultPagination } from '../../utils';
import { useAdminGetDiscountCodeQuery } from '../../graphql/queries/adminGetDiscountCode.generated';
import { Messages } from '../../constants';

import { ModalSelectProduct } from './modal-select-products';

type Props = {
  isEdit?: boolean;
};

const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  return current && current < dayjs().startOf('day');
};

const FormDiscount = memo(({ isEdit }: Props) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id = '' } = useParams();
  const { data, loading: fetching } = useAdminGetDiscountCodeQuery({ variables: { id }, skip: !id });
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState<ProductEntity[]>([]);
  const productIds = Form.useWatch('productIds', form);
  const discount = useMemo(() => data?.adminGetDiscountCode, [data?.adminGetDiscountCode]);

  const unit = Form.useWatch('unit', form);
  const [createDiscountApi, { loading: creating }] = useAdminCreateDiscountCodeMutation({
    onCompleted() {
      notification.success({
        message: Messages.create.success('mã giảm giá'),
      });
      navigate(AppRoutes.discountManagement);
    },
    onError(error) {
      notification.error({
        message: Messages.create.fail('mã giảm giá'),
        description: error?.message,
      });
    },
  });

  const [updateDiscountApi, { loading: updating }] = useAdminUpdateDiscountCodeMutation({
    onCompleted() {
      notification.success({
        message: Messages.update.success('mã giảm giá'),
      });
      navigate(AppRoutes.discountManagement);
    },
    onError(error) {
      notification.error({
        message: Messages.update.fail('mã giảm giá'),
        description: error?.message,
      });
    },
  });

  const [startAt, setStartAt] = useState(dayjs());
  const [endAt, setEndAt] = useState(dayjs());

  useEffect(() => {
    form.setFieldsValue({
      ...discount,
      startDate: dayjs(discount?.startDate),
      endDate: discount?.endDate ? dayjs(discount?.endDate) : undefined,
      productIds: (discount?.products ?? []).map((it) => it?.id),
    });
    setProducts((discount?.products as ProductEntity[]) ?? []);
  }, [discount, form]);

  const onFinish = useCallback(
    (input: any) => {
      return discount
        ? updateDiscountApi({ variables: { input: { ...input, id: discount?.id } } })
        : createDiscountApi({ variables: { input: { ...input } } });
    },
    [createDiscountApi, discount, updateDiscountApi],
  );

  const handleFinishSelectProducts = useCallback(
    (productIds: string[], new_products: ProductEntity[]) => {
      form.setFieldsValue({ productIds: productIds });
      setProducts(new_products);
      setOpen(false);
    },
    [form],
  );

  const handleRemoveProduct = useCallback(
    (id: string) => {
      const newProductIds = productIds.filter((item: string) => item !== id);
      form.setFieldsValue({ productIds: newProductIds });
      setProducts(products.filter((p) => p.id !== id));
    },
    [form, productIds, products],
  );

  const handleChangeStartAt: DatePickerProps['onChange'] = useCallback(
    (date: any) => {
      setStartAt(dayjs(date));
      //handle endAt < startAt
      if (dayjs(endAt).isBefore(dayjs(date))) {
        setEndAt(dayjs(date));
        form.setFieldsValue({ end: dayjs(date) });
      }
    },
    [endAt, form],
  );

  const handleChangeEndAt: DatePickerProps['onChange'] = useCallback((date: any) => {
    setEndAt(dayjs(date));
  }, []);

  if (fetching) return <ProgressLoading />;

  return (
    <div>
      <PageTitle title={id ? 'Thông tin Mã giảm giá' : 'Thêm mới Mã giảm giá'} link={AppRoutes.discountManagement} />
      <div>
        <Form
          form={form}
          labelCol={{ span: 5 }}
          labelAlign="left"
          wrapperCol={{ span: 15 }}
          size="small"
          initialValues={{ isActive: true, unit: DiscountCodeUnitEnum.PERCENTAGE, startDate: dayjs() }}
          onFinish={onFinish}
          autoComplete="off">
          <Form.Item name="id">
            <Input className="hidden" />
          </Form.Item>
          <Form.Item
            label={
              <span>
                Tên mã<span className="text-red"> *</span>
              </span>
            }
            name="name"
            normalize={(e) => e.trimStart()}
            rules={[{ required: true, message: 'Tên mã là trường bắt buộc nhập.' }]}>
            <Input placeholder={`Vui lòng nhập Tên mã giảm giá.`} maxLength={255} />
          </Form.Item>
          <Form.Item
            label={
              <span>
                Ngày bắt đầu<span className="text-red"> *</span>
              </span>
            }
            name="startDate"
            rules={[{ required: true, message: 'Ngày bắt đầu là trường bắt buộc.' }]}>
            <DatePicker format={'DD/MM/YYYY'} disabledDate={disabledDate} onChange={handleChangeStartAt} />
          </Form.Item>
          <Form.Item label={' Ngày kết thúc'} name="endDate">
            <DatePicker
              format={'DD/MM/YYYY'}
              disabledDate={(current) => current && current < dayjs(startAt)}
              onChange={handleChangeEndAt}
            />
          </Form.Item>

          <Form.Item
            label={
              <span>
                Mức giảm<span className="text-red"> *</span>
              </span>
            }
            name="value"
            rules={[{ required: true, message: 'Mức giảm là trường bắt buộc nhập.' }]}>
            <InputNumberFormat
              className="w-full"
              suffix={unit === DiscountCodeUnitEnum.PERCENTAGE ? '%' : 'đ'}
              max={unit === DiscountCodeUnitEnum.PERCENTAGE ? 100 : undefined}
            />
          </Form.Item>

          <Form.Item
            name="unit"
            label={
              <span>
                Giá trị giảm<span className="text-red"> *</span>
              </span>
            }>
            <Radio.Group>
              <Radio value={DiscountCodeUnitEnum.PERCENTAGE}> % </Radio>
              <Radio value={DiscountCodeUnitEnum.VND}> vnđ </Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="productIds"
            label={
              <span>
                <span className="text-error">* </span> Sản phẩm áp dụng
              </span>
            }>
            {productIds && productIds.length > 0 ? (
              <Table
                dataSource={products}
                pagination={{
                  ...DefaultPagination,
                }}
                bordered={true}
                columns={[
                  {
                    title: 'Mã sản phẩm',
                    dataIndex: 'id',
                    key: 'id',
                  },
                  {
                    title: 'Tên sản phẩm',
                    dataIndex: 'name',
                    key: 'name',
                  },
                  {
                    title: 'Tùy chọn',
                    dataIndex: 'id',
                    key: 'action',
                    render(id) {
                      return (
                        <span className="text-error hover:cursor-pointer" onClick={() => handleRemoveProduct(id)}>
                          Bỏ chọn
                        </span>
                      );
                    },
                  },
                ]}
              />
            ) : (
              <span>Áp dụng cho tất cả sản phẩm</span>
            )}
          </Form.Item>

          <div className="w-10/12 flex justify-end mb-16px">
            <Button type="primary" onClick={() => setOpen(true)}>
              Chọn sản phẩm
            </Button>
          </div>

          <Form.Item label={'Giới hạn tổng số lượt sử dụng'} name="limit">
            <InputNumberFormat className="w-full" min={1} placeholder={`Nhập giới hạn tổng số lượt sử dụng.`} />
          </Form.Item>

          <Form.Item label={'Giới hạn số lượt sử dụng tài khoản'} name="limitPerAccount">
            <InputNumberFormat className="w-full" min={1} placeholder={`Nhập giới hạn số lượt sử dụng tài khoản.`} />
          </Form.Item>

          <Form.Item
            label={
              <span>
                Giá trị đơn hàng tối thiểu<span className="text-red"> *</span>
              </span>
            }
            name="minOrderValue"
            rules={[{ required: true, message: 'Giá trị đơn hàng tối thiểu là trường bắt buộc nhập' }]}>
            <InputNumberFormat className="w-full" placeholder={`Nhập giá trị đơn hàng tối thiểu.`} min={1} suffix="đ" />
          </Form.Item>

          <Form.Item label={'Trạng thái'} name="isActive">
            <Switch defaultChecked={!isEdit ? true : discount?.isActive} disabled={!isEdit} />
          </Form.Item>

          <Row>
            <Col span={20} className="flex justify-end space-x-4">
              <Button className="w-20" type="default" onClick={() => navigate(-1)}>
                Huỷ
              </Button>
              <Button
                className="px-2 py-8"
                type="primary"
                htmlType="submit"
                loading={creating || updating}
                disabled={creating || updating}>
                Lưu
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      {open && (
        <ModalSelectProduct
          open={open}
          setOpen={setOpen}
          onFinish={handleFinishSelectProducts}
          productIds={productIds}
          products={products}
        />
      )}
    </div>
  );
});

export default FormDiscount;
