import { memo } from 'react';
import { Button, Col, DatePicker, Form, Input, InputNumber, Radio, Row, Select, Switch, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';

import { InputNumberFormat, PageTitle, ProgressLoading } from '../../components';
import { AppRoutes } from '../../helpers';
import { OperatingUnitEnum, ProductTypeEnum } from '../../graphql/type.interface';
import { UploadListImage } from '../../components/upload-image/uploadListImage';
import { Messages } from '../../constants/message';
import { AddIcon } from '../../assets/icons';

import './style.less';
import { useProdForm } from './hooks/useProdForm';
import ModalSelectDevice from './modal-select-device';

type Props = {
  isEdit?: boolean;
  productType?: ProductTypeEnum;
};

export const AppButton = memo(({ type, ...props }: any) => {
  return (
    <div className="imp-wrapper inline-block leading-none select-none">
      <Button type={type as any} {...props} />
    </div>
  );
});

const ProductForm = memo(({ isEdit = false, productType = ProductTypeEnum.VEHICLE }: Props) => {
  const isVehicle = productType === ProductTypeEnum.VEHICLE;

  const {
    form,
    modalForm,
    dropdownData,
    initValues,
    fetching,
    updating,
    imgLoading,
    modalSelectDeviceData,
    onFinish,
    navigate,
    handleSubmitModal,
    setModalSelectDeviceData,
  } = useProdForm({
    isEdit,
    productType,
  });

  if (fetching) return <ProgressLoading />;

  return (
    <div>
      <PageTitle
        link={AppRoutes.productManagement}
        title={`${isEdit ? 'Chỉnh sửa' : 'Thêm mới'} ${isVehicle ? 'thiết bị' : 'phụ tùng'}`}
      />
      <Form
        form={form}
        labelCol={{ span: 4 }}
        labelAlign="left"
        wrapperCol={{ span: 15 }}
        size="small"
        initialValues={initValues}
        onFinish={onFinish}
        autoComplete="off">
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Form.Item
          label={
            <span>
              Ảnh sản phẩm<span className="text-red"> *</span>
            </span>
          }
          name="avatarId"
          rules={[{ required: true, message: Messages.required('Ảnh sản phẩm') }]}
          valuePropName="files">
          <UploadListImage
            disabled={imgLoading}
            onChangeSinUpload={(file) => form.setFields([{ name: 'avatarId', value: [file], errors: [] }])}
            onRemove={() => form.setFieldValue('avatarId', undefined)}
          />
        </Form.Item>
        <Form.Item label={<span>Ảnh mô tả</span>} name="descriptionImageIds" valuePropName="files">
          <UploadListImage
            multiple
            disabled={imgLoading}
            onChangeMulUpload={(files) => form.setFieldValue('descriptionImageIds', files)}
          />
        </Form.Item>
        <Form.Item
          label={
            <span>
              Tên {isVehicle ? 'máy' : 'phụ tùng'}
              <span className="text-red"> *</span>
            </span>
          }
          name="name"
          normalize={(e) => e.trimStart()}
          rules={[{ required: true, message: Messages.required(`Tên ${isVehicle ? 'máy' : 'phụ tùng'}`) }]}>
          <Input placeholder={`Vui lòng nhập tên ${isVehicle ? 'máy' : 'phụ tùng'}.`} maxLength={255} />
        </Form.Item>
        {isVehicle ? (
          <>
            <Form.Item label="Biển số" name="vehicleRegistrationPlate">
              <Input placeholder={`Vui lòng nhập biển số.`} maxLength={255} />
            </Form.Item>
            <Form.Item label="Số thứ tự" name="ordinalNumber">
              <InputNumber placeholder="Vui lòng nhập số thứ tự" style={{ width: '100%' }} maxLength={255} />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  Chủng loại máy<span className="text-red"> *</span>
                </span>
              }
              name="vehicleTypeId"
              rules={[{ required: true, message: Messages.required('Chủng loại máy') }]}>
              <Select
                showSearch
                allowClear
                placeholder="Chọn chủng loại"
                options={dropdownData?.vehicleTypes?.map((el) => ({ label: el.name, value: el.id }))}
                filterOption={(input, option) => option?.label?.toLowerCase().includes(input.toLowerCase()) ?? false}
              />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  Hãng sản xuất<span className="text-red"> *</span>
                </span>
              }
              name="manufacturerId"
              rules={[{ required: true, message: Messages.required('Hãng sản xuất') }]}>
              <Select
                showSearch
                allowClear
                placeholder="Chọn hãng sản xuất"
                options={dropdownData?.manufacturers?.map((el) => ({ label: el.name, value: el.id }))}
                filterOption={(input, option) => option?.label?.toLowerCase().includes(input.toLowerCase()) ?? false}
              />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  Model<span className="text-red"> *</span>
                </span>
              }
              name="modelId"
              rules={[{ required: true, message: Messages.required('Model') }]}>
              <Select
                showSearch
                allowClear
                placeholder="Chọn model"
                options={dropdownData?.models?.map((el) => ({ label: el.name, value: el.id }))}
                filterOption={(input, option) => option?.label?.toLowerCase().includes(input.toLowerCase()) ?? false}
              />
            </Form.Item>
            <Form.Item label="Số Serial" name="serialNumber">
              <Input placeholder={`Vui lòng nhập số serial. `} style={{ width: '100%' }} maxLength={255} />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  Số VIN<span className="text-red"> *</span>
                </span>
              }
              name="vinNumber"
              rules={[{ required: true, message: Messages.required('Số VIN') }]}>
              <Input placeholder={`Vui lòng nhập số VIN.`} style={{ width: '100%' }} maxLength={255} />
            </Form.Item>
            <Form.Item label={<span>Năm sản xuất</span>} name="yearOfManufacture">
              <DatePicker
                placeholder="Chọn năm sản xuất"
                disabledDate={(v) => dayjs(v).unix() > dayjs().unix()}
                picker="year"
              />
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item label="Ký hiệu/Model" name="serialNumber" normalize={(e) => e.trimStart()}>
              <Input placeholder={`Vui lòng nhập Ký hiệu/Model. `} maxLength={255} />
            </Form.Item>
            <Form.Item label="Serial/Part-number" name="partNumber">
              <Input placeholder={`Vui lòng nhập Serial/Part-number. `} maxLength={255} />
            </Form.Item>
          </>
        )}
        <Form.Item
          label={
            <span>
              Tình trạng<span className="text-red"> *</span>
            </span>
          }
          name="isNew">
          <Radio.Group>
            <Radio value={true}>Mới</Radio>
            <Radio value={false}>Đã qua sử dụng</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prev, cur) => prev.isNew !== cur.isNew}>
          {({ getFieldValue }) =>
            !getFieldValue('isNew') ? (
              <Form.Item label={<span />} colon={false}>
                <Row align="middle">
                  <Form.Item
                    name="operatingNumber"
                    rules={[{ required: true, message: Messages.required('Tình trạng') }]}>
                    <InputNumberFormat placeholder="Nhập số" />
                  </Form.Item>
                  <Form.Item
                    name="operatingUnit"
                    rules={[{ required: true, message: Messages.required('Tình trạng') }]}>
                    <Select
                      placeholder="Chọn thời gian"
                      style={{ width: 100, marginLeft: 12 }}
                      options={[
                        { label: 'Giờ', value: OperatingUnitEnum.HOURS },
                        { label: 'Km', value: OperatingUnitEnum.KM },
                      ]}
                      loading={false}
                    />
                  </Form.Item>
                </Row>
              </Form.Item>
            ) : null
          }
        </Form.Item>
        <Form.Item label={<span>Xuất xứ</span>} name="originId">
          <Select
            showSearch
            allowClear
            placeholder="Chọn xuất xứ"
            options={dropdownData?.origins?.map((el) => ({ label: el.name, value: el.id }))}
            filterOption={(input, option) => option?.label?.toLowerCase().includes(input.toLowerCase()) ?? false}
          />
        </Form.Item>
        {!isVehicle && (
          <>
            <Form.Item
              label={
                <span>
                  Dùng cho bộ phận<span className="text-red"> *</span>
                </span>
              }
              name="partId"
              rules={[{ required: true, message: Messages.required('Dùng cho bộ phận') }]}>
              <Select
                showSearch
                allowClear
                placeholder="Chọn bộ phận"
                options={dropdownData.partOfNumbers.map((el) => ({
                  label: el.name,
                  value: el.id,
                }))}
                filterOption={(input, option) => option?.label?.toLowerCase().includes(input.toLowerCase()) ?? false}
              />
            </Form.Item>
            <div>
              <h2 className="text-14px font-semibold leading-[20px] mb-8px">Lắp cho các thiết bị</h2>
              <Form.List name="productDevices">
                {(fields, { remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <div key={key} className="border border-solid rounded border-[#000000] w-[80%] p-20px mb-20px">
                        <Typography.Text strong>
                          Thiết bị <span className="text-red"> *</span>:
                        </Typography.Text>
                        <Row gutter={20} align={'middle'} className="mt-8px">
                          <Col span={22}>
                            <Row gutter={20}>
                              <Col span={8}>
                                <Typography.Text strong>Chủng loại máy</Typography.Text>
                                <Form.Item
                                  {...restField}
                                  className="row-item-full-width"
                                  colon={false}
                                  name={[name, 'vehicleTypeId']}
                                  rules={[{ required: true, message: Messages.required('Thiết bị') }]}>
                                  <Select
                                    placeholder="Chọn chủng loại"
                                    options={dropdownData.vehicleTypes.map((el) => ({
                                      label: el.name,
                                      value: el.id,
                                    }))}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Typography.Text strong>Hãng sản xuất</Typography.Text>
                                <Form.Item
                                  {...restField}
                                  className="row-item-full-width"
                                  colon={false}
                                  name={[name, 'manufacturerId']}>
                                  <Select
                                    placeholder="Chọn hãng sản xuất"
                                    options={dropdownData.manufacturers.map((el) => ({
                                      label: el.name,
                                      value: el.id,
                                    }))}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Typography.Text strong>Model</Typography.Text>
                                <Form.Item
                                  {...restField}
                                  className="row-item-full-width"
                                  colon={false}
                                  name={[name, 'modelId']}>
                                  <Select
                                    placeholder="Chọn model"
                                    options={dropdownData.models.map((el) => ({
                                      label: el.name,
                                      value: el.id,
                                    }))}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={2}>
                            <Form.Item className="row-item-full-width" colon={false} name="">
                              <AppButton
                                className="mt-5"
                                style={{ borderColor: '#D63120', color: '#D63120', width: '100%' }}
                                onClick={() => remove(name)}>
                                Xóa
                              </AppButton>
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    ))}
                    <Row>
                      <Col span={19} className="flex justify-end">
                        <Button
                          type="primary"
                          className="mb-[8px]"
                          onClick={() =>
                            setModalSelectDeviceData({
                              vehicleTypesData: dropdownData.vehicleTypes,
                              modelsData: dropdownData.models,
                              manufacturersData: dropdownData.manufacturers,
                            })
                          }>
                          <AddIcon fill="#202C38" />
                          <span>Thêm thiết bị</span>
                        </Button>
                      </Col>
                      <Col span={5} />
                    </Row>
                  </>
                )}
              </Form.List>
            </div>
          </>
        )}
        <Form.Item
          label={
            <span>
              Chi tiết<span className="text-red"> *</span>
            </span>
          }
          name="detail"
          normalize={(e) => e.trimStart()}
          rules={[{ required: true, message: Messages.required('Chi tiết') }]}>
          <TextArea placeholder={`Nhập chi tiết.`} rows={4} maxLength={1000} showCount />
        </Form.Item>
        <Form.Item
          label={
            <span>
              Giá sản phẩm (VND)<span className="text-red"> *</span>
            </span>
          }
          name="isFixedCost">
          <Radio.Group>
            <Radio value={true}>Cố định</Radio>
            <Radio value={false}>Thương lượng</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prev, cur) => prev.isFixedCost !== cur.isFixedCost}>
          {({ getFieldValue }) =>
            getFieldValue('isFixedCost') ? (
              <Form.Item
                name="unitPrice"
                label={<span />}
                colon={false}
                rules={[{ required: true, message: 'Giá sản phẩm là trường bắt buộc.' }]}>
                <InputNumberFormat placeholder="Nhập giá" style={{ width: '100%' }} maxLength={255} suffix="đ" />
              </Form.Item>
            ) : null
          }
        </Form.Item>
        <Form.Item
          label={
            <span>
              Tồn kho<span className="text-red"> *</span>
            </span>
          }
          name="quantity"
          rules={[{ required: true, message: 'Tồn kho là trường bắt buộc.' }]}>
          <InputNumberFormat placeholder={`Nhập số lượng.`} style={{ width: '100%' }} maxLength={255} />
        </Form.Item>
        <Form.Item
          label={
            <span>
              Chọn đơn vị<span className="text-red"> *</span>
            </span>
          }
          name="productUnitId"
          rules={[{ required: true, message: Messages.required('Đơn vị') }]}>
          <Select
            placeholder="Chọn đơn vị"
            options={dropdownData?.productUnits.map((el) => ({ label: el.name, value: el.id }))}
            loading={false}
          />
        </Form.Item>
        <Form.Item label={<span>Trạng thái</span>} name="isActive">
          <Switch defaultChecked={initValues.isActive ?? true} />
        </Form.Item>
        <Row>
          <Col span={20} className="flex justify-end space-x-4">
            <Button className="w-20" type="default" onClick={() => navigate(-1)}>
              Huỷ
            </Button>
            <Button className="px-2 py-8" type="primary" htmlType="submit" loading={updating || imgLoading}>
              Lưu
            </Button>
          </Col>
        </Row>
      </Form>
      <ModalSelectDevice
        {...modalSelectDeviceData}
        form={modalForm}
        open={!!modalSelectDeviceData}
        handleClose={() => setModalSelectDeviceData(undefined)}
        onFinish={handleSubmitModal}
      />
    </div>
  );
});

export default ProductForm;
