import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminGetUserMaintenancesQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Array<Types.Scalars['JSONObject']> | Types.Scalars['JSONObject']>;
  isActive?: Types.InputMaybe<Types.StatusEnum>;
  isApproved?: Types.InputMaybe<Types.Scalars['Boolean']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  search?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.SortInput>;
  userId: Types.Scalars['String'];
}>;

export type AdminGetUserMaintenancesQueryResponse = { __typename?: 'Query' } & {
  adminGetUserMaintenances: { __typename?: 'MaintenanceConnection' } & {
    items?: Types.Maybe<
      Array<
        { __typename?: 'MaintenanceEntity' } & Pick<
          Types.MaintenanceEntity,
          'id' | 'code' | 'createdAt' | 'maintenanceLevel' | 'mapAddress'
        > & {
            accessories_available?: Types.Maybe<
              Array<
                { __typename?: 'MaintenanceAccessoryEntity' } & Pick<
                  Types.MaintenanceAccessoryEntity,
                  'accessoryId' | 'id' | 'isAvailable' | 'maintenanceId'
                >
              >
            >;
            vehicle?: Types.Maybe<{ __typename?: 'VehicleEntity' } & Pick<Types.VehicleEntity, 'name'>>;
          }
      >
    >;
    meta: { __typename?: 'BasePaginationMeta' } & Pick<
      Types.BasePaginationMeta,
      'currentPage' | 'itemCount' | 'itemsPerPage' | 'totalItems' | 'totalPages'
    >;
  };
};

export const AdminGetUserMaintenancesDocument = gql`
  query adminGetUserMaintenances(
    $filters: [JSONObject!]
    $isActive: StatusEnum
    $isApproved: Boolean
    $limit: Int
    $page: Int
    $search: String
    $sort: SortInput
    $userId: String!
  ) {
    adminGetUserMaintenances(
      filters: $filters
      isActive: $isActive
      isApproved: $isApproved
      limit: $limit
      page: $page
      search: $search
      sort: $sort
      userId: $userId
    ) {
      items {
        id
        accessories_available {
          accessoryId
          id
          isAvailable
          maintenanceId
        }
        code
        createdAt
        maintenanceLevel
        mapAddress
        vehicle {
          name
        }
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;
export function useAdminGetUserMaintenancesQuery(
  baseOptions: Apollo.QueryHookOptions<AdminGetUserMaintenancesQueryResponse, AdminGetUserMaintenancesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetUserMaintenancesQueryResponse, AdminGetUserMaintenancesQueryVariables>(
    AdminGetUserMaintenancesDocument,
    options,
  );
}
export function useAdminGetUserMaintenancesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetUserMaintenancesQueryResponse,
    AdminGetUserMaintenancesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetUserMaintenancesQueryResponse, AdminGetUserMaintenancesQueryVariables>(
    AdminGetUserMaintenancesDocument,
    options,
  );
}
export type AdminGetUserMaintenancesQueryHookResult = ReturnType<typeof useAdminGetUserMaintenancesQuery>;
export type AdminGetUserMaintenancesLazyQueryHookResult = ReturnType<typeof useAdminGetUserMaintenancesLazyQuery>;
export type AdminGetUserMaintenancesQueryResult = Apollo.QueryResult<
  AdminGetUserMaintenancesQueryResponse,
  AdminGetUserMaintenancesQueryVariables
>;
