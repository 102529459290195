import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type UpdateQuotationPriceListMutationVariables = Types.Exact<{
  input: Types.UpdateQuotationPriceListInput;
}>;

export type UpdateQuotationPriceListMutationResponse = { __typename?: 'Mutation' } & {
  updateQuotationPriceList: { __typename?: 'QuotationPriceListEntity' } & Pick<Types.QuotationPriceListEntity, 'id'>;
};

export const UpdateQuotationPriceListDocument = gql`
  mutation updateQuotationPriceList($input: UpdateQuotationPriceListInput!) {
    updateQuotationPriceList(input: $input) {
      id
    }
  }
`;
export function useUpdateQuotationPriceListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateQuotationPriceListMutationResponse,
    UpdateQuotationPriceListMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateQuotationPriceListMutationResponse, UpdateQuotationPriceListMutationVariables>(
    UpdateQuotationPriceListDocument,
    options,
  );
}
export type UpdateQuotationPriceListMutationHookResult = ReturnType<typeof useUpdateQuotationPriceListMutation>;
export type UpdateQuotationPriceListMutationResult = Apollo.MutationResult<UpdateQuotationPriceListMutationResponse>;
export type UpdateQuotationPriceListMutationOptions = Apollo.BaseMutationOptions<
  UpdateQuotationPriceListMutationResponse,
  UpdateQuotationPriceListMutationVariables
>;
