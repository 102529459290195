import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';

import { request } from '../request';

import { UploadMutationInput } from './useUploadImagesMutation';

const UPLOAD_ENDPOINT = '/api/quotation-price-list/import-excel';

type UploadFileMutationResponse = {
  id: string;
  fileSize: number;
  name: string;
  originalUrl: string;
  thumbUrl: null;
  mimeType: string;
  isDeleted: boolean;
  ownerId: null;
  type: string;
  fullOriginalUrl: string;
};

type UploadMutationOptions = UseMutationOptions<UploadFileMutationResponse, AxiosError, UploadMutationInput>;

export function useUploadExcelQuotationPriceMutation(
  options?: UploadMutationOptions,
  onProgress?: (percent: number) => void,
) {
  return useMutation(
    (values) =>
      request
        .post(UPLOAD_ENDPOINT, values, {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total ?? 1));
            onProgress && onProgress(percentCompleted);
          },
        })
        .then((res) => res.data),
    options,
  );
}
