import React, { memo, useMemo } from 'react';
import { Upload, UploadFile, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { RcFile, UploadProps } from 'antd/es/upload';

interface UploadListProps {
  multiple?: boolean;
  files?: UploadFile[];
  onChangeMulUpload?: (files: UploadFile[]) => void;
  onChangeSinUpload?: (file: UploadFile) => void;
  disabled?: boolean;
  onRemove?: UploadProps['onRemove'];
}

export const UploadListImage = memo((uploadListProps: UploadListProps) => {
  const { multiple = false, files, onChangeMulUpload, onChangeSinUpload, disabled, onRemove } = uploadListProps;

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    onChangeMulUpload?.(newFileList);
    newFileList.length !== 0 && onChangeSinUpload?.(newFileList[0]);
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const props: UploadProps = {
    listType: 'picture-card',
    accept: 'image/*',
    onChange: onChange,
    beforeUpload: (file: RcFile) => {
      const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
      const isAllowedType = allowedTypes.includes(file.type);
      if (!isAllowedType) {
        message.error('Vui lòng chọn ảnh thuộc các định dạng JPG/JPEG/PNG/GIF !');
        return Upload.LIST_IGNORE;
      }
      const maxSizeInMB = 10;
      const isAllowedSize = file.size / 1024 / 1024 < maxSizeInMB;
      if (!isAllowedSize) {
        message.error(`Dung lượng ảnh tối đa là ${maxSizeInMB} MB`);
        return Upload.LIST_IGNORE;
      }
      return false;
    },
    onPreview,
    multiple,
    showUploadList: true,
    disabled,
    maxCount: multiple ? 10 : 1,
    onRemove,
  };

  const uploadButton = useMemo(
    () => (
      <>
        <PlusOutlined />
        <div className="mt-2">Tải ảnh lên</div>
      </>
    ),
    [],
  );

  if (multiple)
    return (
      <div className="flex items-center">
        <Upload {...props} fileList={files}>
          {uploadButton}
        </Upload>
      </div>
    );

  return (
    <div className="flex items-center">
      <Upload {...props} fileList={files} showUploadList={false}>
        {files && files.length ? (
          <img
            src={files[0].originFileObj ? URL.createObjectURL(files[0].originFileObj) : files[0].url}
            alt="avatar"
            className="w-[151px] h-[151px] bg-cover"
          />
        ) : (
          uploadButton
        )}
      </Upload>
    </div>
  );
});
