import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminGetGuidesQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Array<Types.Scalars['JSONObject']> | Types.Scalars['JSONObject']>;
  isActive?: Types.InputMaybe<Types.StatusEnum>;
  isApproved?: Types.InputMaybe<Types.Scalars['Boolean']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  search?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;

export type AdminGetGuidesQueryResponse = { __typename?: 'Query' } & {
  adminGetGuides: { __typename?: 'GuideConnection' } & {
    items?: Types.Maybe<
      Array<{ __typename?: 'GuideEntity' } & Pick<Types.GuideEntity, 'description' | 'id' | 'isActive' | 'name'>>
    >;
    meta: { __typename?: 'BasePaginationMeta' } & Pick<
      Types.BasePaginationMeta,
      'currentPage' | 'itemCount' | 'itemsPerPage' | 'totalItems' | 'totalPages'
    >;
  };
};

export const AdminGetGuidesDocument = gql`
  query adminGetGuides(
    $filters: [JSONObject!]
    $isActive: StatusEnum
    $isApproved: Boolean
    $limit: Int
    $page: Int
    $search: String
    $sort: SortInput
  ) {
    adminGetGuides(
      filters: $filters
      isActive: $isActive
      isApproved: $isApproved
      limit: $limit
      page: $page
      search: $search
      sort: $sort
    ) {
      items {
        description
        id
        isActive
        name
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;
export function useAdminGetGuidesQuery(
  baseOptions?: Apollo.QueryHookOptions<AdminGetGuidesQueryResponse, AdminGetGuidesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetGuidesQueryResponse, AdminGetGuidesQueryVariables>(AdminGetGuidesDocument, options);
}
export function useAdminGetGuidesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminGetGuidesQueryResponse, AdminGetGuidesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetGuidesQueryResponse, AdminGetGuidesQueryVariables>(
    AdminGetGuidesDocument,
    options,
  );
}
export type AdminGetGuidesQueryHookResult = ReturnType<typeof useAdminGetGuidesQuery>;
export type AdminGetGuidesLazyQueryHookResult = ReturnType<typeof useAdminGetGuidesLazyQuery>;
export type AdminGetGuidesQueryResult = Apollo.QueryResult<AdminGetGuidesQueryResponse, AdminGetGuidesQueryVariables>;
