import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminSettlementQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type AdminSettlementQueryResponse = { __typename?: 'Query' } & {
  adminSettlement: { __typename?: 'SettlementEntity' } & Pick<Types.SettlementEntity, 'status' | 'total' | 'userId'> & {
      additionalFees: Array<
        { __typename?: 'SettlementAdditionalFeeEntity' } & Pick<Types.SettlementAdditionalFeeEntity, 'amount' | 'name'>
      >;
      booking: { __typename?: 'BookingEntity' } & Pick<Types.BookingEntity, 'code' | 'mapAddress' | 'status'> & {
          partner: { __typename?: 'PartnerEntity' } & Pick<
            Types.PartnerEntity,
            'fullname' | 'hotline' | 'mapAddress' | 'phone'
          >;
          technician?: Types.Maybe<{ __typename?: 'PartnerEntity' } & Pick<Types.PartnerEntity, 'fullname'>>;
          user: { __typename?: 'UserEntity' } & Pick<Types.UserEntity, 'address' | 'fullname' | 'phone'>;
          vehicle: { __typename?: 'VehicleEntity' } & Pick<
            Types.VehicleEntity,
            | 'mapAddress'
            | 'name'
            | 'operatingNumber'
            | 'operatingUnit'
            | 'vehicleRegistrationPlate'
            | 'vinNumber'
            | 'serialNumber'
          >;
        };
      quotation: { __typename?: 'QuotationEntity' } & Pick<
        Types.QuotationEntity,
        'diagnosisFee' | 'diagnosisNote' | 'estimatedCompleteAt' | 'id' | 'repairFee' | 'total' | 'transportFee'
      > & {
          accessories: Array<
            { __typename?: 'QuotationAccessoryEntity' } & Pick<
              Types.QuotationAccessoryEntity,
              'name' | 'quantity' | 'unit' | 'unitPrice'
            >
          >;
          additionalFees: Array<
            { __typename?: 'QuotationAdditionalFeeEntity' } & Pick<
              Types.QuotationAdditionalFeeEntity,
              'amount' | 'name'
            >
          >;
          diagnostics: Array<
            { __typename?: 'QuotationDiagnosticEntity' } & Pick<
              Types.QuotationDiagnosticEntity,
              'diagnosticCode' | 'expense' | 'id'
            >
          >;
        };
      technician: { __typename?: 'PartnerEntity' } & Pick<Types.PartnerEntity, 'fullname'>;
      user: { __typename?: 'UserEntity' } & Pick<Types.UserEntity, 'address' | 'fullname' | 'phone'>;
    };
};

export const AdminSettlementDocument = gql`
  query adminSettlement($id: String!) {
    adminSettlement(id: $id) {
      additionalFees {
        amount
        name
      }
      booking {
        code
        mapAddress
        partner {
          fullname
          hotline
          mapAddress
          phone
        }
        status
        technician {
          fullname
        }
        user {
          address
          fullname
          phone
        }
        vehicle {
          mapAddress
          name
          operatingNumber
          operatingUnit
          vehicleRegistrationPlate
          vinNumber
          serialNumber
        }
      }
      quotation {
        accessories {
          name
          quantity
          unit
          unitPrice
        }
        additionalFees {
          amount
          name
        }
        diagnosisFee
        diagnosisNote
        diagnostics {
          diagnosticCode
          expense
          id
        }
        estimatedCompleteAt
        id
        repairFee
        total
        transportFee
        diagnosisFee
      }
      status
      technician {
        fullname
      }
      total
      user {
        address
        fullname
        phone
      }
      userId
    }
  }
`;
export function useAdminSettlementQuery(
  baseOptions: Apollo.QueryHookOptions<AdminSettlementQueryResponse, AdminSettlementQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminSettlementQueryResponse, AdminSettlementQueryVariables>(AdminSettlementDocument, options);
}
export function useAdminSettlementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminSettlementQueryResponse, AdminSettlementQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminSettlementQueryResponse, AdminSettlementQueryVariables>(
    AdminSettlementDocument,
    options,
  );
}
export type AdminSettlementQueryHookResult = ReturnType<typeof useAdminSettlementQuery>;
export type AdminSettlementLazyQueryHookResult = ReturnType<typeof useAdminSettlementLazyQuery>;
export type AdminSettlementQueryResult = Apollo.QueryResult<
  AdminSettlementQueryResponse,
  AdminSettlementQueryVariables
>;
