import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminRemoveTechnicianMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type AdminRemoveTechnicianMutationResponse = { __typename?: 'Mutation' } & Pick<
  Types.Mutation,
  'adminRemoveTechnician'
>;

export const AdminRemoveTechnicianDocument = gql`
  mutation adminRemoveTechnician($id: String!) {
    adminRemoveTechnician(id: $id)
  }
`;
export function useAdminRemoveTechnicianMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminRemoveTechnicianMutationResponse,
    AdminRemoveTechnicianMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminRemoveTechnicianMutationResponse, AdminRemoveTechnicianMutationVariables>(
    AdminRemoveTechnicianDocument,
    options,
  );
}
export type AdminRemoveTechnicianMutationHookResult = ReturnType<typeof useAdminRemoveTechnicianMutation>;
export type AdminRemoveTechnicianMutationResult = Apollo.MutationResult<AdminRemoveTechnicianMutationResponse>;
export type AdminRemoveTechnicianMutationOptions = Apollo.BaseMutationOptions<
  AdminRemoveTechnicianMutationResponse,
  AdminRemoveTechnicianMutationVariables
>;
