import { memo, ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { IconArrowLeft, LogoOutlineIcon } from '../../assets/icons';
import { AppRoutes } from '../../helpers';

interface LayoutProps {
  children: ReactNode;
}

export const LoginLayout = memo(({ children }: LayoutProps) => {
  const location = useLocation();
  const isForgotPasswordPage = location.pathname === AppRoutes.auth.forgotPassword;

  return (
    <div className="w-screen h-screen flex-center grid place-items-center bg-slate-100 relative">
      {isForgotPasswordPage && (
        <Link
          to={AppRoutes.auth.login}
          type="text"
          className="absolute top-8 left-8 flex items-center space-x-2 cursor-pointer no-underline text-black p-4">
          <IconArrowLeft />
          <span>Back</span>
        </Link>
      )}
      <div className="min-w-fit grid place-items-center bg-white shadow-md rounded-md px-6 py-10">
        <div className="bg-primary w-full grid place-items-center rounded-md p-10">
          <LogoOutlineIcon width={56} height={56} />
          <span className="text-xl font-semibold mt-2">Call me</span>
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
});
