import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminCreateVehicleTypeCategoryMutationVariables = Types.Exact<{
  input: Types.CreateVehicleTypeCategory;
}>;

export type AdminCreateVehicleTypeCategoryMutationResponse = { __typename?: 'Mutation' } & {
  adminCreateVehicleTypeCategory: { __typename?: 'VechicleTypeCategoryEntity' } & Pick<
    Types.VechicleTypeCategoryEntity,
    'id'
  >;
};

export const AdminCreateVehicleTypeCategoryDocument = gql`
  mutation adminCreateVehicleTypeCategory($input: CreateVehicleTypeCategory!) {
    adminCreateVehicleTypeCategory(input: $input) {
      id
    }
  }
`;
export function useAdminCreateVehicleTypeCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateVehicleTypeCategoryMutationResponse,
    AdminCreateVehicleTypeCategoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminCreateVehicleTypeCategoryMutationResponse,
    AdminCreateVehicleTypeCategoryMutationVariables
  >(AdminCreateVehicleTypeCategoryDocument, options);
}
export type AdminCreateVehicleTypeCategoryMutationHookResult = ReturnType<
  typeof useAdminCreateVehicleTypeCategoryMutation
>;
export type AdminCreateVehicleTypeCategoryMutationResult =
  Apollo.MutationResult<AdminCreateVehicleTypeCategoryMutationResponse>;
export type AdminCreateVehicleTypeCategoryMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateVehicleTypeCategoryMutationResponse,
  AdminCreateVehicleTypeCategoryMutationVariables
>;
