import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminCancelOrderMutationVariables = Types.Exact<{
  input: Types.CancelOrderInput;
}>;

export type AdminCancelOrderMutationResponse = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'adminCancelOrder'>;

export const AdminCancelOrderDocument = gql`
  mutation adminCancelOrder($input: CancelOrderInput!) {
    adminCancelOrder(input: $input)
  }
`;
export function useAdminCancelOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminCancelOrderMutationResponse, AdminCancelOrderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminCancelOrderMutationResponse, AdminCancelOrderMutationVariables>(
    AdminCancelOrderDocument,
    options,
  );
}
export type AdminCancelOrderMutationHookResult = ReturnType<typeof useAdminCancelOrderMutation>;
export type AdminCancelOrderMutationResult = Apollo.MutationResult<AdminCancelOrderMutationResponse>;
export type AdminCancelOrderMutationOptions = Apollo.BaseMutationOptions<
  AdminCancelOrderMutationResponse,
  AdminCancelOrderMutationVariables
>;
