import './styles.less';
import { memo, useMemo } from 'react';
import { Card, Descriptions, DescriptionsProps, Space, Spin, Table, Typography } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { PageTitle } from '../../components';
import { AppRoutes } from '../../helpers';
import { useAdminQuotationQuery } from '../../graphql/queries/adminQuotation.generated';
import { useAdminSettlementQuery } from '../../graphql/queries/adminSettlement.generated';
import { joinStringArray, numberWithDots } from '../../utils';
import { OperatingUnitEnum, QuotationStatusEnum } from '../../graphql/type.interface';
import { quotationStatusText } from '../../constants';

import { QUOTATION_KIND } from './const';

const DescriptionsTable = ({ children, column = 2, ...props }: DescriptionsProps) => {
  const widthStyle = Number.isInteger(column) ? { width: `${50 / Number(column)}%` } : {};
  const labelStyle = { ...widthStyle, color: '#000000', fontWeight: 600 };
  return (
    <Descriptions column={column} bordered labelStyle={labelStyle} contentStyle={widthStyle} {...props}>
      {children}
    </Descriptions>
  );
};

const sumArray = (arr: any[], key: string) => arr.reduce((a, c) => a + c[key], 0);

export const QuotationDetail = memo(({ type }: { type: QUOTATION_KIND }) => {
  const navigate = useNavigate();
  const isSettlement = type === QUOTATION_KIND.SETTLEMENT;

  const { id } = useParams() as {
    id: string;
  };
  if (!id) navigate(-1);

  const { data: quotationData, loading: quotationLoading } = useAdminQuotationQuery({
    skip: !id || isSettlement,
    variables: { id },
    fetchPolicy: 'cache-and-network',
  });

  const { data: settlementData, loading: settlementLoading } = useAdminSettlementQuery({
    skip: !id || !isSettlement,
    variables: { id },
    fetchPolicy: 'cache-and-network',
  });

  const detailData = useMemo(
    () => (isSettlement ? settlementData?.adminSettlement : quotationData?.adminQuotation),
    [isSettlement, quotationData?.adminQuotation, settlementData?.adminSettlement],
  );

  const detailDataAny = detailData as any;

  const tableDataDiagnostic = useMemo(() => {
    const diagnostics = detailDataAny?.diagnostics ?? detailDataAny?.quotation?.diagnostics ?? [];
    return diagnostics.map((el: any, i: number) => ({ ...el, key: el.id, index: i + 1 }));
  }, [detailDataAny?.diagnostics, detailDataAny?.quotation?.diagnostics]);

  const tableDataAccessories =
    (detailDataAny?.accessories ?? detailDataAny?.quotation?.accessories)?.map((acc: any, i: number) => ({
      key: i + 1,
      ...acc,
    })) ?? [];

  const tableEveryFee = [
    {
      key: 1,
      name: 'Phí di chuyển',
      amount: detailDataAny?.transportFee ?? detailDataAny?.quotation?.transportFee,
    },
    {
      key: 2,
      name: 'Phí chuẩn đoán',
      amount: detailDataAny?.diagnosisFee ?? detailDataAny?.quotation?.diagnosisFee,
    },
    {
      key: 3,
      name: 'Phí sửa chữa thay thế',
      amount: detailDataAny?.repairFee ?? detailDataAny?.quotation?.repairFee,
    },
  ];

  const tableDataAdditionalFees =
    detailData?.additionalFees?.map((acc: any, i: number) => ({
      key: i + 1,
      ...acc,
    })) ?? [];

  return (
    <div>
      <Spin spinning={quotationLoading || settlementLoading}>
        <Descriptions
          title={
            <PageTitle
              link={AppRoutes.quotationManagement}
              title={`Thông tin chi tiết ${isSettlement ? 'quyết toán' : 'báo giá'}`}
            />
          }
          column={1}
          labelStyle={{ width: '150px' }}
        />
        <Space direction="vertical" size="middle" className="w-full">
          <Card bordered title={<h1 className="text-4xl my-6">Thông tin chung</h1>}>
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
              <DescriptionsTable>
                <Descriptions.Item label="Mã yêu cầu sửa chữa">{detailData?.booking.code}</Descriptions.Item>
                <Descriptions.Item label={`Trạng thái ${isSettlement ? 'quyết toán' : 'báo giá'}`}>
                  {detailData?.status
                    ? quotationStatusText(detailData.status as QuotationStatusEnum, !isSettlement)
                    : 'Unknown'}
                </Descriptions.Item>
              </DescriptionsTable>
              {isSettlement && (
                <DescriptionsTable title="1. Thông tin đại lý">
                  <Descriptions.Item label="Tên đại lý">{detailData?.booking.partner.fullname}</Descriptions.Item>
                  <Descriptions.Item label="Địa chỉ">{detailData?.booking.partner.mapAddress}</Descriptions.Item>
                  <Descriptions.Item label="Số điện thoại">{detailData?.booking.partner.phone}</Descriptions.Item>
                  <Descriptions.Item label="Hotline">{detailData?.booking.partner.hotline}</Descriptions.Item>
                </DescriptionsTable>
              )}
              <DescriptionsTable title={`${isSettlement ? 2 : 1}. Thông tin khách hàng`}>
                <Descriptions.Item label="Họ và tên">{detailData?.user.fullname}</Descriptions.Item>
                {isSettlement && <Descriptions.Item label="Địa chỉ">{detailData?.user.address}</Descriptions.Item>}
                <Descriptions.Item label="Số điện thoại">{detailData?.user.phone}</Descriptions.Item>
                {isSettlement && (
                  <>
                    <Descriptions.Item label="Biển số">
                      {detailData?.booking.vehicle.vehicleRegistrationPlate}
                    </Descriptions.Item>
                    <Descriptions.Item label="Model xe">
                      {detailDataAny?.booking?.vehicle?.serialNumber}
                    </Descriptions.Item>
                    <Descriptions.Item label="Số VIN">{detailData?.booking.vehicle.vinNumber}</Descriptions.Item>
                  </>
                )}
              </DescriptionsTable>
              <DescriptionsTable title={`${isSettlement ? 3 : 2}. Thông tin cố vấn`} style={{ width: '50%' }}>
                <Descriptions.Item label="Họ và tên">{detailData?.booking.technician?.fullname}</Descriptions.Item>
              </DescriptionsTable>
              {!isSettlement && (
                <DescriptionsTable title="3. Thông tin xe gặp sự cố">
                  <Descriptions.Item label="Tên xe">{detailData?.booking.vehicle.name}</Descriptions.Item>
                  <Descriptions.Item label="Biển kiểm soát">
                    {detailData?.booking.vehicle.vehicleRegistrationPlate ?? 'Unknown'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Vị trí xe">{detailData?.booking.mapAddress}</Descriptions.Item>
                </DescriptionsTable>
              )}
              <DescriptionsTable title="4. Chuẩn đoán">
                {!isSettlement && (
                  <Descriptions.Item label="Đã vận hành">
                    {detailDataAny?.operatingNumber}{' '}
                    {detailDataAny?.operatingUnit === OperatingUnitEnum.HOURS
                      ? 'giờ'
                      : detailData?.booking.vehicle.operatingUnit}
                  </Descriptions.Item>
                )}
                <Descriptions.Item label="Mã chuẩn đoán">
                  {joinStringArray(
                    detailDataAny?.diagnostics ?? detailDataAny?.quotation?.diagnostics ?? [],
                    'diagnosticCode',
                    ',',
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Chi phí">
                  {numberWithDots(
                    sumArray(detailDataAny?.diagnostics ?? detailDataAny?.quotation?.diagnostics ?? [], 'expense'),
                  )}
                </Descriptions.Item>
              </DescriptionsTable>
            </Space>
          </Card>
          <Space size="middle" className="w-full items-start space-3-1">
            <Card bordered title={<h1 className="text-4xl my-6">Thông tin chi phí</h1>}>
              <Space direction="vertical" size="large" className="w-full">
                <Typography.Title level={5}>1. Chuẩn đoán</Typography.Title>
                <Table
                  columns={[
                    { title: 'STT', dataIndex: 'index', width: 70, align: 'center' },
                    { title: 'Mã chuẩn đoán', dataIndex: 'diagnosticCode' },
                    {
                      title: 'Thành tiền',
                      dataIndex: 'expense',
                      align: 'center',
                      render: (v) => numberWithDots(v),
                    },
                  ]}
                  dataSource={tableDataDiagnostic}
                  pagination={false}
                  bordered
                />
                <Typography.Title level={5}>2. Vật tư phụ tùng</Typography.Title>
                <Table
                  columns={[
                    { title: 'STT', dataIndex: 'key', width: 70, align: 'center' },
                    { title: 'Tên phụ tùng', dataIndex: 'name' },
                    { title: 'Số lượng', dataIndex: 'quantity', width: 100, align: 'center' },
                    { title: 'Đơn giá', dataIndex: 'unit' },
                    {
                      title: 'Thành tiền',
                      dataIndex: 'unitPrice',
                      align: 'center',
                      render: (v) => numberWithDots(v),
                    },
                  ]}
                  dataSource={tableDataAccessories}
                  pagination={false}
                  bordered
                />
                <Typography.Title level={5}>3. Chi phí công dịch vụ</Typography.Title>
                <Table
                  columns={[
                    { title: 'STT', dataIndex: 'key', width: 70, align: 'center' },
                    { title: `Tên ${isSettlement ? 'giá' : 'chi phí'}`, dataIndex: 'name' },
                    { title: 'Thành tiền', dataIndex: 'amount', align: 'center', render: (v) => numberWithDots(v) },
                  ]}
                  dataSource={tableEveryFee}
                  pagination={false}
                  bordered
                />
                <Typography.Title level={5}>4. Chi phí phát sinh</Typography.Title>
                <Table
                  columns={[
                    { title: 'STT', dataIndex: 'key', width: 70, align: 'center' },
                    { title: `Tên ${isSettlement ? 'giá' : 'chi phí'}`, dataIndex: 'name' },
                    { title: 'Thành tiền', dataIndex: 'amount', align: 'center', render: (v) => numberWithDots(v) },
                  ]}
                  dataSource={tableDataAdditionalFees}
                  pagination={false}
                  bordered
                />
              </Space>
            </Card>
            <Card bordered title="Tổng chi phí">
              <DescriptionsTable column={1}>
                <Descriptions.Item label={isSettlement ? 'Thành tiền' : 'Tổng'}>
                  {numberWithDots(detailData?.total ?? 0)}
                </Descriptions.Item>
              </DescriptionsTable>
            </Card>
          </Space>
        </Space>
      </Spin>
    </div>
  );
});
