import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminUpdateAccessaryMutationVariables = Types.Exact<{
  input: Types.UpdateAccessaryInput;
}>;

export type AdminUpdateAccessaryMutationResponse = { __typename?: 'Mutation' } & {
  adminUpdateAccessary: { __typename?: 'ProductEntity' } & Pick<Types.ProductEntity, 'id' | 'name'>;
};

export const AdminUpdateAccessaryDocument = gql`
  mutation adminUpdateAccessary($input: UpdateAccessaryInput!) {
    adminUpdateAccessary(input: $input) {
      id
      name
    }
  }
`;
export function useAdminUpdateAccessaryMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminUpdateAccessaryMutationResponse, AdminUpdateAccessaryMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminUpdateAccessaryMutationResponse, AdminUpdateAccessaryMutationVariables>(
    AdminUpdateAccessaryDocument,
    options,
  );
}
export type AdminUpdateAccessaryMutationHookResult = ReturnType<typeof useAdminUpdateAccessaryMutation>;
export type AdminUpdateAccessaryMutationResult = Apollo.MutationResult<AdminUpdateAccessaryMutationResponse>;
export type AdminUpdateAccessaryMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateAccessaryMutationResponse,
  AdminUpdateAccessaryMutationVariables
>;
