import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminGetInstructionQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type AdminGetInstructionQueryResponse = { __typename?: 'Query' } & {
  adminGetInstruction: { __typename?: 'InstructionEntity' } & Pick<
    Types.InstructionEntity,
    'guideId' | 'id' | 'isActive' | 'name'
  > & {
      files: Array<
        { __typename?: 'Media' } & Pick<Types.Media, 'name' | 'originalUrl' | 'thumbUrl' | 'fullOriginalUrl' | 'id'>
      >;
      guide: { __typename?: 'GuideEntity' } & Pick<Types.GuideEntity, 'id' | 'name'>;
    };
};

export const AdminGetInstructionDocument = gql`
  query adminGetInstruction($id: String!) {
    adminGetInstruction(id: $id) {
      files {
        name
        originalUrl
        thumbUrl
        fullOriginalUrl
        id
      }
      guide {
        id
        name
      }
      guideId
      id
      isActive
      name
    }
  }
`;
export function useAdminGetInstructionQuery(
  baseOptions: Apollo.QueryHookOptions<AdminGetInstructionQueryResponse, AdminGetInstructionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetInstructionQueryResponse, AdminGetInstructionQueryVariables>(
    AdminGetInstructionDocument,
    options,
  );
}
export function useAdminGetInstructionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminGetInstructionQueryResponse, AdminGetInstructionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetInstructionQueryResponse, AdminGetInstructionQueryVariables>(
    AdminGetInstructionDocument,
    options,
  );
}
export type AdminGetInstructionQueryHookResult = ReturnType<typeof useAdminGetInstructionQuery>;
export type AdminGetInstructionLazyQueryHookResult = ReturnType<typeof useAdminGetInstructionLazyQuery>;
export type AdminGetInstructionQueryResult = Apollo.QueryResult<
  AdminGetInstructionQueryResponse,
  AdminGetInstructionQueryVariables
>;
