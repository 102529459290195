import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
import { PartnerFragmentFragmentDoc } from '../fragments/partner.fragment.generated';

const defaultOptions = {} as const;
export type AdminTechniciansQueryVariables = Types.Exact<{
  agencyId?: Types.InputMaybe<Types.Scalars['String']>;
  filters?: Types.InputMaybe<Array<Types.Scalars['JSONObject']> | Types.Scalars['JSONObject']>;
  isActive?: Types.InputMaybe<Types.StatusEnum>;
  isApproved?: Types.InputMaybe<Types.Scalars['Boolean']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  search?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;

export type AdminTechniciansQueryResponse = { __typename?: 'Query' } & {
  adminTechnicians?: Types.Maybe<
    { __typename?: 'PartnerConnection' } & {
      items?: Types.Maybe<
        Array<
          { __typename?: 'PartnerEntity' } & Pick<
            Types.PartnerEntity,
            | 'addressMoreInfo'
            | 'avatarId'
            | 'bank'
            | 'birthday'
            | 'cardNumber'
            | 'citizenId'
            | 'createdAt'
            | 'deletedAt'
            | 'description'
            | 'suggestionPoint'
            | 'email'
            | 'fullname'
            | 'hotline'
            | 'id'
            | 'isActive'
            | 'isApproved'
            | 'latitude'
            | 'longitude'
            | 'mapAddress'
            | 'parentId'
            | 'phone'
            | 'type'
          > & {
              avatar?: Types.Maybe<
                { __typename?: 'Media' } & Pick<
                  Types.Media,
                  | 'id'
                  | 'fullOriginalUrl'
                  | 'fullThumbUrl'
                  | 'createdAt'
                  | 'fileSize'
                  | 'name'
                  | 'originalUrl'
                  | 'ownerId'
                  | 'thumbUrl'
                  | 'type'
                >
              >;
              education?: Types.Maybe<
                { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>
              >;
              level?: Types.Maybe<
                { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>
              >;
              parentInfo?: Types.Maybe<
                { __typename?: 'PartnerEntity' } & Pick<
                  Types.PartnerEntity,
                  | 'addressMoreInfo'
                  | 'avatarId'
                  | 'bank'
                  | 'birthday'
                  | 'cardNumber'
                  | 'citizenId'
                  | 'createdAt'
                  | 'deletedAt'
                  | 'description'
                  | 'email'
                  | 'fullname'
                  | 'hotline'
                  | 'id'
                  | 'isActive'
                  | 'isApproved'
                  | 'latitude'
                  | 'longitude'
                  | 'mapAddress'
                  | 'parentId'
                  | 'phone'
                  | 'type'
                >
              >;
              qualifications?: Types.Maybe<
                Array<
                  { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>
                >
              >;
            }
        >
      >;
      meta: { __typename?: 'BasePaginationMeta' } & Pick<
        Types.BasePaginationMeta,
        'currentPage' | 'itemCount' | 'itemsPerPage' | 'totalItems' | 'totalPages'
      >;
    }
  >;
};

export const AdminTechniciansDocument = gql`
  query adminTechnicians(
    $agencyId: String
    $filters: [JSONObject!]
    $isActive: StatusEnum
    $isApproved: Boolean
    $limit: Int
    $page: Int
    $search: String
    $sort: SortInput
  ) {
    adminTechnicians(
      agencyId: $agencyId
      filters: $filters
      isActive: $isActive
      isApproved: $isApproved
      limit: $limit
      page: $page
      search: $search
      sort: $sort
    ) {
      items {
        ...PartnerFragment
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
  ${PartnerFragmentFragmentDoc}
`;
export function useAdminTechniciansQuery(
  baseOptions?: Apollo.QueryHookOptions<AdminTechniciansQueryResponse, AdminTechniciansQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminTechniciansQueryResponse, AdminTechniciansQueryVariables>(
    AdminTechniciansDocument,
    options,
  );
}
export function useAdminTechniciansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminTechniciansQueryResponse, AdminTechniciansQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminTechniciansQueryResponse, AdminTechniciansQueryVariables>(
    AdminTechniciansDocument,
    options,
  );
}
export type AdminTechniciansQueryHookResult = ReturnType<typeof useAdminTechniciansQuery>;
export type AdminTechniciansLazyQueryHookResult = ReturnType<typeof useAdminTechniciansLazyQuery>;
export type AdminTechniciansQueryResult = Apollo.QueryResult<
  AdminTechniciansQueryResponse,
  AdminTechniciansQueryVariables
>;
