import React, { memo } from 'react';
import { Col, Form, FormInstance, Row, Select } from 'antd';

import { CategoryEntity } from '../../graphql/type.interface';
import { ModalCustomize } from '../../components/modal-customize/modal-customize';
import { Messages } from '../../constants/message';

export interface AccessaryInput {
  vehicleTypeId: string;
  manufacturerId: string;
  modelId: string;
}

type Props = {
  open: boolean;
  form: FormInstance;
  handleClose: () => void;
  onFinish: (values: AccessaryInput) => void;
  vehicleTypesData?: CategoryEntity[];
  modelsData?: CategoryEntity[];
  manufacturersData?: CategoryEntity[];
};

const ModalSelectDevice = memo(
  ({ open, form, handleClose, onFinish, vehicleTypesData, modelsData, manufacturersData }: Props) => {
    return (
      <ModalCustomize
        open={open}
        title="Dùng cho (các) thiết bị"
        width={800}
        okButtonProps={{
          form: 'add-accessary',
          htmlType: 'submit',
        }}
        onCancel={handleClose}>
        <Form form={form} labelAlign="left" id="add-accessary" layout="vertical" onFinish={onFinish} autoComplete="off">
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item
                label="Chủng loại máy"
                name="vehicleTypeId"
                rules={[{ required: true, message: Messages.required('Chủng loại máy') }]}>
                <Select
                  showSearch
                  allowClear
                  placeholder="Chọn chủng loại"
                  options={(vehicleTypesData ?? []).map((el) => ({
                    label: el.name,
                    value: el.id,
                  }))}
                  filterOption={(input, option) => option?.label?.toLowerCase().includes(input.toLowerCase()) ?? false}
                  className="w-[220px]"
                  popupClassName="dropdown-modal-accesaary"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Hãng sản xuất"
                name="manufacturerId"
                rules={[{ required: true, message: Messages.required('Hãng sản xuất') }]}>
                <Select
                  showSearch
                  allowClear
                  placeholder="Chọn hãng sản xuất"
                  options={(manufacturersData ?? []).map((el) => ({
                    label: el.name,
                    value: el.id,
                  }))}
                  filterOption={(input, option) => option?.label?.toLowerCase().includes(input.toLowerCase()) ?? false}
                  className="w-[220px]"
                  popupClassName="dropdown-modal-accesaary"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Model" name="modelId" rules={[{ required: true, message: Messages.required('Model') }]}>
                <Select
                  showSearch
                  allowClear
                  placeholder="Chọn model"
                  options={(modelsData ?? []).map((el) => ({
                    label: el.name,
                    value: el.id,
                  }))}
                  filterOption={(input, option) => option?.label?.toLowerCase().includes(input.toLowerCase()) ?? false}
                  className="w-[220px]"
                  popupClassName="dropdown-modal-accesaary"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </ModalCustomize>
    );
  },
);

export default ModalSelectDevice;
