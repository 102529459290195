import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type QuotationPriceListQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type QuotationPriceListQueryResponse = { __typename?: 'Query' } & {
  quotationPriceList: { __typename?: 'QuotationPriceListEntity' } & Pick<
    Types.QuotationPriceListEntity,
    | 'accessoriesName'
    | 'diagnosticCode'
    | 'expense'
    | 'fixable'
    | 'id'
    | 'isActive'
    | 'vehicleType'
    | 'workingCode'
    | 'workingHour'
  >;
};

export const QuotationPriceListDocument = gql`
  query quotationPriceList($id: String!) {
    quotationPriceList(id: $id) {
      accessoriesName
      diagnosticCode
      expense
      fixable
      id
      isActive
      vehicleType
      workingCode
      workingHour
    }
  }
`;
export function useQuotationPriceListQuery(
  baseOptions: Apollo.QueryHookOptions<QuotationPriceListQueryResponse, QuotationPriceListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QuotationPriceListQueryResponse, QuotationPriceListQueryVariables>(
    QuotationPriceListDocument,
    options,
  );
}
export function useQuotationPriceListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QuotationPriceListQueryResponse, QuotationPriceListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QuotationPriceListQueryResponse, QuotationPriceListQueryVariables>(
    QuotationPriceListDocument,
    options,
  );
}
export type QuotationPriceListQueryHookResult = ReturnType<typeof useQuotationPriceListQuery>;
export type QuotationPriceListLazyQueryHookResult = ReturnType<typeof useQuotationPriceListLazyQuery>;
export type QuotationPriceListQueryResult = Apollo.QueryResult<
  QuotationPriceListQueryResponse,
  QuotationPriceListQueryVariables
>;
