import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
import { PartnerFragmentFragmentDoc } from '../fragments/partner.fragment.generated';

const defaultOptions = {} as const;
export type AdminAddAgencyMutationVariables = Types.Exact<{
  input: Types.CreateAgentInput;
}>;

export type AdminAddAgencyMutationResponse = { __typename?: 'Mutation' } & {
  adminAddAgency: { __typename?: 'PartnerEntity' } & Pick<
    Types.PartnerEntity,
    | 'addressMoreInfo'
    | 'avatarId'
    | 'bank'
    | 'birthday'
    | 'cardNumber'
    | 'citizenId'
    | 'createdAt'
    | 'deletedAt'
    | 'description'
    | 'suggestionPoint'
    | 'email'
    | 'fullname'
    | 'hotline'
    | 'id'
    | 'isActive'
    | 'isApproved'
    | 'latitude'
    | 'longitude'
    | 'mapAddress'
    | 'parentId'
    | 'phone'
    | 'type'
  > & {
      avatar?: Types.Maybe<
        { __typename?: 'Media' } & Pick<
          Types.Media,
          | 'id'
          | 'fullOriginalUrl'
          | 'fullThumbUrl'
          | 'createdAt'
          | 'fileSize'
          | 'name'
          | 'originalUrl'
          | 'ownerId'
          | 'thumbUrl'
          | 'type'
        >
      >;
      education?: Types.Maybe<
        { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>
      >;
      level?: Types.Maybe<
        { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>
      >;
      parentInfo?: Types.Maybe<
        { __typename?: 'PartnerEntity' } & Pick<
          Types.PartnerEntity,
          | 'addressMoreInfo'
          | 'avatarId'
          | 'bank'
          | 'birthday'
          | 'cardNumber'
          | 'citizenId'
          | 'createdAt'
          | 'deletedAt'
          | 'description'
          | 'email'
          | 'fullname'
          | 'hotline'
          | 'id'
          | 'isActive'
          | 'isApproved'
          | 'latitude'
          | 'longitude'
          | 'mapAddress'
          | 'parentId'
          | 'phone'
          | 'type'
        >
      >;
      qualifications?: Types.Maybe<
        Array<{ __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>>
      >;
    };
};

export const AdminAddAgencyDocument = gql`
  mutation adminAddAgency($input: CreateAgentInput!) {
    adminAddAgency(input: $input) {
      ...PartnerFragment
    }
  }
  ${PartnerFragmentFragmentDoc}
`;
export function useAdminAddAgencyMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminAddAgencyMutationResponse, AdminAddAgencyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminAddAgencyMutationResponse, AdminAddAgencyMutationVariables>(
    AdminAddAgencyDocument,
    options,
  );
}
export type AdminAddAgencyMutationHookResult = ReturnType<typeof useAdminAddAgencyMutation>;
export type AdminAddAgencyMutationResult = Apollo.MutationResult<AdminAddAgencyMutationResponse>;
export type AdminAddAgencyMutationOptions = Apollo.BaseMutationOptions<
  AdminAddAgencyMutationResponse,
  AdminAddAgencyMutationVariables
>;
