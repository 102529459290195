import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminBookingSettlementsQueryVariables = Types.Exact<{
  bookingId: Types.Scalars['String'];
}>;

export type AdminBookingSettlementsQueryResponse = { __typename?: 'Query' } & {
  adminBookingSettlements: Array<
    { __typename?: 'SettlementEntity' } & Pick<
      Types.SettlementEntity,
      | 'bookingId'
      | 'createdAt'
      | 'deletedAt'
      | 'discount'
      | 'id'
      | 'invoiceId'
      | 'quotationId'
      | 'status'
      | 'technicianId'
      | 'total'
      | 'updatedAt'
      | 'userId'
      | 'vatTax'
    > & {
        additionalFees: Array<
          { __typename?: 'SettlementAdditionalFeeEntity' } & Pick<
            Types.SettlementAdditionalFeeEntity,
            'amount' | 'createdAt' | 'deletedAt' | 'id' | 'name' | 'settlementId' | 'updatedAt'
          >
        >;
        booking: { __typename?: 'BookingEntity' } & Pick<
          Types.BookingEntity,
          | 'addressMoreInfo'
          | 'code'
          | 'createdAt'
          | 'deletedAt'
          | 'description'
          | 'id'
          | 'latitude'
          | 'longitude'
          | 'mapAddress'
          | 'partnerId'
          | 'problemTexts'
          | 'scheduleReason'
          | 'scheduleTime'
          | 'status'
          | 'technicianCanReviewUser'
          | 'technicianId'
          | 'transportDistance'
          | 'transportDuration'
          | 'transportFee'
          | 'updatedAt'
          | 'userCanReviewAgency'
          | 'userCanReviewTechnician'
          | 'userId'
          | 'vehicleId'
        > & {
            medias: Array<
              { __typename?: 'Media' } & Pick<
                Types.Media,
                | 'createdAt'
                | 'fileSize'
                | 'fullOriginalUrl'
                | 'fullThumbUrl'
                | 'id'
                | 'isDeleted'
                | 'mimeType'
                | 'name'
                | 'originalUrl'
                | 'ownerId'
                | 'thumbUrl'
                | 'type'
                | 'updatedAt'
                | 'videoUrl'
              >
            >;
            partner: { __typename?: 'PartnerEntity' } & Pick<
              Types.PartnerEntity,
              | 'addressMoreInfo'
              | 'avatarId'
              | 'bank'
              | 'birthday'
              | 'cardNumber'
              | 'citizenId'
              | 'countProduct'
              | 'countTechnician'
              | 'createdAt'
              | 'deletedAt'
              | 'description'
              | 'email'
              | 'fullname'
              | 'hotline'
              | 'id'
              | 'isActive'
              | 'isAdmin'
              | 'isApproved'
              | 'latitude'
              | 'longitude'
              | 'mapAddress'
              | 'menus'
              | 'parentId'
              | 'phone'
              | 'star'
              | 'storeStar'
              | 'suggestionPoint'
              | 'type'
              | 'updatedAt'
            >;
            problems?: Types.Maybe<
              Array<
                { __typename?: 'CategoryEntity' } & Pick<
                  Types.CategoryEntity,
                  'createdAt' | 'deletedAt' | 'id' | 'isActive' | 'name' | 'type' | 'updatedAt'
                >
              >
            >;
            quotationAccepted?: Types.Maybe<
              { __typename?: 'QuotationEntity' } & Pick<
                Types.QuotationEntity,
                | 'bookingId'
                | 'createdAt'
                | 'deletedAt'
                | 'diagnosisFee'
                | 'diagnosisNote'
                | 'estimatedCompleteAt'
                | 'id'
                | 'operatingNumber'
                | 'operatingUnit'
                | 'rejectReasons'
                | 'repairFee'
                | 'status'
                | 'technicianId'
                | 'total'
                | 'transportFee'
                | 'updatedAt'
                | 'userId'
              >
            >;
            settlementAccepted?: Types.Maybe<
              { __typename?: 'SettlementEntity' } & Pick<
                Types.SettlementEntity,
                | 'bookingId'
                | 'createdAt'
                | 'deletedAt'
                | 'discount'
                | 'id'
                | 'invoiceId'
                | 'quotationId'
                | 'status'
                | 'technicianId'
                | 'total'
                | 'updatedAt'
                | 'userId'
                | 'vatTax'
              >
            >;
            statusDetail?: Types.Maybe<
              { __typename?: 'BookingStatusEntity' } & Pick<
                Types.BookingStatusEntity,
                | 'bookingId'
                | 'createdAt'
                | 'id'
                | 'note'
                | 'partnerId'
                | 'scheduleReason'
                | 'scheduleTime'
                | 'status'
                | 'userId'
              >
            >;
            technician?: Types.Maybe<
              { __typename?: 'PartnerEntity' } & Pick<
                Types.PartnerEntity,
                | 'addressMoreInfo'
                | 'avatarId'
                | 'bank'
                | 'birthday'
                | 'cardNumber'
                | 'citizenId'
                | 'countProduct'
                | 'countTechnician'
                | 'createdAt'
                | 'deletedAt'
                | 'description'
                | 'email'
                | 'fullname'
                | 'hotline'
                | 'id'
                | 'isActive'
                | 'isAdmin'
                | 'isApproved'
                | 'latitude'
                | 'longitude'
                | 'mapAddress'
                | 'menus'
                | 'parentId'
                | 'phone'
                | 'star'
                | 'storeStar'
                | 'suggestionPoint'
                | 'type'
                | 'updatedAt'
              >
            >;
            technicianReviewUser?: Types.Maybe<
              { __typename?: 'ReviewEntity' } & Pick<
                Types.ReviewEntity,
                | 'assessorId'
                | 'bookingId'
                | 'comment'
                | 'createdAt'
                | 'deletedAt'
                | 'id'
                | 'orderId'
                | 'personEvaluatedId'
                | 'productId'
                | 'star'
                | 'type'
                | 'updatedAt'
              >
            >;
            user: { __typename?: 'UserEntity' } & Pick<
              Types.UserEntity,
              | 'address'
              | 'avatarId'
              | 'birthday'
              | 'certificate'
              | 'createdAt'
              | 'deletedAt'
              | 'email'
              | 'fullname'
              | 'id'
              | 'isActive'
              | 'numberBooking'
              | 'numberMaintenance'
              | 'numberOrder'
              | 'phone'
              | 'star'
              | 'totalBookings'
              | 'totalMaintenanceRequests'
              | 'totalOrders'
              | 'totalPayment'
              | 'totalSpending'
              | 'updatedAt'
            >;
            userReviewAgency?: Types.Maybe<
              { __typename?: 'ReviewEntity' } & Pick<
                Types.ReviewEntity,
                | 'assessorId'
                | 'bookingId'
                | 'comment'
                | 'createdAt'
                | 'deletedAt'
                | 'id'
                | 'orderId'
                | 'personEvaluatedId'
                | 'productId'
                | 'star'
                | 'type'
                | 'updatedAt'
              >
            >;
            userReviewTechnician?: Types.Maybe<
              { __typename?: 'ReviewEntity' } & Pick<
                Types.ReviewEntity,
                | 'assessorId'
                | 'bookingId'
                | 'comment'
                | 'createdAt'
                | 'deletedAt'
                | 'id'
                | 'orderId'
                | 'personEvaluatedId'
                | 'productId'
                | 'star'
                | 'type'
                | 'updatedAt'
              >
            >;
            vehicle: { __typename?: 'VehicleEntity' } & Pick<
              Types.VehicleEntity,
              | 'addressMoreInfo'
              | 'avatarId'
              | 'createdAt'
              | 'deletedAt'
              | 'detail'
              | 'hidden'
              | 'id'
              | 'latitude'
              | 'longitude'
              | 'mapAddress'
              | 'name'
              | 'operatingNumber'
              | 'operatingUnit'
              | 'ordinalNumber'
              | 'serialNumber'
              | 'updatedAt'
              | 'userId'
              | 'vehicleRegistrationPlate'
              | 'vinNumber'
              | 'yearOfManufacture'
            >;
          };
        quotation: { __typename?: 'QuotationEntity' } & Pick<
          Types.QuotationEntity,
          | 'bookingId'
          | 'createdAt'
          | 'deletedAt'
          | 'diagnosisFee'
          | 'diagnosisNote'
          | 'estimatedCompleteAt'
          | 'id'
          | 'operatingNumber'
          | 'operatingUnit'
          | 'rejectReasons'
          | 'repairFee'
          | 'status'
          | 'technicianId'
          | 'total'
          | 'transportFee'
          | 'updatedAt'
          | 'userId'
        > & {
            accessories: Array<
              { __typename?: 'QuotationAccessoryEntity' } & Pick<
                Types.QuotationAccessoryEntity,
                | 'available'
                | 'createdAt'
                | 'deletedAt'
                | 'id'
                | 'name'
                | 'quantity'
                | 'quotationId'
                | 'unit'
                | 'unitPrice'
                | 'updatedAt'
              >
            >;
            additionalFees: Array<
              { __typename?: 'QuotationAdditionalFeeEntity' } & Pick<
                Types.QuotationAdditionalFeeEntity,
                'amount' | 'createdAt' | 'deletedAt' | 'id' | 'name' | 'quotationId' | 'updatedAt'
              >
            >;
            booking: { __typename?: 'BookingEntity' } & Pick<
              Types.BookingEntity,
              | 'addressMoreInfo'
              | 'code'
              | 'createdAt'
              | 'deletedAt'
              | 'description'
              | 'id'
              | 'latitude'
              | 'longitude'
              | 'mapAddress'
              | 'partnerId'
              | 'problemTexts'
              | 'scheduleReason'
              | 'scheduleTime'
              | 'status'
              | 'technicianCanReviewUser'
              | 'technicianId'
              | 'transportDistance'
              | 'transportDuration'
              | 'transportFee'
              | 'updatedAt'
              | 'userCanReviewAgency'
              | 'userCanReviewTechnician'
              | 'userId'
              | 'vehicleId'
            >;
            diagnostics: Array<
              { __typename?: 'QuotationDiagnosticEntity' } & Pick<
                Types.QuotationDiagnosticEntity,
                | 'createdAt'
                | 'deletedAt'
                | 'description'
                | 'diagnosticCode'
                | 'expense'
                | 'id'
                | 'quotationId'
                | 'quotationPriceListId'
                | 'updatedAt'
                | 'workingHour'
              >
            >;
            reasons?: Types.Maybe<
              Array<
                { __typename?: 'CategoryEntity' } & Pick<
                  Types.CategoryEntity,
                  'createdAt' | 'deletedAt' | 'id' | 'isActive' | 'name' | 'type' | 'updatedAt'
                >
              >
            >;
            technician: { __typename?: 'PartnerEntity' } & Pick<
              Types.PartnerEntity,
              | 'addressMoreInfo'
              | 'avatarId'
              | 'bank'
              | 'birthday'
              | 'cardNumber'
              | 'citizenId'
              | 'countProduct'
              | 'countTechnician'
              | 'createdAt'
              | 'deletedAt'
              | 'description'
              | 'email'
              | 'fullname'
              | 'hotline'
              | 'id'
              | 'isActive'
              | 'isAdmin'
              | 'isApproved'
              | 'latitude'
              | 'longitude'
              | 'mapAddress'
              | 'menus'
              | 'parentId'
              | 'phone'
              | 'star'
              | 'storeStar'
              | 'suggestionPoint'
              | 'type'
              | 'updatedAt'
            >;
            user: { __typename?: 'UserEntity' } & Pick<
              Types.UserEntity,
              | 'address'
              | 'avatarId'
              | 'birthday'
              | 'certificate'
              | 'createdAt'
              | 'deletedAt'
              | 'email'
              | 'fullname'
              | 'id'
              | 'isActive'
              | 'numberBooking'
              | 'numberMaintenance'
              | 'numberOrder'
              | 'phone'
              | 'star'
              | 'totalBookings'
              | 'totalMaintenanceRequests'
              | 'totalOrders'
              | 'totalPayment'
              | 'totalSpending'
              | 'updatedAt'
            >;
          };
        technician: { __typename?: 'PartnerEntity' } & Pick<
          Types.PartnerEntity,
          | 'addressMoreInfo'
          | 'avatarId'
          | 'bank'
          | 'birthday'
          | 'cardNumber'
          | 'citizenId'
          | 'countProduct'
          | 'countTechnician'
          | 'createdAt'
          | 'deletedAt'
          | 'description'
          | 'email'
          | 'fullname'
          | 'hotline'
          | 'id'
          | 'isActive'
          | 'isAdmin'
          | 'isApproved'
          | 'latitude'
          | 'longitude'
          | 'mapAddress'
          | 'menus'
          | 'parentId'
          | 'phone'
          | 'star'
          | 'storeStar'
          | 'suggestionPoint'
          | 'type'
          | 'updatedAt'
        > & {
            avatar?: Types.Maybe<
              { __typename?: 'Media' } & Pick<
                Types.Media,
                | 'createdAt'
                | 'fileSize'
                | 'fullOriginalUrl'
                | 'fullThumbUrl'
                | 'id'
                | 'isDeleted'
                | 'mimeType'
                | 'name'
                | 'originalUrl'
                | 'ownerId'
                | 'thumbUrl'
                | 'type'
                | 'updatedAt'
                | 'videoUrl'
              >
            >;
            education?: Types.Maybe<
              { __typename?: 'CategoryEntity' } & Pick<
                Types.CategoryEntity,
                'createdAt' | 'deletedAt' | 'id' | 'isActive' | 'name' | 'type' | 'updatedAt'
              >
            >;
            expenseInfo?: Types.Maybe<{ __typename?: 'Expense' } & Pick<Types.Expense, 'cost' | 'distance' | 'time'>>;
            level?: Types.Maybe<
              { __typename?: 'CategoryEntity' } & Pick<
                Types.CategoryEntity,
                'createdAt' | 'deletedAt' | 'id' | 'isActive' | 'name' | 'type' | 'updatedAt'
              >
            >;
            parentInfo?: Types.Maybe<
              { __typename?: 'PartnerEntity' } & Pick<
                Types.PartnerEntity,
                | 'addressMoreInfo'
                | 'avatarId'
                | 'bank'
                | 'birthday'
                | 'cardNumber'
                | 'citizenId'
                | 'countProduct'
                | 'countTechnician'
                | 'createdAt'
                | 'deletedAt'
                | 'description'
                | 'email'
                | 'fullname'
                | 'hotline'
                | 'id'
                | 'isActive'
                | 'isAdmin'
                | 'isApproved'
                | 'latitude'
                | 'longitude'
                | 'mapAddress'
                | 'menus'
                | 'parentId'
                | 'phone'
                | 'star'
                | 'storeStar'
                | 'suggestionPoint'
                | 'type'
                | 'updatedAt'
              >
            >;
            qualifications?: Types.Maybe<
              Array<
                { __typename?: 'CategoryEntity' } & Pick<
                  Types.CategoryEntity,
                  'createdAt' | 'deletedAt' | 'id' | 'isActive' | 'name' | 'type' | 'updatedAt'
                >
              >
            >;
            reviewSummary?: Types.Maybe<
              { __typename?: 'ReviewSummary' } & Pick<Types.ReviewSummary, 'percent' | 'starAverage' | 'total'>
            >;
            starInfo: Array<{ __typename?: 'StarInfo' } & Pick<Types.StarInfo, 'star' | 'total'>>;
            storeReviewSummary?: Types.Maybe<
              { __typename?: 'ReviewSummary' } & Pick<Types.ReviewSummary, 'percent' | 'starAverage' | 'total'>
            >;
            storeStarInfo?: Types.Maybe<Array<{ __typename?: 'StarInfo' } & Pick<Types.StarInfo, 'star' | 'total'>>>;
          };
        user: { __typename?: 'UserEntity' } & Pick<
          Types.UserEntity,
          | 'address'
          | 'avatarId'
          | 'birthday'
          | 'certificate'
          | 'createdAt'
          | 'deletedAt'
          | 'email'
          | 'fullname'
          | 'id'
          | 'isActive'
          | 'numberBooking'
          | 'numberMaintenance'
          | 'numberOrder'
          | 'phone'
          | 'star'
          | 'totalBookings'
          | 'totalMaintenanceRequests'
          | 'totalOrders'
          | 'totalPayment'
          | 'totalSpending'
          | 'updatedAt'
        > & {
            avatar?: Types.Maybe<
              { __typename?: 'Media' } & Pick<
                Types.Media,
                | 'createdAt'
                | 'fileSize'
                | 'fullOriginalUrl'
                | 'fullThumbUrl'
                | 'id'
                | 'isDeleted'
                | 'mimeType'
                | 'name'
                | 'originalUrl'
                | 'ownerId'
                | 'thumbUrl'
                | 'type'
                | 'updatedAt'
                | 'videoUrl'
              >
            >;
            userAddress?: Types.Maybe<
              { __typename?: 'AddressEntity' } & Pick<
                Types.AddressEntity,
                | 'addressDetail'
                | 'addressName'
                | 'contactName'
                | 'contactPhone'
                | 'createdAt'
                | 'deletedAt'
                | 'id'
                | 'isDefault'
                | 'latitude'
                | 'longitude'
                | 'mapAddress'
                | 'updatedAt'
                | 'userId'
              >
            >;
          };
      }
  >;
};

export const AdminBookingSettlementsDocument = gql`
  query adminBookingSettlements($bookingId: String!) {
    adminBookingSettlements(bookingId: $bookingId) {
      additionalFees {
        amount
        createdAt
        deletedAt
        id
        name
        settlementId
        updatedAt
      }
      booking {
        addressMoreInfo
        code
        createdAt
        deletedAt
        description
        id
        latitude
        longitude
        mapAddress
        medias {
          createdAt
          fileSize
          fullOriginalUrl
          fullThumbUrl
          id
          isDeleted
          mimeType
          name
          originalUrl
          ownerId
          thumbUrl
          type
          updatedAt
          videoUrl
        }
        partner {
          addressMoreInfo
          avatarId
          bank
          birthday
          cardNumber
          citizenId
          countProduct
          countTechnician
          createdAt
          deletedAt
          description
          email
          fullname
          hotline
          id
          isActive
          isAdmin
          isApproved
          latitude
          longitude
          mapAddress
          menus
          parentId
          phone
          star
          storeStar
          suggestionPoint
          type
          updatedAt
        }
        partnerId
        problemTexts
        problems {
          createdAt
          deletedAt
          id
          isActive
          name
          type
          updatedAt
        }
        quotationAccepted {
          bookingId
          createdAt
          deletedAt
          diagnosisFee
          diagnosisNote
          estimatedCompleteAt
          id
          operatingNumber
          operatingUnit
          rejectReasons
          repairFee
          status
          technicianId
          total
          transportFee
          updatedAt
          userId
        }
        scheduleReason
        scheduleTime
        settlementAccepted {
          bookingId
          createdAt
          deletedAt
          discount
          id
          invoiceId
          quotationId
          status
          technicianId
          total
          updatedAt
          userId
          vatTax
        }
        status
        statusDetail {
          bookingId
          createdAt
          id
          note
          partnerId
          scheduleReason
          scheduleTime
          status
          userId
        }
        technician {
          addressMoreInfo
          avatarId
          bank
          birthday
          cardNumber
          citizenId
          countProduct
          countTechnician
          createdAt
          deletedAt
          description
          email
          fullname
          hotline
          id
          isActive
          isAdmin
          isApproved
          latitude
          longitude
          mapAddress
          menus
          parentId
          phone
          star
          storeStar
          suggestionPoint
          type
          updatedAt
        }
        technicianCanReviewUser
        technicianId
        technicianReviewUser {
          assessorId
          bookingId
          comment
          createdAt
          deletedAt
          id
          orderId
          personEvaluatedId
          productId
          star
          type
          updatedAt
        }
        transportDistance
        transportDuration
        transportFee
        updatedAt
        user {
          address
          avatarId
          birthday
          certificate
          createdAt
          deletedAt
          email
          fullname
          id
          isActive
          numberBooking
          numberMaintenance
          numberOrder
          phone
          star
          totalBookings
          totalMaintenanceRequests
          totalOrders
          totalPayment
          totalSpending
          updatedAt
        }
        userCanReviewAgency
        userCanReviewTechnician
        userId
        userReviewAgency {
          assessorId
          bookingId
          comment
          createdAt
          deletedAt
          id
          orderId
          personEvaluatedId
          productId
          star
          type
          updatedAt
        }
        userReviewTechnician {
          assessorId
          bookingId
          comment
          createdAt
          deletedAt
          id
          orderId
          personEvaluatedId
          productId
          star
          type
          updatedAt
        }
        vehicle {
          addressMoreInfo
          avatarId
          createdAt
          deletedAt
          detail
          hidden
          id
          latitude
          longitude
          mapAddress
          name
          operatingNumber
          operatingUnit
          ordinalNumber
          serialNumber
          updatedAt
          userId
          vehicleRegistrationPlate
          vinNumber
          yearOfManufacture
        }
        vehicleId
      }
      bookingId
      createdAt
      deletedAt
      discount
      id
      invoiceId
      quotation {
        accessories {
          available
          createdAt
          deletedAt
          id
          name
          quantity
          quotationId
          unit
          unitPrice
          updatedAt
        }
        additionalFees {
          amount
          createdAt
          deletedAt
          id
          name
          quotationId
          updatedAt
        }
        booking {
          addressMoreInfo
          code
          createdAt
          deletedAt
          description
          id
          latitude
          longitude
          mapAddress
          partnerId
          problemTexts
          scheduleReason
          scheduleTime
          status
          technicianCanReviewUser
          technicianId
          transportDistance
          transportDuration
          transportFee
          updatedAt
          userCanReviewAgency
          userCanReviewTechnician
          userId
          vehicleId
        }
        bookingId
        createdAt
        deletedAt
        diagnosisFee
        diagnosisNote
        diagnostics {
          createdAt
          deletedAt
          description
          diagnosticCode
          expense
          id
          quotationId
          quotationPriceListId
          updatedAt
          workingHour
        }
        estimatedCompleteAt
        id
        operatingNumber
        operatingUnit
        reasons {
          createdAt
          deletedAt
          id
          isActive
          name
          type
          updatedAt
        }
        rejectReasons
        repairFee
        status
        technician {
          addressMoreInfo
          avatarId
          bank
          birthday
          cardNumber
          citizenId
          countProduct
          countTechnician
          createdAt
          deletedAt
          description
          email
          fullname
          hotline
          id
          isActive
          isAdmin
          isApproved
          latitude
          longitude
          mapAddress
          menus
          parentId
          phone
          star
          storeStar
          suggestionPoint
          type
          updatedAt
        }
        technicianId
        total
        transportFee
        updatedAt
        user {
          address
          avatarId
          birthday
          certificate
          createdAt
          deletedAt
          email
          fullname
          id
          isActive
          numberBooking
          numberMaintenance
          numberOrder
          phone
          star
          totalBookings
          totalMaintenanceRequests
          totalOrders
          totalPayment
          totalSpending
          updatedAt
        }
        userId
      }
      quotationId
      status
      technician {
        addressMoreInfo
        avatar {
          createdAt
          fileSize
          fullOriginalUrl
          fullThumbUrl
          id
          isDeleted
          mimeType
          name
          originalUrl
          ownerId
          thumbUrl
          type
          updatedAt
          videoUrl
        }
        avatarId
        bank
        birthday
        cardNumber
        citizenId
        countProduct
        countTechnician
        createdAt
        deletedAt
        description
        education {
          createdAt
          deletedAt
          id
          isActive
          name
          type
          updatedAt
        }
        email
        expenseInfo {
          cost
          distance
          time
        }
        fullname
        hotline
        id
        isActive
        isAdmin
        isApproved
        latitude
        level {
          createdAt
          deletedAt
          id
          isActive
          name
          type
          updatedAt
        }
        longitude
        mapAddress
        menus
        parentId
        parentInfo {
          addressMoreInfo
          avatarId
          bank
          birthday
          cardNumber
          citizenId
          countProduct
          countTechnician
          createdAt
          deletedAt
          description
          email
          fullname
          hotline
          id
          isActive
          isAdmin
          isApproved
          latitude
          longitude
          mapAddress
          menus
          parentId
          phone
          star
          storeStar
          suggestionPoint
          type
          updatedAt
        }
        phone
        qualifications {
          createdAt
          deletedAt
          id
          isActive
          name
          type
          updatedAt
        }
        reviewSummary {
          percent
          starAverage
          total
        }
        star
        starInfo {
          star
          total
        }
        storeReviewSummary {
          percent
          starAverage
          total
        }
        storeStar
        storeStarInfo {
          star
          total
        }
        suggestionPoint
        type
        updatedAt
      }
      technicianId
      total
      updatedAt
      user {
        address
        avatar {
          createdAt
          fileSize
          fullOriginalUrl
          fullThumbUrl
          id
          isDeleted
          mimeType
          name
          originalUrl
          ownerId
          thumbUrl
          type
          updatedAt
          videoUrl
        }
        avatarId
        birthday
        certificate
        createdAt
        deletedAt
        email
        fullname
        id
        isActive
        numberBooking
        numberMaintenance
        numberOrder
        phone
        star
        totalBookings
        totalMaintenanceRequests
        totalOrders
        totalPayment
        totalSpending
        updatedAt
        userAddress {
          addressDetail
          addressName
          contactName
          contactPhone
          createdAt
          deletedAt
          id
          isDefault
          latitude
          longitude
          mapAddress
          updatedAt
          userId
        }
      }
      userId
      vatTax
    }
  }
`;
export function useAdminBookingSettlementsQuery(
  baseOptions: Apollo.QueryHookOptions<AdminBookingSettlementsQueryResponse, AdminBookingSettlementsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminBookingSettlementsQueryResponse, AdminBookingSettlementsQueryVariables>(
    AdminBookingSettlementsDocument,
    options,
  );
}
export function useAdminBookingSettlementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminBookingSettlementsQueryResponse,
    AdminBookingSettlementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminBookingSettlementsQueryResponse, AdminBookingSettlementsQueryVariables>(
    AdminBookingSettlementsDocument,
    options,
  );
}
export type AdminBookingSettlementsQueryHookResult = ReturnType<typeof useAdminBookingSettlementsQuery>;
export type AdminBookingSettlementsLazyQueryHookResult = ReturnType<typeof useAdminBookingSettlementsLazyQuery>;
export type AdminBookingSettlementsQueryResult = Apollo.QueryResult<
  AdminBookingSettlementsQueryResponse,
  AdminBookingSettlementsQueryVariables
>;
