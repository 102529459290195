import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminCreateGuideMutationVariables = Types.Exact<{
  input: Types.CreateGuideInput;
}>;

export type AdminCreateGuideMutationResponse = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'adminCreateGuide'>;

export const AdminCreateGuideDocument = gql`
  mutation adminCreateGuide($input: CreateGuideInput!) {
    adminCreateGuide(input: $input)
  }
`;
export function useAdminCreateGuideMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminCreateGuideMutationResponse, AdminCreateGuideMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminCreateGuideMutationResponse, AdminCreateGuideMutationVariables>(
    AdminCreateGuideDocument,
    options,
  );
}
export type AdminCreateGuideMutationHookResult = ReturnType<typeof useAdminCreateGuideMutation>;
export type AdminCreateGuideMutationResult = Apollo.MutationResult<AdminCreateGuideMutationResponse>;
export type AdminCreateGuideMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateGuideMutationResponse,
  AdminCreateGuideMutationVariables
>;
