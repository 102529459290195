import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminUpsertUserMutationVariables = Types.Exact<{
  input: Types.AdminUpsertUserInput;
}>;

export type AdminUpsertUserMutationResponse = { __typename?: 'Mutation' } & {
  adminUpsertUser: { __typename?: 'UserEntity' } & Pick<Types.UserEntity, 'id'>;
};

export const AdminUpsertUserDocument = gql`
  mutation adminUpsertUser($input: AdminUpsertUserInput!) {
    adminUpsertUser(input: $input) {
      id
    }
  }
`;
export function useAdminUpsertUserMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminUpsertUserMutationResponse, AdminUpsertUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminUpsertUserMutationResponse, AdminUpsertUserMutationVariables>(
    AdminUpsertUserDocument,
    options,
  );
}
export type AdminUpsertUserMutationHookResult = ReturnType<typeof useAdminUpsertUserMutation>;
export type AdminUpsertUserMutationResult = Apollo.MutationResult<AdminUpsertUserMutationResponse>;
export type AdminUpsertUserMutationOptions = Apollo.BaseMutationOptions<
  AdminUpsertUserMutationResponse,
  AdminUpsertUserMutationVariables
>;
