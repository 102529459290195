import React, { useCallback, useEffect } from 'react';
import { Checkbox, Form, Input, Radio } from 'antd';

import { AnswerType, QuestionEntity, SurveyResult } from '../../../graphql/type.interface';
import { validationMessages } from '../../../helpers';

type Props = {
  results?: SurveyResult[];
  questions?: QuestionEntity[];
};

export const ContentAnswerSurvey = ({ results, questions }: Props) => {
  const [form] = Form.useForm();

  useEffect(() => {
    const parse: any = {};

    (results ?? []).forEach((it: SurveyResult) => {
      parse[it?.questionId] = it?.answer;
    });
    form.setFieldsValue({
      ...parse,
    });
  }, [form, results]);

  const renderContentByAnswerType = useCallback((q: QuestionEntity) => {
    if (q?.answerType !== AnswerType.SHORT_ANSWER) {
      const options = (q?.answers ?? []).map((a: string) => ({ label: a, value: a }));

      if (q?.answerType === AnswerType.MULTIPLE_CHOICE) {
        return <Checkbox.Group options={options ?? []} />;
      }

      if (q?.answerType === AnswerType.CHECKBOX) {
        return <Radio.Group options={options ?? []} />;
      }
    }
    return <Input.TextArea placeholder="Nhập câu trả lời" rows={4} showCount maxLength={1000} />;
  }, []);

  return (
    <div>
      <Form form={form} name="survey-form" id="survey-form" labelAlign="left" layout="vertical" disabled={true}>
        {(questions ?? []).map((q) => {
          const rules = q?.isRequired ? [{ required: true, message: validationMessages?.required }] : undefined;
          return (
            <div key={q?.id}>
              <Form.Item label={q?.question + ':' ?? ''} name={q?.id} rules={rules}>
                {renderContentByAnswerType(q as QuestionEntity)}
              </Form.Item>
            </div>
          );
        })}
      </Form>
    </div>
  );
};
