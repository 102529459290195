import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminCreateInstructionMutationVariables = Types.Exact<{
  input: Types.CreateInstructionInput;
}>;

export type AdminCreateInstructionMutationResponse = { __typename?: 'Mutation' } & Pick<
  Types.Mutation,
  'adminCreateInstruction'
>;

export const AdminCreateInstructionDocument = gql`
  mutation adminCreateInstruction($input: CreateInstructionInput!) {
    adminCreateInstruction(input: $input)
  }
`;
export function useAdminCreateInstructionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateInstructionMutationResponse,
    AdminCreateInstructionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminCreateInstructionMutationResponse, AdminCreateInstructionMutationVariables>(
    AdminCreateInstructionDocument,
    options,
  );
}
export type AdminCreateInstructionMutationHookResult = ReturnType<typeof useAdminCreateInstructionMutation>;
export type AdminCreateInstructionMutationResult = Apollo.MutationResult<AdminCreateInstructionMutationResponse>;
export type AdminCreateInstructionMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateInstructionMutationResponse,
  AdminCreateInstructionMutationVariables
>;
