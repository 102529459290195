import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminCreateNewsMutationVariables = Types.Exact<{
  input: Types.CreateNewsInput;
}>;

export type AdminCreateNewsMutationResponse = { __typename?: 'Mutation' } & {
  adminCreateNews: { __typename?: 'NewsEntity' } & Pick<
    Types.NewsEntity,
    'body' | 'description' | 'id' | 'isActive' | 'mediaId' | 'title'
  > & { media: { __typename?: 'Media' } & Pick<Types.Media, 'fullOriginalUrl' | 'fullThumbUrl' | 'id' | 'videoUrl'> };
};

export const AdminCreateNewsDocument = gql`
  mutation adminCreateNews($input: CreateNewsInput!) {
    adminCreateNews(input: $input) {
      body
      description
      id
      isActive
      media {
        fullOriginalUrl
        fullThumbUrl
        id
        videoUrl
      }
      mediaId
      title
    }
  }
`;
export function useAdminCreateNewsMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminCreateNewsMutationResponse, AdminCreateNewsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminCreateNewsMutationResponse, AdminCreateNewsMutationVariables>(
    AdminCreateNewsDocument,
    options,
  );
}
export type AdminCreateNewsMutationHookResult = ReturnType<typeof useAdminCreateNewsMutation>;
export type AdminCreateNewsMutationResult = Apollo.MutationResult<AdminCreateNewsMutationResponse>;
export type AdminCreateNewsMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateNewsMutationResponse,
  AdminCreateNewsMutationVariables
>;
