import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminUpdateGuideMutationVariables = Types.Exact<{
  input: Types.UpdateGuideInput;
}>;

export type AdminUpdateGuideMutationResponse = { __typename?: 'Mutation' } & {
  adminUpdateGuide: { __typename?: 'GuideEntity' } & Pick<Types.GuideEntity, 'id' | 'name'>;
};

export const AdminUpdateGuideDocument = gql`
  mutation adminUpdateGuide($input: UpdateGuideInput!) {
    adminUpdateGuide(input: $input) {
      id
      name
    }
  }
`;
export function useAdminUpdateGuideMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminUpdateGuideMutationResponse, AdminUpdateGuideMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminUpdateGuideMutationResponse, AdminUpdateGuideMutationVariables>(
    AdminUpdateGuideDocument,
    options,
  );
}
export type AdminUpdateGuideMutationHookResult = ReturnType<typeof useAdminUpdateGuideMutation>;
export type AdminUpdateGuideMutationResult = Apollo.MutationResult<AdminUpdateGuideMutationResponse>;
export type AdminUpdateGuideMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateGuideMutationResponse,
  AdminUpdateGuideMutationVariables
>;
