import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminUpdateProductVehicleMutationVariables = Types.Exact<{
  input: Types.UpdateProductVehicleInput;
}>;

export type AdminUpdateProductVehicleMutationResponse = { __typename?: 'Mutation' } & {
  adminUpdateProductVehicle: { __typename?: 'ProductEntity' } & Pick<Types.ProductEntity, 'id' | 'name'>;
};

export const AdminUpdateProductVehicleDocument = gql`
  mutation adminUpdateProductVehicle($input: UpdateProductVehicleInput!) {
    adminUpdateProductVehicle(input: $input) {
      id
      name
    }
  }
`;
export function useAdminUpdateProductVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpdateProductVehicleMutationResponse,
    AdminUpdateProductVehicleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminUpdateProductVehicleMutationResponse, AdminUpdateProductVehicleMutationVariables>(
    AdminUpdateProductVehicleDocument,
    options,
  );
}
export type AdminUpdateProductVehicleMutationHookResult = ReturnType<typeof useAdminUpdateProductVehicleMutation>;
export type AdminUpdateProductVehicleMutationResult = Apollo.MutationResult<AdminUpdateProductVehicleMutationResponse>;
export type AdminUpdateProductVehicleMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateProductVehicleMutationResponse,
  AdminUpdateProductVehicleMutationVariables
>;
