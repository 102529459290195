import React, { memo } from 'react';

import { MasterDataTable } from '../../components';
import { CategoryTypeEnum } from '../../graphql/type.interface';
import { AppRoutes } from '../../helpers';

const QualificationManagement = memo(() => {
  return (
    <MasterDataTable
      type={CategoryTypeEnum.QUALIFICATION}
      title="Trình độ chuyên môn"
      urlCreate={AppRoutes.qualificationCreate}
      urlEdit={AppRoutes.qualificationEditId}
    />
  );
});

export default QualificationManagement;
