import { Button, Col, Form, Input, Row, Select, Spin, notification } from 'antd';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';

import { InputNumberFormat, PageTitle } from '../../components';
import { AppRoutes, validationMessages } from '../../helpers';
import { CategoryTypeEnum, CreateVehicleTypeCategory, StatusEnum } from '../../graphql/type.interface';
import { useCategoriesQuery } from '../../graphql/queries/categories.generated';
import { useAdminCreateVehicleTypeCategoryMutation } from '../../graphql/mutations/adminCreateVehicleTypeCategory.generated';
import { useAdminGetVehicleTypeCategoryQuery } from '../../graphql/queries/adminGetVehicleTypeCategory.generated';
import { AdminGetVehicleTypeCategoriesDocument } from '../../graphql/queries/adminGetVehicleTypeCategories.generated';
import { useAdminUpdateVehicleTypeCategoryMutation } from '../../graphql/mutations/adminUpdateVehicleTypeCategory.generated';

interface Props {
  isEdit?: boolean;
  isDetail?: boolean;
}

const FormVehicleType = memo(({ isEdit, isDetail }: Props) => {
  const { id = '' } = useParams();

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { data, loading } = useAdminGetVehicleTypeCategoryQuery({
    variables: { id },
    skip: !id,
  });

  const vehicleType = useMemo(() => data?.adminGetVehicleTypeCategory, [data?.adminGetVehicleTypeCategory]);

  useEffect(() => {
    form.setFieldsValue({
      vehicleTypeId: vehicleType?.vehicleType.id,
      modelId: vehicleType?.model.id,
      operatingNumber: vehicleType?.operatingNumber,
      maintenanceAccessories: vehicleType?.maintenanceAcessories ?? [],
    });
  }, [form, vehicleType]);

  const { loading: gettingVehicleType, data: vehicleTypeData } = useCategoriesQuery({
    variables: {
      type: CategoryTypeEnum.VEHICLE_TYPE,
      limit: 1000,
      isActive: StatusEnum.ACTIVE,
    },
  });

  const { loading: gettingModelType, data: modelTypeData } = useCategoriesQuery({
    variables: {
      type: CategoryTypeEnum.MODEL,
      limit: 1000,
      isActive: StatusEnum.ACTIVE,
    },
  });

  const modelTypeOptions = useMemo(
    () =>
      modelTypeData?.categories.items?.map((it) => ({
        label: it.name,
        value: it.id,
      })),
    [modelTypeData?.categories.items],
  );

  const [createVehicleTypeCategory, { loading: loadingCreateVehicleType }] = useAdminCreateVehicleTypeCategoryMutation({
    onError: (err) =>
      notification.error({ message: 'Thêm mới hạng mục và vật tư bảo dưỡng thất bại.', description: err?.message }),
    onCompleted: () => {
      notification.success({ message: 'Thêm mới hạng mục và vật tư bảo dưỡng thành công.' });
      navigate(AppRoutes.vehicleTypeManagement);
    },
  });

  const [updateVehicleTypeCategory, { loading: loadingUpdateVehicleType }] = useAdminUpdateVehicleTypeCategoryMutation({
    onError: (err) =>
      notification.error({ message: 'Chỉnh sửa hạng mục và vật tư bảo dưỡng thất bại.', description: err?.message }),
    onCompleted: () => {
      notification.success({ message: 'Chỉnh sửa hạng mục và vật tư bảo dưỡng thành công.' });
      navigate(AppRoutes.vehicleTypeManagement);
    },
  });

  const vehicleTypeOptions = useMemo(
    () =>
      vehicleTypeData?.categories.items?.map((it) => ({
        label: it.name,
        value: it.id,
      })),
    [vehicleTypeData?.categories.items],
  );

  const onFinish = useCallback(
    (values: CreateVehicleTypeCategory) => {
      if (isEdit) {
        const newMaintenanceAccessories = (values.maintenanceAccessories ?? []).map(
          ({ id, quantity, unit, name }: any) => {
            return { id: id ?? '', quantity, unit, name };
          },
        );
        updateVehicleTypeCategory({
          variables: {
            input: {
              id: id as string,
              maintenanceAccessories: newMaintenanceAccessories,
              operatingNumber: values.operatingNumber,
            },
          },
          refetchQueries: [AdminGetVehicleTypeCategoriesDocument],
        });
      } else {
        createVehicleTypeCategory({
          variables: {
            input: {
              modelId: values.modelId,
              operatingNumber: values.operatingNumber,
              vehicleTypeId: values.vehicleTypeId,
              maintenanceAccessories: values.maintenanceAccessories,
            },
          },
          refetchQueries: [AdminGetVehicleTypeCategoriesDocument],
        });
      }
    },
    [createVehicleTypeCategory, id, isEdit, updateVehicleTypeCategory],
  );

  return (
    <div>
      <Spin spinning={loading || gettingVehicleType || gettingModelType}>
        <PageTitle
          link={AppRoutes.vehicleTypeManagement}
          title={
            isDetail
              ? 'Thông tin chi tiết hạng mục và vật tư bảo dưỡng'
              : !isDetail && isEdit
              ? 'Chỉnh sửa hạng mục và vật tư bảo dưỡng'
              : 'Thêm mới hạng mục và vật tư bảo dưỡng'
          }
        />
        <div>
          <Form
            form={form}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 15 }}
            labelAlign="left"
            disabled={isDetail}
            size="small"
            id="form-vehicle-type"
            name="form-vehicle-type"
            onFinish={onFinish}>
            <Form.Item
              label={
                <span>
                  Chủng loại máy<span className="text-red"> *</span>
                </span>
              }
              name="vehicleTypeId"
              rules={[{ required: true, message: validationMessages.required }]}>
              <Select
                showSearch
                disabled={isEdit}
                placeholder="Chọn chủng loại máy"
                options={vehicleTypeOptions}
                filterOption={(input, option) =>
                  (option?.label.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
              />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  Model<span className="text-red"> *</span>
                </span>
              }
              name="modelId"
              rules={[{ required: true, message: validationMessages.required }]}>
              <Select
                showSearch
                disabled={isEdit}
                placeholder="Chọn model"
                filterOption={(input, option) =>
                  (option?.label.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={modelTypeOptions}
              />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  Số giờ vận hành<span className="text-red"> *</span>
                </span>
              }
              rules={[{ required: true, message: validationMessages.required }]}
              name="operatingNumber">
              <InputNumberFormat
                suffix="Giờ"
                placeholder="Nhập số giờ vận hành"
                styleContainer="w-[300px]"
                className="w-[300px]"
              />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  Vật tư bảo dưỡng<span className="text-red"> *</span>
                </span>
              }
              rules={[{ required: true, message: validationMessages.required }]}>
              <Form.List name="maintenanceAccessories">
                {(fields, { add, remove }) => {
                  return (
                    <div className="w-full">
                      {fields.map(({ key, name, ...restField }) => (
                        <div key={key} className="flex gap-5 w-full">
                          <Form.Item
                            {...restField}
                            name={[name, 'name']}
                            rules={[{ required: true, message: validationMessages.required }]}>
                            <Input className="w-[250px]" maxLength={255} placeholder="Nhập vật tư bảo dưỡng" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'quantity']}
                            rules={[{ required: true, message: validationMessages.required }]}>
                            <InputNumberFormat className="w-[200px]" placeholder="Nhập số lượng" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'unit']}
                            rules={[{ required: true, message: validationMessages.required }]}>
                            <Input className="w-[180px]" placeholder="Đơn vị" />
                          </Form.Item>
                          {fields.length > 1 ? (
                            <Button type="text" onClick={() => remove(name)}>
                              <DeleteOutlined />
                            </Button>
                          ) : null}
                        </div>
                      ))}
                      <Form.Item>
                        <Button type="primary" onClick={() => add()}>
                          Thêm
                        </Button>
                      </Form.Item>
                    </div>
                  );
                }}
              </Form.List>
            </Form.Item>

            {!isDetail && (
              <Row>
                <Col span={20} className="flex justify-end space-x-4">
                  <Button className="w-20" type="default" onClick={() => navigate(-1)}>
                    Huỷ
                  </Button>
                  <Button
                    className="w-20"
                    type="primary"
                    htmlType="submit"
                    loading={loadingCreateVehicleType || loadingUpdateVehicleType}
                    disabled={loadingCreateVehicleType || loadingUpdateVehicleType}>
                    Lưu
                  </Button>
                </Col>
              </Row>
            )}
          </Form>
        </div>
      </Spin>
    </div>
  );
});

export default FormVehicleType;
