import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminGetUserVehiclesQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Array<Types.Scalars['JSONObject']> | Types.Scalars['JSONObject']>;
  isActive?: Types.InputMaybe<Types.StatusEnum>;
  isApproved?: Types.InputMaybe<Types.Scalars['Boolean']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  search?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.SortInput>;
  userId: Types.Scalars['String'];
}>;

export type AdminGetUserVehiclesQueryResponse = { __typename?: 'Query' } & {
  adminGetUserVehicles: { __typename?: 'VehicleConnection' } & {
    items?: Types.Maybe<
      Array<
        { __typename?: 'VehicleEntity' } & Pick<
          Types.VehicleEntity,
          'id' | 'mapAddress' | 'serialNumber' | 'name' | 'vinNumber' | 'yearOfManufacture'
        > & {
            model?: Types.Maybe<{ __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'name'>>;
            manufacturer?: Types.Maybe<{ __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'name'>>;
          }
      >
    >;
    meta: { __typename?: 'BasePaginationMeta' } & Pick<
      Types.BasePaginationMeta,
      'currentPage' | 'itemCount' | 'itemsPerPage' | 'totalItems' | 'totalPages'
    >;
  };
};

export const AdminGetUserVehiclesDocument = gql`
  query adminGetUserVehicles(
    $filters: [JSONObject!]
    $isActive: StatusEnum
    $isApproved: Boolean
    $limit: Int
    $page: Int
    $search: String
    $sort: SortInput
    $userId: String!
  ) {
    adminGetUserVehicles(
      filters: $filters
      isActive: $isActive
      isApproved: $isApproved
      limit: $limit
      page: $page
      search: $search
      sort: $sort
      userId: $userId
    ) {
      items {
        id
        mapAddress
        model {
          name
        }
        manufacturer {
          name
        }
        serialNumber
        name
        vinNumber
        yearOfManufacture
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;
export function useAdminGetUserVehiclesQuery(
  baseOptions: Apollo.QueryHookOptions<AdminGetUserVehiclesQueryResponse, AdminGetUserVehiclesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetUserVehiclesQueryResponse, AdminGetUserVehiclesQueryVariables>(
    AdminGetUserVehiclesDocument,
    options,
  );
}
export function useAdminGetUserVehiclesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminGetUserVehiclesQueryResponse, AdminGetUserVehiclesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetUserVehiclesQueryResponse, AdminGetUserVehiclesQueryVariables>(
    AdminGetUserVehiclesDocument,
    options,
  );
}
export type AdminGetUserVehiclesQueryHookResult = ReturnType<typeof useAdminGetUserVehiclesQuery>;
export type AdminGetUserVehiclesLazyQueryHookResult = ReturnType<typeof useAdminGetUserVehiclesLazyQuery>;
export type AdminGetUserVehiclesQueryResult = Apollo.QueryResult<
  AdminGetUserVehiclesQueryResponse,
  AdminGetUserVehiclesQueryVariables
>;
