import React from 'react';

import { AppRoutes } from '../../helpers';
import { MasterForm } from '../../components';
import { CategoryTypeEnum } from '../../graphql/type.interface';

export default function FormVehicle() {
  return (
    <MasterForm title="Chủng loại" backTo={AppRoutes.vehicleManagement} typeCategory={CategoryTypeEnum.VEHICLE_TYPE} />
  );
}
