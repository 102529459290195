import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminUpdateSurveyMutationVariables = Types.Exact<{
  input: Types.UpdateSurveyInput;
}>;

export type AdminUpdateSurveyMutationResponse = { __typename?: 'Mutation' } & {
  adminUpdateSurvey: { __typename?: 'SurveyEntity' } & Pick<Types.SurveyEntity, 'id'>;
};

export const AdminUpdateSurveyDocument = gql`
  mutation adminUpdateSurvey($input: UpdateSurveyInput!) {
    adminUpdateSurvey(input: $input) {
      id
    }
  }
`;
export function useAdminUpdateSurveyMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminUpdateSurveyMutationResponse, AdminUpdateSurveyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminUpdateSurveyMutationResponse, AdminUpdateSurveyMutationVariables>(
    AdminUpdateSurveyDocument,
    options,
  );
}
export type AdminUpdateSurveyMutationHookResult = ReturnType<typeof useAdminUpdateSurveyMutation>;
export type AdminUpdateSurveyMutationResult = Apollo.MutationResult<AdminUpdateSurveyMutationResponse>;
export type AdminUpdateSurveyMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateSurveyMutationResponse,
  AdminUpdateSurveyMutationVariables
>;
