import { gql } from '@apollo/client';

import type * as Types from '../type.interface';

import { MediaFragmentFragmentDoc } from './media.fragment.generated';
export type AdminFragmentFragment = { __typename?: 'AdminEntity' } & Pick<
  Types.AdminEntity,
  | 'address'
  | 'addressMoreInfo'
  | 'avatarId'
  | 'birthday'
  | 'createdAt'
  | 'deletedAt'
  | 'email'
  | 'fullname'
  | 'id'
  | 'isActive'
  | 'type'
  | 'updatedAt'
> & {
    avatar?: Types.Maybe<
      { __typename?: 'Media' } & Pick<
        Types.Media,
        | 'id'
        | 'fullOriginalUrl'
        | 'fullThumbUrl'
        | 'createdAt'
        | 'fileSize'
        | 'name'
        | 'originalUrl'
        | 'ownerId'
        | 'thumbUrl'
        | 'type'
      >
    >;
  };

export const AdminFragmentFragmentDoc = gql`
  fragment AdminFragment on AdminEntity {
    address
    addressMoreInfo
    avatar {
      ...MediaFragment
    }
    avatarId
    birthday
    createdAt
    deletedAt
    email
    fullname
    id
    isActive
    type
    updatedAt
  }
  ${MediaFragmentFragmentDoc}
`;
