import { MasterDataTable } from '../../components';
import { CategoryTypeEnum } from '../../graphql/type.interface';
import { AppRoutes } from '../../helpers';

export default function OriginManagement() {
  return (
    <MasterDataTable
      type={CategoryTypeEnum.ORIGIN}
      title="Xuất xứ"
      urlCreate={AppRoutes.productOriginManagementCreate}
      urlEdit={AppRoutes.productOriginManagementEditId}
    />
  );
}
