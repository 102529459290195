import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminUpdateGuideStatusMutationVariables = Types.Exact<{
  input: Types.UpdateGuideStatusInput;
}>;

export type AdminUpdateGuideStatusMutationResponse = { __typename?: 'Mutation' } & {
  adminUpdateGuideStatus: { __typename?: 'GuideEntity' } & Pick<Types.GuideEntity, 'id' | 'name'>;
};

export const AdminUpdateGuideStatusDocument = gql`
  mutation adminUpdateGuideStatus($input: UpdateGuideStatusInput!) {
    adminUpdateGuideStatus(input: $input) {
      id
      name
    }
  }
`;
export function useAdminUpdateGuideStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpdateGuideStatusMutationResponse,
    AdminUpdateGuideStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminUpdateGuideStatusMutationResponse, AdminUpdateGuideStatusMutationVariables>(
    AdminUpdateGuideStatusDocument,
    options,
  );
}
export type AdminUpdateGuideStatusMutationHookResult = ReturnType<typeof useAdminUpdateGuideStatusMutation>;
export type AdminUpdateGuideStatusMutationResult = Apollo.MutationResult<AdminUpdateGuideStatusMutationResponse>;
export type AdminUpdateGuideStatusMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateGuideStatusMutationResponse,
  AdminUpdateGuideStatusMutationVariables
>;
