import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminNewsQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Array<Types.Scalars['JSONObject']> | Types.Scalars['JSONObject']>;
  isActive?: Types.InputMaybe<Types.StatusEnum>;
  isApproved?: Types.InputMaybe<Types.Scalars['Boolean']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  search?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;

export type AdminNewsQueryResponse = { __typename?: 'Query' } & {
  adminNews: { __typename?: 'NewsConnection' } & {
    items?: Types.Maybe<Array<{ __typename?: 'NewsEntity' } & Pick<Types.NewsEntity, 'id' | 'isActive' | 'title'>>>;
    meta: { __typename?: 'BasePaginationMeta' } & Pick<
      Types.BasePaginationMeta,
      'currentPage' | 'itemCount' | 'itemsPerPage' | 'totalItems' | 'totalPages'
    >;
  };
};

export const AdminNewsDocument = gql`
  query adminNews(
    $filters: [JSONObject!]
    $isActive: StatusEnum
    $isApproved: Boolean
    $limit: Int
    $page: Int
    $search: String
    $sort: SortInput
  ) {
    adminNews(
      filters: $filters
      isActive: $isActive
      isApproved: $isApproved
      limit: $limit
      page: $page
      search: $search
      sort: $sort
    ) {
      items {
        id
        isActive
        title
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;
export function useAdminNewsQuery(
  baseOptions?: Apollo.QueryHookOptions<AdminNewsQueryResponse, AdminNewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminNewsQueryResponse, AdminNewsQueryVariables>(AdminNewsDocument, options);
}
export function useAdminNewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminNewsQueryResponse, AdminNewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminNewsQueryResponse, AdminNewsQueryVariables>(AdminNewsDocument, options);
}
export type AdminNewsQueryHookResult = ReturnType<typeof useAdminNewsQuery>;
export type AdminNewsLazyQueryHookResult = ReturnType<typeof useAdminNewsLazyQuery>;
export type AdminNewsQueryResult = Apollo.QueryResult<AdminNewsQueryResponse, AdminNewsQueryVariables>;
