import { memo, useMemo } from 'react';
import dayjs from 'dayjs';
import { Descriptions, Spin, Image, Tabs, TabsProps } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DownloadOutlined } from '@ant-design/icons';

import { PageTitle } from '../../components/page-title';
import { useAdminBookingQuery } from '../../graphql/queries/adminBooking.generated';
import { BookingStatusEnum, QuotationEntity, SettlementEntity } from '../../graphql/type.interface';
import { TIME_FORMAT } from '../../constants';
import { reverArray, convertBookingStatus, getNameCategoriesEntity } from '../../utils';
import { useAdminQuotationHistoriesQuery } from '../../graphql/queries/adminQuotationHistories.generated';
import { useAdminGetInvoiceQuery } from '../../graphql/queries/adminGetInvoice.generated';
import { ShowImage } from '../../components';
import { useAdminBookingSettlementsQuery } from '../../graphql/queries/adminBookingSettlements.generated';

import TableRequestRepair from './table-info';

const DetailRequestRepair = memo(() => {
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const { data: bookingData, loading: bookingLoading } = useAdminBookingQuery({ variables: { id }, skip: !id });
  const booking = useMemo(() => bookingData?.adminBooking, [bookingData]);

  const { data: invoiceData, loading: invoiceLoading } = useAdminGetInvoiceQuery({
    skip: bookingLoading || !bookingData || bookingData.adminBooking.status !== BookingStatusEnum.COMPLETE,
    variables: {
      settlementId: bookingData?.adminBooking.settlementAccepted?.id ?? '',
    },
  });

  const isQuotation = useMemo(
    () =>
      booking?.status
        ? [
            BookingStatusEnum.QUOTATION_ACCEPTED,
            BookingStatusEnum.QUOTATION_REJECTED,
            BookingStatusEnum.QUOTATION_REQUESTED,
          ].includes(booking?.status)
        : false,
    [booking],
  );

  const { data: quotationHistoriesData, loading: gettingQuotation } = useAdminQuotationHistoriesQuery({
    variables: {
      bookingId: booking?.id as string,
    },
    skip: !booking || !isQuotation,
  });

  const { data: settlementsHistoriesData, loading: gettingSettlements } = useAdminBookingSettlementsQuery({
    variables: {
      bookingId: booking?.id as string,
    },
    skip: !booking || isQuotation,
  });

  const settlements = useMemo(
    () => settlementsHistoriesData?.adminBookingSettlements ?? [],
    [settlementsHistoriesData],
  );

  const quotationHistories = useMemo(
    () => reverArray(quotationHistoriesData?.adminQuotationHistories ?? []),
    [quotationHistoriesData],
  );

  const tabItems = useMemo(() => {
    const rs: TabsProps['items'] = [];
    (isQuotation ? quotationHistories : settlements)?.forEach((q: any) => {
      rs.push({
        label: dayjs(q.createdAt).format(TIME_FORMAT),
        key: q.id,
        children: (
          <TableRequestRepair
            quotation={(isQuotation ? q : q?.quotation) as QuotationEntity}
            isQuotation={isQuotation}
            settlement={q as SettlementEntity}
          />
        ),
      });
    });
    return rs;
  }, [isQuotation, quotationHistories, settlements]);

  if (!booking) return null;
  return (
    <div className="content-request-repair">
      <Spin spinning={bookingLoading || gettingQuotation || gettingSettlements}>
        <Descriptions
          title={<PageTitle link="#" onClick={() => navigate(-1)} title="Thông tin chi tiết Yêu cầu sửa chữa" />}>
          <Descriptions.Item label="Mã YCSC" span={1}>
            <span className="font-medium text-[#000000]"> {booking?.code}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Tên xe" span={2}>
            <span className="font-medium text-[#000000]"> {booking?.vehicle?.name}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Khách hàng" span={1}>
            <span className="font-medium text-[#000000]">{booking?.user?.fullname}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Vị trí xe" span={2}>
            <span className="font-medium text-[#000000]"> {booking?.mapAddress}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Hiện tượng hư hỏng" span={3}>
            <span className="font-medium text-[#000000]">
              {booking?.problems ? getNameCategoriesEntity(booking.problems) : 'Chưa rõ nguyên nhân'}
            </span>
          </Descriptions.Item>
          <Descriptions.Item label="" span={3}>
            <div className="flex items-center flex-wrap gap-8px">
              <Image.PreviewGroup>
                {booking.medias.map((media) => (
                  <ShowImage
                    key={media?.id}
                    fileType={media?.type}
                    fullThumbUrl={media?.fullThumbUrl as string}
                    fullOriginalUrl={media?.fullOriginalUrl as string}
                  />
                ))}
              </Image.PreviewGroup>
            </div>
          </Descriptions.Item>

          <Descriptions.Item label="Đơn vị sửa chữa" span={1}>
            {booking?.partner?.fullname}
          </Descriptions.Item>
          <Descriptions.Item label="Kỹ thuật viên" span={2}>
            {booking?.technician?.fullname}
          </Descriptions.Item>
          <Descriptions.Item label="Trạng thái YCSC" span={3}>
            <span className="font-medium text-[#000000]">{convertBookingStatus(booking?.status)}</span>
          </Descriptions.Item>
          {bookingData?.adminBooking.status === BookingStatusEnum.COMPLETE && !invoiceLoading && (
            <Descriptions.Item label="Hóa đơn" span={4}>
              <Link to={invoiceData?.adminGetInvoice.fullOriginalUrl ?? ''} download>
                <span className="hover:cursor-pointer hover:text-primary">
                  Tải xuống <DownloadOutlined />
                </span>
              </Link>
            </Descriptions.Item>
          )}
          {tabItems.length > 0 && (
            <Descriptions.Item
              label={<p className="text-[12px] font-bold text-[#000000] mt-[12px]">Lịch sử báo giá sửa chữa</p>}
              span={3}>
              {' '}
            </Descriptions.Item>
          )}
        </Descriptions>
        {tabItems.length > 0 && <Tabs size={'small'} type="card" items={tabItems} />}
      </Spin>
    </div>
  );
});
export default DetailRequestRepair;
