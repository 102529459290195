import { Input, Spin } from 'antd';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';

import { CategoryEntity, CategoryTypeEnum, StatusEnum } from '../../graphql/type.interface';
import { useCategoriesQuery } from '../../graphql/queries/categories.generated';
import { ModalCustomize } from '../modal-customize/modal-customize';

export type FormDataCancel = {
  reasons: string[];
  note: string;
};

type Props = {
  open: boolean;
  onCancel?: () => void;
  onCompleted: (values: FormDataCancel) => void;
  loading?: boolean;
  reasons?: CategoryEntity[];
  title?: string;
  categoryTypeEnum?: CategoryTypeEnum;
};

export const CancelOrder = ({
  categoryTypeEnum,
  loading = false,
  reasons,
  onCompleted,
  title = 'Chọn lý do hủy đơn hàng',
  ...props
}: Props) => {
  const [selectedReason, setSelectedReason] = useState<string[]>([]);
  const [note, setNote] = useState<string>('');

  const { data, loading: gettingReason } = useCategoriesQuery({
    variables: { limit: 1000, isActive: StatusEnum.ACTIVE, type: categoryTypeEnum as CategoryTypeEnum },
    skip: !categoryTypeEnum,
  });
  const reasonsAsync = useMemo(() => data?.categories.items, [data]);

  const handleSelected = useCallback(
    (id: string) => {
      const exist = selectedReason.includes(id);

      if (!exist) {
        setSelectedReason([...selectedReason, id]);
        return;
      }
      setSelectedReason(selectedReason.filter((i) => i !== id));
    },
    [selectedReason],
  );
  const handleChangeNote = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => setNote(e.target.value), []);

  return (
    <ModalCustomize
      {...props}
      title={title}
      onOk={() => onCompleted({ reasons: selectedReason, note })}
      okButtonProps={{
        disabled: !selectedReason || (selectedReason && selectedReason.length === 0 && !note) || loading,
      }}
      cancelButtonProps={{
        disabled: loading,
      }}>
      <Spin spinning={gettingReason || loading}>
        <div className="h-[440px] overflow-y-auto text-14px leading-18px ">
          <span className="text-[#676E72]">Hủy đơn quá nhiều lần có thể ảnh hưởng đến độ uy tín của Đại lý bạn.</span>
          {(reasons ?? reasonsAsync ?? []).map((reason, key: number) => (
            <div
              onClick={() => handleSelected(reason.id)}
              className={`px-16px py-12px mt-16px border border-[#EEEEEE] border-solid rounded mb-12px last:mb-0 cursor-pointer ${
                selectedReason.includes(reason.id) ? 'border-primary bg-[#fffdf6]' : ''
              }`}
              key={key}>
              {reason.name}
            </div>
          ))}
          <Input.TextArea
            placeholder="Nhập lý do khác"
            value={note}
            maxLength={255}
            onChange={handleChangeNote}
            showCount
          />
        </div>
      </Spin>
    </ModalCustomize>
  );
};
