import { memo, useCallback, useMemo } from 'react';
import { Button, Form, Input, notification, Switch } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { AppRoutes, validationMessages } from '../../helpers';
import { FullscreenLoading, PageTitle } from '../../components';
import { RegexConstant } from '../../helpers/regex';
import { notificationMessages } from '../../helpers/notification-messages';
import { AdminUpsertUserInput } from '../../graphql/type.interface';
import { useAdminUserQuery } from '../../graphql/queries/adminUser.generated';
import { useAdminUpsertUserMutation } from '../../graphql/mutations/adminUpsertUser.generated';

type Props = {
  isDetail?: boolean;
  isCreate?: boolean;
};

type FormData = AdminUpsertUserInput;

const CustomerForm = memo(({ isDetail, isCreate }: Props) => {
  const navigate = useNavigate();
  const { id } = useParams() as {
    id: string;
  };
  const customerFormType = !isDetail && !isCreate ? 'update' : 'create';
  const { data, loading } = useAdminUserQuery({ variables: { id }, skip: !id });

  const user = useMemo(() => data?.adminUser, [data]);

  const [upsertUser, { loading: upsertUserLoading }] = useAdminUpsertUserMutation({
    onError(err) {
      notification.error({
        message: notificationMessages[customerFormType].fail,
        description: err.message,
      });
    },
    onCompleted() {
      notification.success({ message: notificationMessages[customerFormType].success });
      navigate(-1);
    },
  });

  const isLoading = useMemo(() => upsertUserLoading || loading, [loading, upsertUserLoading]);

  const onFinish = useCallback(
    async (values: FormData) => {
      await upsertUser({
        variables: {
          input: {
            ...values,
            id,
          },
        },
      });
    },
    [upsertUser, id],
  );

  if (loading) return <FullscreenLoading />;

  return (
    <div>
      <PageTitle
        title={
          !isDetail && !isCreate
            ? 'Chỉnh sửa thông tin khách hàng'
            : !isDetail && isCreate
            ? 'Thêm mới khách hàng'
            : 'Thông tin khách hàng'
        }
        link={AppRoutes.customerManagement}
      />
      <div>
        <Form
          labelCol={{ span: 5 }}
          labelAlign="left"
          id="form-agency"
          name="form-agency"
          wrapperCol={{ span: 15 }}
          size="small"
          initialValues={
            {
              address: user?.address,
              avatarId: user?.avatarId,
              birthday: user?.birthday,
              email: user?.email,
              fullname: user?.fullname,
              phone: user?.phone,
              isActive: user?.isActive ?? true,
              password: !isDetail && isCreate ? 'Callme@2023' : '',
            } as FormData
          }
          disabled={isDetail || isLoading}
          onFinish={onFinish}
          autoComplete="off"
          className="mb-[60px]">
          <Form.Item name="id">
            <Input className="hidden" />
          </Form.Item>
          <Form.Item
            label={
              <span>
                Họ tên<span className="text-red"> *</span>
              </span>
            }
            name="fullname"
            normalize={(e) => e.trimStart()}
            rules={[{ required: true, message: 'Tên tên là trường bắt buộc.' }]}>
            <Input placeholder={`Nhập Họ và tên`} maxLength={255} />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            normalize={(e) => e.trimStart()}
            rules={[{ pattern: RegexConstant.EMAIL, message: validationMessages.email.notValid }]}>
            <Input placeholder={`Nhập địa chỉ email`} maxLength={255} />
          </Form.Item>
          <Form.Item
            name="phone"
            label={
              <span>
                Số điện thoại<span className="text-red"> *</span>
              </span>
            }
            normalize={(e) => e.trimStart()}
            rules={[
              { required: true, message: validationMessages.phoneNumber.required },
              { pattern: RegexConstant.PHONE, message: validationMessages.phoneNumber.notValid },
            ]}>
            <Input placeholder={`Nhập số điện thoại`} maxLength={10} />
          </Form.Item>

          <Form.Item
            label={<span>Mật khẩu{id == null && <span className="text-red"> *</span>}</span>}
            name="password"
            normalize={(e) => e.trimStart()}
            rules={[{ required: id == null, message: validationMessages.password.required }]}>
            <Input placeholder={`Nhập mật khẩu đăng nhập`} minLength={8} maxLength={10} />
          </Form.Item>

          <Form.Item name="address" label={<span>Địa chỉ mặc định</span>}>
            <Input placeholder={`Nhập địa chỉ mặc định`} maxLength={255} />
          </Form.Item>

          <Form.Item label="Trạng thái" name="isActive" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form>
      </div>
      {!isDetail && (
        <div className="flex justify-end gap-x-[20px] px-24px py-8px bg-white fixed left-0 bottom-0 right-0">
          <Button size="small" className="w-20" type="default" onClick={() => navigate(-1)}>
            Huỷ
          </Button>
          <Button size="small" type="primary" htmlType="submit" loading={isLoading} form="form-agency">
            Lưu
          </Button>
        </div>
      )}
    </div>
  );
});

export default CustomerForm;
