import { Fragment, memo } from 'react';
import { Descriptions } from 'antd';

import { SectionWrapper } from '../../components';
import { OperatingUnitEnum, QuotationEntity, SettlementAdditionalFeeEntity } from '../../graphql/type.interface';
import { numberWithDots } from '../../utils';

import { TitleSection } from './title-section';

type Props = {
  quotation?: QuotationEntity;
  additionalFeesSettlement?: SettlementAdditionalFeeEntity[];
};

const InfoQuotes = memo(({ quotation, additionalFeesSettlement }: Props) => {
  if (!quotation) return null;
  return (
    <Fragment>
      <SectionWrapper title="I. Chẩn đoán">
        <div className="w-full flex items-center space-x-20px">
          <Descriptions className="w-full" bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
            <Descriptions.Item label="Đã vận hành" labelStyle={{ width: '50%' }} contentStyle={{ color: '#202C38' }}>
              {quotation?.operatingNumber +
                (quotation?.operatingUnit === OperatingUnitEnum.HOURS ? ' giờ' : quotation?.operatingUnit)}
            </Descriptions.Item>
            {(quotation?.diagnostics || []).map((diagnostic) => (
              <Descriptions.Item
                key={diagnostic?.id}
                label={diagnostic?.diagnosticCode}
                labelStyle={{ width: '50%' }}
                contentStyle={{ color: '#202C38' }}>
                {numberWithDots(diagnostic?.expense)}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </div>
      </SectionWrapper>
      <SectionWrapper title="II. Báo giá sửa chữa">
        <TitleSection>1. Vật tư phụ tùng</TitleSection>
        <div className="w-full flex items-center space-x-20px">
          <Descriptions className="w-full" bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
            {(quotation?.accessories || []).map((e: any) => (
              <Descriptions.Item
                key={e.id}
                label={e.name}
                labelStyle={{ width: '50%' }}
                contentStyle={{ width: '50%', color: '#202C38', textAlign: 'right' }}>
                <div>
                  <div>{numberWithDots(e.unitPrice)}</div>
                  <div>x{e.quantity + ' (' + e.unit + ')'}</div>
                </div>
              </Descriptions.Item>
            ))}
          </Descriptions>
        </div>
        <TitleSection>2. Chi phí công dịch vụ</TitleSection>
        <div className="w-full flex items-center space-x-20px">
          <Descriptions className="w-full" bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
            <Descriptions.Item
              label="Phí di chuyển"
              labelStyle={{ width: '50%' }}
              contentStyle={{ width: '50%', color: '#202C38', textAlign: 'right' }}>
              {numberWithDots(quotation?.transportFee)}
            </Descriptions.Item>
            <Descriptions.Item
              label="Phí chẩn đoán"
              labelStyle={{ width: '50%' }}
              contentStyle={{ width: '50%', color: '#202C38', textAlign: 'right' }}>
              {numberWithDots(quotation?.diagnosisFee)}
            </Descriptions.Item>
            <Descriptions.Item
              label="Phí sửa chữa, thay thế"
              labelStyle={{ width: '50%' }}
              contentStyle={{ width: '50%', color: '#202C38', textAlign: 'right' }}>
              {numberWithDots(quotation?.repairFee)}
            </Descriptions.Item>
          </Descriptions>
        </div>
        <TitleSection>3. Chi phí phát sinh</TitleSection>
        <div className="w-full flex items-center space-x-20px">
          <Descriptions className="w-full" bordered column={1}>
            {[...(quotation?.additionalFees ?? []), ...(additionalFeesSettlement ?? [])].map((e: any) => (
              <Descriptions.Item
                span={8}
                key={e.name}
                label={e.name}
                labelStyle={{ width: '50%' }}
                contentStyle={{ width: '50%', color: '#202C38', textAlign: 'right' }}>
                {numberWithDots(e.amount)}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </div>
      </SectionWrapper>
    </Fragment>
  );
});

export default InfoQuotes;
