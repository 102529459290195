import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminUpdateInstructionMutationVariables = Types.Exact<{
  input: Types.UpdateInstructionInput;
}>;

export type AdminUpdateInstructionMutationResponse = { __typename?: 'Mutation' } & {
  adminUpdateInstruction: { __typename?: 'InstructionEntity' } & Pick<Types.InstructionEntity, 'id' | 'name'>;
};

export const AdminUpdateInstructionDocument = gql`
  mutation adminUpdateInstruction($input: UpdateInstructionInput!) {
    adminUpdateInstruction(input: $input) {
      id
      name
    }
  }
`;
export function useAdminUpdateInstructionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpdateInstructionMutationResponse,
    AdminUpdateInstructionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminUpdateInstructionMutationResponse, AdminUpdateInstructionMutationVariables>(
    AdminUpdateInstructionDocument,
    options,
  );
}
export type AdminUpdateInstructionMutationHookResult = ReturnType<typeof useAdminUpdateInstructionMutation>;
export type AdminUpdateInstructionMutationResult = Apollo.MutationResult<AdminUpdateInstructionMutationResponse>;
export type AdminUpdateInstructionMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateInstructionMutationResponse,
  AdminUpdateInstructionMutationVariables
>;
