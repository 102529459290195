import React from 'react';

import { AppRoutes } from '../../helpers';
import { MasterForm } from '../../components';
import { CategoryTypeEnum } from '../../graphql/type.interface';

export default function FormEducation() {
  return (
    <MasterForm
      title="Trình độ học vấn"
      backTo={AppRoutes.productModelManagement}
      typeCategory={CategoryTypeEnum.EDUCATION}
    />
  );
}
