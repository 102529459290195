import React, { memo } from 'react';

import { MasterDataTable } from '../../components';
import { CategoryTypeEnum } from '../../graphql/type.interface';
import { AppRoutes } from '../../helpers';

const LevelManagement = memo(() => {
  return (
    <MasterDataTable
      type={CategoryTypeEnum.LEVEL}
      title="Level"
      urlCreate={AppRoutes.levelCreate}
      urlEdit={AppRoutes.levelEditId}
    />
  );
});

export default LevelManagement;
