import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminOrderQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type AdminOrderQueryResponse = { __typename?: 'Query' } & {
  adminOrder: { __typename?: 'OrderEntity' } & Pick<
    Types.OrderEntity,
    | 'code'
    | 'createdAt'
    | 'deletedAt'
    | 'discount'
    | 'id'
    | 'note'
    | 'partnerId'
    | 'shippingFee'
    | 'status'
    | 'total'
    | 'updatedAt'
    | 'userCanReview'
    | 'userId'
  > & {
      address?: Types.Maybe<
        { __typename?: 'OrderAddressEntity' } & Pick<
          Types.OrderAddressEntity,
          | 'addressDetail'
          | 'addressName'
          | 'contactName'
          | 'contactPhone'
          | 'createdAt'
          | 'deletedAt'
          | 'id'
          | 'latitude'
          | 'longitude'
          | 'mapAddress'
          | 'updatedAt'
        >
      >;
      product: Array<
        { __typename?: 'OrderProductEntity' } & Pick<
          Types.OrderProductEntity,
          'id' | 'name' | 'quantity' | 'total' | 'unitPrice'
        > & {
            avatar?: Types.Maybe<
              { __typename?: 'Media' } & Pick<Types.Media, 'fullOriginalUrl' | 'fullThumbUrl' | 'id'>
            >;
          }
      >;
      statusDetail?: Types.Maybe<
        { __typename?: 'OrderStatusEntity' } & Pick<
          Types.OrderStatusEntity,
          'createdAt' | 'id' | 'note' | 'orderId' | 'partnerId' | 'status' | 'userId'
        > & {
            reasons?: Types.Maybe<
              Array<
                { __typename?: 'OrderStatusCategoryEntity' } & Pick<
                  Types.OrderStatusCategoryEntity,
                  'id' | 'name' | 'type'
                >
              >
            >;
          }
      >;
      user: { __typename?: 'UserEntity' } & Pick<Types.UserEntity, 'email' | 'fullname' | 'id' | 'phone'>;
    };
};

export const AdminOrderDocument = gql`
  query adminOrder($id: String!) {
    adminOrder(id: $id) {
      address {
        addressDetail
        addressName
        contactName
        contactPhone
        createdAt
        deletedAt
        id
        latitude
        longitude
        mapAddress
        updatedAt
      }
      code
      createdAt
      deletedAt
      discount
      id
      note
      partnerId
      product {
        avatar {
          fullOriginalUrl
          fullThumbUrl
          id
        }
        id
        name
        quantity
        total
        unitPrice
      }
      shippingFee
      status
      statusDetail {
        createdAt
        id
        note
        orderId
        partnerId
        reasons {
          id
          name
          type
        }
        status
        userId
      }
      total
      updatedAt
      user {
        email
        fullname
        id
        phone
      }
      userCanReview
      userId
    }
  }
`;
export function useAdminOrderQuery(
  baseOptions: Apollo.QueryHookOptions<AdminOrderQueryResponse, AdminOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminOrderQueryResponse, AdminOrderQueryVariables>(AdminOrderDocument, options);
}
export function useAdminOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminOrderQueryResponse, AdminOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminOrderQueryResponse, AdminOrderQueryVariables>(AdminOrderDocument, options);
}
export type AdminOrderQueryHookResult = ReturnType<typeof useAdminOrderQuery>;
export type AdminOrderLazyQueryHookResult = ReturnType<typeof useAdminOrderLazyQuery>;
export type AdminOrderQueryResult = Apollo.QueryResult<AdminOrderQueryResponse, AdminOrderQueryVariables>;
