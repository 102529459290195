import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type CreateQuotationPriceListMutationVariables = Types.Exact<{
  input: Types.CreateQuotationPriceListInput;
}>;

export type CreateQuotationPriceListMutationResponse = { __typename?: 'Mutation' } & {
  createQuotationPriceList: { __typename?: 'QuotationPriceListEntity' } & Pick<Types.QuotationPriceListEntity, 'id'>;
};

export const CreateQuotationPriceListDocument = gql`
  mutation createQuotationPriceList($input: CreateQuotationPriceListInput!) {
    createQuotationPriceList(input: $input) {
      id
    }
  }
`;
export function useCreateQuotationPriceListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateQuotationPriceListMutationResponse,
    CreateQuotationPriceListMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateQuotationPriceListMutationResponse, CreateQuotationPriceListMutationVariables>(
    CreateQuotationPriceListDocument,
    options,
  );
}
export type CreateQuotationPriceListMutationHookResult = ReturnType<typeof useCreateQuotationPriceListMutation>;
export type CreateQuotationPriceListMutationResult = Apollo.MutationResult<CreateQuotationPriceListMutationResponse>;
export type CreateQuotationPriceListMutationOptions = Apollo.BaseMutationOptions<
  CreateQuotationPriceListMutationResponse,
  CreateQuotationPriceListMutationVariables
>;
