import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminForgotPasswordMutationVariables = Types.Exact<{
  input: Types.AdminForgotPasswordInput;
}>;

export type AdminForgotPasswordMutationResponse = { __typename?: 'Mutation' } & Pick<
  Types.Mutation,
  'adminForgotPassword'
>;

export const AdminForgotPasswordDocument = gql`
  mutation adminForgotPassword($input: AdminForgotPasswordInput!) {
    adminForgotPassword(input: $input)
  }
`;
export function useAdminForgotPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminForgotPasswordMutationResponse, AdminForgotPasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminForgotPasswordMutationResponse, AdminForgotPasswordMutationVariables>(
    AdminForgotPasswordDocument,
    options,
  );
}
export type AdminForgotPasswordMutationHookResult = ReturnType<typeof useAdminForgotPasswordMutation>;
export type AdminForgotPasswordMutationResult = Apollo.MutationResult<AdminForgotPasswordMutationResponse>;
export type AdminForgotPasswordMutationOptions = Apollo.BaseMutationOptions<
  AdminForgotPasswordMutationResponse,
  AdminForgotPasswordMutationVariables
>;
