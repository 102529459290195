import { memo } from 'react';
import { Link } from 'react-router-dom';

import { IconArrowLeft } from '../../assets/icons';
type Props = {
  title: string;
  link?: string;
  onClick?: () => void;
};

export const PageTitle = memo(({ title, link, onClick }: Props) => {
  return (
    <div className="flex items-center mb-4 space-x-4">
      {link && (
        <Link to={link} onClick={onClick} className="flex-center p-2">
          <IconArrowLeft width={18} height={18} className="cursor-pointer" />
        </Link>
      )}
      <h3 className="text-24px font-medium">{title}</h3>
    </div>
  );
});
