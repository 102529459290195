export const AppRoutes = {
  home: '/',
  dashboard: '/',
  404: '/404',
  auth: {
    index: '/auth',
    login: '/auth/login',
    forgotPassword: '/auth/forgot-password',
  },

  // DEALER
  agencyManagement: '/agency-management',
  agencyCreate: '/agency-management/create',
  agencyEdit: `/agency-management/edit/:id`,
  agencyEditId: (id: string) => `/agency-management/edit/${id}`,
  agencyDetail: `/agency-management/detail/:id`,
  agencyDetailId: (id: string) => `/agency-management/detail/${id}`,

  // TECHNICIAN
  technicianManagement: '/technician-management',
  technicianCreate: '/technician-management/create',
  technicianEdit: '/technician-management/edit/:id',
  technicianEditId: (id: string) => `/technician-management/edit/${id}`,
  technicianDetail: '/technician-management/detail/:id',
  technicianDetailId: (id: string) => `/technician-management/detail/${id}`,

  //REQUES REPAIR
  requestRepairManagement: '/request-repair-management',
  requestRepairCreate: '/request-repair-management/create',
  requestRepairEdit: '/request-repair-management/edit/:id',
  requestRepairEditId: (id: string) => `/request-repair-management/edit/${id}`,
  requestRepairDetail: '/request-repair-management/detail/:id',
  requestRepairDetailId: (id: string) => `/request-repair-management/detail/${id}`,

  // CUSTOMER
  customerManagement: '/customer-management',
  customerManagementCreate: '/customer-management/create',
  customerDetail: '/customer-management/detail/:id',
  customerDetailId: (id: string) => `/customer-management/detail/${id}`,
  customerManagementEdit: '/customer-management/edit/:id',
  customerManagementEditId: (id: string | number) => `/customer-management/edit/${id}`,
  repairServiceManagement: '/repair-service-management',
  problemManagement: '/repair-service-management/problem-management',
  problemManagementCreate: '/repair-service-management/problem-management/create',
  problemManagementEdit: '/repair-service-management/problem-management/edit/:id',
  problemManagementEditId: (id: string | number) => `/repair-service-management/problem-management/edit/${id}`,
  problemManagementDetail: '/repair-service-management/problem-management/:id',
  problemManagementDetailId: (id: string | number) => `/repair-service-management/problem-management/${id}`,
  // Maintenance
  maintenanceManagement: '/maintenance-management',
  listMaintenanceManagement: '/maintenance-management/list',
  maintenanceDetail: '/maintenance-management/list/:id',
  maintenanceDetailId: (id: string | number) => `/maintenance-management/list/${id}`,
  vehicleTypeManagement: '/maintenance-management/vehicleType-management',
  vehicleTypeManagementCreate: '/maintenance-management/vehicleType-management/create',
  vehicleTypeManagementEdit: '/maintenance-management/vehicleType-management/edit/:id',
  vehicleTypeManagementEditId: (id: string | number) => `/maintenance-management/vehicleType-management/edit/${id}`,
  vehicleTypeManagementDetail: '/maintenance-management/vehicleType-management/:id',
  vehicleTypeManagementDetailId: (id: string | number) => `/maintenance-management/vehicleType-management/${id}`,

  // PRODUCT
  productManagement: '/product-category-management/product-management',
  productDeviceManagementDetail: '/product-category-management/product-device-management/detail/:id',
  productDeviceManagementDetailId: (id: string) =>
    `/product-category-management/product-device-management/detail/${id}`,
  productDeviceManagementCreate: '/product-category-management/product-device-management/create',
  productDeviceManagementEdit: '/product-category-management/product-device-management/edit/:id',
  productDeviceManagementEditId: (id: string | number) =>
    `/product-category-management/product-device-management/edit/${id}`,

  productAccessaryManagementDetail: '/product-category-management/product-accessary-management/detail/:id',
  productAccessaryManagementDetailId: (id: string) =>
    `/product-category-management/product-accessary-management/detail/${id}`,
  productAccessaryManagementCreate: '/product-category-management/product-accessary-management/create',
  productAccessaryManagementEdit: '/product-category-management/product-accessary-management/edit/:id',
  productAccessaryManagementEditId: (id: string | number) =>
    `/product-category-management/product-accessary-management/edit/${id}`,

  productCategoryManagement: '/product-category-management',

  productManufacturerManagement: '/product-category-management/manufacturer-management',
  productManufacturerManagementCreate: '/product-category-management/manufacturer-management/create',
  productManufacturerManagementEdit: '/product-category-management/manufacturer-management/edit/:id',
  productManufacturerManagementEditId: (id: string | number) =>
    `/product-category-management/manufacturer-management/edit/${id}`,

  productModelManagement: '/product-category-management/model-management',
  productModelManagementCreate: '/product-category-management/model-management/create',
  productModelManagementEdit: '/product-category-management/model-management/edit/:id',
  productModelManagementEditId: (id: string | number) => `/product-category-management/model-management/edit/${id}`,

  productOriginManagement: '/product-category-management/origin-management',
  productOriginManagementCreate: '/product-category-management/origin-management/create',
  productOriginManagementEdit: '/product-category-management/origin-management/edit/:id',
  productOriginManagementEditId: (id: string | number) => `/product-category-management/origin-management/edit/${id}`,

  vehicleManagement: '/product-category-management/vehicle-management',
  vehicleCreate: '/product-category-management/vehicle-management/create',
  vehicleEdit: `/product-category-management/vehicle-management/edit/:id`,
  vehicleEditId: (id: string) => `/product-category-management/vehicle-management/edit/${id}`,
  vehicleDetail: `/product-category-management/vehicle-management/detail/:id`,
  vehicleDetailId: (id: string) => `/product-category-management/vehicle-management/detail/${id}`,

  productUnitManagement: '/product-category-management/product-unit-management',
  productUnitCreate: '/product-category-management/product-unit-management/create',
  productUnitEdit: `/product-category-management/product-unit-management/edit/:id`,
  productUnitEditId: (id: string) => `/product-category-management/product-unit-management/edit/${id}`,

  partOfProductManagement: '/product-category-management/part-of-product-management',
  partOfProductCreate: '/product-category-management/part-of-product-management/create',
  partOfProductEdit: `/product-category-management/part-of-product-management/edit/:id`,
  partOfProductEditId: (id: string) => `/product-category-management/part-of-product-management/edit/${id}`,
  partOfProductDetail: `/product-category-management/part-of-product-management/detail/:id`,
  partOfProductDetailId: (id: string) => `/product-category-management/part-of-product-management/detail/${id}`,

  //
  orderCategoryManagement: '/order-category/management',
  orderManagement: '/order-management',
  orderManagementDetail: '/order-management/detail/:id',
  orderManagementDetailId: (id: string) => `/order-management/detail/${id}`,

  stallManagement: '/stall-management',

  pricingManagement: '/pricing-management',
  pricingManagementDetail: '/pricing-management/detail/:id',
  pricingManagementDetailId: (id: string) => `/pricing-management/detail/${id}`,
  pricingManagementCreate: '/pricing-management/create',
  pricingManagementEdit: '/pricing-management/edit/:id',
  pricingManagementEditId: (id: string | number) => `/pricing-management/edit/${id}`,

  quotationManagement: '/quotation-management',
  quotationManagementDetail: '/quotation-management/quotation/:id',
  quotationManagementDetailId: (id: string) => `/quotation-management/quotation/${id}`,
  settlementManagementDetail: '/quotation-management/settlement/:id',
  settlementManagementDetailId: (id: string) => `/quotation-management/settlement/${id}`,
  reviewManagement: '/review-management',
  reviewManagementDetail: '/review-management/:id',
  reviewManagementDetailId: (id: string) => `/review-management/${id}`,

  surveyManagement: '/survey-management',
  surveyManagementDetail: '/survey-management/detail/:id',
  surveyManagementDetailId: (id: string) => `/survey-management/detail/${id}`,
  surveyManagementCreate: '/survey-management/create',
  surveyManagementEdit: '/survey-management/edit/:id',
  surveyManagementEditId: (id: string | number) => `/survey-management/edit/${id}`,

  feedbackManagement: '/feedback-management',
  feedbackManagementDetail: `/feedback-management/detail/:id`,
  feedbackManagementDetailId: (id: string) => `/feedback-management/detail/${id}`,

  setting: '/setting',

  // CATEGORY
  categoryManagement: '/category-management',
  educationManagement: '/education-management',
  educationCreate: '/education-management/create',
  educationEdit: `/education-management/edit/:id`,
  educationEditId: (id: string) => `/education-management/edit/${id}`,
  educationDetail: `/education-management/detail/:id`,
  educationDetailId: (id: string) => `/education-management/detail/${id}`,
  levelManagement: '/level-management',
  levelCreate: '/level-management/create',
  levelEdit: `/level-management/edit/:id`,
  levelEditId: (id: string) => `/level-management/edit/${id}`,
  levelDetail: `/level-management/detail/:id`,
  levelDetailId: (id: string) => `/level-management/detail/${id}`,
  qualificationManagement: '/qualification-management',
  qualificationCreate: '/qualification-management/create',
  qualificationEdit: `/qualification-management/edit/:id`,
  qualificationEditId: (id: string) => `/qualification-management/edit/${id}`,
  qualificationDetail: `/qualification-management/detail/:id`,
  qualificationDetailId: (id: string) => `/qualification-management/detail/${id}`,

  cancelRequestByUserManagement: '/cancel-request-by-user-management',
  cancelRequestByUserCreate: '/cancel-request-by-user-management/create',
  cancelRequestByUserEdit: `/cancel-request-by-user-management/edit/:id`,
  cancelRequestByUserEditId: (id: string) => `/cancel-request-by-user-management/edit/${id}`,

  cancelRequestByPartnerManagement: '/cancel-request-by-partner-management',
  cancelRequestByPartnerCreate: '/cancel-request-by-partner-management/create',
  cancelRequestByPartnerEdit: `/cancel-request-by-partner-management/edit/:id`,
  cancelRequestByPartnerEditId: (id: string) => `/cancel-request-by-partner-management/edit/${id}`,

  cancelQuotationManagement: '/cancel-quotation-management',
  cancelQuotationCreate: '/cancel-quotation-management/create',
  cancelQuotationEdit: `/cancel-quotation-management/edit/:id`,
  cancelQuotationEditId: (id: string) => `/cancel-quotation-management/edit/${id}`,
  cancelQuotationDetail: `/cancel-quotation-management/detail/:id`,
  cancelQuotationDetailId: (id: string) => `/cancel-quotation-management/detail/${id}`,

  cancelOrderByUserManagement: '/cancel-order-by-user',
  cancelOrderByUserCreate: '/cancel-order-by-user/create',
  cancelOrderByUserEdit: `/cancel-order-by-user/edit/:id`,
  cancelOrderByUserEditId: (id: string) => `/cancel-order-by-user/edit/${id}`,

  cancelOrderByPartnerManagement: '/cancel-order-by-partner',
  cancelOrderByPartnerCreate: '/cancel-order-by-partner/create',
  cancelOrderByPartnerEdit: `/cancel-order-by-partner/edit/:id`,
  cancelOrderByPartnerEditId: (id: string) => `/cancel-order-by-partner/edit/${id}`,

  // DISCOUNT
  discountManagement: '/discount-management',
  discountCreate: '/discount-management/create',
  discountEdit: `/discount-management/edit/:id`,
  discountEditId: (id: string) => `/discount-management/edit/${id}`,
  discountDetail: `/discount-management/detail/:id`,
  discountDetailId: (id: string) => `/discount-management/detail/${id}`,

  // COURSE
  courseManagement: '/course-management',
  courseCreate: '/course-management/create',
  courseEdit: `/course-management/edit/:id`,
  courseEditId: (id: string) => `/course-management/edit/${id}`,
  courseDetail: `/course-management/detail/:id`,
  courseDetailId: (id: string) => `/course-management/detail/${id}`,

  // NOTIFICATION
  notificationManagement: '/notification-management',
  notificationCreate: '/notification-management/create',
  notificationEdit: `/notification-management/edit/:id`,
  notificationEditId: (id: string) => `/notification-management/edit/${id}`,
  notificationDetail: `/notification-management/detail/:id`,
  notificationDetailId: (id: string) => `/notification-management/detail/${id}`,

  // INSTRUCTION
  insKey: '/instruction',
  instructionManagement: '/instruction-management',
  instructionCreate: '/instruction-management/create',
  instructionEdit: '/instruction-management/edit/:id',
  instructionEditId: (id: string) => `/instruction-management/edit/${id}`,
  instructionDetail: `/instruction-management/detail/:id`,
  instructionDetailId: (id: string) => `/instruction-management/detail/${id}`,
  instructCateManagement: '/instruction-cate-management',
  instructionCateCreate: '/instruction-cate-management/create',
  instructionCateEdit: '/instruction-cate-management/edit/:id',
  instructionCateEditId: (id: string) => `/instruction-cate-management/edit/${id}`,
  instructionCateDetail: `/instruction-cate-management/detail/:id`,
  instructionCateDetailId: (id: string) => `/instruction-cate-management/detail/${id}`,

  // REFERENCE
  documentKey: '/reference',
  documentManagement: '/document-management',
  documentCreate: '/document-management/create',
  documentEdit: '/document-management/edit/:id',
  documentEditId: (id: string) => `/document-management/edit/${id}`,
  documentDetail: `/document-management/detail/:id`,
  documentDetailId: (id: string) => `/document-management/detail/${id}`,
  referenceManagement: '/reference-management',
  referenceCreate: '/reference-management/create',
  referenceEdit: '/reference-management/edit/:id',
  referenceEditId: (id: string) => `/reference-management/edit/${id}`,
  referenceDetail: `/reference-management/detail/:id`,
  referenceDetailId: (id: string) => `/reference-management/detail/${id}`,

  // REPORT
  report: '/report',
  reportDetail: `/report/:id`,
  reportDetailId: (id: string) => `/report/${id}`,

  // ACCOUNT INFORMATION
  accountInfo: '/account-information',

  // PRODUCT QUOTATION
  productQuotationManagement: '/product-quotation-management',
  productQuotationCreate: '/product-quotation-management/create',
  productQuotationEdit: '/product-quotation-management/edit/:id',
  productQuotationEditId: (id: string) => `/product-quotation-management/edit/${id}`,
  productQuotationDetail: `/product-quotation-management/:id`,
  productQuotationDetailId: (id: string) => `/product-quotation-management/${id}`,

  // INVOICE
  invoiceManagement: '/invoice-management',
  invoiceDetail: `/invoice-management/:id`,
  invoiceDetailId: (id: string) => `/invoice-management/${id}`,

  // NEWS
  newsManagement: '/news-management',
  newsManagementDetail: '/news-management/detail/:id',
  newsManagementDetailId: (id: string) => `/news-management/detail/${id}`,
  newsManagementCreate: '/news-management/create',
  newsManagementEdit: '/news-management/edit/:id',
  newsManagementEditId: (id: string | number) => `/news-management/edit/${id}`,
};
