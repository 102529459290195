import { memo, useCallback } from 'react';
import { Avatar, Button, Descriptions, Popconfirm, Spin, Switch, notification } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { AppRoutes } from '../../helpers';
import { PageTitle } from '../../components/page-title';
import { notificationMessages } from '../../helpers/notification-messages';
import { useAdminRemoveTechnicianMutation } from '../../graphql/mutations/adminRemoveTechnician.generated';
import { useAdminDetailPartnerQuery } from '../../graphql/queries/adminDetailPartner.generated';
import { useAdminUpdateTechnicianApproveStatusMutation } from '../../graphql/mutations/adminUpdateTechnicianApproveStatus.generated';
import { DATE_FORMAT } from '../../constants';

const DetailTechnician = memo(() => {
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const { data, loading, error } = useAdminDetailPartnerQuery({ variables: { id }, skip: !id });

  const [deleteTechnician, { loading: removing }] = useAdminRemoveTechnicianMutation({
    onError: (err) => notification.error({ message: notificationMessages.delete.fail, description: err?.message }),
    onCompleted: () => {
      notification.success({ message: notificationMessages.delete.success });
      navigate(-1);
    },
  });
  const [updateApproveTechnician, { loading: updatingApprove }] = useAdminUpdateTechnicianApproveStatusMutation({
    onError: (err) => notification.error({ message: 'Bạn đã phê duyệt KTV thất bại.', description: err?.message }),
    onCompleted: () => {
      notification.success({ message: 'Bạn đã phê duyệt KTV thành công.' });
    },
  });
  const handleUpdateApproveTechnician = useCallback(() => {
    updateApproveTechnician({
      variables: {
        input: { id: data?.adminDetailPartner?.id || '', isApproved: !data?.adminDetailPartner?.isApproved },
      },
    });
  }, [updateApproveTechnician, data]);
  const handleDelete = useCallback(() => {
    deleteTechnician({ variables: { id: data?.adminDetailPartner?.id || '' } });
  }, [deleteTechnician, data]);
  if (error) return null;

  return (
    <div>
      <Spin spinning={loading || removing || updatingApprove}>
        <Descriptions
          title={<PageTitle link={AppRoutes.technicianManagement} title="Thông tin kỹ thuật viên" />}
          column={1}
          labelStyle={{ width: '150px' }}>
          <Descriptions.Item label="Avatar">
            <Avatar
              size={64}
              src={
                <img
                  src={
                    data?.adminDetailPartner?.avatar?.fullThumbUrl ??
                    (data?.adminDetailPartner?.avatar?.fullOriginalUrl || '')
                  }
                  alt={
                    data?.adminDetailPartner?.avatar?.fullThumbUrl ??
                    (data?.adminDetailPartner?.avatar?.fullOriginalUrl || '')
                  }
                />
              }
            />
          </Descriptions.Item>
          <Descriptions.Item label="Họ và tên">{data?.adminDetailPartner?.fullname}</Descriptions.Item>
          <Descriptions.Item label="Ngày sinh">
            {dayjs(data?.adminDetailPartner?.birthday).format(DATE_FORMAT)}
          </Descriptions.Item>
          <Descriptions.Item label="Email">{data?.adminDetailPartner?.email}</Descriptions.Item>
          <Descriptions.Item label="Số diện thoại">{data?.adminDetailPartner?.phone}</Descriptions.Item>
          <Descriptions.Item label="Mật khẩu mặc định">Callme@2023</Descriptions.Item>
          <Descriptions.Item label="Số CMND/CCCD">{data?.adminDetailPartner?.citizenId}</Descriptions.Item>
          <Descriptions.Item label="Địa chỉ">{data?.adminDetailPartner?.mapAddress}</Descriptions.Item>
          <Descriptions.Item label="Đại lý trực thuộc">
            <Link to={AppRoutes.agencyDetailId(data?.adminDetailPartner?.parentInfo?.id as string)}>
              {data?.adminDetailPartner?.parentInfo?.fullname}
            </Link>
          </Descriptions.Item>
          <Descriptions.Item label="Chuyên môn">
            {(data?.adminDetailPartner?.qualifications || []).map((qualification: any, index: number) => {
              if (
                data?.adminDetailPartner?.qualifications &&
                index === data?.adminDetailPartner?.qualifications.length - 1
              )
                return qualification.name;
              return qualification.name + ', ';
            })}
          </Descriptions.Item>
          <Descriptions.Item label="Level">
            {(data?.adminDetailPartner?.level && data?.adminDetailPartner?.level.name) || ''}
          </Descriptions.Item>
          <Descriptions.Item label="Trình độ học vấn">
            {(data?.adminDetailPartner?.education && data?.adminDetailPartner?.education.name) || ''}
          </Descriptions.Item>
          <Descriptions.Item label="Ngân hàng">{data?.adminDetailPartner?.bank}</Descriptions.Item>
          <Descriptions.Item label="Số thẻ">{data?.adminDetailPartner?.cardNumber}</Descriptions.Item>
          <Descriptions.Item label="Trạng thái">
            <Switch checked={data?.adminDetailPartner?.isActive} disabled />
          </Descriptions.Item>
          {!data?.adminDetailPartner?.isApproved && (
            <div className="float-left">
              <Popconfirm
                title={`Bạn có chắc muốn từ chối không?`}
                okText="Đồng ý"
                cancelText="Huỷ bỏ"
                placement="topLeft"
                onConfirm={handleDelete}>
                <Button size="small" className="mr-2">
                  Từ chối KTV
                </Button>
              </Popconfirm>
              <Popconfirm
                title={`Bạn có chắc muốn phê duyệt không?`}
                okText="Đồng ý"
                cancelText="Huỷ bỏ"
                placement="topLeft"
                onConfirm={handleUpdateApproveTechnician}>
                <Button size="small" type="primary">
                  Phê duyệt KTV
                </Button>
              </Popconfirm>
            </div>
          )}
        </Descriptions>
      </Spin>
    </div>
  );
});
export default DetailTechnician;
