import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminGetVehicleTypeCategoriesQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Array<Types.Scalars['JSONObject']> | Types.Scalars['JSONObject']>;
  isActive?: Types.InputMaybe<Types.StatusEnum>;
  isApproved?: Types.InputMaybe<Types.Scalars['Boolean']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  search?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;

export type AdminGetVehicleTypeCategoriesQueryResponse = { __typename?: 'Query' } & {
  adminGetVehicleTypeCategories: { __typename?: 'VechicleTypeCategoryConnection' } & {
    items?: Types.Maybe<
      Array<
        { __typename?: 'VechicleTypeCategoryEntity' } & Pick<
          Types.VechicleTypeCategoryEntity,
          'id' | 'maintenanceAcessoryIds' | 'modelId' | 'operatingNumber' | 'updatedAt'
        > & {
            maintenanceAcessories: Array<
              { __typename?: 'MaintenancesAccessoryEntity' } & Pick<
                Types.MaintenancesAccessoryEntity,
                'id' | 'name' | 'quantity' | 'unit'
              >
            >;
            model: { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name' | 'type'>;
            vehicleType: { __typename?: 'CategoryEntity' } & Pick<
              Types.CategoryEntity,
              'id' | 'isActive' | 'name' | 'type'
            >;
          }
      >
    >;
    meta: { __typename?: 'BasePaginationMeta' } & Pick<
      Types.BasePaginationMeta,
      'currentPage' | 'itemCount' | 'itemsPerPage' | 'totalItems' | 'totalPages'
    >;
  };
};

export const AdminGetVehicleTypeCategoriesDocument = gql`
  query adminGetVehicleTypeCategories(
    $filters: [JSONObject!]
    $isActive: StatusEnum
    $isApproved: Boolean
    $limit: Int
    $page: Int
    $search: String
    $sort: SortInput
  ) {
    adminGetVehicleTypeCategories(
      filters: $filters
      isActive: $isActive
      isApproved: $isApproved
      limit: $limit
      page: $page
      search: $search
      sort: $sort
    ) {
      items {
        id
        maintenanceAcessories {
          id
          name
          quantity
          unit
        }
        maintenanceAcessoryIds
        model {
          id
          isActive
          name
          type
        }
        modelId
        operatingNumber
        updatedAt
        vehicleType {
          id
          isActive
          name
          type
        }
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;
export function useAdminGetVehicleTypeCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminGetVehicleTypeCategoriesQueryResponse,
    AdminGetVehicleTypeCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetVehicleTypeCategoriesQueryResponse, AdminGetVehicleTypeCategoriesQueryVariables>(
    AdminGetVehicleTypeCategoriesDocument,
    options,
  );
}
export function useAdminGetVehicleTypeCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetVehicleTypeCategoriesQueryResponse,
    AdminGetVehicleTypeCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetVehicleTypeCategoriesQueryResponse, AdminGetVehicleTypeCategoriesQueryVariables>(
    AdminGetVehicleTypeCategoriesDocument,
    options,
  );
}
export type AdminGetVehicleTypeCategoriesQueryHookResult = ReturnType<typeof useAdminGetVehicleTypeCategoriesQuery>;
export type AdminGetVehicleTypeCategoriesLazyQueryHookResult = ReturnType<
  typeof useAdminGetVehicleTypeCategoriesLazyQuery
>;
export type AdminGetVehicleTypeCategoriesQueryResult = Apollo.QueryResult<
  AdminGetVehicleTypeCategoriesQueryResponse,
  AdminGetVehicleTypeCategoriesQueryVariables
>;
