import { createBrowserRouter } from 'react-router-dom';

import {
  Dashboard,
  Login,
  Manufacturer,
  Model,
  UIComponent,
  Problem,
  Customer,
  RequestRepair,
  Order,
  MaintenanceManagement,
  Pricing,
  Discount,
  Product,
  CancelReasonByPartner,
  CancelReasonByUser,
  PartOfProduct,
  PartOfProductForm,
  CancelQuotation,
  CancelQuotationForm,
  Survey,
  FormNotification,
  NotificationList,
  QuotationsList,
  CourseList,
  CourseDetail,
  FormCourse,
  FeedbackManagement,
  FeedbackManagementDetail,
  CancelOrderByUser,
  FormCancelOrderByUser,
  CancelOrderByPartner,
  FormCancelOrderByPartner,
  ReferenceList,
  DetailReference,
  ReferenceForm,
  DocumentList,
  DetailDocument,
  DocumentForm,
  Report,
  DetailReport,
  DetailNotification,
  Review,
  AccountInfo,
  ProductQuotation,
  DetailProductQuotation,
  StallManagement,
  InvoiceManagement,
  DetailInvoice,
  DetailMaintenance,
  VehicleType,
  NewsManagement,
  ProductUnitManagement,
  ProductUnitForm,
} from '../screens';
import { PrivateRoute } from '../components';
import { MainLayout } from '../layouts';
import { LoginLayout } from '../layouts/login-layout';
import ForgotPassword from '../screens/forgot-password';
import ListTechnician from '../screens/technician-management/list';
import DetailTechnician from '../screens/technician-management/detail';
import FormTechnician from '../screens/technician-management/form';
import FormManufacturer from '../screens/manufacturer/form';
import FormModel from '../screens/model/form';
import OriginManagement from '../screens/origin';
import FormOrigin from '../screens/origin/form';
import AgencyManagement from '../screens/agency';
import FormAgency from '../screens/agency/form';
import EducationManagement from '../screens/education';
import LevelManagement from '../screens/level';
import QualificationManagement from '../screens/qualification';
import VehicleManagement from '../screens/vehicle';
import FormEducation from '../screens/education/form';
import FormLevel from '../screens/level/form';
import FormQualification from '../screens/qualification/form';
import FormVehicle from '../screens/vehicle/form';
import FormProblem from '../screens/problem/form';
import DetailCustomer from '../screens/customer/detail';
import DetailRequestRepair from '../screens/request-repair/detail';
import DetailOrder from '../screens/order/detail';
import FormPricing from '../screens/pricing/form';
import FormDiscount from '../screens/discount/form';
import DetailDiscount from '../screens/discount/detail';
import FormCancelReasonsByPartner from '../screens/cancel-reason-by-partner/form';
import FormCancelReasonsByUser from '../screens/cancel-reason-by-user/form';
import { ProductTypeEnum } from '../graphql/type.interface';
import ProductForm from '../screens/product/productForm';
import ProductDetail from '../screens/product/detail';
import { QUOTATION_KIND } from '../screens/quotation/const';
import { SurveyDetail } from '../screens/survey/survey-detail';
import {
  DetailInstruct,
  DetailInstructCate,
  InstructionCateForm,
  InstructionCateList,
  InstructionForm,
  InstructionList,
} from '../screens/instruction';
import { QuotationDetail } from '../screens/quotation/detail';
import ReviewDetail from '../screens/review/detail';
import { FormSurvey } from '../screens/survey/form-survey';
import FormVehicleType from '../screens/vehicleType/form';
import FormNews from '../screens/news/form';
import CustomerForm from '../screens/customer/form';

import { AppRoutes } from './app-routes';

export const useRouter = () => {
  return createBrowserRouter([
    {
      path: AppRoutes.auth.index,
      element: <PrivateRoute isAuthRoute isPrivate={false} layout={LoginLayout} />,
      children: [
        {
          path: AppRoutes.auth.login,
          element: <Login />,
        },
        {
          path: AppRoutes.auth.forgotPassword,
          element: <ForgotPassword />,
        },
      ],
    },
    {
      path: AppRoutes.home,
      element: <PrivateRoute isPrivate layout={MainLayout} />,
      children: [
        {
          path: AppRoutes.dashboard,
          element: <Dashboard />,
        },
        {
          path: AppRoutes.home,
          element: <UIComponent />,
        },
        // REPART SERVICE
        {
          path: AppRoutes.problemManagement,
          element: <Problem />,
        },
        {
          path: AppRoutes.problemManagementCreate,
          element: <FormProblem />,
        },
        {
          path: AppRoutes.problemManagementEdit,
          element: <FormProblem />,
        },
        {
          path: AppRoutes.problemManagementDetail,
          element: <FormProblem isDetail />,
        },
        {
          path: AppRoutes.listMaintenanceManagement,
          element: <MaintenanceManagement />,
        },
        {
          path: AppRoutes.maintenanceDetail,
          element: <DetailMaintenance />,
        },

        // VehicleType
        {
          path: AppRoutes.vehicleTypeManagement,
          element: <VehicleType />,
        },
        {
          path: AppRoutes.vehicleTypeManagementCreate,
          element: <FormVehicleType />,
        },
        {
          path: AppRoutes.vehicleTypeManagementDetail,
          element: <FormVehicleType isDetail />,
        },
        {
          path: AppRoutes.vehicleTypeManagementEdit,
          element: <FormVehicleType isEdit />,
        },
        //CUSTOMMER
        {
          path: AppRoutes.customerManagement,
          element: <Customer />,
        },
        {
          path: AppRoutes.customerManagementCreate,
          element: <CustomerForm isCreate={true} />,
        },
        {
          path: AppRoutes.customerManagementEdit,
          element: <CustomerForm isDetail={false} />,
        },
        {
          path: AppRoutes.customerDetail,
          element: <DetailCustomer />,
        },
        {
          path: AppRoutes.orderManagement,
          element: <Order />,
        },
        {
          path: AppRoutes.orderManagementDetail,
          element: <DetailOrder />,
        },
        //TECHNICIAN
        {
          path: AppRoutes.technicianManagement,
          element: <ListTechnician />,
        },
        {
          path: AppRoutes.technicianDetail,
          element: <DetailTechnician />,
        },
        {
          path: AppRoutes.technicianCreate,
          element: <FormTechnician />,
        },
        {
          path: AppRoutes.technicianEdit,
          element: <FormTechnician isEdit />,
        },
        // PRICING
        {
          path: AppRoutes.pricingManagement,
          element: <Pricing />,
        },
        {
          path: AppRoutes.pricingManagementDetail,
          element: <FormPricing isDetail />,
        },
        {
          path: AppRoutes.pricingManagementCreate,
          element: <FormPricing isCreate />,
        },
        {
          path: AppRoutes.pricingManagementEdit,
          element: <FormPricing />,
        },
        //PRODUCT
        {
          path: AppRoutes.productManagement,
          element: <Product />,
        },
        {
          path: AppRoutes.productAccessaryManagementCreate,
          element: <ProductForm productType={ProductTypeEnum.ACCESSARY} />,
        },
        {
          path: AppRoutes.productAccessaryManagementDetail,
          element: <ProductDetail productType={ProductTypeEnum.ACCESSARY} />,
        },
        {
          path: AppRoutes.productAccessaryManagementEdit,
          element: <ProductForm productType={ProductTypeEnum.ACCESSARY} isEdit />,
        },
        {
          path: AppRoutes.productDeviceManagementCreate,
          element: <ProductForm productType={ProductTypeEnum.VEHICLE} />,
        },
        {
          path: AppRoutes.productDeviceManagementDetail,
          element: <ProductDetail productType={ProductTypeEnum.VEHICLE} />,
        },
        {
          path: AppRoutes.productDeviceManagementEdit,
          element: <ProductForm productType={ProductTypeEnum.VEHICLE} isEdit />,
        },
        {
          path: AppRoutes.productManufacturerManagement,
          element: <Manufacturer />,
        },
        {
          path: AppRoutes.productManufacturerManagementCreate,
          element: <FormManufacturer />,
        },
        {
          path: AppRoutes.productManufacturerManagementEdit,
          element: <FormManufacturer />,
        },
        {
          path: AppRoutes.productModelManagement,
          element: <Model />,
        },
        {
          path: AppRoutes.productModelManagementCreate,
          element: <FormModel />,
        },
        {
          path: AppRoutes.productModelManagementEdit,
          element: <FormModel />,
        },
        {
          path: AppRoutes.productOriginManagement,
          element: <OriginManagement />,
        },
        {
          path: AppRoutes.productOriginManagementCreate,
          element: <FormOrigin />,
        },
        {
          path: AppRoutes.productOriginManagementEdit,
          element: <FormOrigin />,
        },
        {
          path: AppRoutes.agencyManagement,
          element: <AgencyManagement />,
        },
        {
          path: AppRoutes.agencyCreate,
          element: <FormAgency isCreate />,
        },
        {
          path: AppRoutes.agencyEdit,
          element: <FormAgency />,
        },
        {
          path: AppRoutes.agencyDetail,
          element: <FormAgency isDetail />,
        },

        // CATEGORY
        {
          path: AppRoutes.educationManagement,
          element: <EducationManagement />,
        },
        {
          path: AppRoutes.educationCreate,
          element: <FormEducation />,
        },
        {
          path: AppRoutes.educationEdit,
          element: <FormEducation />,
        },
        {
          path: AppRoutes.levelManagement,
          element: <LevelManagement />,
        },
        {
          path: AppRoutes.levelCreate,
          element: <FormLevel />,
        },
        {
          path: AppRoutes.levelEdit,
          element: <FormLevel />,
        },
        {
          path: AppRoutes.qualificationManagement,
          element: <QualificationManagement />,
        },
        {
          path: AppRoutes.qualificationCreate,
          element: <FormQualification />,
        },
        {
          path: AppRoutes.qualificationEdit,
          element: <FormQualification />,
        },
        {
          path: AppRoutes.vehicleManagement,
          element: <VehicleManagement />,
        },
        {
          path: AppRoutes.vehicleCreate,
          element: <FormVehicle />,
        },
        {
          path: AppRoutes.vehicleEdit,
          element: <FormVehicle />,
        },
        {
          path: AppRoutes.cancelRequestByPartnerManagement,
          element: <CancelReasonByPartner />,
        },
        {
          path: AppRoutes.cancelRequestByPartnerCreate,
          element: <FormCancelReasonsByPartner />,
        },
        {
          path: AppRoutes.cancelRequestByPartnerEdit,
          element: <FormCancelReasonsByPartner />,
        },
        {
          path: AppRoutes.cancelRequestByUserManagement,
          element: <CancelReasonByUser />,
        },
        {
          path: AppRoutes.cancelRequestByUserCreate,
          element: <FormCancelReasonsByUser />,
        },
        {
          path: AppRoutes.cancelRequestByUserEdit,
          element: <FormCancelReasonsByUser />,
        },
        //REASONS CANCEL ORDER
        {
          path: AppRoutes.cancelOrderByUserManagement,
          element: <CancelOrderByUser />,
        },
        {
          path: AppRoutes.cancelOrderByUserCreate,
          element: <FormCancelOrderByUser />,
        },
        {
          path: AppRoutes.cancelOrderByUserEdit,
          element: <FormCancelOrderByUser />,
        },
        {
          path: AppRoutes.cancelOrderByPartnerManagement,
          element: <CancelOrderByPartner />,
        },
        {
          path: AppRoutes.cancelOrderByPartnerCreate,
          element: <FormCancelOrderByPartner />,
        },
        {
          path: AppRoutes.cancelRequestByPartnerEdit,
          element: <FormCancelOrderByPartner />,
        },
        // END CATEGORY
        {
          path: AppRoutes.requestRepairManagement,
          element: <RequestRepair />,
        },
        {
          path: AppRoutes.requestRepairDetail,
          element: <DetailRequestRepair />,
        },
        {
          path: AppRoutes.discountManagement,
          element: <Discount />,
        },
        {
          path: AppRoutes.discountCreate,
          element: <FormDiscount />,
        },
        {
          path: AppRoutes.discountEdit,
          element: <FormDiscount isEdit />,
        },
        {
          path: AppRoutes.discountDetail,
          element: <DetailDiscount />,
        },
        {
          path: AppRoutes.partOfProductManagement,
          element: <PartOfProduct />,
        },
        {
          path: AppRoutes.partOfProductEdit,
          element: <PartOfProductForm />,
        },
        {
          path: AppRoutes.partOfProductCreate,
          element: <PartOfProductForm />,
        },
        {
          path: AppRoutes.productUnitManagement,
          element: <ProductUnitManagement />,
        },
        {
          path: AppRoutes.productUnitCreate,
          element: <ProductUnitForm />,
        },
        {
          path: AppRoutes.productUnitEdit,
          element: <ProductUnitForm />,
        },

        {
          path: AppRoutes.cancelQuotationManagement,
          element: <CancelQuotation />,
        },
        {
          path: AppRoutes.cancelQuotationEdit,
          element: <CancelQuotationForm />,
        },
        {
          path: AppRoutes.cancelQuotationCreate,
          element: <CancelQuotationForm />,
        },
        // QUOTATION
        {
          path: AppRoutes.quotationManagement,
          element: <QuotationsList />,
        },
        {
          path: AppRoutes.quotationManagementDetail,
          element: <QuotationDetail type={QUOTATION_KIND.QUOTATION} />,
        },
        {
          path: AppRoutes.settlementManagementDetail,
          element: <QuotationDetail type={QUOTATION_KIND.SETTLEMENT} />,
        },
        // INVOICE
        {
          path: AppRoutes.invoiceManagement,
          element: <InvoiceManagement />,
        },
        {
          path: AppRoutes.invoiceDetail,
          element: <DetailInvoice />,
        },
        // REVIEW
        {
          path: AppRoutes.reviewManagement,
          element: <Review />,
        },
        {
          path: AppRoutes.reviewManagementDetail,
          element: <ReviewDetail />,
        },
        // NOTIFICATION
        {
          path: AppRoutes.notificationManagement,
          element: <NotificationList />,
        },
        {
          path: AppRoutes.notificationDetail,
          element: <DetailNotification />,
        },
        {
          path: AppRoutes.notificationCreate,
          element: <FormNotification />,
        },
        {
          path: AppRoutes.notificationEdit,
          element: <FormNotification />,
        },
        // COURSE
        {
          path: AppRoutes.courseManagement,
          element: <CourseList />,
        },
        {
          path: AppRoutes.courseDetail,
          element: <CourseDetail />,
        },
        {
          path: AppRoutes.courseCreate,
          element: <FormCourse />,
        },
        {
          path: AppRoutes.courseEdit,
          element: <FormCourse />,
        },
        // REFERENCE
        {
          path: AppRoutes.documentManagement,
          element: <DocumentList />,
        },
        {
          path: AppRoutes.documentDetail,
          element: <DetailDocument />,
        },
        {
          path: AppRoutes.documentCreate,
          element: <DocumentForm />,
        },
        {
          path: AppRoutes.documentEdit,
          element: <DocumentForm />,
        },
        {
          path: AppRoutes.referenceManagement,
          element: <ReferenceList />,
        },
        {
          path: AppRoutes.referenceDetail,
          element: <DetailReference />,
        },
        {
          path: AppRoutes.referenceCreate,
          element: <ReferenceForm />,
        },
        {
          path: AppRoutes.referenceEdit,
          element: <ReferenceForm />,
        },
        // INSTRUCTION
        {
          path: AppRoutes.instructionManagement,
          element: <InstructionList />,
        },
        {
          path: AppRoutes.instructionDetail,
          element: <DetailInstruct />,
        },
        {
          path: AppRoutes.instructionCreate,
          element: <InstructionForm />,
        },
        {
          path: AppRoutes.instructionEdit,
          element: <InstructionForm />,
        },
        {
          path: AppRoutes.instructCateManagement,
          element: <InstructionCateList />,
        },
        {
          path: AppRoutes.instructionCateCreate,
          element: <InstructionCateForm />,
        },
        {
          path: AppRoutes.instructionCateEdit,
          element: <InstructionCateForm />,
        },
        {
          path: AppRoutes.instructionCateDetail,
          element: <DetailInstructCate />,
        },
        // NEWS
        {
          path: AppRoutes.newsManagement,
          element: <NewsManagement />,
        },
        {
          path: AppRoutes.newsManagementCreate,
          element: <FormNews />,
        },
        {
          path: AppRoutes.newsManagementDetail,
          element: <FormNews isDetail />,
        },
        {
          path: AppRoutes.newsManagementEdit,
          element: <FormNews isEdit />,
        },

        // SURVEY
        {
          path: AppRoutes.surveyManagement,
          element: <Survey />,
        },
        {
          path: AppRoutes.surveyManagementDetail,
          element: <SurveyDetail isDetail />,
        },
        {
          path: AppRoutes.surveyManagementEdit,
          element: <SurveyDetail />,
        },
        {
          path: AppRoutes.surveyManagementCreate,
          element: <FormSurvey isCreate />,
        },
        // FEEDBACK
        {
          path: AppRoutes.feedbackManagement,
          element: <FeedbackManagement />,
        },
        {
          path: AppRoutes.feedbackManagementDetail,
          element: <FeedbackManagementDetail />,
        },
        // REPORT
        {
          path: AppRoutes.report,
          element: <Report />,
        },
        {
          path: AppRoutes.reportDetail,
          element: <DetailReport />,
        },
        // ACCOUNT INFORMATION
        {
          path: AppRoutes.accountInfo,
          element: <AccountInfo />,
        },
        {
          path: AppRoutes.productQuotationManagement,
          element: <ProductQuotation />,
        },
        {
          path: AppRoutes.productQuotationDetail,
          element: <DetailProductQuotation />,
        },
        {
          path: AppRoutes.stallManagement,
          element: <StallManagement />,
        },
      ],
    },
    {
      path: '/ui-components',
      element: <UIComponent />,
    },
  ]);
};
