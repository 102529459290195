import React, { ReactNode } from 'react';
import { InputNumber, InputNumberProps } from 'antd';

type NumericInputProps = {
  styleContainer?: React.CSSProperties | string;
  className?: React.CSSProperties | string;
  value?: number;
  onChange?: (value: number) => void;
  suffix?: ReactNode | string;
} & InputNumberProps;

export const InputNumberFormat = ({ onChange, styleContainer, ...props }: NumericInputProps) => {
  const handleChange = (newValue: any) => {
    onChange?.(newValue);
  };

  return (
    <div className={`w-full relative ${styleContainer}`}>
      <InputNumber
        {...props}
        onChange={handleChange}
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
        parser={(value) => value!.replace(/\$\s?|([.]*)/g, '')}
      />
      <div className="absolute right-[8px] top-[50%] translate-y-[-50%]">{props.suffix}</div>
    </div>
  );
};
