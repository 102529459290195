import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminUpdateInstructionStatusMutationVariables = Types.Exact<{
  input: Types.UpdateInstructionStatusInput;
}>;

export type AdminUpdateInstructionStatusMutationResponse = { __typename?: 'Mutation' } & {
  adminUpdateInstructionStatus: { __typename?: 'InstructionEntity' } & Pick<Types.InstructionEntity, 'id' | 'name'>;
};

export const AdminUpdateInstructionStatusDocument = gql`
  mutation adminUpdateInstructionStatus($input: UpdateInstructionStatusInput!) {
    adminUpdateInstructionStatus(input: $input) {
      id
      name
    }
  }
`;
export function useAdminUpdateInstructionStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpdateInstructionStatusMutationResponse,
    AdminUpdateInstructionStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminUpdateInstructionStatusMutationResponse,
    AdminUpdateInstructionStatusMutationVariables
  >(AdminUpdateInstructionStatusDocument, options);
}
export type AdminUpdateInstructionStatusMutationHookResult = ReturnType<typeof useAdminUpdateInstructionStatusMutation>;
export type AdminUpdateInstructionStatusMutationResult =
  Apollo.MutationResult<AdminUpdateInstructionStatusMutationResponse>;
export type AdminUpdateInstructionStatusMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateInstructionStatusMutationResponse,
  AdminUpdateInstructionStatusMutationVariables
>;
