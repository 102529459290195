import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminCreateDiscountCodeMutationVariables = Types.Exact<{
  input: Types.CreateDiscountCodeInput;
}>;

export type AdminCreateDiscountCodeMutationResponse = { __typename?: 'Mutation' } & Pick<
  Types.Mutation,
  'adminCreateDiscountCode'
>;

export const AdminCreateDiscountCodeDocument = gql`
  mutation adminCreateDiscountCode($input: CreateDiscountCodeInput!) {
    adminCreateDiscountCode(input: $input)
  }
`;
export function useAdminCreateDiscountCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateDiscountCodeMutationResponse,
    AdminCreateDiscountCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminCreateDiscountCodeMutationResponse, AdminCreateDiscountCodeMutationVariables>(
    AdminCreateDiscountCodeDocument,
    options,
  );
}
export type AdminCreateDiscountCodeMutationHookResult = ReturnType<typeof useAdminCreateDiscountCodeMutation>;
export type AdminCreateDiscountCodeMutationResult = Apollo.MutationResult<AdminCreateDiscountCodeMutationResponse>;
export type AdminCreateDiscountCodeMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateDiscountCodeMutationResponse,
  AdminCreateDiscountCodeMutationVariables
>;
