import { Suspense, lazy } from 'react';

import { ProgressLoading } from '../components';

const LazyLayout = (importStatement: () => Promise<any>) => {
  const Component = lazy(importStatement);

  return (
    <Suspense fallback={<ProgressLoading />}>
      <Component />
    </Suspense>
  );
};

export const Home = () => LazyLayout(() => import('./home'));
export const Login = () => LazyLayout(() => import('./login'));
export const UIComponent = () => LazyLayout(() => import('./ui-components'));
export const Dashboard = () => LazyLayout(() => import('./dashboard'));
export const Manufacturer = () => LazyLayout(() => import('./manufacturer'));
export const Model = () => LazyLayout(() => import('./model'));
export const Origin = () => LazyLayout(() => import('./origin'));
export const Problem = () => LazyLayout(() => import('./problem'));
export const Customer = () => LazyLayout(() => import('./customer'));
export const RequestRepair = () => LazyLayout(() => import('./request-repair'));
export const MaintenanceManagement = () => LazyLayout(() => import('./maintenance'));
export const Order = () => LazyLayout(() => import('./order'));
export const Pricing = () => LazyLayout(() => import('./pricing'));
export const Discount = () => LazyLayout(() => import('./discount'));
export const Product = () => LazyLayout(() => import('./product'));
export const CancelReasonByUser = () => LazyLayout(() => import('./cancel-reason-by-user'));
export const CancelReasonByPartner = () => LazyLayout(() => import('./cancel-reason-by-partner'));
export const PartOfProduct = () => LazyLayout(() => import('./part-of-product'));
export const PartOfProductForm = () => LazyLayout(() => import('./part-of-product/form'));
export const CancelQuotation = () => LazyLayout(() => import('./cancel-quotation'));
export const CancelQuotationForm = () => LazyLayout(() => import('./cancel-quotation/form'));
export const Survey = () => LazyLayout(() => import('./survey/index'));
export const NotificationList = () => LazyLayout(() => import('./notification/list'));
export const FormNotification = () => LazyLayout(() => import('./notification/form'));
export const DetailNotification = () => LazyLayout(() => import('./notification/detail'));
export const QuotationsList = () => LazyLayout(() => import('./quotation/list'));
export const CourseList = () => LazyLayout(() => import('./courses/list'));
export const FormCourse = () => LazyLayout(() => import('./courses/form'));
export const CourseDetail = () => LazyLayout(() => import('./courses/detail'));
export const FeedbackManagement = () => LazyLayout(() => import('./feedback/index'));
export const FeedbackManagementDetail = () => LazyLayout(() => import('./feedback/detail'));
export const CancelOrderByUser = () => LazyLayout(() => import('./cancel-order-by-user'));
export const FormCancelOrderByUser = () => LazyLayout(() => import('./cancel-order-by-user/form'));
export const CancelOrderByPartner = () => LazyLayout(() => import('./cancel-order-by-partner'));
export const FormCancelOrderByPartner = () => LazyLayout(() => import('./cancel-order-by-partner/form'));
export const ReferenceList = () => LazyLayout(() => import('./reference/references/list'));
export const DetailReference = () => LazyLayout(() => import('./reference/references/detail'));
export const ReferenceForm = () => LazyLayout(() => import('./reference/references/form'));
export const DocumentList = () => LazyLayout(() => import('./reference/documents/list'));
export const DetailDocument = () => LazyLayout(() => import('./reference/documents/detail'));
export const DocumentForm = () => LazyLayout(() => import('./reference/documents/form'));
export const Report = () => LazyLayout(() => import('./report/index'));
export const DetailReport = () => LazyLayout(() => import('./report/detail'));
export const Review = () => LazyLayout(() => import('./review/index'));
export const DetailReview = () => LazyLayout(() => import('./review/detail'));
export const AccountInfo = () => LazyLayout(() => import('./account/account-info'));
export const ProductQuotation = () => LazyLayout(() => import('./product-quotation/index'));
export const DetailProductQuotation = () => LazyLayout(() => import('./product-quotation/detail'));
export const StallManagement = () => LazyLayout(() => import('./stall/index'));
export const InvoiceManagement = () => LazyLayout(() => import('./invoice/index'));
export const DetailInvoice = () => LazyLayout(() => import('./invoice/detail'));
export const DetailMaintenance = () => LazyLayout(() => import('./maintenance/detail'));
export const VehicleType = () => LazyLayout(() => import('./vehicleType/index'));
export const NewsManagement = () => LazyLayout(() => import('./news/index'));
export const ProductUnitManagement = () => LazyLayout(() => import('./product-unit/index'));
export const ProductUnitForm = () => LazyLayout(() => import('./product-unit/form'));
