import { memo, useMemo, useCallback } from 'react';
import { Button, Col, Form, Input, Row, Switch, notification } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useNavigate, useParams } from 'react-router-dom';

import { PageTitle, ProgressLoading } from '../../../components';
import { AppRoutes } from '../../../helpers';
import { Messages } from '../../../constants/message';
import { useAdminGetGuideQuery } from '../../../graphql/queries/adminGetGuide.generated';
import { useAdminCreateGuideMutation } from '../../../graphql/mutations/adminCreateGuide.generated';
import { useAdminUpdateGuideMutation } from '../../../graphql/mutations/adminUpdateGuide.generated';

export const InstructionCateForm = memo(() => {
  const { id = '' } = useParams();
  const navigate = useNavigate();

  const { data: guideData, loading } = useAdminGetGuideQuery({ skip: !id, variables: { id } });

  const guide = useMemo(() => guideData?.adminGetGuide, [guideData?.adminGetGuide]);

  const [createGuide, { loading: creating }] = useAdminCreateGuideMutation({
    onError(err) {
      notification.error({ message: Messages.create.fail('danh mục hướng dẫn'), description: err.message });
    },
    onCompleted() {
      notification.success({ message: Messages.create.success('danh mục hướng dẫn') });
      navigate(-1);
    },
  });

  const [updateGuide, { loading: updating }] = useAdminUpdateGuideMutation({
    onError: (err) =>
      notification.error({ message: Messages.update.fail('danh mục hướng dẫn'), description: err?.message }),
    onCompleted: () => {
      notification.success({ message: Messages.update.success('danh mục hướng dẫn') });
      navigate(-1);
    },
  });

  const onFinish = useCallback(
    (values: any) => {
      return id
        ? updateGuide({ variables: { input: { ...values, id } } })
        : createGuide({ variables: { input: values } });
    },
    [createGuide, id, updateGuide],
  );

  if (loading) return <ProgressLoading />;

  return (
    <div>
      <PageTitle
        link={AppRoutes.instructCateManagement}
        title={`${id ? 'Chỉnh sửa' : 'Thêm mới'} danh mục hướng dẫn`}
      />
      <Form
        labelCol={{ span: 4 }}
        labelAlign="left"
        wrapperCol={{ span: 15 }}
        size="small"
        initialValues={guide ?? { isActive: true }}
        onFinish={onFinish}
        autoComplete="off">
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Form.Item
          label={
            <span>
              Tên danh mục hướng dẫn<span className="text-red"> *</span>
            </span>
          }
          name="name"
          rules={[{ required: true, message: Messages.required('Tên danh mục hướng dẫn') }]}>
          <Input placeholder={`Vui lòng nhập tên danh mục hướng dẫn.`} maxLength={255} />
        </Form.Item>
        <Form.Item label={<span>Mô tả</span>} name="description">
          <TextArea placeholder={`Vui lòng nhập mô tả.`} rows={4} maxLength={1000} showCount />
        </Form.Item>
        <Form.Item label="Trạng thái" name="isActive" valuePropName="checked">
          <Switch disabled={!id} />
        </Form.Item>
        <Row>
          <Col span={20} className="flex justify-end space-x-4">
            <Button className="w-20" type="default" onClick={() => navigate(-1)}>
              Huỷ
            </Button>
            <Button className="px-2 py-8" type="primary" htmlType="submit" loading={creating || updating}>
              Lưu
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
});
