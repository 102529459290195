import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, notification, Popconfirm, Row, Select, Switch, Table } from 'antd';
import { memo, useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';

import { CategoriesQueryVariables, useCategoriesQuery } from '../../graphql/queries/categories.generated';
import { CategoryTypeEnum, CategoryEntity } from '../../graphql/type.interface';
import { notificationMessages } from '../../helpers/notification-messages';
import { PageTitle } from '../page-title';
import { DefaultPagination } from '../../utils/pagination';
import ActionGroup from '../group-action';
import { useAdminDeleteCategoryMutation } from '../../graphql/mutations/adminDeleteCategory.generated';
import { useAdminUpdateCategoryMutation } from '../../graphql/mutations/adminUpdateCategory.generated';
import { LIST_PARAMS } from '../../constants';
import { serialColumnTable } from '../../utils';

type MasterDataTableProps = {
  type: CategoryTypeEnum;
  title: string;
  urlCreate: string;
  urlEdit: (id: string) => string;
  urlDetail?: (id: string) => string;
  hiddenId?: boolean;
};

export const MasterDataTable = memo((props: MasterDataTableProps) => {
  const { title, urlCreate, urlEdit, type, urlDetail, hiddenId } = props;
  const [params, setParams] = useState<CategoriesQueryVariables>({
    ...LIST_PARAMS,
    filters: null,
    isActive: undefined,
    search: '',
    isApproved: undefined,
    type,
  });

  const {
    data: result,
    refetch,
    loading,
  } = useCategoriesQuery({ variables: params, fetchPolicy: 'cache-and-network' });

  const data =
    result?.categories?.items?.map((items: any) => ({
      key: items.id,
      id: items.id,
      name: items.name,
      isActive: items.isActive,
    })) ?? [];

  const [deleteItem, { loading: removing }] = useAdminDeleteCategoryMutation({
    onError: (err) => notification.error({ message: notificationMessages.delete.fail, description: err?.message }),
    onCompleted: () => {
      notification.success({ message: notificationMessages.delete.success });
      refetch();
    },
  });

  const [updateOrigin, { loading: updating }] = useAdminUpdateCategoryMutation({
    onError: (err) => notification.error({ message: notificationMessages.update.fail, description: err?.message }),
    onCompleted: () => {
      notification.success({ message: notificationMessages.update.success });
      refetch();
    },
  });

  const handleUpdate = useCallback(
    (data: CategoryEntity) => {
      updateOrigin({
        variables: {
          input: {
            id: data.id,
            name: data.name,
            isActive: !data.isActive,
          },
        },
      });
    },
    [updateOrigin],
  );

  const onChangePage = useCallback(
    (newPage: number, pageSize: number) => {
      setParams({ ...params, page: newPage, limit: pageSize });
    },
    [params],
  );

  const handleDelete = useCallback(
    (id: string) => {
      deleteItem({
        variables: {
          input: { id },
        },
      });
    },
    [deleteItem],
  );

  const handleFilter = useCallback(
    (values: CategoriesQueryVariables) => {
      setParams({
        ...params,
        page: 1,
        search: values.search,
        isActive: values.isActive,
      });
    },
    [params],
  );

  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: 'STT',
        key: 'index',
        dataIndex: 'index',
        align: 'center',
        width: '5%',
        render: (_, __, index) => serialColumnTable(index, { page: params.page, limit: params.limit }),
      },
      {
        title: 'ID',
        key: 'id',
        dataIndex: 'id',
        align: 'center',
        width: '25%',
        hidden: hiddenId,
      },
      {
        title: `Tên ${title}`,
        dataIndex: 'name',
        key: 'name',
        ellipsis: true,
      },
      {
        title: 'Trạng thái',
        key: 'status',
        render: (row: CategoryEntity) => (
          <Popconfirm
            title={`Bạn có chắc muốn thay đổi trạng thái không?`}
            okText="Đồng ý"
            cancelText="Huỷ bỏ"
            placement="topLeft"
            onConfirm={() => handleUpdate(row)}>
            <Switch checked={row?.isActive} size="default" />
          </Popconfirm>
        ),
        align: 'center',
        width: '6%',
      },
      {
        title: 'Action',
        key: 'action',
        dataIndex: 'id',
        render: (id: string) => <ActionGroup urlDetail={urlDetail} urlEdit={urlEdit} id={id} onDelete={handleDelete} />,
        align: 'center',
        width: '10%',
      },
    ],
    [handleDelete, handleUpdate, hiddenId, params.limit, params.page, title, urlDetail, urlEdit],
  );

  return (
    <div>
      <PageTitle title={`Danh sách ${title}`} />
      <div>
        <Form size="small" onFinish={handleFilter}>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item name="search" normalize={(e) => e.trimStart()}>
                <Input placeholder="Tìm kiếm theo tên..." addonBefore={<SearchOutlined />} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="isActive">
                <Select
                  placeholder="Trạng thái"
                  options={[
                    { label: 'Tất cả', value: null },
                    { label: 'Kích hoạt', value: 'ACTIVE' },
                    { label: 'Vô hiệu', value: 'INACTIVE' },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Áp dụng
                </Button>
              </Form.Item>
            </Col>
            {urlCreate && (
              <Col offset={8} span={2} className="flex space-x-4">
                <Link to={urlCreate}>
                  <Button type="primary">Tạo mới</Button>
                </Link>
              </Col>
            )}
          </Row>
        </Form>
      </div>
      <div>
        <Table
          size="small"
          loading={loading || updating || removing}
          bordered
          columns={columns.filter((it: any) => !it.hidden)}
          dataSource={data}
          pagination={{
            ...DefaultPagination,
            onChange: onChangePage,
            current: Number(params.page),
            total: result?.categories?.meta?.totalItems,
          }}
          scroll={{ y: 'calc(100vh - 320px)' }}
        />
      </div>
    </div>
  );
});
