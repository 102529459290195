import { memo } from 'react';

import {
  CategoryEntity,
  QuotationEntity,
  SettlementAdditionalFeeEntity,
  SettlementEntity,
} from '../../graphql/type.interface';
import { quotationStatusText } from '../../constants';
import { getNameCategoriesEntity } from '../../utils';

import { DetailExpense } from './detail-total-expense';
import InfoQuotes from './info-quotes';

type Props = {
  quotation: QuotationEntity;
  isQuotation?: boolean;
  settlement: SettlementEntity;
};

const TableRequestRepair = memo(({ quotation, isQuotation, settlement }: Props) => {
  return (
    <div>
      <div className="py-12px">
        <span>Trạng thái:</span>
        <span className="font-medium text-[#000000]"> {quotationStatusText(quotation.status, isQuotation)}</span>
      </div>
      {quotation?.reasons && quotation?.reasons.length > 0 && (
        <div className="pb-12px">
          <span>Lý do từ chối:</span>
          <span className="font-medium text-[#000000]">
            {getNameCategoriesEntity(quotation?.reasons as CategoryEntity[])}
          </span>
        </div>
      )}
      {quotation.rejectReasons && (
        <div className="pb-12px">
          <span>Ghi chú:</span>
          <span className="font-medium text-[#000000]"> {quotation.rejectReasons}</span>
        </div>
      )}
      <div className="flex flex-col space-y-8px">
        <div className="grid grid-cols-3 gap-x-[20px]">
          <div className="col-span-2">
            <InfoQuotes
              quotation={isQuotation ? quotation : (settlement?.quotation as QuotationEntity)}
              additionalFeesSettlement={settlement?.additionalFees as SettlementAdditionalFeeEntity[]}
            />
          </div>
          <div>
            <DetailExpense
              articles={[
                {
                  name: 'Chuẩn đoán',
                  price: (isQuotation ? quotation.diagnostics ?? [] : settlement?.quotation.diagnostics ?? []).reduce(
                    (rs: any, item: any) => rs + item.expense,
                    0,
                  ),
                },
                {
                  name: 'Vật tư phụ tùng',
                  price: (isQuotation ? quotation?.accessories ?? [] : settlement?.quotation?.accessories ?? []).reduce(
                    (rs: any, item: any) => rs + item.quantity * item.unitPrice,
                    0,
                  ),
                },
                {
                  name: 'Công dịch vụ',
                  price: isQuotation
                    ? quotation?.diagnosisFee + quotation?.repairFee + quotation?.transportFee
                    : settlement?.quotation?.diagnosisFee +
                      settlement?.quotation?.repairFee +
                      settlement?.quotation?.transportFee,
                },
                {
                  name: 'Phát sinh Công dịch vụ',
                  price: [...(quotation?.additionalFees ?? []), ...(settlement?.additionalFees ?? [])].reduce(
                    (rs: any, item: any) => rs + item.amount,
                    0,
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default TableRequestRepair;
