import { ReactNode, memo } from 'react';
import { Popconfirm, PopconfirmProps } from 'antd';

type Props = {
  children: ReactNode;
} & PopconfirmProps;

export const PopConfirmDelete = memo(({ children, title, ...props }: Props) => {
  return (
    <Popconfirm
      title={`Bạn có chắc chắn muốn xóa ${title ? title : ''} không?`}
      okText="Đồng ý"
      cancelText="Huỷ bỏ"
      {...props}>
      {children}
    </Popconfirm>
  );
});
