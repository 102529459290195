import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type CategoryQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type CategoryQueryResponse = { __typename?: 'Query' } & {
  category: { __typename?: 'CategoryEntity' } & Pick<
    Types.CategoryEntity,
    'createdAt' | 'deletedAt' | 'id' | 'isActive' | 'name' | 'type' | 'updatedAt'
  >;
};

export const CategoryDocument = gql`
  query category($id: String!) {
    category(id: $id) {
      createdAt
      deletedAt
      id
      isActive
      name
      type
      updatedAt
    }
  }
`;
export function useCategoryQuery(baseOptions: Apollo.QueryHookOptions<CategoryQueryResponse, CategoryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CategoryQueryResponse, CategoryQueryVariables>(CategoryDocument, options);
}
export function useCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CategoryQueryResponse, CategoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CategoryQueryResponse, CategoryQueryVariables>(CategoryDocument, options);
}
export type CategoryQueryHookResult = ReturnType<typeof useCategoryQuery>;
export type CategoryLazyQueryHookResult = ReturnType<typeof useCategoryLazyQuery>;
export type CategoryQueryResult = Apollo.QueryResult<CategoryQueryResponse, CategoryQueryVariables>;
