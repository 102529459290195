import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminUpdateOrderStatusMutationVariables = Types.Exact<{
  input: Types.UpdateOrderInput;
}>;

export type AdminUpdateOrderStatusMutationResponse = { __typename?: 'Mutation' } & Pick<
  Types.Mutation,
  'adminUpdateOrderStatus'
>;

export const AdminUpdateOrderStatusDocument = gql`
  mutation adminUpdateOrderStatus($input: UpdateOrderInput!) {
    adminUpdateOrderStatus(input: $input)
  }
`;
export function useAdminUpdateOrderStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpdateOrderStatusMutationResponse,
    AdminUpdateOrderStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminUpdateOrderStatusMutationResponse, AdminUpdateOrderStatusMutationVariables>(
    AdminUpdateOrderStatusDocument,
    options,
  );
}
export type AdminUpdateOrderStatusMutationHookResult = ReturnType<typeof useAdminUpdateOrderStatusMutation>;
export type AdminUpdateOrderStatusMutationResult = Apollo.MutationResult<AdminUpdateOrderStatusMutationResponse>;
export type AdminUpdateOrderStatusMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateOrderStatusMutationResponse,
  AdminUpdateOrderStatusMutationVariables
>;
