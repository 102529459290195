import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminCreateProductVehicleMutationVariables = Types.Exact<{
  input: Types.CreateProductVehicleInput;
}>;

export type AdminCreateProductVehicleMutationResponse = { __typename?: 'Mutation' } & {
  adminCreateProductVehicle: { __typename?: 'ProductEntity' } & Pick<Types.ProductEntity, 'id' | 'name'>;
};

export const AdminCreateProductVehicleDocument = gql`
  mutation adminCreateProductVehicle($input: CreateProductVehicleInput!) {
    adminCreateProductVehicle(input: $input) {
      id
      name
    }
  }
`;
export function useAdminCreateProductVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateProductVehicleMutationResponse,
    AdminCreateProductVehicleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminCreateProductVehicleMutationResponse, AdminCreateProductVehicleMutationVariables>(
    AdminCreateProductVehicleDocument,
    options,
  );
}
export type AdminCreateProductVehicleMutationHookResult = ReturnType<typeof useAdminCreateProductVehicleMutation>;
export type AdminCreateProductVehicleMutationResult = Apollo.MutationResult<AdminCreateProductVehicleMutationResponse>;
export type AdminCreateProductVehicleMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateProductVehicleMutationResponse,
  AdminCreateProductVehicleMutationVariables
>;
