import { Button, Form, Input, UploadFile } from 'antd';
import { memo, useCallback, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import CKEditorBuild from 'ckeditor5-custom-build';

import { FullscreenLoading, PageTitle } from '../../components';
import { AppRoutes, validationMessages } from '../../helpers';
import { UploadImage, UploadImageRef } from '../../components/upload-image';
import { useAdminCreateNewsMutation } from '../../graphql/mutations/adminCreateNews.generated';
import { showNotification } from '../../utils';
import { CreateNewsInput } from '../../graphql/type.interface';
import { AdminNewsDocument } from '../../graphql/queries/adminNews.generated';
import { useAdminGetNewsByIdQuery } from '../../graphql/queries/adminGetNewsById.generated';
import { useAdminUpdateNewsMutation } from '../../graphql/mutations/adminUpdateNews.generated';

import './style.less';

interface Props {
  isEdit?: boolean;
  isDetail?: boolean;
}

const FormNews = memo(({ isEdit, isDetail }: Props) => {
  const { id = '' } = useParams();

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const ref = useRef<UploadImageRef>(null);

  const { data, loading } = useAdminGetNewsByIdQuery({ variables: { id }, skip: !id });
  const news = useMemo(() => data?.adminGetNewsById, [data?.adminGetNewsById]);

  const [adminCreateNews, { loading: loadingCreateNews }] = useAdminCreateNewsMutation({
    onCompleted() {
      showNotification('success', 'Thêm mới tin tức thành công');
      navigate(AppRoutes.newsManagement);
    },
    onError(e) {
      showNotification('error', 'Thêm mới tin tức thất bại', e?.message);
    },
  });

  const [adminUpdateNews] = useAdminUpdateNewsMutation({
    onCompleted() {
      showNotification('success', 'Sửa tin tức thành công');
      navigate(AppRoutes.newsManagement);
    },
    onError(e) {
      showNotification('error', 'Sửa tin tức thất bại', e?.message);
    },
  });

  const onFinish = useCallback(
    async (values: CreateNewsInput) => {
      const avatarId = await ref?.current?.upload();
      if (isEdit) {
        await adminUpdateNews({
          variables: {
            input: {
              id,
              body: values.body,
              isActive: true,
              mediaId: avatarId as string,
              title: values.title,
              description: values.description,
            },
          },
        });
      } else {
        await adminCreateNews({
          variables: {
            input: {
              body: values.body,
              isActive: true,
              mediaId: avatarId as string,
              title: values.title,
              description: values.description,
            },
          },
          refetchQueries: [AdminNewsDocument],
        });
      }
    },
    [adminCreateNews, adminUpdateNews, id, isEdit],
  );

  if (loading) {
    return <FullscreenLoading />;
  }

  return (
    <div>
      <PageTitle
        link={AppRoutes.newsManagement}
        title={isDetail ? 'Thông tin chi tiết tin tức' : !isDetail && isEdit ? 'Chỉnh sửa tin tức' : 'Thêm mới tin tức'}
      />
      <div className="form-news">
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          labelAlign="left"
          disabled={isDetail}
          size="small"
          id="form-news"
          name="form-news"
          initialValues={data?.adminGetNewsById}
          onFinish={onFinish}>
          <Form.Item
            label={
              <span>
                Ảnh<span className="text-red"> *</span>
              </span>
            }
            name="avatarId"
            rules={[
              {
                validator: (rule, value) => {
                  if (!isEdit && !value) {
                    return Promise.reject(validationMessages.required);
                  }
                  return Promise.resolve();
                },
              },
            ]}>
            <UploadImage
              ref={ref}
              files={
                news?.media?.fullThumbUrl
                  ? ([
                      {
                        uid: news?.media?.id,
                        name: news?.media?.name,
                        status: 'done',
                        url: news?.media?.fullThumbUrl,
                      },
                    ] as UploadFile[])
                  : undefined
              }
            />
          </Form.Item>
          <Form.Item
            label={
              <span>
                Tiêu đề<span className="text-red"> *</span>
              </span>
            }
            name="title"
            rules={[{ required: true, message: validationMessages.required }]}
            normalize={(e) => e.trimStart()}>
            <Input placeholder="Nhập tiêu đề" maxLength={255}></Input>
          </Form.Item>
          <Form.Item
            label={
              <span>
                Mô tả<span className="text-red"> *</span>
              </span>
            }
            name="description"
            rules={[{ required: true, message: validationMessages.required }]}
            normalize={(e) => e.trimStart()}>
            <Input placeholder="Nhập mô tả" maxLength={255}></Input>
          </Form.Item>
          <Form.Item
            label={
              <span>
                Nội dung<span className="text-red"> *</span>
              </span>
            }
            name="body">
            <CustomEditor />
          </Form.Item>
        </Form>
      </div>
      {!isDetail && (
        <div className="w-full flex justify-end gap-x-[20px]">
          <Button className="w-20" type="default" size="small" onClick={() => navigate(-1)}>
            Huỷ
          </Button>
          <Button
            form="form-news"
            size="small"
            className="w-20"
            type="primary"
            htmlType="submit"
            loading={loadingCreateNews}
            disabled={loadingCreateNews}>
            Lưu
          </Button>
        </div>
      )}
    </div>
  );
});

const CustomEditor = ({ onChange, value }: { onChange?(val: string): void; value?: string }) => {
  return (
    <CKEditor
      editor={CKEditorBuild}
      data={value}
      onChange={(event, editor: any) => {
        const data = editor.getData();
        onChange?.(data);
      }}
    />
  );
};

export default FormNews;
