import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminDeleteCategoryMutationVariables = Types.Exact<{
  input: Types.DeleteCategoryInput;
}>;

export type AdminDeleteCategoryMutationResponse = { __typename?: 'Mutation' } & Pick<
  Types.Mutation,
  'adminDeleteCategory'
>;

export const AdminDeleteCategoryDocument = gql`
  mutation adminDeleteCategory($input: DeleteCategoryInput!) {
    adminDeleteCategory(input: $input)
  }
`;
export function useAdminDeleteCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminDeleteCategoryMutationResponse, AdminDeleteCategoryMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminDeleteCategoryMutationResponse, AdminDeleteCategoryMutationVariables>(
    AdminDeleteCategoryDocument,
    options,
  );
}
export type AdminDeleteCategoryMutationHookResult = ReturnType<typeof useAdminDeleteCategoryMutation>;
export type AdminDeleteCategoryMutationResult = Apollo.MutationResult<AdminDeleteCategoryMutationResponse>;
export type AdminDeleteCategoryMutationOptions = Apollo.BaseMutationOptions<
  AdminDeleteCategoryMutationResponse,
  AdminDeleteCategoryMutationVariables
>;
