import { memo } from 'react';
import { Descriptions } from 'antd';

import './style.less';
import { numberWithDots } from '../../utils';

interface Article {
  name: string;
  price: number;
}

interface Props {
  articles?: Article[];
  total?: number;
}
export const DetailExpense = memo((props: Props) => {
  const { articles } = props;

  if (!articles) return null;
  return (
    <div className="flex flex-col space-y-8px">
      <div className="bg-white p-[20px] space-y-[20px]">
        <div className="flex flex-row items-center justify-between">
          <div className="font-semibold leading-[24px] text-yankees-blue uppercase">Tổng chi phí</div>
          <div className="text-[14px] leading-[20px] text-grayscale-gray">{articles.length} hạng mục</div>
        </div>
        <Descriptions className="expense-wrapper" bordered column={1}>
          {articles.map((e) => (
            <Descriptions.Item
              key={e.name}
              label={e.name}
              labelStyle={{ width: '50%' }}
              contentStyle={{ color: '#202C38', textAlign: 'right' }}>
              {numberWithDots(e.price)}
            </Descriptions.Item>
          ))}
          <Descriptions.Item
            label="Tổng số tiền"
            labelStyle={{ width: '50%', backgroundColor: '#EEEEEE' }}
            contentStyle={{ width: '50%', fontWeight: 600, fontSize: '20px', color: '#202C38', textAlign: 'right' }}>
            {props?.total
              ? numberWithDots(props?.total)
              : numberWithDots(articles.reduce((rs, item) => rs + item.price, 0))}
          </Descriptions.Item>
        </Descriptions>
      </div>
    </div>
  );
});
