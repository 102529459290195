import { Dropdown, MenuProps, Avatar, Space } from 'antd';
import { memo } from 'react';
import { LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { LogoIcon } from '../../assets/icons';
import { useAuth } from '../../contexts';
import { AppRoutes } from '../../helpers';

export const Header = memo(() => {
  const { user, logout } = useAuth();

  const items: MenuProps['items'] = [
    {
      key: 'setting',
      label: <Link to={AppRoutes.accountInfo}>Thiết lập hồ sơ</Link>,
      icon: <SettingOutlined />,
    },
    {
      key: 'log-out',
      label: (
        <Link to="#" type="link" onClick={() => logout()}>
          Logout
        </Link>
      ),
      icon: <LogoutOutlined />,
    },
  ];

  return (
    <div className="h-full w-full leading-normal">
      <div className="px-24px py-10px flex justify-between items-center h-full">
        <LogoIcon />
        <Dropdown menu={{ items }} trigger={['click']} placement="bottomLeft">
          <Space>
            <p className="mx-12px text-inherit truncate p-0 m-0">{user?.fullname ?? 'Unknown'}</p>
            <Avatar size="small" src={user?.avatar?.fullThumbUrl} icon={<UserOutlined />} />
          </Space>
        </Dropdown>
      </div>
    </div>
  );
});
