import React, { memo } from 'react';

import { MasterDataTable } from '../../components';
import { CategoryTypeEnum } from '../../graphql/type.interface';
import { AppRoutes } from '../../helpers';

const VehicleManagement = memo(() => {
  return (
    <MasterDataTable
      type={CategoryTypeEnum.VEHICLE_TYPE}
      title="Chủng loại"
      urlCreate={AppRoutes.vehicleCreate}
      urlEdit={AppRoutes.vehicleEditId}
    />
  );
});

export default VehicleManagement;
