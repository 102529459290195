import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { Button, Col, Form, Input, notification, Row, Switch } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { PageTitle } from '../page-title';
import { useCategoryQuery } from '../../graphql/queries/category.generated';
import { notificationMessages } from '../../helpers/notification-messages';
import { CategoryTypeEnum } from '../../graphql/type.interface';
import { useAdminCreateCategoryMutation } from '../../graphql/mutations/adminCreateCategory.generated';
import { useAdminUpdateCategoryMutation } from '../../graphql/mutations/adminUpdateCategory.generated';

type Props = {
  backTo: string;
  title: string;
  typeCategory: CategoryTypeEnum;
  isDetail?: boolean;
};

export const MasterForm = memo(({ backTo, title, typeCategory, isDetail = false }: Props) => {
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const [form] = Form.useForm();

  const { data: results, loading: getting } = useCategoryQuery({ variables: { id }, skip: !id });

  const data = useMemo(
    () =>
      results?.category || {
        createdAt: null,
        deletedAt: null,
        id,
        isActive: false,
        name: '',
        type: typeCategory,
        updatedAt: null,
      },
    [results?.category, id, typeCategory],
  );

  const [createByTypeCategory, { loading: creating }] = useAdminCreateCategoryMutation({
    onError(err) {
      notification.error({ message: notificationMessages.create.fail, description: err.message });
    },
    onCompleted() {
      notification.success({ message: notificationMessages.create.success });
      navigate(-1);
    },
  });

  const [updateByTypeCategory, { loading: updating }] = useAdminUpdateCategoryMutation({
    onError(err) {
      notification.error({ message: notificationMessages.update.fail, description: err.message });
    },
    onCompleted() {
      notification.success({ message: notificationMessages.update.success });
      navigate(-1);
    },
  });

  const handleSubmit = useCallback(
    (values: any) => {
      const newName = values.name.replace(/\s+/g, ' ').trim();
      if (id) {
        updateByTypeCategory({ variables: { input: { ...values, id, name: newName } } });
      } else {
        createByTypeCategory({ variables: { input: { ...values, type: typeCategory, name: newName } } });
      }
    },
    [createByTypeCategory, updateByTypeCategory, id, typeCategory],
  );

  useEffect(() => {
    if (data && id) {
      form.setFieldsValue({
        name: data?.name,
        isActive: data?.isActive,
      });
    }
  }, [data, form, id]);

  return (
    <>
      <PageTitle title={`${!id ? 'Thêm mới' : id && isDetail ? 'Thông tin' : 'Chỉnh sửa'} ${title}`} link={backTo} />
      <div>
        <Form
          form={form}
          disabled={isDetail}
          labelCol={{ span: 5 }}
          labelAlign="left"
          wrapperCol={{ span: 15 }}
          size="small"
          initialValues={{ isActive: true }}
          onFinish={handleSubmit}
          autoComplete="off">
          <Form.Item
            label={
              <span>
                {`Tên ${title}`}
                <span className="text-red"> *</span>
              </span>
            }
            name="name"
            normalize={(e) => e.trimStart()}
            rules={[{ required: true, message: 'Vui lòng không để trống trường này' }]}>
            <Input value={data?.name} placeholder={`Nhập tên ${title}`} maxLength={255} />
          </Form.Item>
          <Form.Item label="Trạng thái" name="isActive" valuePropName="checked">
            <Switch disabled={!id ? true : false} />
          </Form.Item>
          {!isDetail && (
            <Row>
              <Col span={20} className="flex justify-end space-x-4">
                <Button className="w-20" type="default" onClick={() => navigate(-1)}>
                  Huỷ
                </Button>
                <Button className="w-20" type="primary" htmlType="submit" loading={getting || creating || updating}>
                  {!id ? 'Thêm' : 'Lưu'}
                </Button>
              </Col>
            </Row>
          )}
        </Form>
      </div>
    </>
  );
});
