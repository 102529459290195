import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminUpdateDiscountCodeMutationVariables = Types.Exact<{
  input: Types.UpdateDiscountCodeInput;
}>;

export type AdminUpdateDiscountCodeMutationResponse = { __typename?: 'Mutation' } & {
  adminUpdateDiscountCode: { __typename?: 'DiscountCodeEntity' } & Pick<
    Types.DiscountCodeEntity,
    | 'createdAt'
    | 'deletedAt'
    | 'endDate'
    | 'id'
    | 'isActive'
    | 'isAssignAllProduct'
    | 'limit'
    | 'limitPerAccount'
    | 'minOrderValue'
    | 'name'
    | 'startDate'
    | 'unit'
    | 'updatedAt'
    | 'value'
  > & {
      products?: Types.Maybe<
        Array<
          { __typename?: 'ProductEntity' } & Pick<
            Types.ProductEntity,
            | 'avatarId'
            | 'createdAt'
            | 'deletedAt'
            | 'descriptionImageIds'
            | 'detail'
            | 'id'
            | 'isActive'
            | 'isFixedCost'
            | 'isNew'
            | 'name'
            | 'operatingNumber'
            | 'operatingUnit'
            | 'ordinalNumber'
            | 'partNumber'
            | 'partnerId'
            | 'quantity'
            | 'serialNumber'
            | 'type'
            | 'unitPrice'
            | 'updatedAt'
            | 'vehicleRegistrationPlate'
            | 'vinNumber'
            | 'yearOfManufacture'
          > & {
              avatar?: Types.Maybe<
                { __typename?: 'Media' } & Pick<
                  Types.Media,
                  | 'createdAt'
                  | 'fileSize'
                  | 'fullOriginalUrl'
                  | 'fullThumbUrl'
                  | 'id'
                  | 'isDeleted'
                  | 'mimeType'
                  | 'name'
                  | 'originalUrl'
                  | 'ownerId'
                  | 'thumbUrl'
                  | 'type'
                  | 'updatedAt'
                  | 'videoUrl'
                >
              >;
              descriptionImages: Array<
                { __typename?: 'Media' } & Pick<
                  Types.Media,
                  | 'createdAt'
                  | 'fileSize'
                  | 'fullOriginalUrl'
                  | 'fullThumbUrl'
                  | 'id'
                  | 'isDeleted'
                  | 'mimeType'
                  | 'name'
                  | 'originalUrl'
                  | 'ownerId'
                  | 'thumbUrl'
                  | 'type'
                  | 'updatedAt'
                  | 'videoUrl'
                >
              >;
              manufacturer?: Types.Maybe<
                { __typename?: 'CategoryEntity' } & Pick<
                  Types.CategoryEntity,
                  'createdAt' | 'deletedAt' | 'id' | 'isActive' | 'name' | 'type' | 'updatedAt'
                >
              >;
              model?: Types.Maybe<
                { __typename?: 'CategoryEntity' } & Pick<
                  Types.CategoryEntity,
                  'createdAt' | 'deletedAt' | 'id' | 'isActive' | 'name' | 'type' | 'updatedAt'
                >
              >;
              origin?: Types.Maybe<
                { __typename?: 'CategoryEntity' } & Pick<
                  Types.CategoryEntity,
                  'createdAt' | 'deletedAt' | 'id' | 'isActive' | 'name' | 'type' | 'updatedAt'
                >
              >;
              partOfProduct?: Types.Maybe<
                { __typename?: 'CategoryEntity' } & Pick<
                  Types.CategoryEntity,
                  'createdAt' | 'deletedAt' | 'id' | 'isActive' | 'name' | 'type' | 'updatedAt'
                >
              >;
              partner?: Types.Maybe<
                { __typename?: 'PartnerEntity' } & Pick<
                  Types.PartnerEntity,
                  | 'addressMoreInfo'
                  | 'avatarId'
                  | 'bank'
                  | 'birthday'
                  | 'cardNumber'
                  | 'citizenId'
                  | 'countProduct'
                  | 'countTechnician'
                  | 'createdAt'
                  | 'deletedAt'
                  | 'description'
                  | 'email'
                  | 'fullname'
                  | 'hotline'
                  | 'id'
                  | 'isActive'
                  | 'isApproved'
                  | 'latitude'
                  | 'longitude'
                  | 'mapAddress'
                  | 'parentId'
                  | 'phone'
                  | 'star'
                  | 'suggestionPoint'
                  | 'type'
                  | 'updatedAt'
                >
              >;
              productType?: Types.Maybe<
                { __typename?: 'CategoryEntity' } & Pick<
                  Types.CategoryEntity,
                  'createdAt' | 'deletedAt' | 'id' | 'isActive' | 'name' | 'type' | 'updatedAt'
                >
              >;
              productUnit?: Types.Maybe<
                { __typename?: 'CategoryEntity' } & Pick<
                  Types.CategoryEntity,
                  'createdAt' | 'deletedAt' | 'id' | 'isActive' | 'name' | 'type' | 'updatedAt'
                >
              >;
            }
        >
      >;
    };
};

export const AdminUpdateDiscountCodeDocument = gql`
  mutation adminUpdateDiscountCode($input: UpdateDiscountCodeInput!) {
    adminUpdateDiscountCode(input: $input) {
      createdAt
      deletedAt
      endDate
      id
      isActive
      isAssignAllProduct
      limit
      limitPerAccount
      minOrderValue
      name
      products {
        avatar {
          createdAt
          fileSize
          fullOriginalUrl
          fullThumbUrl
          id
          isDeleted
          mimeType
          name
          originalUrl
          ownerId
          thumbUrl
          type
          updatedAt
          videoUrl
        }
        avatarId
        createdAt
        deletedAt
        descriptionImageIds
        descriptionImages {
          createdAt
          fileSize
          fullOriginalUrl
          fullThumbUrl
          id
          isDeleted
          mimeType
          name
          originalUrl
          ownerId
          thumbUrl
          type
          updatedAt
          videoUrl
        }
        detail
        id
        isActive
        isFixedCost
        isNew
        manufacturer {
          createdAt
          deletedAt
          id
          isActive
          name
          type
          updatedAt
        }
        model {
          createdAt
          deletedAt
          id
          isActive
          name
          type
          updatedAt
        }
        name
        operatingNumber
        operatingUnit
        ordinalNumber
        origin {
          createdAt
          deletedAt
          id
          isActive
          name
          type
          updatedAt
        }
        partNumber
        partOfProduct {
          createdAt
          deletedAt
          id
          isActive
          name
          type
          updatedAt
        }
        partner {
          addressMoreInfo
          avatarId
          bank
          birthday
          cardNumber
          citizenId
          countProduct
          countTechnician
          createdAt
          deletedAt
          description
          email
          fullname
          hotline
          id
          isActive
          isApproved
          latitude
          longitude
          mapAddress
          parentId
          phone
          star
          suggestionPoint
          type
          updatedAt
        }
        partnerId
        productType {
          createdAt
          deletedAt
          id
          isActive
          name
          type
          updatedAt
        }
        productUnit {
          createdAt
          deletedAt
          id
          isActive
          name
          type
          updatedAt
        }
        quantity
        serialNumber
        type
        unitPrice
        updatedAt
        vehicleRegistrationPlate
        vinNumber
        yearOfManufacture
      }
      startDate
      unit
      updatedAt
      value
    }
  }
`;
export function useAdminUpdateDiscountCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpdateDiscountCodeMutationResponse,
    AdminUpdateDiscountCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminUpdateDiscountCodeMutationResponse, AdminUpdateDiscountCodeMutationVariables>(
    AdminUpdateDiscountCodeDocument,
    options,
  );
}
export type AdminUpdateDiscountCodeMutationHookResult = ReturnType<typeof useAdminUpdateDiscountCodeMutation>;
export type AdminUpdateDiscountCodeMutationResult = Apollo.MutationResult<AdminUpdateDiscountCodeMutationResponse>;
export type AdminUpdateDiscountCodeMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateDiscountCodeMutationResponse,
  AdminUpdateDiscountCodeMutationVariables
>;
