import { PropsWithChildren, memo } from 'react';

import { CheckedCircleIcon, ErrorIcon } from '../../assets/icons';

type Props = PropsWithChildren<{ error?: boolean; success?: boolean }>;

export const ResponseMessage = memo(({ error, success, children }: Props) => {
  if (!children) return null;
  return (
    <div
      className={`px-[16px] py-12px rounded flex flex-row items-center text-[13px] text-yankees-blue ${
        error ? 'bg-[#FFEAE8]' : ''
      } ${success ? 'bg-[#E5F7ED]' : ''}`}>
      {error && <ErrorIcon className="mr-12px" />}
      {success && <CheckedCircleIcon className="mr-12px" />}
      {children}
    </div>
  );
});
