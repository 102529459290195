import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminBookingQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type AdminBookingQueryResponse = { __typename?: 'Query' } & {
  adminBooking: { __typename?: 'BookingEntity' } & Pick<
    Types.BookingEntity,
    | 'addressMoreInfo'
    | 'code'
    | 'createdAt'
    | 'deletedAt'
    | 'description'
    | 'id'
    | 'latitude'
    | 'longitude'
    | 'mapAddress'
    | 'partnerId'
    | 'problemTexts'
    | 'status'
    | 'transportDistance'
    | 'transportDuration'
    | 'transportFee'
    | 'updatedAt'
    | 'userId'
    | 'vehicleId'
  > & {
      medias: Array<
        { __typename?: 'Media' } & Pick<
          Types.Media,
          | 'createdAt'
          | 'fileSize'
          | 'fullOriginalUrl'
          | 'fullThumbUrl'
          | 'id'
          | 'isDeleted'
          | 'mimeType'
          | 'name'
          | 'originalUrl'
          | 'ownerId'
          | 'thumbUrl'
          | 'type'
          | 'updatedAt'
        >
      >;
      technician?: Types.Maybe<{ __typename?: 'PartnerEntity' } & Pick<Types.PartnerEntity, 'fullname'>>;
      partner: { __typename?: 'PartnerEntity' } & Pick<Types.PartnerEntity, 'fullname'>;
      problems?: Types.Maybe<Array<{ __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'name'>>>;
      user: { __typename?: 'UserEntity' } & Pick<Types.UserEntity, 'fullname'>;
      vehicle: { __typename?: 'VehicleEntity' } & Pick<Types.VehicleEntity, 'name'> & {
          avatar?: Types.Maybe<{ __typename?: 'Media' } & Pick<Types.Media, 'fullThumbUrl'>>;
        };
      settlementAccepted?: Types.Maybe<{ __typename?: 'SettlementEntity' } & Pick<Types.SettlementEntity, 'id'>>;
    };
};

export const AdminBookingDocument = gql`
  query adminBooking($id: String!) {
    adminBooking(id: $id) {
      addressMoreInfo
      code
      createdAt
      deletedAt
      description
      id
      latitude
      longitude
      mapAddress
      medias {
        createdAt
        fileSize
        fullOriginalUrl
        fullThumbUrl
        id
        isDeleted
        mimeType
        name
        originalUrl
        ownerId
        thumbUrl
        type
        updatedAt
      }
      technician {
        fullname
      }
      partner {
        fullname
      }
      partnerId
      problemTexts
      problems {
        name
      }
      status
      transportDistance
      transportDuration
      transportFee
      updatedAt
      user {
        fullname
      }
      userId
      vehicle {
        name
        avatar {
          fullThumbUrl
        }
      }
      vehicleId
      settlementAccepted {
        id
      }
    }
  }
`;
export function useAdminBookingQuery(
  baseOptions: Apollo.QueryHookOptions<AdminBookingQueryResponse, AdminBookingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminBookingQueryResponse, AdminBookingQueryVariables>(AdminBookingDocument, options);
}
export function useAdminBookingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminBookingQueryResponse, AdminBookingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminBookingQueryResponse, AdminBookingQueryVariables>(AdminBookingDocument, options);
}
export type AdminBookingQueryHookResult = ReturnType<typeof useAdminBookingQuery>;
export type AdminBookingLazyQueryHookResult = ReturnType<typeof useAdminBookingLazyQuery>;
export type AdminBookingQueryResult = Apollo.QueryResult<AdminBookingQueryResponse, AdminBookingQueryVariables>;
