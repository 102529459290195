import { useCallback, useState, useMemo, memo } from 'react';
import {
  Button,
  Col,
  Dropdown,
  Form,
  Popconfirm,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  Upload,
  UploadProps,
  message,
  notification,
} from 'antd';
import Search from 'antd/es/transfer/search';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import { Maybe } from 'graphql/jsutils/Maybe';
import { RcFile } from 'antd/es/upload';

import { useUploadExcelMutation } from '../../services';
import { AppRoutes } from '../../helpers';
import { PageTitle } from '../../components/page-title';
import { CategoryEntity, PartnerEntity } from '../../graphql/type.interface';
import { notificationMessages } from '../../helpers/notification-messages';
import { DefaultPagination } from '../../utils/pagination';
import ActionGroup from '../../components/group-action';
import {
  AdminTechniciansQueryVariables,
  useAdminTechniciansQuery,
} from '../../graphql/queries/adminTechnicians.generated';
import { useAdminRemoveTechnicianMutation } from '../../graphql/mutations/adminRemoveTechnician.generated';
import { useAdminUpdateTechnicianStatusMutation } from '../../graphql/mutations/adminUpdateTechnicianStatus.generated';
import { useAdminUpdateTechnicianApproveStatusMutation } from '../../graphql/mutations/adminUpdateTechnicianApproveStatus.generated';
import { useAdminAgenciesQuery } from '../../graphql/queries/adminAgencies.generated';
import { ALL_PARAMS, LIST_PARAMS, Messages } from '../../constants';
import { downloadExcel, serialColumnTable, showNotification } from '../../utils';
import { useAdminExportTechniciansLazyQuery } from '../../graphql/queries/adminExportTechnicians.generated';

const ListTechnician = memo(() => {
  const [params, setParams] = useState<AdminTechniciansQueryVariables>(LIST_PARAMS);

  const {
    data: results,
    loading,
    refetch,
  } = useAdminTechniciansQuery({ variables: params, fetchPolicy: 'cache-and-network' });
  const data = useMemo(
    () =>
      results?.adminTechnicians?.items
        ? results?.adminTechnicians?.items.map((item) => ({ ...item, key: item.id }))
        : [],
    [results],
  );

  const { data: agenciesData, loading: agenciesLoading } = useAdminAgenciesQuery({ variables: ALL_PARAMS });

  const [deleteTechnician, { loading: removing }] = useAdminRemoveTechnicianMutation({
    onError: (err) => notification.error({ message: notificationMessages.delete.fail, description: err?.message }),
    onCompleted: () => {
      notification.success({ message: notificationMessages.delete.success });
      refetch();
    },
  });
  const [updateStatusTechnician, { loading: updating }] = useAdminUpdateTechnicianStatusMutation({
    onError: (err) => notification.error({ message: notificationMessages.update.fail, description: err?.message }),
    onCompleted: () => {
      notification.success({ message: notificationMessages.update.success });
      refetch();
    },
  });
  const [updateApproveTechnician, { loading: updatingApprove }] = useAdminUpdateTechnicianApproveStatusMutation({
    onError: (err) => notification.error({ message: 'Bạn đã phê duyệt KTV thất bại.', description: err?.message }),
    onCompleted: () => {
      notification.success({ message: 'Bạn đã phê duyệt KTV thành công.' });
      refetch();
    },
  });

  const { mutateAsync: mutateFileAsync, isLoading: fileUploading } = useUploadExcelMutation({
    onError(error: any) {
      showNotification('error', Messages.upload.fail('file'), error?.response?.data?.message ?? '');
    },
    onSuccess: () => {
      notification.success({ message: 'Tải lên file excel thành công.' });
      refetch();
    },
  });

  const handleDelete = useCallback(
    (id: string) => {
      deleteTechnician({ variables: { id } });
    },
    [deleteTechnician],
  );
  const handleUpdateActiveTechnician = useCallback(
    (values: PartnerEntity) => {
      updateStatusTechnician({ variables: { input: { id: values?.id, isActive: !values?.isActive } } });
    },
    [updateStatusTechnician],
  );
  const handleUpdateApproveTechnician = useCallback(
    (values: PartnerEntity) => {
      updateApproveTechnician({ variables: { input: { id: values?.id, isApproved: !values?.isApproved } } });
    },
    [updateApproveTechnician],
  );

  const handleFilter = useCallback(
    (values: any) => {
      setParams({
        ...params,
        ...values,
        page: 1,
      });
    },
    [params],
  );

  const onChangePage = useCallback(
    (newPage: number, pageSize: number) => setParams({ ...params, page: newPage, limit: pageSize }),
    [params],
  );

  const [adminExportAgenciesMutation, { loading: exporting }] = useAdminExportTechniciansLazyQuery({
    onCompleted(data) {
      downloadExcel(data?.adminExportTechnicians, 'Danh-Sach-Ky-Thuat-Vien');
    },
    onError(error) {
      showNotification('error', 'Tải xuống thất bại', error?.message);
    },
  });

  const handleExport = useCallback(() => {
    adminExportAgenciesMutation({ variables: params });
  }, [adminExportAgenciesMutation, params]);

  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      key: 'name',
      width: 60,
      align: 'center',
      render: (_, __, index) => serialColumnTable(index, { page: params.page, limit: params.limit }),
    },
    {
      title: 'Họ và tên',
      dataIndex: 'fullname',
      key: 'fullname',
      ellipsis: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
    },
    {
      title: 'Số điện thoại',
      key: 'phone',
      dataIndex: 'phone',
      align: 'center',
      width: 120,
    },
    {
      title: 'Địa chỉ',
      key: 'addressMoreInfo',
      dataIndex: 'addressMoreInfo',
      ellipsis: true,
    },
    {
      title: 'Chuyên môn',
      key: 'qualifications',
      dataIndex: 'qualifications',
      render: (qualifications: CategoryEntity[]) => {
        return (qualifications || []).map((qualification, index: number) => {
          if (index === qualifications.length - 1) return qualification.name;
          return qualification.name + ', ';
        });
      },
      ellipsis: true,
    },
    {
      title: 'Trình độ học vấn',
      key: 'education',
      dataIndex: 'education',
      render: (education: CategoryEntity) => {
        if (!education) return;
        return education.name || '';
      },
      ellipsis: true,
    },
    {
      title: 'Đại lý trực thuộc',
      dataIndex: 'parentInfo',
      render: (parentInfo: Maybe<PartnerEntity>) =>
        parentInfo && <Link to={AppRoutes.agencyDetailId(parentInfo.id)}>{parentInfo.fullname}</Link>,
      ellipsis: true,
    },
    {
      title: 'Trạng thái chờ duyệt',
      key: 'isApproved',
      render: (row: PartnerEntity) => (row?.isApproved ? 'Đã duyệt' : 'Chưa duyệt'),
      align: 'center',
      width: 100,
    },
    {
      title: 'Duyệt KTV',
      key: 'action',
      width: 180,
      render: (row: PartnerEntity) =>
        !row?.isApproved && (
          <div className="flex items-center space-x-4">
            <Popconfirm
              title={`Bạn có chắc muốn phê duyệt không?`}
              okText="Đồng ý"
              cancelText="Huỷ bỏ"
              placement="topLeft"
              onConfirm={() => handleUpdateApproveTechnician(row)}>
              <Button size="small" type="primary">
                Phê duyệt
              </Button>
            </Popconfirm>
            <Popconfirm
              title={`Bạn có chắc muốn từ chối không?`}
              okText="Đồng ý"
              cancelText="Huỷ bỏ"
              placement="topLeft"
              onConfirm={() => handleDelete(row?.id)}>
              <Button size="small">Từ chối</Button>
            </Popconfirm>
          </div>
        ),
      align: 'center',
    },
    {
      title: 'Trạng thái',
      key: 'isActive',
      render: (row: PartnerEntity) => (
        <Popconfirm
          title={`Bạn có chắc muốn thay đổi trạng thái không?`}
          okText="Đồng ý"
          cancelText="Huỷ bỏ"
          placement="topLeft"
          onConfirm={() => handleUpdateActiveTechnician(row)}>
          <Switch checked={row?.isActive} size="default" />
        </Popconfirm>
      ),
      align: 'center',
      width: 60,
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'id',
      render: (id: string) => (
        <ActionGroup
          urlDetail={AppRoutes.technicianDetailId}
          urlEdit={AppRoutes.technicianEditId}
          id={id}
          onDelete={handleDelete}
        />
      ),
      align: 'center',
      width: 120,
    },
  ];

  const uploadProps: UploadProps = {
    listType: 'text',
    maxCount: 1,
    accept: '.xls, .xlsx',
    beforeUpload: async (file: RcFile) => {
      const allowedTypes = [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ];
      const isAllowedType = allowedTypes.includes(file.type);
      if (!isAllowedType) {
        message.error('Vui lòng chọn ảnh thuộc định dạng Excel!');
        return Upload.LIST_IGNORE;
      }
      const maxSizeInMB = 20;
      const isAllowedSize = file.size / 1024 / 1024 < maxSizeInMB;
      if (!isAllowedSize) {
        message.error(`Dung lượng file tối đa là ${maxSizeInMB} MB`);
        return Upload.LIST_IGNORE;
      }
      async function handleUploadExcel(file: any) {
        const formData = new FormData();
        formData.append('file', file);
        return mutateFileAsync(formData);
      }
      handleUploadExcel(file);
      return false;
    },
  };

  return (
    <Spin spinning={exporting}>
      <div>
        <PageTitle title="Danh sách kỹ thuật viên" />
        <div>
          <Form size="small" onFinish={handleFilter}>
            <Row gutter={{ xs: 8, sm: 12 }} wrap>
              <Col xl={6} lg={4} md={10} sm={12}>
                <Form.Item name="search">
                  <Search placeholder="Tìm kiếm theo Họ và tên, số điện thoại, email" />
                </Form.Item>
              </Col>
              <Col lg={4} md={4} sm={12}>
                <Form.Item name="agencyId">
                  <Select
                    allowClear
                    disabled={agenciesLoading}
                    placeholder="Đại lý trực thuộc"
                    showSearch
                    filterOption={(input, option) =>
                      option?.label?.toLowerCase().includes(input.toLowerCase()) ?? false
                    }
                    options={agenciesData?.adminAgencies?.items?.map((agency) => ({
                      label: agency.fullname,
                      value: agency.id,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col lg={4} md={4} sm={12}>
                <Form.Item name="isApproved">
                  <Select
                    placeholder="Trạng thái KTV"
                    options={[
                      { label: 'Tất cả', value: null },
                      { label: 'Đã duyệt', value: true },
                      { label: 'Chờ duyệt', value: false },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col lg={4} md={4} sm={12}>
                <Form.Item name="isActive">
                  <Select
                    placeholder="Trạng thái kích hoạt"
                    options={[
                      { label: 'Tất cả', value: null },
                      { label: 'Kích hoạt', value: 'ACTIVE' },
                      { label: 'Vô hiệu', value: 'INACTIVE' },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col lg={2} md={2} sm={12}>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Áp dụng
                  </Button>
                </Form.Item>
              </Col>
              <Col xl={4} lg={6} md={24} sm={12}>
                <div className="flex flex-row-reverse">
                  <Dropdown
                    menu={{
                      items: [
                        {
                          label: (
                            <Link to={AppRoutes.technicianCreate}>
                              <Button type="primary">Tạo mới thủ công</Button>
                            </Link>
                          ),
                          key: '0',
                        },
                        {
                          label: (
                            <Upload {...uploadProps}>
                              <Button type="primary">Tải lên file excel</Button>
                            </Upload>
                          ),
                          key: '1',
                        },
                      ],
                    }}
                    trigger={['click']}>
                    <Button type="primary">Tạo mới</Button>
                  </Dropdown>
                  <Button
                    loading={fileUploading}
                    disabled={fileUploading}
                    type="primary"
                    className="mr-[12px]"
                    onClick={handleExport}>
                    Tải xuống
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
        <div>
          <Table
            size="small"
            bordered
            loading={loading || removing || updating || updatingApprove}
            columns={columns}
            dataSource={data}
            tableLayout="fixed"
            pagination={{
              ...DefaultPagination,
              onChange: onChangePage,
              current: Number(params?.page),
              total: results?.adminTechnicians?.meta?.totalItems,
            }}
            scroll={{ y: 'calc(100vh - 320px)' }}
          />
        </div>
      </div>
    </Spin>
  );
});
export default ListTechnician;
