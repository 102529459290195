import { memo, useMemo } from 'react';
import { Descriptions, Spin, Switch, Table } from 'antd';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { PageTitle, ProgressLoading } from '../../components';
import { AppRoutes } from '../../helpers';
import { DiscountCodeUnitEnum, OperatingUnitEnum } from '../../graphql/type.interface';
import { DATE_FORMAT } from '../../constants';
import { numberWithDots } from '../../utils';
import { useAdminGetDiscountCodeQuery } from '../../graphql/queries/adminGetDiscountCode.generated';

const DetailDiscount = memo(() => {
  const { id = '' } = useParams();
  const { data, loading } = useAdminGetDiscountCodeQuery({ variables: { id }, skip: !id });
  const discount = useMemo(() => data?.adminGetDiscountCode, [data?.adminGetDiscountCode]);
  if (!discount) return <ProgressLoading />;
  return (
    <Spin spinning={loading}>
      <PageTitle link={AppRoutes.discountManagement} title="Thông tin chi tiết mã giảm giá" />
      <Descriptions column={1} labelStyle={{ width: 300 }} bordered>
        <Descriptions.Item label="Tên mã giảm giá">{discount.name}</Descriptions.Item>
        <Descriptions.Item label="Thời gian">
          {(discount?.startDate ? dayjs(discount?.startDate).format(DATE_FORMAT) : '') +
            ' - ' +
            (discount?.endDate ? dayjs(discount.endDate.slice(0, -1)).format(DATE_FORMAT) : 'Vô thời hạn')}
        </Descriptions.Item>
        {discount?.unit && discount?.value && (
          <Descriptions.Item label="Giá trị giảm">
            {`${discount?.value} ${discount?.unit === DiscountCodeUnitEnum?.PERCENTAGE ? '%' : discount?.unit}`}
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Sản phẩm áp dụng">
          {discount.products && (
            <Table
              columns={[
                {
                  title: 'STT',
                  dataIndex: 'key',
                  key: 'key',
                  align: 'center',
                  width: 80,
                },
                {
                  title: 'Tên sản phẩm',
                  dataIndex: 'name',
                  ellipsis: true,
                  render: (name, record) => (
                    <div className="flex flex-row items-center break-words">
                      <img
                        src={record.avatar ? (record.avatar.fullThumbUrl as string) : ''}
                        width={32}
                        height={32}
                        className="rounded"
                      />
                      <div className="ml-[12px]" style={{ maxWidth: 'calc(100% - 32px - 12px)' }}>
                        <div className="font-medium text-[14px] leading-[20px] text-yankees-blue whitespace-nowrap text-ellipsis overflow-hidden">
                          {name}
                        </div>
                      </div>
                    </div>
                  ),
                },
                {
                  title: 'Tình trạng',
                  key: 'isNew',
                  dataIndex: 'isNew',
                  render(value, record) {
                    return value
                      ? 'Mới'
                      : `Đã qua sử dụng ${
                          record?.operatingNumber + (record?.operatingUnit === OperatingUnitEnum.HOURS ? ' giờ' : ' km')
                        } `;
                  },
                },
                {
                  title: 'Đơn giá',
                  key: 'unitPrice',
                  dataIndex: 'unitPrice',
                  render: (value, record) => (!record?.isFixedCost ? 'Thương lượng' : numberWithDots(value) + 'đ'),
                },
                {
                  title: 'Tồn kho',
                  key: 'quantity',
                  dataIndex: 'quantity',
                },
              ]}
              dataSource={discount.products.map((p, i) => ({ ...p, key: i + 1 }))}
              pagination={false}
            />
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Giới hạn tổng số lượt sử dụng">{discount?.limit}</Descriptions.Item>
        <Descriptions.Item label="Giới hạn tổng số lượt sử dụng/ 1 tài khoản">
          {discount?.limitPerAccount}
        </Descriptions.Item>
        <Descriptions.Item label="Giới hạn đơn hàng tối thiểu">{discount?.minOrderValue}</Descriptions.Item>
        <Descriptions.Item label="Ngày tạo">
          {discount?.createdAt ? dayjs(discount?.createdAt).format(DATE_FORMAT) : ''}
        </Descriptions.Item>
        <Descriptions.Item label="Trạng thái">
          <Switch disabled checked={discount.isActive} />
        </Descriptions.Item>
      </Descriptions>
    </Spin>
  );
});

export default DetailDiscount;
