import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminExportAgenciesQueryVariables = Types.Exact<{
  isActive?: Types.InputMaybe<Types.StatusEnum>;
  isApproved?: Types.InputMaybe<Types.Scalars['Boolean']>;
  search?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;

export type AdminExportAgenciesQueryResponse = { __typename?: 'Query' } & Pick<Types.Query, 'adminExportAgencies'>;

export const AdminExportAgenciesDocument = gql`
  query adminExportAgencies($isActive: StatusEnum, $isApproved: Boolean, $search: String, $sort: SortInput) {
    adminExportAgencies(isActive: $isActive, isApproved: $isApproved, search: $search, sort: $sort)
  }
`;
export function useAdminExportAgenciesQuery(
  baseOptions?: Apollo.QueryHookOptions<AdminExportAgenciesQueryResponse, AdminExportAgenciesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminExportAgenciesQueryResponse, AdminExportAgenciesQueryVariables>(
    AdminExportAgenciesDocument,
    options,
  );
}
export function useAdminExportAgenciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminExportAgenciesQueryResponse, AdminExportAgenciesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminExportAgenciesQueryResponse, AdminExportAgenciesQueryVariables>(
    AdminExportAgenciesDocument,
    options,
  );
}
export type AdminExportAgenciesQueryHookResult = ReturnType<typeof useAdminExportAgenciesQuery>;
export type AdminExportAgenciesLazyQueryHookResult = ReturnType<typeof useAdminExportAgenciesLazyQuery>;
export type AdminExportAgenciesQueryResult = Apollo.QueryResult<
  AdminExportAgenciesQueryResponse,
  AdminExportAgenciesQueryVariables
>;
