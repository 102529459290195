import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminGetReviewsQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Array<Types.Scalars['JSONObject']> | Types.Scalars['JSONObject']>;
  isActive?: Types.InputMaybe<Types.StatusEnum>;
  isApproved?: Types.InputMaybe<Types.Scalars['Boolean']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  search?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.SortInput>;
  star?: Types.InputMaybe<Types.Scalars['Float']>;
  type: Types.ReviewTypeEnum;
}>;

export type AdminGetReviewsQueryResponse = { __typename?: 'Query' } & {
  adminGetReviews: { __typename?: 'ReviewConnection' } & {
    items?: Types.Maybe<
      Array<
        { __typename?: 'ReviewEntity' } & Pick<Types.ReviewEntity, 'comment' | 'id' | 'createdAt' | 'star' | 'type'> & {
            partnerAssessor?: Types.Maybe<
              { __typename?: 'PartnerEntity' } & Pick<Types.PartnerEntity, 'fullname' | 'id'>
            >;
            userAssessor?: Types.Maybe<{ __typename?: 'UserEntity' } & Pick<Types.UserEntity, 'fullname' | 'id'>>;
          }
      >
    >;
    meta: { __typename?: 'BasePaginationMeta' } & Pick<
      Types.BasePaginationMeta,
      'currentPage' | 'itemCount' | 'itemsPerPage' | 'totalItems' | 'totalPages'
    >;
  };
};

export const AdminGetReviewsDocument = gql`
  query adminGetReviews(
    $filters: [JSONObject!]
    $isActive: StatusEnum
    $isApproved: Boolean
    $limit: Int
    $page: Int
    $search: String
    $sort: SortInput
    $star: Float
    $type: ReviewTypeEnum!
  ) {
    adminGetReviews(
      filters: $filters
      isActive: $isActive
      isApproved: $isApproved
      limit: $limit
      page: $page
      search: $search
      sort: $sort
      star: $star
      type: $type
    ) {
      items {
        comment
        id
        createdAt
        partnerAssessor {
          fullname
          id
        }
        star
        type
        userAssessor {
          fullname
          id
        }
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;
export function useAdminGetReviewsQuery(
  baseOptions: Apollo.QueryHookOptions<AdminGetReviewsQueryResponse, AdminGetReviewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetReviewsQueryResponse, AdminGetReviewsQueryVariables>(AdminGetReviewsDocument, options);
}
export function useAdminGetReviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminGetReviewsQueryResponse, AdminGetReviewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetReviewsQueryResponse, AdminGetReviewsQueryVariables>(
    AdminGetReviewsDocument,
    options,
  );
}
export type AdminGetReviewsQueryHookResult = ReturnType<typeof useAdminGetReviewsQuery>;
export type AdminGetReviewsLazyQueryHookResult = ReturnType<typeof useAdminGetReviewsLazyQuery>;
export type AdminGetReviewsQueryResult = Apollo.QueryResult<
  AdminGetReviewsQueryResponse,
  AdminGetReviewsQueryVariables
>;
