import { memo, useCallback, useEffect } from 'react';
import { Button, Col, Divider, Form, Input, Row, Select, Spin, Switch } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import './style.css';
import { AnswerType, CreateSurveyInput, SurveyEntity, UpdateSurveyInput } from '../../graphql/type.interface';
import { useAdminCreateSurveyMutation } from '../../graphql/mutations/adminCreateSurvey.generated';
import { showNotification } from '../../utils';
import { AppRoutes, validationMessages } from '../../helpers';
import { useAdminUpdateSurveyMutation } from '../../graphql/mutations/adminUpdateSurvey.generated';
import { PageTitle } from '../../components';

type Props = {
  isCreate?: boolean;
  isDetail?: boolean;
  survey?: SurveyEntity;
};

type FormData = CreateSurveyInput & UpdateSurveyInput;

export const FormSurvey = memo(({ isCreate, isDetail, survey }: Props) => {
  const navigator = useNavigate();

  const [form] = Form.useForm();
  const questions = Form.useWatch('questions', form);

  const [createSurveyMutation, { loading: creating }] = useAdminCreateSurveyMutation({
    onCompleted() {
      showNotification('success', 'Thêm mới khảo sát thành công');
      navigator(AppRoutes.surveyManagement);
    },
    onError(e) {
      showNotification('error', 'Thêm mới khảo sát thất bại', e?.message);
    },
  });
  const [updateSurveyMutation, { loading: updating }] = useAdminUpdateSurveyMutation({
    onCompleted() {
      showNotification('success', 'Cập nhật khảo sát thành công');
      navigator(AppRoutes.surveyManagement);
    },
    onError(e) {
      showNotification('error', 'Cập nhật khảo sát thất bại', e?.message);
    },
  });

  const onFinish = useCallback(
    (values: FormData) => {
      const { questions: currentQuestions, ...withoutValues } = values;

      const questions = currentQuestions.map((q) => {
        return {
          question: q?.question,
          answerType: q?.answerType,
          isRequired: !!q?.isRequired,
          answers: q?.answerType === AnswerType.SHORT_ANSWER ? [] : q?.answers,
        };
      });

      if (isCreate) {
        createSurveyMutation({ variables: { input: { ...withoutValues, questions } } });
      } else {
        updateSurveyMutation({
          variables: { input: { ...withoutValues, questions, id: survey?.id as string } },
        });
      }
    },
    [createSurveyMutation, isCreate, survey?.id, updateSurveyMutation],
  );

  const renderAnswers = (key: number, name: number) => {
    const surveyByKey = questions?.[name];
    if (!surveyByKey || (surveyByKey && surveyByKey?.answerType === AnswerType.SHORT_ANSWER)) {
      return null;
    }
    return (
      <Form.List key={key} name={[name, 'answers']}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key: answerKey, name: answerName, ...restField }) => (
              <>
                <div key={answerKey} className="border border-solid border-grayscale-border rounded p-16px mb-20px">
                  <Row gutter={20}>
                    <Col span={22}>
                      <Form.Item
                        {...restField}
                        label={
                          <span>
                            {'Câu trả lời '} <span className="text-error"> *</span>:
                          </span>
                        }
                        name={[answerName]}
                        normalize={(e) => e.trimStart()}
                        rules={[{ required: true, message: validationMessages.required }]}>
                        <Input placeholder="Nhập câu trả lời" />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      {surveyByKey.answers && surveyByKey.answers.length > 1 && (
                        <Form.Item label={<span className="none"></span>}>
                          <Button
                            type="link"
                            onClick={() => remove(answerName)}
                            block
                            icon={<CloseOutlined style={{ fontSize: '22px', color: '#000' }} />}
                          />
                        </Form.Item>
                      )}
                    </Col>
                  </Row>
                </div>
              </>
            ))}
            {(surveyByKey?.answerType === AnswerType.MULTIPLE_CHOICE ||
              surveyByKey?.answerType === AnswerType.CHECKBOX) && (
              <div className="flex justify-start">
                <Form.Item>
                  <Button type="primary" onClick={() => add()} block icon={<PlusOutlined />}>
                    Thêm câu trả lời
                  </Button>
                </Form.Item>
              </div>
            )}
          </>
        )}
      </Form.List>
    );
  };

  useEffect(() => {
    if (survey) {
      const { __typename, ...withoutSurvey } = survey;
      form.setFieldsValue({
        ...withoutSurvey,
      });
    }

    if (!survey && isCreate) {
      form.setFieldsValue({
        questions: [
          {
            answers: [''],
            answerType: AnswerType.CHECKBOX,
            isRequired: false,
            question: '',
          },
        ],
        isActive: true,
      });
    }
  }, [survey, form, isCreate]);

  if (!isCreate && !survey) return null;

  return (
    <Spin spinning={creating || updating}>
      {isCreate && <PageTitle title={'Thêm mới khảo sát'} link={AppRoutes.surveyManagement} />}
      <Form
        form={form}
        name="form-survey"
        id="form-survey"
        initialValues={{ isActive: true }}
        size="small"
        disabled={isDetail}
        onFinish={onFinish}
        layout="vertical">
        <div className="bg-white p-20px mb-12px">
          <Form.Item
            label={
              <span>
                Tên khảo sát<span className="text-error"> *</span>:
              </span>
            }
            normalize={(e) => e.trimStart()}
            name="name"
            rules={[{ required: true, message: validationMessages.required }]}>
            <Input placeholder="Nhập tên khảo sát" />
          </Form.Item>
        </div>
        <Form.List name="questions">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, i) => (
                <div className="bg-white p-20px mb-12px" key={key}>
                  <Row gutter={20}>
                    <Col span={20}>
                      <Form.Item
                        {...restField}
                        label={
                          <span>
                            Câu hỏi {i + 1}
                            <span className="text-error"> *</span>:
                          </span>
                        }
                        name={[name, 'question']}
                        rules={[{ required: true, message: validationMessages.required }]}>
                        <Input placeholder="Nhập câu hỏi" />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        {...restField}
                        label={
                          <span>
                            Loại câu trả lời <span className="text-error"> *</span>:
                          </span>
                        }
                        name={[name, 'answerType']}
                        rules={[{ required: true, message: validationMessages.required }]}>
                        <Select
                          placeholder="Chọn Loại câu trả lời"
                          options={[
                            {
                              label: 'Trắc nghiệm',
                              value: AnswerType.CHECKBOX,
                            },
                            {
                              label: 'Checkbox',
                              value: AnswerType.MULTIPLE_CHOICE,
                            },
                            {
                              label: 'Trả lời ngắn',
                              value: AnswerType.SHORT_ANSWER,
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {renderAnswers(key, name)}
                  <div className="flex justify-end reset-margin-form-item">
                    {questions && questions.length > 1 && (
                      <Form.Item>
                        <Button
                          type="default"
                          onClick={() => remove(name)}
                          className="border-error text-error !px-12px">
                          Xóa câu hỏi
                        </Button>
                      </Form.Item>
                    )}
                    <Divider type="vertical" className="h-[36px] mx-12px" />
                    <div className="flex items-center">
                      <label className="pr-12px">Bắt buộc</label>
                      <Form.Item name={[name, 'isRequired']} valuePropName="checked">
                        <Switch />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              ))}
              <div className="flex justify-end">
                <Form.Item>
                  <Button type="primary" onClick={() => add()} block icon={<PlusOutlined />}>
                    Thêm câu hỏi
                  </Button>
                </Form.Item>
              </div>
            </>
          )}
        </Form.List>
        <div className="bg-white p-20px mb-[56px] ">
          <div className="flex items-center reset-margin-form-item">
            <label htmlFor="" className="mr-12px">
              Trạng thái
            </label>
            <Form.Item name="isActive" valuePropName="checked">
              <Switch />
            </Form.Item>
          </div>
        </div>
      </Form>
      {!isDetail && (
        <div className="fixed left-0 right-0 bottom-0 flex justify-end py-12px pr-20px bg-white">
          <Button type="primary" htmlType="submit" size="small" className="!px-16px" form="form-survey">
            Lưu
          </Button>
        </div>
      )}
    </Spin>
  );
});
