import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminCreateProductAccessaryMutationVariables = Types.Exact<{
  input: Types.CreateAccessaryInput;
}>;

export type AdminCreateProductAccessaryMutationResponse = { __typename?: 'Mutation' } & {
  adminCreateProductAccessary: { __typename?: 'ProductEntity' } & Pick<Types.ProductEntity, 'id' | 'name'>;
};

export const AdminCreateProductAccessaryDocument = gql`
  mutation adminCreateProductAccessary($input: CreateAccessaryInput!) {
    adminCreateProductAccessary(input: $input) {
      id
      name
    }
  }
`;
export function useAdminCreateProductAccessaryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateProductAccessaryMutationResponse,
    AdminCreateProductAccessaryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminCreateProductAccessaryMutationResponse, AdminCreateProductAccessaryMutationVariables>(
    AdminCreateProductAccessaryDocument,
    options,
  );
}
export type AdminCreateProductAccessaryMutationHookResult = ReturnType<typeof useAdminCreateProductAccessaryMutation>;
export type AdminCreateProductAccessaryMutationResult =
  Apollo.MutationResult<AdminCreateProductAccessaryMutationResponse>;
export type AdminCreateProductAccessaryMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateProductAccessaryMutationResponse,
  AdminCreateProductAccessaryMutationVariables
>;
