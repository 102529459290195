import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminUpdateCategoryMutationVariables = Types.Exact<{
  input: Types.UpdateCategoryInput;
}>;

export type AdminUpdateCategoryMutationResponse = { __typename?: 'Mutation' } & {
  adminUpdateCategory: { __typename?: 'CategoryEntity' } & Pick<
    Types.CategoryEntity,
    'createdAt' | 'deletedAt' | 'id' | 'isActive' | 'name' | 'type' | 'updatedAt'
  >;
};

export const AdminUpdateCategoryDocument = gql`
  mutation adminUpdateCategory($input: UpdateCategoryInput!) {
    adminUpdateCategory(input: $input) {
      createdAt
      deletedAt
      id
      isActive
      name
      type
      updatedAt
    }
  }
`;
export function useAdminUpdateCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminUpdateCategoryMutationResponse, AdminUpdateCategoryMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminUpdateCategoryMutationResponse, AdminUpdateCategoryMutationVariables>(
    AdminUpdateCategoryDocument,
    options,
  );
}
export type AdminUpdateCategoryMutationHookResult = ReturnType<typeof useAdminUpdateCategoryMutation>;
export type AdminUpdateCategoryMutationResult = Apollo.MutationResult<AdminUpdateCategoryMutationResponse>;
export type AdminUpdateCategoryMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateCategoryMutationResponse,
  AdminUpdateCategoryMutationVariables
>;
