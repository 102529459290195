import React from 'react';

import { AppRoutes } from '../../helpers';
import { MasterForm } from '../../components';
import { CategoryTypeEnum } from '../../graphql/type.interface';

export default function FormOrigin() {
  return (
    <MasterForm title="xuất xứ" backTo={AppRoutes.productOriginManagement} typeCategory={CategoryTypeEnum.ORIGIN} />
  );
}
