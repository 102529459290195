import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminGetSurveyQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type AdminGetSurveyQueryResponse = { __typename?: 'Query' } & {
  adminGetSurvey: { __typename?: 'SurveyEntity' } & Pick<
    Types.SurveyEntity,
    'createdAt' | 'deletedAt' | 'id' | 'isActive' | 'name' | 'updatedAt'
  > & {
      questions: Array<
        { __typename?: 'QuestionEntity' } & Pick<
          Types.QuestionEntity,
          | 'answerType'
          | 'answers'
          | 'createdAt'
          | 'deletedAt'
          | 'id'
          | 'isRequired'
          | 'question'
          | 'surveyId'
          | 'updatedAt'
        >
      >;
    };
};

export const AdminGetSurveyDocument = gql`
  query adminGetSurvey($id: String!) {
    adminGetSurvey(id: $id) {
      createdAt
      deletedAt
      id
      isActive
      name
      questions {
        answerType
        answers
        createdAt
        deletedAt
        id
        isRequired
        question
        surveyId
        updatedAt
      }
      updatedAt
    }
  }
`;
export function useAdminGetSurveyQuery(
  baseOptions: Apollo.QueryHookOptions<AdminGetSurveyQueryResponse, AdminGetSurveyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetSurveyQueryResponse, AdminGetSurveyQueryVariables>(AdminGetSurveyDocument, options);
}
export function useAdminGetSurveyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminGetSurveyQueryResponse, AdminGetSurveyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetSurveyQueryResponse, AdminGetSurveyQueryVariables>(
    AdminGetSurveyDocument,
    options,
  );
}
export type AdminGetSurveyQueryHookResult = ReturnType<typeof useAdminGetSurveyQuery>;
export type AdminGetSurveyLazyQueryHookResult = ReturnType<typeof useAdminGetSurveyLazyQuery>;
export type AdminGetSurveyQueryResult = Apollo.QueryResult<AdminGetSurveyQueryResponse, AdminGetSurveyQueryVariables>;
