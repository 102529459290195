import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminGetUsersSubmitSurveyQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Array<Types.Scalars['JSONObject']> | Types.Scalars['JSONObject']>;
  isActive?: Types.InputMaybe<Types.StatusEnum>;
  isApproved?: Types.InputMaybe<Types.Scalars['Boolean']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  search?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.SortInput>;
  surveyId: Types.Scalars['String'];
}>;

export type AdminGetUsersSubmitSurveyQueryResponse = { __typename?: 'Query' } & {
  adminGetUsersSubmitSurvey: { __typename?: 'UserConnection' } & {
    items?: Types.Maybe<Array<{ __typename?: 'UserEntity' } & Pick<Types.UserEntity, 'fullname' | 'id'>>>;
    meta: { __typename?: 'BasePaginationMeta' } & Pick<
      Types.BasePaginationMeta,
      'currentPage' | 'itemCount' | 'itemsPerPage' | 'totalItems' | 'totalPages'
    >;
  };
};

export const AdminGetUsersSubmitSurveyDocument = gql`
  query adminGetUsersSubmitSurvey(
    $filters: [JSONObject!]
    $isActive: StatusEnum
    $isApproved: Boolean
    $limit: Int
    $page: Int
    $search: String
    $sort: SortInput
    $surveyId: String!
  ) {
    adminGetUsersSubmitSurvey(
      filters: $filters
      isActive: $isActive
      isApproved: $isApproved
      limit: $limit
      page: $page
      search: $search
      sort: $sort
      surveyId: $surveyId
    ) {
      items {
        fullname
        id
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;
export function useAdminGetUsersSubmitSurveyQuery(
  baseOptions: Apollo.QueryHookOptions<AdminGetUsersSubmitSurveyQueryResponse, AdminGetUsersSubmitSurveyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetUsersSubmitSurveyQueryResponse, AdminGetUsersSubmitSurveyQueryVariables>(
    AdminGetUsersSubmitSurveyDocument,
    options,
  );
}
export function useAdminGetUsersSubmitSurveyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetUsersSubmitSurveyQueryResponse,
    AdminGetUsersSubmitSurveyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetUsersSubmitSurveyQueryResponse, AdminGetUsersSubmitSurveyQueryVariables>(
    AdminGetUsersSubmitSurveyDocument,
    options,
  );
}
export type AdminGetUsersSubmitSurveyQueryHookResult = ReturnType<typeof useAdminGetUsersSubmitSurveyQuery>;
export type AdminGetUsersSubmitSurveyLazyQueryHookResult = ReturnType<typeof useAdminGetUsersSubmitSurveyLazyQuery>;
export type AdminGetUsersSubmitSurveyQueryResult = Apollo.QueryResult<
  AdminGetUsersSubmitSurveyQueryResponse,
  AdminGetUsersSubmitSurveyQueryVariables
>;
