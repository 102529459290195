import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminQuotationHistoriesQueryVariables = Types.Exact<{
  bookingId: Types.Scalars['String'];
}>;

export type AdminQuotationHistoriesQueryResponse = { __typename?: 'Query' } & {
  adminQuotationHistories: Array<
    { __typename?: 'QuotationEntity' } & Pick<
      Types.QuotationEntity,
      | 'createdAt'
      | 'diagnosisFee'
      | 'diagnosisNote'
      | 'estimatedCompleteAt'
      | 'id'
      | 'operatingNumber'
      | 'operatingUnit'
      | 'rejectReasons'
      | 'repairFee'
      | 'status'
      | 'technicianId'
      | 'total'
      | 'transportFee'
      | 'userId'
    > & {
        accessories: Array<
          { __typename?: 'QuotationAccessoryEntity' } & Pick<
            Types.QuotationAccessoryEntity,
            'available' | 'id' | 'name' | 'quantity' | 'quotationId' | 'unit' | 'unitPrice'
          >
        >;
        additionalFees: Array<
          { __typename?: 'QuotationAdditionalFeeEntity' } & Pick<
            Types.QuotationAdditionalFeeEntity,
            'amount' | 'id' | 'name' | 'quotationId'
          >
        >;
        diagnostics: Array<
          { __typename?: 'QuotationDiagnosticEntity' } & Pick<
            Types.QuotationDiagnosticEntity,
            'diagnosticCode' | 'expense' | 'id' | 'quotationId'
          >
        >;
        reasons?: Types.Maybe<Array<{ __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'name' | 'id'>>>;
      }
  >;
};

export const AdminQuotationHistoriesDocument = gql`
  query adminQuotationHistories($bookingId: String!) {
    adminQuotationHistories(bookingId: $bookingId) {
      accessories {
        available
        id
        name
        quantity
        quotationId
        unit
        unitPrice
      }
      additionalFees {
        amount
        id
        name
        quotationId
      }
      createdAt
      diagnosisFee
      diagnosisNote
      diagnostics {
        diagnosticCode
        expense
        id
        quotationId
      }
      estimatedCompleteAt
      id
      operatingNumber
      operatingUnit
      rejectReasons
      repairFee
      status
      technicianId
      total
      transportFee
      userId
      reasons {
        name
        id
      }
    }
  }
`;
export function useAdminQuotationHistoriesQuery(
  baseOptions: Apollo.QueryHookOptions<AdminQuotationHistoriesQueryResponse, AdminQuotationHistoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminQuotationHistoriesQueryResponse, AdminQuotationHistoriesQueryVariables>(
    AdminQuotationHistoriesDocument,
    options,
  );
}
export function useAdminQuotationHistoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminQuotationHistoriesQueryResponse,
    AdminQuotationHistoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminQuotationHistoriesQueryResponse, AdminQuotationHistoriesQueryVariables>(
    AdminQuotationHistoriesDocument,
    options,
  );
}
export type AdminQuotationHistoriesQueryHookResult = ReturnType<typeof useAdminQuotationHistoriesQuery>;
export type AdminQuotationHistoriesLazyQueryHookResult = ReturnType<typeof useAdminQuotationHistoriesLazyQuery>;
export type AdminQuotationHistoriesQueryResult = Apollo.QueryResult<
  AdminQuotationHistoriesQueryResponse,
  AdminQuotationHistoriesQueryVariables
>;
