import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
import { PartnerFragmentFragmentDoc } from '../fragments/partner.fragment.generated';

const defaultOptions = {} as const;
export type AdminDetailPartnerQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type AdminDetailPartnerQueryResponse = { __typename?: 'Query' } & {
  adminDetailPartner?: Types.Maybe<
    { __typename?: 'PartnerEntity' } & Pick<
      Types.PartnerEntity,
      | 'addressMoreInfo'
      | 'avatarId'
      | 'bank'
      | 'birthday'
      | 'cardNumber'
      | 'citizenId'
      | 'createdAt'
      | 'deletedAt'
      | 'description'
      | 'suggestionPoint'
      | 'email'
      | 'fullname'
      | 'hotline'
      | 'id'
      | 'isActive'
      | 'isApproved'
      | 'latitude'
      | 'longitude'
      | 'mapAddress'
      | 'parentId'
      | 'phone'
      | 'type'
    > & {
        avatar?: Types.Maybe<
          { __typename?: 'Media' } & Pick<
            Types.Media,
            | 'id'
            | 'fullOriginalUrl'
            | 'fullThumbUrl'
            | 'createdAt'
            | 'fileSize'
            | 'name'
            | 'originalUrl'
            | 'ownerId'
            | 'thumbUrl'
            | 'type'
          >
        >;
        education?: Types.Maybe<
          { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>
        >;
        level?: Types.Maybe<
          { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>
        >;
        parentInfo?: Types.Maybe<
          { __typename?: 'PartnerEntity' } & Pick<
            Types.PartnerEntity,
            | 'addressMoreInfo'
            | 'avatarId'
            | 'bank'
            | 'birthday'
            | 'cardNumber'
            | 'citizenId'
            | 'createdAt'
            | 'deletedAt'
            | 'description'
            | 'email'
            | 'fullname'
            | 'hotline'
            | 'id'
            | 'isActive'
            | 'isApproved'
            | 'latitude'
            | 'longitude'
            | 'mapAddress'
            | 'parentId'
            | 'phone'
            | 'type'
          >
        >;
        qualifications?: Types.Maybe<
          Array<{ __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>>
        >;
      }
  >;
};

export const AdminDetailPartnerDocument = gql`
  query adminDetailPartner($id: String!) {
    adminDetailPartner(id: $id) {
      ...PartnerFragment
    }
  }
  ${PartnerFragmentFragmentDoc}
`;
export function useAdminDetailPartnerQuery(
  baseOptions: Apollo.QueryHookOptions<AdminDetailPartnerQueryResponse, AdminDetailPartnerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminDetailPartnerQueryResponse, AdminDetailPartnerQueryVariables>(
    AdminDetailPartnerDocument,
    options,
  );
}
export function useAdminDetailPartnerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminDetailPartnerQueryResponse, AdminDetailPartnerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminDetailPartnerQueryResponse, AdminDetailPartnerQueryVariables>(
    AdminDetailPartnerDocument,
    options,
  );
}
export type AdminDetailPartnerQueryHookResult = ReturnType<typeof useAdminDetailPartnerQuery>;
export type AdminDetailPartnerLazyQueryHookResult = ReturnType<typeof useAdminDetailPartnerLazyQuery>;
export type AdminDetailPartnerQueryResult = Apollo.QueryResult<
  AdminDetailPartnerQueryResponse,
  AdminDetailPartnerQueryVariables
>;
