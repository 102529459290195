export const validationMessages = {
  email: {
    placeholder: 'Nhập email',
    required: 'Email là trường bắt buộc',
    required2: 'Vui lòng điền thông tin email',
    notValid: 'Email không hợp lệ',
    used: 'Email đã được sử dụng',
  },
  password: {
    required: 'Vui lòng điền mật khẩu',
    failed: 'Mật khẩu không đúng. Vui lòng kiểm tra lại',
  },
  phoneNumber: {
    placeholder: 'Nhập số điện thoại',
    required: 'Số điện thoại là trường bắt buộc',
    notValid: 'Số điện thoại không hợp lệ',
    used: 'Số điện thoại đã dược sử dụng',
  },
  hotline: {
    required: 'Hotline là trường bắt buộc',
    notValid: 'Hotline không hợp lệ',
    used: 'Hotline đã dược sử dụng',
  },
  nationalIdentityNumber: {
    required: 'Số CMND/CCCD là trường bắt buộc',
    used: 'Số CMND/CCCD đã được sử dụng',
  },
  citizenId: {
    placeholder: 'Nhập CMND/CCCD',
    required: 'Số CMND/CCCD là trường bắt buộc nhập.',
    notValid: 'Số CMND/CCCD không hợp lệ',
  },
  education: {
    placeholder: 'Chọn trình độ học vấn',
  },
  bank: {
    placeholder: 'Nhập ngân hàng',
    name: 'Ngân hàng là trường bắt buộc',
    cardNumber: 'Số thẻ là trường bắt buộc',
    cardNumberNotValid: 'Số thẻ không hợp lệ',
  },
  cardNumber: {
    placeholder: 'Nhập số thẻ ngân hàng',
  },
  address: {
    placeholder: 'Nhập địa chỉ cụ thể',
    required: 'Địa chỉ là trường bắt buộc',
    cityRequired: 'Tỉnh/thành phố là trường bắt buộc',
    districtRequired: 'Quận/Huyện là trường bắt buộc',
    wardRequired: 'Phường/Xã là trường bắt buộc',
    specific: 'Địa chỉ cụ thể là trường bắt buộc',
  },
  agency: {
    placeholder: 'Chọn đại lý',
    required: 'Đại lý trực thuộc là trường bắt buộc',
  },
  firstAndLastNameRequired: 'Họ và tên là trường bắt buộc',
  expertiseSkill: 'Chuyên môn là trường bắt buộc',
  level: {
    placeholder: 'Chọn level',
    required: 'Level là trường bắt buộc',
  },
  emailAndPasswordRequired: 'Vui lòng điền đầy đủ email và mật khẩu',
  avatarNotValid: 'Ảnh đại diện không hợp lệ',
  birthDay: 'Ngày sinh là trường bắt buộc',
  required: 'Trường này là trường bắt buộc',
};
