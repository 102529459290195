import { Form, notification } from 'antd';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useCreateQuotationPriceListMutation } from '../../graphql/mutations/createQuotationPriceList.generated';
import { useUpdateQuotationPriceListMutation } from '../../graphql/mutations/updateQuotationPriceList.generated';
import { useQuotationPriceListQuery } from '../../graphql/queries/quotationPriceList.generated';
import { Messages } from '../../constants';

export function useFormQuotationPrice() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id }: any = useParams();

  const { data: quotationPriceData, loading: quotationPriceLoading } = useQuotationPriceListQuery({
    skip: !id,
    variables: { id },
    fetchPolicy: 'cache-and-network',
  });

  const [createQuotationPrice, { loading: creating }] = useCreateQuotationPriceListMutation({
    onError(err) {
      notification.error({ message: Messages.create.fail('bảng giá'), description: err.message });
    },
    onCompleted() {
      notification.success({ message: Messages.create.success('bảng giá') });
      navigate(-1);
    },
  });

  const [updateQuotationPrice, { loading: updating }] = useUpdateQuotationPriceListMutation({
    onError(err) {
      notification.error({ message: Messages.update.fail('bảng giá'), description: err.message });
    },
    onCompleted() {
      notification.success({ message: Messages.update.success('bảng giá') });
      navigate(-1);
    },
  });

  const initialValues = useMemo(
    () =>
      id && quotationPriceData
        ? quotationPriceData.quotationPriceList
        : {
            fixable: true,
            isActive: false,
          },
    [id, quotationPriceData],
  );

  const onFinish = useCallback(
    (values: any) =>
      id
        ? updateQuotationPrice({ variables: { input: values } })
        : createQuotationPrice({ variables: { input: values } }),
    [createQuotationPrice, id, updateQuotationPrice],
  );

  return { form, initialValues, fetching: quotationPriceLoading, loading: creating || updating, navigate, onFinish };
}
