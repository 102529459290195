import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminGetSubmitResultQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Array<Types.Scalars['JSONObject']> | Types.Scalars['JSONObject']>;
  isActive?: Types.InputMaybe<Types.StatusEnum>;
  isApproved?: Types.InputMaybe<Types.Scalars['Boolean']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  search?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.SortInput>;
  surveyId: Types.Scalars['String'];
  type: Types.SurveyHistoryType;
  userId: Types.Scalars['String'];
}>;

export type AdminGetSubmitResultQueryResponse = { __typename?: 'Query' } & {
  adminGetSubmitResult: { __typename?: 'SurveyHistoryEntity' } & Pick<
    Types.SurveyHistoryEntity,
    'id' | 'surveyId' | 'type' | 'userId'
  > & { results: Array<{ __typename?: 'SurveyResult' } & Pick<Types.SurveyResult, 'answer' | 'questionId'>> };
};

export const AdminGetSubmitResultDocument = gql`
  query adminGetSubmitResult(
    $filters: [JSONObject!]
    $isActive: StatusEnum
    $isApproved: Boolean
    $limit: Int
    $page: Int
    $search: String
    $sort: SortInput
    $surveyId: String!
    $type: SurveyHistoryType!
    $userId: String!
  ) {
    adminGetSubmitResult(
      filters: $filters
      isActive: $isActive
      isApproved: $isApproved
      limit: $limit
      page: $page
      search: $search
      sort: $sort
      surveyId: $surveyId
      type: $type
      userId: $userId
    ) {
      id
      results {
        answer
        questionId
      }
      surveyId
      type
      userId
    }
  }
`;
export function useAdminGetSubmitResultQuery(
  baseOptions: Apollo.QueryHookOptions<AdminGetSubmitResultQueryResponse, AdminGetSubmitResultQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetSubmitResultQueryResponse, AdminGetSubmitResultQueryVariables>(
    AdminGetSubmitResultDocument,
    options,
  );
}
export function useAdminGetSubmitResultLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminGetSubmitResultQueryResponse, AdminGetSubmitResultQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetSubmitResultQueryResponse, AdminGetSubmitResultQueryVariables>(
    AdminGetSubmitResultDocument,
    options,
  );
}
export type AdminGetSubmitResultQueryHookResult = ReturnType<typeof useAdminGetSubmitResultQuery>;
export type AdminGetSubmitResultLazyQueryHookResult = ReturnType<typeof useAdminGetSubmitResultLazyQuery>;
export type AdminGetSubmitResultQueryResult = Apollo.QueryResult<
  AdminGetSubmitResultQueryResponse,
  AdminGetSubmitResultQueryVariables
>;
