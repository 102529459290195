import { memo, useCallback, useEffect, useMemo } from 'react';
import { Button, Checkbox, Col, Form, Input, notification, Row } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

const { TextArea } = Input;

import { AppRoutes, validationMessages } from '../../helpers';
import { InputNumberFormat, PageTitle } from '../../components';
import { RegexConstant } from '../../helpers/regex';
import { notificationMessages } from '../../helpers/notification-messages';
import SearchAddress, { Address } from '../../components/seach-address';
import { useAdminUpdateAgencyMutation } from '../../graphql/mutations/adminUpdateAgency.generated';
import { useAdminAddAgencyMutation } from '../../graphql/mutations/adminAddAgency.generated';
import { useAdminDetailPartnerQuery } from '../../graphql/queries/adminDetailPartner.generated';
import { useCategoriesQuery } from '../../graphql/queries/categories.generated';
import { CategoryTypeEnum, StatusEnum } from '../../graphql/type.interface';

type Props = {
  isDetail?: boolean;
  isCreate?: boolean;
};

type AgentValue = {
  addressMoreInfo: string;
  email?: string;
  fullname: string;
  hotline: string;
  phone: string;
  password: string;
  address: Address;
  description?: string;
  id: string;
  suggestionPoint?: number;
  qualifications?: string[];
};

const FormAgency = memo(({ isDetail, isCreate }: Props) => {
  const param = {
    limit: 1000,
    filters: null,
    isActive: StatusEnum.ACTIVE,
    search: '',
    isApproved: null,
  };

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams() as {
    id: string;
  };
  const { data, loading } = useAdminDetailPartnerQuery({ variables: { id }, skip: !id });
  const agency = useMemo(() => data?.adminDetailPartner, [data]);

  const { data: dataQualifications } = useCategoriesQuery({
    variables: { ...param, type: CategoryTypeEnum.QUALIFICATION },
    fetchPolicy: 'cache-and-network',
  });
  const qualifications = useMemo(() => dataQualifications?.categories?.items || [], [dataQualifications]);

  const [updateAgency, { loading: loadingUpdate }] = useAdminUpdateAgencyMutation({
    onError(err) {
      notification.error({
        message: notificationMessages.update.fail,
        description: err.message,
      });
    },
    onCompleted() {
      notification.success({ message: notificationMessages.update.success });
      navigate(-1);
    },
  });
  const [createAgency, { loading: loadingCreate }] = useAdminAddAgencyMutation({
    onError(err) {
      notification.error({
        message: notificationMessages.create.fail,
        description: err.message,
      });
    },
    onCompleted() {
      notification.success({ message: notificationMessages.create.success });
      navigate(-1);
    },
  });

  const isLoading = useMemo(() => loadingUpdate || loading || loadingCreate, [loading, loadingCreate, loadingUpdate]);

  useEffect(() => {
    form.setFieldsValue({
      ...agency,
      qualifications: (agency?.qualifications || []).map((qualification) => qualification.id),
      address: {
        mapAddress: agency?.mapAddress,
        latitude: agency?.latitude,
        longitude: agency?.longitude,
      },
    });
  }, [agency, form]);

  const onFinish = useCallback(
    async (values: AgentValue) => {
      const {
        address,
        email,
        fullname,
        hotline,
        phone,
        description,
        addressMoreInfo,
        suggestionPoint,
        qualifications,
      } = values;
      const data = {
        email,
        fullname,
        hotline,
        phone,
        description,
        addressMoreInfo,
        suggestionPoint,
        qualifications,
      };
      if (!isDetail && !isCreate) {
        const { id } = values;
        updateAgency({ variables: { input: { ...data, id, ...address } } });
      } else {
        createAgency({ variables: { input: { ...data, ...address } } });
      }
    },
    [updateAgency, createAgency, isDetail, isCreate],
  );
  return (
    <div>
      <PageTitle
        title={
          !isDetail && !isCreate ? 'Chỉnh sửa Đại lý' : !isDetail && isCreate ? 'Thêm mới đại lý' : 'Thông tin đại lý'
        }
        link={AppRoutes.agencyManagement}
      />
      <div>
        <Form
          form={form}
          labelCol={{ span: 5 }}
          labelAlign="left"
          id="form-agency"
          name="form-agency"
          wrapperCol={{ span: 15 }}
          size="small"
          initialValues={{
            password: 'Callme@2023',
          }}
          disabled={isDetail || isLoading}
          onFinish={onFinish}
          autoComplete="off"
          className="mb-[60px]">
          <Form.Item name="id">
            <Input className="hidden" />
          </Form.Item>
          <Form.Item
            label={
              <span>
                Tên đại lý<span className="text-red"> *</span>
              </span>
            }
            name="fullname"
            normalize={(e) => e.trimStart()}
            rules={[{ required: true, message: 'Tên đại lý là trường bắt buộc.' }]}>
            <Input placeholder={`Nhập tên đại lý`} maxLength={255} />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            normalize={(e) => e.trimStart()}
            rules={[{ pattern: RegexConstant.EMAIL, message: validationMessages.email.notValid }]}>
            <Input placeholder={`Nhập email của đại lý`} maxLength={255} />
          </Form.Item>
          <Form.Item
            name="phone"
            label={
              <span>
                Số điện thoại<span className="text-red"> *</span>
              </span>
            }
            normalize={(e) => e.trimStart()}
            rules={[
              { required: true, message: validationMessages.phoneNumber.required },
              { pattern: RegexConstant.PHONE, message: validationMessages.phoneNumber.notValid },
            ]}>
            <Input placeholder={`Nhập số điện thoại của đại lý`} maxLength={10} />
          </Form.Item>

          <Form.Item
            label={
              <span>
                Hotline<span className="text-red"> *</span>
              </span>
            }
            name="hotline"
            normalize={(e) => e.trimStart()}
            rules={[
              { required: true, message: validationMessages.hotline.required },
              { pattern: RegexConstant.HOTLINE, message: validationMessages.hotline.notValid },
            ]}>
            <Input placeholder={`Nhập hotline của đại lý`} minLength={8} maxLength={10} />
          </Form.Item>

          <Form.Item label={<p>Điểm đề xuất bởi call me</p>} name="suggestionPoint">
            <InputNumberFormat
              className="w-full"
              placeholder={`Điểm càng cao sẽ quyết định vị trí xuất hiện trong danh sách ktv/agency khi người dùng chọn đơn vị sửa chữa`}
              maxLength={255}
            />
          </Form.Item>

          <Form.Item
            name="address"
            label={
              <span>
                Địa chỉ<span className="text-red"> *</span>
              </span>
            }
            required
            rules={[
              {
                validator: async (_, values: string[]) => {
                  if (!values) {
                    return Promise.reject(validationMessages.address.required);
                  }
                },
              },
            ]}>
            <SearchAddress
              address={{
                mapAddress: agency?.mapAddress,
                latitude: agency?.latitude,
                longitude: agency?.longitude,
              }}
              loading={isLoading}
            />
          </Form.Item>

          <Form.Item
            label={
              <span>
                Địa chỉ cụ thể<span className="text-red"> *</span>
              </span>
            }
            name="addressMoreInfo"
            normalize={(e) => e.trimStart()}
            rules={[{ required: true, message: 'Địa chỉ cụ thể là trường bắt buộc' }]}>
            <Input placeholder={`Nhập địa chỉ của thể`} maxLength={255} />
          </Form.Item>
          <Form.Item label="Mô tả" name="description" normalize={(e) => e.trimStart()}>
            <TextArea placeholder={`Nhập mô tả của Đại lý`} rows={4} maxLength={1000} showCount />
          </Form.Item>
          <Form.Item
            label={
              <span>
                Chuyên môn<span className="text-red"> *</span>
              </span>
            }
            name="qualifications"
            rules={[
              {
                required: true,
                message: validationMessages.expertiseSkill,
              },
            ]}>
            <Checkbox.Group>
              <Row>
                {qualifications.map((item: any) => (
                  <Col span={8} key={item.id}>
                    <Checkbox value={item.id}>{item.name}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item label="Mật khẩu mặc định" name="password">
            <Input disabled />
          </Form.Item>
        </Form>
      </div>
      {!isDetail && (
        <div className="flex justify-end gap-x-[20px] px-24px py-8px bg-white fixed left-0 bottom-0 right-0">
          <Button size="small" className="w-20" type="default" onClick={() => navigate(-1)}>
            Huỷ
          </Button>
          <Button size="small" type="primary" htmlType="submit" loading={isLoading} form="form-agency">
            Lưu
          </Button>
        </div>
      )}
    </div>
  );
});

export default FormAgency;
