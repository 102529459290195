import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminCreateSurveyMutationVariables = Types.Exact<{
  input: Types.CreateSurveyInput;
}>;

export type AdminCreateSurveyMutationResponse = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'adminCreateSurvey'>;

export const AdminCreateSurveyDocument = gql`
  mutation adminCreateSurvey($input: CreateSurveyInput!) {
    adminCreateSurvey(input: $input)
  }
`;
export function useAdminCreateSurveyMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminCreateSurveyMutationResponse, AdminCreateSurveyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminCreateSurveyMutationResponse, AdminCreateSurveyMutationVariables>(
    AdminCreateSurveyDocument,
    options,
  );
}
export type AdminCreateSurveyMutationHookResult = ReturnType<typeof useAdminCreateSurveyMutation>;
export type AdminCreateSurveyMutationResult = Apollo.MutationResult<AdminCreateSurveyMutationResponse>;
export type AdminCreateSurveyMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateSurveyMutationResponse,
  AdminCreateSurveyMutationVariables
>;
