import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
import { PartnerFragmentFragmentDoc } from '../fragments/partner.fragment.generated';

const defaultOptions = {} as const;
export type AdminCreateTechnicianMutationVariables = Types.Exact<{
  input: Types.CreateTechnicianInput;
}>;

export type AdminCreateTechnicianMutationResponse = { __typename?: 'Mutation' } & {
  adminCreateTechnician: { __typename?: 'PartnerEntity' } & Pick<
    Types.PartnerEntity,
    | 'addressMoreInfo'
    | 'avatarId'
    | 'bank'
    | 'birthday'
    | 'cardNumber'
    | 'citizenId'
    | 'createdAt'
    | 'deletedAt'
    | 'description'
    | 'suggestionPoint'
    | 'email'
    | 'fullname'
    | 'hotline'
    | 'id'
    | 'isActive'
    | 'isApproved'
    | 'latitude'
    | 'longitude'
    | 'mapAddress'
    | 'parentId'
    | 'phone'
    | 'type'
  > & {
      avatar?: Types.Maybe<
        { __typename?: 'Media' } & Pick<
          Types.Media,
          | 'id'
          | 'fullOriginalUrl'
          | 'fullThumbUrl'
          | 'createdAt'
          | 'fileSize'
          | 'name'
          | 'originalUrl'
          | 'ownerId'
          | 'thumbUrl'
          | 'type'
        >
      >;
      education?: Types.Maybe<
        { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>
      >;
      level?: Types.Maybe<
        { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>
      >;
      parentInfo?: Types.Maybe<
        { __typename?: 'PartnerEntity' } & Pick<
          Types.PartnerEntity,
          | 'addressMoreInfo'
          | 'avatarId'
          | 'bank'
          | 'birthday'
          | 'cardNumber'
          | 'citizenId'
          | 'createdAt'
          | 'deletedAt'
          | 'description'
          | 'email'
          | 'fullname'
          | 'hotline'
          | 'id'
          | 'isActive'
          | 'isApproved'
          | 'latitude'
          | 'longitude'
          | 'mapAddress'
          | 'parentId'
          | 'phone'
          | 'type'
        >
      >;
      qualifications?: Types.Maybe<
        Array<{ __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>>
      >;
    };
};

export const AdminCreateTechnicianDocument = gql`
  mutation adminCreateTechnician($input: CreateTechnicianInput!) {
    adminCreateTechnician(input: $input) {
      ...PartnerFragment
    }
  }
  ${PartnerFragmentFragmentDoc}
`;
export function useAdminCreateTechnicianMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateTechnicianMutationResponse,
    AdminCreateTechnicianMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminCreateTechnicianMutationResponse, AdminCreateTechnicianMutationVariables>(
    AdminCreateTechnicianDocument,
    options,
  );
}
export type AdminCreateTechnicianMutationHookResult = ReturnType<typeof useAdminCreateTechnicianMutation>;
export type AdminCreateTechnicianMutationResult = Apollo.MutationResult<AdminCreateTechnicianMutationResponse>;
export type AdminCreateTechnicianMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateTechnicianMutationResponse,
  AdminCreateTechnicianMutationVariables
>;
