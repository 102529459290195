import {
  BookingStatusEnum,
  MaintenanceEntity,
  MaintenanceLevelEnum,
  MaintenanceStatusEnum,
  OrderStatusEnum,
  ProductTypeEnum,
  QuotationStatusEnum,
} from '../graphql/type.interface';

export const Messages = {
  required: (field: string) => `${field} là trường bắt buộc.`,
  create: {
    success: (field: string) => `Thêm mới ${field} thành công!`,
    fail: (field: string) => `Thêm mới ${field} thất bại!`,
  },
  update: {
    success: (field: string) => `Chỉnh sửa ${field} thành công!`,
    fail: (field: string) => `Chỉnh sửa ${field} thất bại!`,
  },
  delete: {
    success: (field: string) => `Xóa ${field} thành công!`,
    fail: (field: string) => `Xóa ${field} thất bại!`,
  },
  upload: {
    fail: (field: string) => `Tải ${field} lên thất bại!`,
  },
};

export const productTypeText = (value: ProductTypeEnum): string => {
  switch (value) {
    case ProductTypeEnum.VEHICLE:
      return 'Thiết bị';
    case ProductTypeEnum.ACCESSARY:
      return 'Phụ tùng';
    default: {
      const exhaustiveCheck: never = value;
      throw new Error(`ProductTypeEnum. not supported value: ${exhaustiveCheck}`);
    }
  }
};

export const quotationStatusText = (value: QuotationStatusEnum, isQuotation = false): string => {
  const subfix = isQuotation ? ' báo giá.' : ' quyết toán.';
  switch (value) {
    case QuotationStatusEnum.ACCEPTED:
      return 'KH đã chấp nhận' + subfix;
    case QuotationStatusEnum.REJECTED:
      return 'KH đã từ chối' + subfix;
    case QuotationStatusEnum.PENDING:
      return 'Chờ KH xác nhận' + subfix;
    default: {
      const exhaustiveCheck: never = value;
      throw new Error(`Not supported value: ${exhaustiveCheck}`);
    }
  }
};

export const orderStatusText = (value: OrderStatusEnum): string => {
  switch (value) {
    case OrderStatusEnum.CANCEL:
      return 'Đơn hàng đã bị hủy.';
    case OrderStatusEnum.COMPLETE:
      return 'Đơn hàng đã hoàn thành.';
    case OrderStatusEnum.SHIPPING:
      return 'Đơn hàng đang giao.';
    case OrderStatusEnum.WAIT_FOR_CONFIRM:
      return 'Đơn hàng đang chờ xác nhận.';
    case OrderStatusEnum.DELIVERED:
      return 'Đơn hàng đã được giao.';
    default: {
      const exhaustiveCheck: never = value;
      throw new Error(`OrderStatusEnum. not supported value: ${exhaustiveCheck}`);
    }
  }
};

export const maintenanceText = (maintenance: MaintenanceEntity): string =>
  maintenance?.maintenanceLevel === MaintenanceLevelEnum.INCURRED
    ? 'Bảo dưỡng phát sinh'
    : `Bảo dưỡng giờ vận hành: ${maintenance?.vehicleTypeCategory?.operatingNumber ?? '0'}h`;

export const maintenanceStatusText = (value: MaintenanceStatusEnum): string => {
  switch (value) {
    case MaintenanceStatusEnum.ACCEPTED:
      return 'Phê duyệt';
    case MaintenanceStatusEnum.DENY:
      return 'Từ chối';
    case MaintenanceStatusEnum.NEW:
      return 'Chờ xác nhận';
    case MaintenanceStatusEnum.CANCEL:
      return 'Đã hủy';
    default: {
      const exhaustiveCheck: never = value;
      throw new Error(`MaintenanceStatusEnum. not supported value: ${exhaustiveCheck}`);
    }
  }
};

export const bookingStatusText = (value: BookingStatusEnum): string => {
  switch (value) {
    case BookingStatusEnum.ASSIGNED_TECHNICIAN:
      return 'Đã được giao cho KTV';
    case BookingStatusEnum.COMPLETE:
      return 'Đã hoàn thành';
    case BookingStatusEnum.QUOTATION_ACCEPTED:
      return 'Báo giá được chấp nhận';
    case BookingStatusEnum.QUOTATION_REJECTED:
      return 'Báo giá bị từ chối';
    case BookingStatusEnum.QUOTATION_REQUESTED:
      return 'Đã gửi báo giá';
    // TODO: Update message
    case BookingStatusEnum.RESCHEDULED:
      return 'Hẹn lại';
    case BookingStatusEnum.SETTLEMENT_ACCEPTED:
      return 'Quyết toán được chấp nhận';
    case BookingStatusEnum.SETTLEMENT_REJECTED:
      return 'Quyết toán bị từ chối';
    case BookingStatusEnum.SETTLEMENT_REQUESTED:
      return 'Đã gửi quyết toán';
    case BookingStatusEnum.TECHNICIAN_ARRIVED:
      return 'KTX đã đến';
    case BookingStatusEnum.TECHNICIAN_ARRIVING:
      return 'KTV đang trên đường đến';
    case BookingStatusEnum.WAIT_FOR_CONFIRM:
      return 'Đang chờ xác nhận';
    case BookingStatusEnum.CANCEL:
      return 'Đã hủy';
    default: {
      const exhaustiveCheck: never = value;
      throw new Error(`BookingStatusEnum. not supported value: ${exhaustiveCheck}`);
    }
  }
};
