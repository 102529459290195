import { memo, useMemo } from 'react';
import { Descriptions, Space, Spin, Switch } from 'antd';
import { Link, useParams } from 'react-router-dom';

import { PageTitle } from '../../../components';
import { AppRoutes } from '../../../helpers';
import { useAdminGetInstructionQuery } from '../../../graphql/queries/adminGetInstruction.generated';

export const DetailInstruct = memo(() => {
  const { id = '' } = useParams();
  const { data: instructData, loading } = useAdminGetInstructionQuery({ variables: { id } });

  const instruction = useMemo(() => instructData?.adminGetInstruction, [instructData?.adminGetInstruction]);

  return (
    <div>
      <Spin spinning={loading}>
        <PageTitle link={AppRoutes.instructionManagement} title="Thông tin chi tiết hướng dẫn" />
        <Descriptions column={1} labelStyle={{ width: '150px' }}>
          <Descriptions.Item label="Tên danh mục">{instruction?.guide.name}</Descriptions.Item>
          <Descriptions.Item label="Tên hướng dẫn">{instruction?.name}</Descriptions.Item>
          <Descriptions.Item label="Chi tiết hướng dẫn">
            <Space direction="vertical">
              {(instruction?.files ?? []).map((file) => (
                <Link key={file.id} to={file.fullOriginalUrl ?? file.originalUrl} download>
                  {file.name}
                </Link>
              ))}
            </Space>
          </Descriptions.Item>
          <Descriptions.Item label="Trạng thái">
            <Switch disabled checked={instruction?.isActive} />
          </Descriptions.Item>
        </Descriptions>
      </Spin>
    </div>
  );
});
