import { memo, useMemo } from 'react';
import { Descriptions, Spin, Switch } from 'antd';
import { useParams } from 'react-router-dom';

import { PageTitle } from '../../../components';
import { AppRoutes } from '../../../helpers';
import { useAdminGetGuideQuery } from '../../../graphql/queries/adminGetGuide.generated';

export const DetailInstructCate = memo(() => {
  const { id = '' } = useParams();
  const { data: guideData, loading } = useAdminGetGuideQuery({ variables: { id } });

  const guide = useMemo(() => guideData?.adminGetGuide, [guideData?.adminGetGuide]);

  return (
    <div>
      <Spin spinning={loading}>
        <PageTitle link={AppRoutes.instructCateManagement} title="Thông tin chi tiết danh mục hướng dẫn" />
        <Descriptions column={1} labelStyle={{ width: '150px' }}>
          <Descriptions.Item label="Tên danh mục">{guide?.name}</Descriptions.Item>
          <Descriptions.Item label="Mô tả">{guide?.description}</Descriptions.Item>
          <Descriptions.Item label="Trạng thái">
            <Switch disabled checked={guide?.isActive} />
          </Descriptions.Item>
        </Descriptions>
      </Spin>
    </div>
  );
});
