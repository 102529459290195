import { Button, Col, Form, Row, Select } from 'antd';
import { memo } from 'react';

type Props = {
  onFinish: (v: any) => void;
};

export const FormReviewFilter = memo(({ onFinish }: Props) => {
  return (
    <Form size="small" onFinish={onFinish}>
      <Row gutter={24}>
        <Col span={4}>
          <Form.Item name="star">
            <Select
              placeholder="Số sao"
              options={[
                { label: 'Tất cả', value: null },
                { label: '5 sao', value: 5 },
                { label: '4 sao', value: 4 },
                { label: '3 sao', value: 3 },
                { label: '2 sao', value: 2 },
                { label: '1 sao', value: 1 },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Áp dụng
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});
