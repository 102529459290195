import { AppRoutes } from '../../helpers';
import { MasterForm } from '../../components';
import { CategoryTypeEnum } from '../../graphql/type.interface';

export default function FormManufacturer() {
  return (
    <div>
      <MasterForm
        typeCategory={CategoryTypeEnum.MANUFACTURER}
        title="hãng sản xuất"
        backTo={AppRoutes.productManufacturerManagement}
      />
    </div>
  );
}
