import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminCreateCategoryMutationVariables = Types.Exact<{
  input: Types.CreateCategoryInput;
}>;

export type AdminCreateCategoryMutationResponse = { __typename?: 'Mutation' } & Pick<
  Types.Mutation,
  'adminCreateCategory'
>;

export const AdminCreateCategoryDocument = gql`
  mutation adminCreateCategory($input: CreateCategoryInput!) {
    adminCreateCategory(input: $input)
  }
`;
export function useAdminCreateCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminCreateCategoryMutationResponse, AdminCreateCategoryMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminCreateCategoryMutationResponse, AdminCreateCategoryMutationVariables>(
    AdminCreateCategoryDocument,
    options,
  );
}
export type AdminCreateCategoryMutationHookResult = ReturnType<typeof useAdminCreateCategoryMutation>;
export type AdminCreateCategoryMutationResult = Apollo.MutationResult<AdminCreateCategoryMutationResponse>;
export type AdminCreateCategoryMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateCategoryMutationResponse,
  AdminCreateCategoryMutationVariables
>;
