import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminUpdateVehicleTypeCategoryMutationVariables = Types.Exact<{
  input: Types.UpdateVehicleTypeCategory;
}>;

export type AdminUpdateVehicleTypeCategoryMutationResponse = { __typename?: 'Mutation' } & {
  adminUpdateVehicleTypeCategory: { __typename?: 'VechicleTypeCategoryEntity' } & Pick<
    Types.VechicleTypeCategoryEntity,
    'id'
  >;
};

export const AdminUpdateVehicleTypeCategoryDocument = gql`
  mutation adminUpdateVehicleTypeCategory($input: UpdateVehicleTypeCategory!) {
    adminUpdateVehicleTypeCategory(input: $input) {
      id
    }
  }
`;
export function useAdminUpdateVehicleTypeCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpdateVehicleTypeCategoryMutationResponse,
    AdminUpdateVehicleTypeCategoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminUpdateVehicleTypeCategoryMutationResponse,
    AdminUpdateVehicleTypeCategoryMutationVariables
  >(AdminUpdateVehicleTypeCategoryDocument, options);
}
export type AdminUpdateVehicleTypeCategoryMutationHookResult = ReturnType<
  typeof useAdminUpdateVehicleTypeCategoryMutation
>;
export type AdminUpdateVehicleTypeCategoryMutationResult =
  Apollo.MutationResult<AdminUpdateVehicleTypeCategoryMutationResponse>;
export type AdminUpdateVehicleTypeCategoryMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateVehicleTypeCategoryMutationResponse,
  AdminUpdateVehicleTypeCategoryMutationVariables
>;
