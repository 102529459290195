import { memo } from 'react';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Tag, Tooltip } from 'antd';
import { Link } from 'react-router-dom';

import { PopConfirmDelete } from '../popconfirm-delete';

type Props = {
  id: string;
  titleDelete?: string;
  urlDetail?: (id: string) => string;
  urlEdit?: (id: string) => string;
  onDelete?: (id: string) => void;
};

const ActionGroup = memo(({ urlDetail, urlEdit, id, titleDelete, onDelete }: Props) => {
  return (
    <div className="flex-center">
      {urlDetail && (
        <Link to={urlDetail(id)} className="action-group text-black">
          <Tooltip title="Xem chi tiết">
            <Tag color="gold">
              <EyeOutlined />
            </Tag>
          </Tooltip>
        </Link>
      )}
      {urlEdit && (
        <Link to={urlEdit(id)} className="text-black">
          <Tooltip title="Chỉnh sửa">
            <Tag color="#2db7f5">
              <EditOutlined />
            </Tag>
          </Tooltip>
        </Link>
      )}
      {onDelete && (
        <PopConfirmDelete title={titleDelete || ''} placement="topLeft" onConfirm={() => onDelete(id)}>
          <Tooltip title="Xóa">
            <Tag color="#f50" className="hover:cursor-pointer">
              <DeleteOutlined />
            </Tag>
          </Tooltip>
        </PopConfirmDelete>
      )}
    </div>
  );
});

export default ActionGroup;
