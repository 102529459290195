import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminGetReviewQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type AdminGetReviewQueryResponse = { __typename?: 'Query' } & {
  adminGetReview: { __typename?: 'ReviewEntity' } & Pick<
    Types.ReviewEntity,
    'comment' | 'id' | 'createdAt' | 'star' | 'type'
  > & {
      partnerAssessor?: Types.Maybe<{ __typename?: 'PartnerEntity' } & Pick<Types.PartnerEntity, 'fullname' | 'id'>>;
      userAssessor?: Types.Maybe<{ __typename?: 'UserEntity' } & Pick<Types.UserEntity, 'fullname' | 'id'>>;
    };
};

export const AdminGetReviewDocument = gql`
  query adminGetReview($id: String!) {
    adminGetReview(id: $id) {
      comment
      id
      createdAt
      partnerAssessor {
        fullname
        id
      }
      star
      type
      userAssessor {
        fullname
        id
      }
    }
  }
`;
export function useAdminGetReviewQuery(
  baseOptions: Apollo.QueryHookOptions<AdminGetReviewQueryResponse, AdminGetReviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetReviewQueryResponse, AdminGetReviewQueryVariables>(AdminGetReviewDocument, options);
}
export function useAdminGetReviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminGetReviewQueryResponse, AdminGetReviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetReviewQueryResponse, AdminGetReviewQueryVariables>(
    AdminGetReviewDocument,
    options,
  );
}
export type AdminGetReviewQueryHookResult = ReturnType<typeof useAdminGetReviewQuery>;
export type AdminGetReviewLazyQueryHookResult = ReturnType<typeof useAdminGetReviewLazyQuery>;
export type AdminGetReviewQueryResult = Apollo.QueryResult<AdminGetReviewQueryResponse, AdminGetReviewQueryVariables>;
