import { useState, useCallback, useMemo } from 'react';
import { notification } from 'antd';

import { LIST_PARAMS, Messages } from '../../../constants';
import { useAdminGetInstructionsQuery } from '../../../graphql/queries/adminGetInstructions.generated';
import { useAdminUpdateInstructionStatusMutation } from '../../../graphql/mutations/adminUpdateInstructionStatus.generated';
import { useAdminDeleteInstructionMutation } from '../../../graphql/mutations/adminDeleteInstruction.generated';

import { useInstructionCate } from './use-instruction-cate';

export function useInstruction() {
  const [params, setParams] = useState(LIST_PARAMS);
  const { tableData: instructCates, fetching: instructCatesLoading } = useInstructionCate({ getAll: true });

  const {
    data: instructionData,
    loading: instructionLoading,
    refetch,
  } = useAdminGetInstructionsQuery({
    variables: params,
    fetchPolicy: 'cache-and-network',
  });

  const tableData = useMemo(
    () => instructionData?.adminGetInstructions.items ?? [],
    [instructionData?.adminGetInstructions.items],
  );

  const [updateStatus, { loading: updating }] = useAdminUpdateInstructionStatusMutation({
    onError(error) {
      notification.error({
        message: Messages.update.fail('trạng thái hướng dẫn'),
        description: error?.message,
      });
    },
    onCompleted() {
      notification.success({
        message: Messages.update.success('trạng thái hướng dẫn'),
      });
      refetch();
    },
  });

  const [removeInstruction] = useAdminDeleteInstructionMutation({
    onError(error) {
      notification.error({
        message: Messages.delete.fail('hướng dẫn'),
        description: error?.message,
      });
    },
    onCompleted() {
      notification.success({
        message: Messages.delete.success('hướng dẫn'),
      });
      refetch();
    },
  });

  const handleFilter = useCallback(
    (values: any) => {
      setParams({ ...params, page: 1, ...values });
    },
    [params],
  );

  const handleDelete = useCallback(
    (id: string) => {
      removeInstruction({ variables: { input: { id } } });
    },
    [removeInstruction],
  );

  const onEditStatus = useCallback(
    (data: any) => updateStatus({ variables: { input: { id: data.id, isActive: !data.isActive } } }),
    [updateStatus],
  );

  const onChangePage = useCallback(
    (newPage: number, pageSize: number) => {
      setParams({ ...params, page: newPage, limit: pageSize });
    },
    [params],
  );

  return {
    params,
    tableData,
    instructCates,
    totalItems: instructionData?.adminGetInstructions.meta.totalItems,
    fetching: instructionLoading || instructCatesLoading,
    updating,
    setParams,
    handleFilter,
    handleDelete,
    onEditStatus,
    onChangePage,
  };
}
