import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminGetInvoiceQueryVariables = Types.Exact<{
  settlementId: Types.Scalars['String'];
}>;

export type AdminGetInvoiceQueryResponse = { __typename?: 'Query' } & {
  adminGetInvoice: { __typename?: 'Media' } & Pick<
    Types.Media,
    | 'createdAt'
    | 'fileSize'
    | 'fullOriginalUrl'
    | 'fullThumbUrl'
    | 'id'
    | 'isDeleted'
    | 'mimeType'
    | 'name'
    | 'originalUrl'
    | 'ownerId'
    | 'thumbUrl'
    | 'type'
    | 'updatedAt'
    | 'videoUrl'
  >;
};

export const AdminGetInvoiceDocument = gql`
  query adminGetInvoice($settlementId: String!) {
    adminGetInvoice(settlementId: $settlementId) {
      createdAt
      fileSize
      fullOriginalUrl
      fullThumbUrl
      id
      isDeleted
      mimeType
      name
      originalUrl
      ownerId
      thumbUrl
      type
      updatedAt
      videoUrl
    }
  }
`;
export function useAdminGetInvoiceQuery(
  baseOptions: Apollo.QueryHookOptions<AdminGetInvoiceQueryResponse, AdminGetInvoiceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetInvoiceQueryResponse, AdminGetInvoiceQueryVariables>(AdminGetInvoiceDocument, options);
}
export function useAdminGetInvoiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminGetInvoiceQueryResponse, AdminGetInvoiceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetInvoiceQueryResponse, AdminGetInvoiceQueryVariables>(
    AdminGetInvoiceDocument,
    options,
  );
}
export type AdminGetInvoiceQueryHookResult = ReturnType<typeof useAdminGetInvoiceQuery>;
export type AdminGetInvoiceLazyQueryHookResult = ReturnType<typeof useAdminGetInvoiceLazyQuery>;
export type AdminGetInvoiceQueryResult = Apollo.QueryResult<
  AdminGetInvoiceQueryResponse,
  AdminGetInvoiceQueryVariables
>;
