import { memo, useCallback, useMemo, useState } from 'react';
import { Button, Descriptions, Spin } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { CancelOrder, FormDataCancel, MasterTable, PageTitle } from '../../components';
import { AppRoutes, showGraphQLErrorMessage } from '../../helpers';
import { useAdminOrderQuery } from '../../graphql/queries/adminOrder.generated';
import { TIME_FORMAT, orderStatusText } from '../../constants';
import { getNameCategoriesEntity, numberWithDots, showNotification } from '../../utils';
import { CategoryTypeEnum, OrderStatusEnum } from '../../graphql/type.interface';
import { useAdminCancelOrderMutation } from '../../graphql/mutations/adminCancelOrder.generated';
import { useAdminUpdateOrderStatusMutation } from '../../graphql/mutations/adminUpdateOrderStatus.generated';

const DetailOrder = memo(() => {
  const navigate = useNavigate();

  const { id = '' } = useParams();
  const { data: orderData, loading: orderLoading } = useAdminOrderQuery({
    skip: !id,
    variables: { id },
    fetchPolicy: 'cache-and-network',
  });

  const order = useMemo(() => orderData?.adminOrder, [orderData?.adminOrder]);

  const productTable = useMemo(() => order?.product ?? [], [order?.product]);

  const [updateStatusOrder, { loading: updating }] = useAdminUpdateOrderStatusMutation({
    onError(error) {
      showNotification('error', 'Đã có lỗi xảy ra ', showGraphQLErrorMessage(error, true));
    },
    onCompleted() {
      navigate(-1);
    },
  });

  const handleChangeStatusToShipping = useCallback(() => {
    if (!order) return;

    updateStatusOrder({
      variables: {
        input: {
          orderId: order?.id,
          status: OrderStatusEnum.SHIPPING,
        },
      },
    }).then(() => {
      showNotification('success', `Đơn hàng ${order?.code} đã chuyển sang trạng thái đang giao`);
    });
  }, [order, updateStatusOrder]);

  const handleChangeStatusToShiped = useCallback(() => {
    if (!order) return;
    updateStatusOrder({
      variables: {
        input: {
          orderId: order?.id,
          status: OrderStatusEnum.DELIVERED,
        },
      },
    }).then(() => {
      showNotification('success', `Đơn hàng ${order?.code} đã chuyển sang trạng thái đã giao`);
    });
  }, [order, updateStatusOrder]);

  const handleChangeStatusToCompleted = useCallback(() => {
    if (!order) return;
    updateStatusOrder({
      variables: {
        input: {
          orderId: order?.id,
          status: OrderStatusEnum.COMPLETE,
        },
      },
    }).then(() => {
      showNotification('success', `Đơn hàng ${order?.code} đã chuyển sang trạng thái hoàn thành`);
    });
  }, [order, updateStatusOrder]);

  const columns: ColumnsType<any> = [
    {
      title: `STT`,
      dataIndex: 'index',
      align: 'center',
      width: '5%',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Tên sản phẩm',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: `Số lượng`,
      dataIndex: 'quantity',
      align: 'center',
      width: '10%',
    },
    {
      title: `Đơn giá`,
      dataIndex: 'unitPrice',
      align: 'center',
      width: '10%',
      render: (v) => numberWithDots(v),
    },
    {
      title: `Giá`,
      dataIndex: 'total',
      align: 'right',
      render: (v) => numberWithDots(v),
    },
  ];
  const [showCancelOrder, setShowCancelOrder] = useState(false);
  const [cancelOrderMutation, { loading: canceling }] = useAdminCancelOrderMutation({
    onError(error) {
      showNotification('error', 'Hủy đơn hàng thất bại', showGraphQLErrorMessage(error, true));
    },
    onCompleted() {
      showNotification('success', `Hủy đơn hàng thành công ${order?.code}`);
      setShowCancelOrder(false);
      navigate(-1);
    },
  });
  const handleCancelOrder = useCallback(
    (values: FormDataCancel) => {
      if (!order) return;
      const input = {
        orderId: order.id,
        ...values,
      };
      cancelOrderMutation({ variables: { input } });
    },
    [order, cancelOrderMutation],
  );

  const loading = useMemo(() => orderLoading || updating, [orderLoading, updating]);

  const actionsByOrderStatus = useCallback(() => {
    switch (order?.status) {
      case OrderStatusEnum.WAIT_FOR_CONFIRM:
        return (
          <div className="flex items-center justify-center gap-x-20px">
            <Button size="small" onClick={() => setShowCancelOrder(true)}>
              Hủy đơn hàng
            </Button>
            <Button size="small" type="primary" onClick={handleChangeStatusToShipping}>
              Xác nhận đơn hàng
            </Button>
          </div>
        );

      case OrderStatusEnum.SHIPPING:
        return (
          <Button size="small" type="primary" onClick={handleChangeStatusToShiped}>
            Đã giao hàng
          </Button>
        );

      case OrderStatusEnum.DELIVERED:
        return (
          <Button size="small" type="primary" onClick={handleChangeStatusToCompleted}>
            Hoàn thành đơn hàng
          </Button>
        );

      default:
        return null;
    }
  }, [handleChangeStatusToCompleted, handleChangeStatusToShiped, handleChangeStatusToShipping, order?.status]);

  if (!order) return null;
  return (
    <div>
      <Spin spinning={loading}>
        <div className="flex justify-between items-center">
          <PageTitle link={AppRoutes.orderManagement} title="Thông tin chi tiết đơn hàng" />
          {actionsByOrderStatus()}
        </div>
        <h2 className="mb-[12px]">Thông tin khách hàng</h2>
        <Descriptions column={1} labelStyle={{ width: '150px' }}>
          <Descriptions.Item label="Mã đơn hàng">{order?.code}</Descriptions.Item>
          <Descriptions.Item label="Tên khách hàng">{order?.address?.contactName}</Descriptions.Item>
          <Descriptions.Item label="Số điện thoại">{order?.address?.contactPhone}</Descriptions.Item>
          <Descriptions.Item label="Địa chỉ">
            {order?.address?.addressName} {order?.address?.addressDetail}, {order?.address?.mapAddress}
          </Descriptions.Item>
          {order.discount && <Descriptions.Item label="Giá giảm">{numberWithDots(order.discount)}</Descriptions.Item>}
          <Descriptions.Item label="Trạng thái đơn hàng">
            {order?.status && orderStatusText(order.status)}
          </Descriptions.Item>
          <Descriptions.Item label="Thời gian đặt hàng">
            {order?.createdAt && dayjs(order.createdAt).format(TIME_FORMAT)}
          </Descriptions.Item>
          {order?.status === OrderStatusEnum.CANCEL && (
            <Descriptions.Item label="Thời gian huỷ">
              {order?.statusDetail?.createdAt && dayjs(order?.statusDetail?.createdAt).format(TIME_FORMAT)}
            </Descriptions.Item>
          )}
          {order?.status === OrderStatusEnum.CANCEL && (
            <Descriptions.Item label="Lý do hủy đơn">
              {getNameCategoriesEntity(order?.statusDetail?.reasons ?? [])}
            </Descriptions.Item>
          )}
          {order?.status === OrderStatusEnum.CANCEL && (
            <Descriptions.Item label="Ghi chú hủy đơn">{order?.statusDetail?.note}</Descriptions.Item>
          )}
          {order?.status === OrderStatusEnum.DELIVERED && (
            <Descriptions.Item label="Thời gian đã giao hàng">
              {order?.statusDetail?.createdAt && dayjs(order?.statusDetail?.createdAt).format(TIME_FORMAT)}
            </Descriptions.Item>
          )}
          {order?.status === OrderStatusEnum.COMPLETE && (
            <Descriptions.Item label="Thời gian hoàn thành">
              {order?.statusDetail?.createdAt && dayjs(order?.statusDetail?.createdAt).format(TIME_FORMAT)}
            </Descriptions.Item>
          )}
        </Descriptions>

        {order?.product && (
          <>
            <h2 className="mb-[12px] mt-[36px]">Thông tin sản phẩm</h2>
            <MasterTable loading={loading} bordered columns={columns} data={productTable} total={productTable.length} />
            <div className="flex justify-end font-bold text-[18px] mt-[18px]">
              <span className="pr-[140px]">Tổng tiền:</span>
              <span className="text-18px">{numberWithDots(order?.total)}</span>
            </div>
          </>
        )}
        {showCancelOrder && (
          <CancelOrder
            open={showCancelOrder}
            onCancel={() => setShowCancelOrder(false)}
            onCompleted={handleCancelOrder}
            categoryTypeEnum={CategoryTypeEnum.CANCEL_ORDER_REASON_BY_PARTNER}
            loading={canceling}
          />
        )}
      </Spin>
    </div>
  );
});

export default DetailOrder;
