import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
import { PartnerFragmentFragmentDoc } from '../fragments/partner.fragment.generated';

const defaultOptions = {} as const;
export type AdminUpdateTechnicianStatusMutationVariables = Types.Exact<{
  input: Types.UpdateAgentStatusInput;
}>;

export type AdminUpdateTechnicianStatusMutationResponse = { __typename?: 'Mutation' } & {
  adminUpdateTechnicianStatus: { __typename?: 'PartnerEntity' } & Pick<
    Types.PartnerEntity,
    | 'addressMoreInfo'
    | 'avatarId'
    | 'bank'
    | 'birthday'
    | 'cardNumber'
    | 'citizenId'
    | 'createdAt'
    | 'deletedAt'
    | 'description'
    | 'suggestionPoint'
    | 'email'
    | 'fullname'
    | 'hotline'
    | 'id'
    | 'isActive'
    | 'isApproved'
    | 'latitude'
    | 'longitude'
    | 'mapAddress'
    | 'parentId'
    | 'phone'
    | 'type'
  > & {
      avatar?: Types.Maybe<
        { __typename?: 'Media' } & Pick<
          Types.Media,
          | 'id'
          | 'fullOriginalUrl'
          | 'fullThumbUrl'
          | 'createdAt'
          | 'fileSize'
          | 'name'
          | 'originalUrl'
          | 'ownerId'
          | 'thumbUrl'
          | 'type'
        >
      >;
      education?: Types.Maybe<
        { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>
      >;
      level?: Types.Maybe<
        { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>
      >;
      parentInfo?: Types.Maybe<
        { __typename?: 'PartnerEntity' } & Pick<
          Types.PartnerEntity,
          | 'addressMoreInfo'
          | 'avatarId'
          | 'bank'
          | 'birthday'
          | 'cardNumber'
          | 'citizenId'
          | 'createdAt'
          | 'deletedAt'
          | 'description'
          | 'email'
          | 'fullname'
          | 'hotline'
          | 'id'
          | 'isActive'
          | 'isApproved'
          | 'latitude'
          | 'longitude'
          | 'mapAddress'
          | 'parentId'
          | 'phone'
          | 'type'
        >
      >;
      qualifications?: Types.Maybe<
        Array<{ __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>>
      >;
    };
};

export const AdminUpdateTechnicianStatusDocument = gql`
  mutation adminUpdateTechnicianStatus($input: UpdateAgentStatusInput!) {
    adminUpdateTechnicianStatus(input: $input) {
      ...PartnerFragment
    }
  }
  ${PartnerFragmentFragmentDoc}
`;
export function useAdminUpdateTechnicianStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpdateTechnicianStatusMutationResponse,
    AdminUpdateTechnicianStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminUpdateTechnicianStatusMutationResponse, AdminUpdateTechnicianStatusMutationVariables>(
    AdminUpdateTechnicianStatusDocument,
    options,
  );
}
export type AdminUpdateTechnicianStatusMutationHookResult = ReturnType<typeof useAdminUpdateTechnicianStatusMutation>;
export type AdminUpdateTechnicianStatusMutationResult =
  Apollo.MutationResult<AdminUpdateTechnicianStatusMutationResponse>;
export type AdminUpdateTechnicianStatusMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateTechnicianStatusMutationResponse,
  AdminUpdateTechnicianStatusMutationVariables
>;
