import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminExportTechniciansQueryVariables = Types.Exact<{
  agencyId?: Types.InputMaybe<Types.Scalars['String']>;
  isActive?: Types.InputMaybe<Types.StatusEnum>;
  isApproved?: Types.InputMaybe<Types.Scalars['Boolean']>;
  search?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;

export type AdminExportTechniciansQueryResponse = { __typename?: 'Query' } & Pick<
  Types.Query,
  'adminExportTechnicians'
>;

export const AdminExportTechniciansDocument = gql`
  query adminExportTechnicians(
    $agencyId: String
    $isActive: StatusEnum
    $isApproved: Boolean
    $search: String
    $sort: SortInput
  ) {
    adminExportTechnicians(
      agencyId: $agencyId
      isActive: $isActive
      isApproved: $isApproved
      search: $search
      sort: $sort
    )
  }
`;
export function useAdminExportTechniciansQuery(
  baseOptions?: Apollo.QueryHookOptions<AdminExportTechniciansQueryResponse, AdminExportTechniciansQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminExportTechniciansQueryResponse, AdminExportTechniciansQueryVariables>(
    AdminExportTechniciansDocument,
    options,
  );
}
export function useAdminExportTechniciansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminExportTechniciansQueryResponse, AdminExportTechniciansQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminExportTechniciansQueryResponse, AdminExportTechniciansQueryVariables>(
    AdminExportTechniciansDocument,
    options,
  );
}
export type AdminExportTechniciansQueryHookResult = ReturnType<typeof useAdminExportTechniciansQuery>;
export type AdminExportTechniciansLazyQueryHookResult = ReturnType<typeof useAdminExportTechniciansLazyQuery>;
export type AdminExportTechniciansQueryResult = Apollo.QueryResult<
  AdminExportTechniciansQueryResponse,
  AdminExportTechniciansQueryVariables
>;
