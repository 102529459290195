import React from 'react';

import { AppRoutes } from '../../helpers';
import { MasterForm } from '../../components';
import { CategoryTypeEnum } from '../../graphql/type.interface';

export default function FormQualification() {
  return (
    <MasterForm
      title="Trình độ chuyên môn"
      backTo={AppRoutes.qualificationManagement}
      typeCategory={CategoryTypeEnum.QUALIFICATION}
    />
  );
}
