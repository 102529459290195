/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useMemo, useState } from 'react';
import { Avatar, Row, Table, Tooltip } from 'antd';
import { ColumnType, ColumnsType, TableProps } from 'antd/es/table';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';

import { DefaultPagination, numberWithDots } from '../../utils';
import { DATE_FORMAT, bookingStatusText, maintenanceText, orderStatusText } from '../../constants';
import { AppRoutes } from '../../helpers';
import { ModalCustomize } from '../../components/modal-customize/modal-customize';

import { TABLE_TYPE } from './detail';
import DetailVehicle from './detailVehicle';

const defaultConfig = {
  stt: {
    title: 'STT',
    dataIndex: 'index',
    align: 'center',
    width: 60,
  } as ColumnType<any>,
  agency: {
    title: `Đại lý`,
    dataIndex: 'partner',
    key: 'partner-name',
    render: (v: any) => v?.fullname,
  } as ColumnType<any>,
};

const orderTableCols: ColumnsType<any> = [
  { key: 'extendable', width: 42 },
  defaultConfig.stt,
  // TODO: Wait B.A confirm what exactly name of product
  {
    title: 'Sản phẩm',
    dataIndex: 'name',
    onCell: (rec) => (rec.code ? { colSpan: 7 } : {}),
    render: (v, rec) =>
      rec.code ? (
        <Row className="items-center mx-2">
          <span className="text-grayscale-gray">Mã đơn hàng:&nbsp;</span>
          {rec.code}
        </Row>
      ) : (
        <div className="flex items-center">
          <div>
            <Avatar className="mx-2" shape="square" src={rec.avatar} />
          </div>
          <span className="text-grayscale-black font-medium truncate">{v}</span>
        </div>
      ),
  },
  {
    title: `Đại lý`,
    dataIndex: 'partnerName',
    align: 'center',
    ellipsis: true,
    render: (v, rec) => <Link to={AppRoutes.agencyDetailId(rec.partnerId)}>{v}</Link>,
    onCell: (rec) =>
      rec.code ? { colSpan: 0, rowSpan: 1 } : { colSpan: rec.partnerName ? 1 : 0, rowSpan: rec.numRows },
  },
  {
    title: `Số lượng`,
    dataIndex: 'quantity',
    align: 'center',
    onCell: (rec) => (rec.code ? { colSpan: 0 } : {}),
  },
  {
    title: `Đơn giá`,
    dataIndex: 'unitPrice',
    align: 'center',
    onCell: (rec) => (rec.code ? { colSpan: 0 } : {}),
    render: (v) => v && numberWithDots(v),
  },
  {
    title: `Tổng tiền`,
    dataIndex: 'total',
    align: 'center',
    onCell: (rec) => (rec.code ? { colSpan: 0, rowSpan: 1 } : { colSpan: rec.total ? 1 : 0, rowSpan: rec.numRows }),
    render: (v) => v && numberWithDots(v),
  },
  {
    title: `Trạng thái đơn hàng`,
    dataIndex: 'status',
    onCell: (rec) => (rec.code ? { colSpan: 0, rowSpan: 1 } : { colSpan: rec.status ? 1 : 0, rowSpan: rec.numRows }),
    render: (v) => v && orderStatusText(v),
  },
  {
    title: `Ngày mua`,
    dataIndex: 'createdAt',
    align: 'center',
    onCell: (rec) => (rec.code ? { colSpan: 0, rowSpan: 1 } : { colSpan: rec.createdAt ? 1 : 0, rowSpan: rec.numRows }),
    render: (v) => v && dayjs(v).format(DATE_FORMAT),
  },
];
const bookingTableCols: ColumnsType<any> = [
  defaultConfig.stt,
  {
    title: 'Mã yêu cầu sửa chữa',
    dataIndex: 'code',
  },
  {
    title: 'Tên máy',
    dataIndex: 'vehicle',
    key: 'vehicle-name',
    render: (v) => v?.name,
  },
  defaultConfig.agency,
  {
    title: `Kỹ thuật viên`,
    dataIndex: 'technician',
    key: 'technician-name',
    render: (v) => v?.fullname,
  },
  {
    title: `Trạng thái yêu cầu sửa chữa`,
    dataIndex: 'status',
    render: (v) => bookingStatusText(v),
  },
  {
    title: `Tổng chi phí`,
    dataIndex: 'quotationAccepted',
    key: 'total',
    render: (v, rec) => numberWithDots(v?.total ?? rec?.settlementAccepted?.total),
  },
  {
    title: `Ngày tạo`,
    dataIndex: 'createdAt',
    render: (v) => v && dayjs(v).format(DATE_FORMAT),
  },
];
const maintenanceTableCols: ColumnsType<any> = [
  defaultConfig.stt,
  {
    title: 'Mã YCBD',
    dataIndex: 'code',
  },
  {
    title: 'Tên máy',
    dataIndex: 'vehicle',
    key: 'vehicle-name',
    render: (v) => v?.name,
  },
  {
    title: 'Mốc bảo dưỡng',
    dataIndex: 'maintenanceLevel',
    render: (_, rec) => maintenanceText(rec),
  },
  {
    title: `Ngày tạo`,
    dataIndex: 'createdAt',
    render: (v) => v && dayjs(v).format(DATE_FORMAT),
  },
];

const TableListInfo = memo(({ tab, table }: { tab: TABLE_TYPE; table: TableProps<any> }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [chooseId, setChooseId] = useState<string | null>('');
  const vehicleTableCols: ColumnsType<any> = [
    defaultConfig.stt,
    {
      title: 'Tên thiết bị',
      dataIndex: 'name',
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'mapAddress',
    },
    {
      title: `Số VIN`,
      dataIndex: 'vinNumber',
    },
    {
      title: `Hãng sản xuất`,
      dataIndex: 'manufacturer',
      render: (v) => v?.name,
    },
    {
      title: `Năm sản xuất`,
      dataIndex: 'yearOfManufacture',
    },
    {
      title: `Model`,
      dataIndex: 'model',
      key: 'model-name',
      render: (v) => v?.name,
    },
    {
      title: `Serial`,
      dataIndex: 'serialNumber',
    },
    {
      title: `Hành động`,
      dataIndex: 'action',
      align: 'center',
      render: (_, { id }) => (
        <Tooltip title="Xem chi tiết">
          <EyeOutlined
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setOpen(true);
              setChooseId(id);
            }}
          />
        </Tooltip>
      ),
      width: 120,
    },
  ];

  const columns = useMemo(() => {
    switch (tab) {
      case TABLE_TYPE.ORDERS:
        return orderTableCols;
      case TABLE_TYPE.BOOKINGS:
        return bookingTableCols;
      case TABLE_TYPE.MAINTENANCES:
        return maintenanceTableCols;
      case TABLE_TYPE.VEHICLES:
        return vehicleTableCols;
      default: {
        const exhaustiveCheck: never = tab;
        throw new Error(`Columns. not supported value: ${exhaustiveCheck}`);
      }
    }
  }, [tab]);

  return (
    <div>
      <Table
        size="small"
        bordered
        columns={columns}
        scroll={{ y: 'calc(100vh - 250px)' }}
        pagination={DefaultPagination}
        {...table}
      />
      {tab === TABLE_TYPE.VEHICLES && chooseId && open && (
        <ModalCustomize
          open={open}
          onCancel={() => {
            setOpen(false);
            setChooseId(null);
          }}
          width={1000}
          footer={null}
          title="Thông tin xe">
          <DetailVehicle id={chooseId} />
        </ModalCustomize>
      )}
    </div>
  );
});

export default TableListInfo;
