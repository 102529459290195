import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminGetVehicleTypeCategoryQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type AdminGetVehicleTypeCategoryQueryResponse = { __typename?: 'Query' } & {
  adminGetVehicleTypeCategory: { __typename?: 'VechicleTypeCategoryEntity' } & Pick<
    Types.VechicleTypeCategoryEntity,
    'id' | 'maintenanceAcessoryIds' | 'operatingNumber' | 'updatedAt'
  > & {
      maintenanceAcessories: Array<
        { __typename?: 'MaintenancesAccessoryEntity' } & Pick<
          Types.MaintenancesAccessoryEntity,
          'id' | 'name' | 'quantity' | 'unit'
        >
      >;
      model: { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name'>;
      vehicleType: { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'isActive' | 'name'>;
    };
};

export const AdminGetVehicleTypeCategoryDocument = gql`
  query adminGetVehicleTypeCategory($id: String!) {
    adminGetVehicleTypeCategory(id: $id) {
      id
      maintenanceAcessories {
        id
        name
        quantity
        unit
      }
      maintenanceAcessoryIds
      model {
        id
        isActive
        name
      }
      operatingNumber
      updatedAt
      vehicleType {
        id
        isActive
        name
      }
    }
  }
`;
export function useAdminGetVehicleTypeCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetVehicleTypeCategoryQueryResponse,
    AdminGetVehicleTypeCategoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetVehicleTypeCategoryQueryResponse, AdminGetVehicleTypeCategoryQueryVariables>(
    AdminGetVehicleTypeCategoryDocument,
    options,
  );
}
export function useAdminGetVehicleTypeCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetVehicleTypeCategoryQueryResponse,
    AdminGetVehicleTypeCategoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetVehicleTypeCategoryQueryResponse, AdminGetVehicleTypeCategoryQueryVariables>(
    AdminGetVehicleTypeCategoryDocument,
    options,
  );
}
export type AdminGetVehicleTypeCategoryQueryHookResult = ReturnType<typeof useAdminGetVehicleTypeCategoryQuery>;
export type AdminGetVehicleTypeCategoryLazyQueryHookResult = ReturnType<typeof useAdminGetVehicleTypeCategoryLazyQuery>;
export type AdminGetVehicleTypeCategoryQueryResult = Apollo.QueryResult<
  AdminGetVehicleTypeCategoryQueryResponse,
  AdminGetVehicleTypeCategoryQueryVariables
>;
