import { memo, useMemo, useCallback, useEffect } from 'react';
import { Button, Col, Form, Input, Row, Select, Switch, UploadFile, notification } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { RcFile } from 'antd/es/upload';

import { PageTitle, ProgressLoading } from '../../../components';
import { AppRoutes } from '../../../helpers';
import { Messages } from '../../../constants/message';
import { useAdminGetInstructionQuery } from '../../../graphql/queries/adminGetInstruction.generated';
import { useAdminCreateInstructionMutation } from '../../../graphql/mutations/adminCreateInstruction.generated';
import { useAdminUpdateInstructionMutation } from '../../../graphql/mutations/adminUpdateInstruction.generated';
import { useInstructionCate } from '../hooks/use-instruction-cate';
import { UploadFilePdf } from '../../courses/upload-file';
import { useUploadFileMutation } from '../../../services';

export const InstructionForm = memo(() => {
  const { id = '' } = useParams();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const { tableData: instructCates } = useInstructionCate({ getAll: true });

  const { data: instructData, loading } = useAdminGetInstructionQuery({ variables: { id } });

  const instruct = useMemo(() => instructData?.adminGetInstruction, [instructData?.adminGetInstruction]);

  useEffect(() => {
    form.setFieldsValue({ ...instruct });
  }, [form, instruct]);

  const [createInstruct, { loading: creating }] = useAdminCreateInstructionMutation({
    onError(err) {
      notification.error({ message: Messages.create.fail('hướng dẫn'), description: err.message });
    },
    onCompleted() {
      notification.success({ message: Messages.create.success('hướng dẫn') });
      navigate(-1);
    },
  });

  const [updateInstruct, { loading: updating }] = useAdminUpdateInstructionMutation({
    onError: (err) => notification.error({ message: Messages.update.fail('hướng dẫn'), description: err?.message }),
    onCompleted: () => {
      notification.success({ message: Messages.update.success('hướng dẫn') });
      navigate(-1);
    },
  });

  const { mutateAsync: mutateFileAsync, isLoading: fileUploading } = useUploadFileMutation({
    onError(error) {
      notification.error({ message: Messages.upload.fail('file'), description: error?.message });
    },
  });

  const onFinish = useCallback(
    async (values: any) => {
      async function handleUploadPdfs(files: UploadFile[]) {
        const uploadData = files.filter((file) => file.status !== 'done');
        return Promise.all(
          uploadData.map((data) => {
            const formData = new FormData();
            formData.append('file', data.originFileObj as RcFile);
            return mutateFileAsync(formData);
          }),
        ).then((values: any) => values.map((v: any) => v.id));
      }
      const filesState = values.fileIds as UploadFile[];
      const fileUploadIds = await handleUploadPdfs(filesState);
      const fileIds = filesState
        .filter((i) => i.status === 'done')
        .map((i) => i.uid)
        .concat(fileUploadIds);
      return id
        ? updateInstruct({ variables: { input: { ...values, id, fileIds } } })
        : createInstruct({ variables: { input: { ...values, fileIds } } });
    },
    [createInstruct, id, mutateFileAsync, updateInstruct],
  );

  if (loading) return <ProgressLoading />;

  return (
    <div>
      <PageTitle link={AppRoutes.instructionManagement} title={`${id ? 'Chỉnh sửa' : 'Thêm mới'} hướng dẫn`} />
      <Form
        form={form}
        labelCol={{ span: 4 }}
        labelAlign="left"
        wrapperCol={{ span: 15 }}
        size="small"
        initialValues={instruct ?? { isActive: true }}
        onFinish={onFinish}>
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Form.Item
          label={
            <span>
              Tên danh mục hướng dẫn<span className="text-red"> *</span>
            </span>
          }
          name="guideId"
          rules={[{ required: true, message: Messages.required('Tên danh mục hướng dẫn') }]}>
          <Select
            showSearch
            allowClear
            placeholder="Chọn danh mục hướng dẫn"
            options={instructCates.map((el) => ({ label: el.name, value: el.id }))}
            filterOption={(input, option) => option?.label?.toLowerCase().includes(input.toLowerCase()) ?? false}
          />
        </Form.Item>
        <Form.Item
          label={
            <span>
              Tên hướng dẫn<span className="text-red"> *</span>
            </span>
          }
          name="name"
          rules={[{ required: true, message: Messages.required('Tên hướng dẫn') }]}>
          <Input placeholder={`Vui lòng nhập tên hướng dẫn.`} maxLength={255} />
        </Form.Item>
        <Form.Item
          label={
            <span>
              Chi tiết hướng dẫn<span className="text-red"> *</span>
            </span>
          }
          name="fileIds"
          rules={[{ required: true, message: Messages.required('Chi tiết hướng dẫn') }]}
          valuePropName="files">
          <UploadFilePdf
            multiple
            onChangeUpload={(v) => form.setFields([{ name: 'fileIds', value: v, errors: [] }])}
            disabled={fileUploading}
            defaultFileList={
              (instruct?.files ?? []).map((it) => ({
                uid: it?.id,
                name: it?.name,
                status: 'done',
                fileName: it?.name,
                url: it?.fullOriginalUrl,
              })) as UploadFile[]
            }
          />
        </Form.Item>
        <Form.Item label="Trạng thái" name="isActive" valuePropName="checked">
          <Switch disabled={!id} />
        </Form.Item>
        <Row>
          <Col span={20} className="flex justify-end space-x-4">
            <Button className="w-20" type="default" onClick={() => navigate(-1)}>
              Huỷ
            </Button>
            <Button className="px-2 py-8" type="primary" htmlType="submit" loading={creating || updating}>
              Lưu
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
});
