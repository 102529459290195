import { memo, useMemo, useState, useReducer } from 'react';
import { Descriptions, Spin, Switch, Tabs } from 'antd';
import { useParams } from 'react-router-dom';

import { PageTitle } from '../../components/page-title';
import { AppRoutes } from '../../helpers';
import { useAdminUserQuery } from '../../graphql/queries/adminUser.generated';
import {
  AdminGetUserOrdersQueryVariables,
  useAdminGetUserOrdersQuery,
} from '../../graphql/queries/adminGetUserOrders.generated';
import {
  AdminGetUserBookingsQueryVariables,
  useAdminGetUserBookingsQuery,
} from '../../graphql/queries/adminGetUserBookings.generated';
import {
  AdminGetUserMaintenancesQueryVariables,
  useAdminGetUserMaintenancesQuery,
} from '../../graphql/queries/adminGetUserMaintenances.generated';
import {
  AdminGetUserVehiclesQueryVariables,
  useAdminGetUserVehiclesQuery,
} from '../../graphql/queries/adminGetUserVehicles.generated';
import { LIST_PARAMS } from '../../constants';
import { serialColumnTable } from '../../utils';

import TableListInfo from './table-list-info';

export enum TABLE_TYPE {
  ORDERS = 'ORDERS',
  BOOKINGS = 'BOOKINGS',
  MAINTENANCES = 'MAINTENANCES',
  VEHICLES = 'VEHICLES',
}
interface ParamsAction {
  type: TABLE_TYPE;
  payload: Partial<ParamsType>;
}

type ParamsType =
  | AdminGetUserOrdersQueryVariables
  | AdminGetUserBookingsQueryVariables
  | AdminGetUserMaintenancesQueryVariables
  | AdminGetUserVehiclesQueryVariables;

type ParamsState = {
  [key in TABLE_TYPE]: ParamsType;
};

function paramsReducer(state: ParamsState, action: ParamsAction) {
  const { type, payload } = action;
  return { ...state, [type]: { ...state[type], ...payload } };
}

const DetailCustomer = memo(() => {
  const [tab, setTab] = useState(TABLE_TYPE.ORDERS);
  const { id = '' } = useParams();
  const defaultParam = { ...LIST_PARAMS, userId: id };
  const [listParams, dispatchListParams] = useReducer(paramsReducer, {
    [TABLE_TYPE.ORDERS]: defaultParam,
    [TABLE_TYPE.BOOKINGS]: defaultParam,
    [TABLE_TYPE.MAINTENANCES]: defaultParam,
    [TABLE_TYPE.VEHICLES]: defaultParam,
  });

  const handleChangePage = (newPage: number, pageSize: number, type: TABLE_TYPE) =>
    dispatchListParams({ type, payload: { page: newPage, limit: pageSize } });

  const { data, loading } = useAdminUserQuery({ variables: { id }, skip: !id });
  const { data: orderData, loading: orderLoading } = useAdminGetUserOrdersQuery({
    skip: !id || tab !== TABLE_TYPE.ORDERS,
    variables: listParams.ORDERS,
  });
  const { data: bookingData, loading: bookingLoading } = useAdminGetUserBookingsQuery({
    skip: !id || tab !== TABLE_TYPE.BOOKINGS,
    variables: listParams.BOOKINGS,
  });
  const { data: maintenanceData, loading: maintenanceLoading } = useAdminGetUserMaintenancesQuery({
    skip: !id || tab !== TABLE_TYPE.MAINTENANCES,
    variables: listParams.MAINTENANCES,
  });
  const { data: vehicleData, loading: vehicleLoading } = useAdminGetUserVehiclesQuery({
    skip: !id || tab !== TABLE_TYPE.VEHICLES,
    variables: listParams.VEHICLES,
  });

  const tableOrder = useMemo(
    () =>
      orderData?.adminGetUserOrders.items?.map((el, i) => ({
        key: el.id,
        code: el.code,
        partnerId: el.partner?.id,
        index: serialColumnTable(i, { page: listParams.ORDERS.page, limit: listParams.ORDERS.limit }),
        children: el.product.map((oneP, i) => {
          const oneRow =
            i === 0
              ? {
                  partnerName: el.partner?.fullname,
                  total: el.total,
                  createdAt: el.createdAt,
                  status: el.status,
                  numRows: el.product.length,
                }
              : {};
          return {
            ...oneRow,
            key: oneP.id,
            avatar: oneP.avatar?.fullThumbUrl ?? '',
            name: oneP.name,
            quantity: oneP.quantity,
            unitPrice: oneP.unitPrice,
          };
        }),
      })) ?? [],
    [listParams.ORDERS.limit, listParams.ORDERS.page, orderData?.adminGetUserOrders.items],
  );
  const tableBooking = useMemo(
    () =>
      bookingData?.adminGetUserBookings.items?.map((el, i) => ({
        ...el,
        key: el.id,
        index: serialColumnTable(i, { page: listParams.BOOKINGS.page, limit: listParams.BOOKINGS.limit }),
      })) ?? [],
    [bookingData?.adminGetUserBookings.items, listParams.BOOKINGS.limit, listParams.BOOKINGS.page],
  );
  const tableMaintenance = useMemo(
    () =>
      maintenanceData?.adminGetUserMaintenances.items?.map((el, i) => ({
        ...el,
        key: el?.id,
        index: serialColumnTable(i, { page: listParams.MAINTENANCES.page, limit: listParams.MAINTENANCES.limit }),
      })) ?? [],
    [listParams.MAINTENANCES.limit, listParams.MAINTENANCES.page, maintenanceData?.adminGetUserMaintenances.items],
  );
  const tableVehicle = useMemo(
    () =>
      vehicleData?.adminGetUserVehicles.items?.map((el, i) => ({
        ...el,
        key: el.id,
        index: serialColumnTable(i, { page: listParams.VEHICLES.page, limit: listParams.VEHICLES.limit }),
      })) ?? [],
    [listParams.VEHICLES.limit, listParams.VEHICLES.page, vehicleData?.adminGetUserVehicles.items],
  );
  const user = useMemo(() => data?.adminUser, [data]);

  return (
    <div>
      <Spin spinning={loading}>
        <PageTitle link={AppRoutes.customerManagement} title="Thông tin chi tiết khách hàng" />
        <h2 className="mt-[36px] mb-[12px]">Thông tin cá nhân</h2>
        <Descriptions column={1} labelStyle={{ width: '150px' }}>
          <Descriptions.Item label="Mã khách hàng">{user?.id}</Descriptions.Item>
          <Descriptions.Item label="Họ và tên">{user?.fullname}</Descriptions.Item>
          <Descriptions.Item label="Email">{user?.email}</Descriptions.Item>
          <Descriptions.Item label="Số diện thoại">{user?.phone}</Descriptions.Item>
          <Descriptions.Item label="Địa chỉ mặc định">
            {user && user?.userAddress ? user.userAddress?.mapAddress : ''}
          </Descriptions.Item>
          <Descriptions.Item label="Trạng thái">
            <Switch checked={user?.isActive} disabled />
          </Descriptions.Item>
        </Descriptions>
      </Spin>
      <Tabs
        size={'small'}
        onChange={(key) => setTab(key as TABLE_TYPE)}
        type="card"
        items={[
          {
            label: 'Danh sách đơn hàng',
            key: TABLE_TYPE.ORDERS,
            children: (
              <TableListInfo
                tab={tab}
                table={{
                  loading: orderLoading,
                  dataSource: tableOrder,
                  pagination: {
                    total: orderData?.adminGetUserOrders.meta.totalItems,
                    onChange: (newPage, pageSize) => handleChangePage(newPage, pageSize, TABLE_TYPE.ORDERS),
                    current: Number(listParams.ORDERS.page),
                  },
                }}
              />
            ),
          },
          {
            label: 'Danh sách yêu cầu sửa chữa',
            key: TABLE_TYPE.BOOKINGS,
            children: (
              <TableListInfo
                tab={tab}
                table={{
                  loading: bookingLoading,
                  dataSource: tableBooking,
                  pagination: {
                    total: bookingData?.adminGetUserBookings.meta.totalItems,
                    onChange: (newPage, pageSize) => handleChangePage(newPage, pageSize, TABLE_TYPE.BOOKINGS),
                    current: Number(listParams.BOOKINGS.page),
                  },
                }}
              />
            ),
          },
          {
            label: 'Danh sách yêu cầu bảo dưỡng',
            key: TABLE_TYPE.MAINTENANCES,
            children: (
              <TableListInfo
                tab={tab}
                table={{
                  loading: maintenanceLoading,
                  dataSource: tableMaintenance,
                  pagination: {
                    total: maintenanceData?.adminGetUserMaintenances.meta.totalItems,
                    onChange: (newPage, pageSize) => handleChangePage(newPage, pageSize, TABLE_TYPE.MAINTENANCES),
                    current: Number(listParams.MAINTENANCES.page),
                  },
                }}
              />
            ),
          },
          {
            label: 'Danh sách xe',
            key: TABLE_TYPE.VEHICLES,
            children: (
              <TableListInfo
                tab={tab}
                table={{
                  loading: vehicleLoading,
                  dataSource: tableVehicle,
                  pagination: {
                    total: vehicleData?.adminGetUserVehicles.meta.totalItems,
                    onChange: (newPage, pageSize) => handleChangePage(newPage, pageSize, TABLE_TYPE.VEHICLES),
                    current: Number(listParams.VEHICLES.page),
                  },
                }}
              />
            ),
          },
        ]}
      />
    </div>
  );
});
export default DetailCustomer;
