import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
import { MediaFragmentFragmentDoc } from '../fragments/media.fragment.generated';

const defaultOptions = {} as const;
export type AdminUserQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type AdminUserQueryResponse = { __typename?: 'Query' } & {
  adminUser?: Types.Maybe<
    { __typename?: 'UserEntity' } & Pick<
      Types.UserEntity,
      | 'address'
      | 'avatarId'
      | 'birthday'
      | 'certificate'
      | 'email'
      | 'fullname'
      | 'id'
      | 'isActive'
      | 'phone'
      | 'totalBookings'
      | 'totalMaintenanceRequests'
      | 'totalOrders'
      | 'totalSpending'
    > & {
        avatar?: Types.Maybe<
          { __typename?: 'Media' } & Pick<
            Types.Media,
            | 'id'
            | 'fullOriginalUrl'
            | 'fullThumbUrl'
            | 'createdAt'
            | 'fileSize'
            | 'name'
            | 'originalUrl'
            | 'ownerId'
            | 'thumbUrl'
            | 'type'
          >
        >;
        userAddress?: Types.Maybe<{ __typename?: 'AddressEntity' } & Pick<Types.AddressEntity, 'mapAddress'>>;
      }
  >;
};

export const AdminUserDocument = gql`
  query adminUser($id: String!) {
    adminUser(id: $id) {
      address
      avatar {
        ...MediaFragment
      }
      avatarId
      birthday
      certificate
      email
      fullname
      id
      isActive
      phone
      totalBookings
      totalMaintenanceRequests
      totalOrders
      totalSpending
      userAddress {
        mapAddress
      }
    }
  }
  ${MediaFragmentFragmentDoc}
`;
export function useAdminUserQuery(
  baseOptions: Apollo.QueryHookOptions<AdminUserQueryResponse, AdminUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminUserQueryResponse, AdminUserQueryVariables>(AdminUserDocument, options);
}
export function useAdminUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminUserQueryResponse, AdminUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminUserQueryResponse, AdminUserQueryVariables>(AdminUserDocument, options);
}
export type AdminUserQueryHookResult = ReturnType<typeof useAdminUserQuery>;
export type AdminUserLazyQueryHookResult = ReturnType<typeof useAdminUserLazyQuery>;
export type AdminUserQueryResult = Apollo.QueryResult<AdminUserQueryResponse, AdminUserQueryVariables>;
