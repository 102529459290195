import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Pagination, PaginationProps, Row, Spin, Tabs } from 'antd';
import { memo, useCallback, useMemo, useState } from 'react';

import { useAdminGetUsersSubmitSurveyQuery } from '../../../graphql/queries/adminGetUsersSubmitSurvey.generated';
import { useAdminGetPartnersSubmitSurveyQuery } from '../../../graphql/queries/adminGetPartnersSubmitSurvey.generated';
import { useAdminGetSubmitResultLazyQuery } from '../../../graphql/queries/adminGetSubmitResult.generated';
import { QuestionEntity, SurveyHistoryType, SurveyResult } from '../../../graphql/type.interface';

import { ContentAnswerSurvey } from './content-answer-survey';

const tabsKey = {
  user: SurveyHistoryType.USER,
  ktv: SurveyHistoryType.PARTNER,
};

const tabs = [
  {
    key: tabsKey.user,
    label: 'Người dùng',
  },
  {
    key: tabsKey.ktv,
    label: 'KTV',
  },
];

type Props = {
  surveyId: string;
  questions?: QuestionEntity[];
};

export const FormReplySurvey = memo(({ surveyId, questions }: Props) => {
  const [tabActive, setTabActive] = useState<SurveyHistoryType>(tabsKey.user);
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const [filter, setFilter] = useState({
    limit: 10,
    page: 1,
    surveyId: surveyId,
  });

  const { data: dataUsers, loading: loadingUsers } = useAdminGetUsersSubmitSurveyQuery({
    variables: filter,
    skip: tabActive !== tabsKey.user,
  });
  const { data: dataTechnicians, loading: loadingTechnicians } = useAdminGetPartnersSubmitSurveyQuery({
    variables: filter,
    skip: tabActive !== tabsKey.ktv,
  });

  const data = useMemo(
    () =>
      tabActive === tabsKey.user ? dataUsers?.adminGetUsersSubmitSurvey : dataTechnicians?.adminGetPartnersSubmitSurvey,
    [dataTechnicians?.adminGetPartnersSubmitSurvey, dataUsers?.adminGetUsersSubmitSurvey, tabActive],
  );

  const onChange = useCallback((key: string) => {
    setTabActive(key as SurveyHistoryType);
  }, []);

  const handleFilter = useCallback(
    (values: any) => {
      setFilter({ ...filter, ...values, page: 1 });
    },
    [filter],
  );

  const changePage: PaginationProps['onChange'] = useCallback(
    (newPage: number) => {
      setFilter({ ...filter, page: newPage });
    },
    [filter],
  );

  const [getAnswerMutation, { data: dataResults, loading: gettingResult }] = useAdminGetSubmitResultLazyQuery();
  const results = useMemo(() => dataResults?.adminGetSubmitResult?.results, [dataResults]);

  const handleLoadAnswer = useCallback(
    (id: string) => {
      setSelectedId(id);
      getAnswerMutation({
        variables: {
          surveyId: surveyId as string,
          type: tabActive,
          userId: id,
        },
      });
    },
    [getAnswerMutation, surveyId, tabActive],
  );

  return (
    <Spin spinning={loadingUsers || loadingTechnicians || gettingResult}>
      <Row gutter={24}>
        <Col span={8}>
          <div className="bg-white p-20px w-full">
            <Tabs items={tabs} activeKey={tabActive} onChange={onChange} size="small" />
            <Form size="small" onFinish={handleFilter}>
              <div className="flex items-center justify-between gap-x-20px">
                <Form.Item name="search" normalize={(e) => e.trimStart()} className="w-full">
                  <Input
                    placeholder={`Tìm kiếm theo tên ${tabActive === tabsKey.user ? 'Người dùng' : 'KTV'}`}
                    addonBefore={<SearchOutlined />}
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Áp dụng
                  </Button>
                </Form.Item>
              </div>
            </Form>
            <div>
              {(data?.items ?? []).map((it) => (
                <p
                  key={it?.id}
                  className={`hover:cursor-pointer line-clamp-1 py-12px ${it?.id === selectedId ? 'text-primary' : ''}`}
                  onClick={() => handleLoadAnswer(it?.id)}>
                  {it?.fullname}
                </p>
              ))}
            </div>
            <div className="flex justify-end">
              <Pagination
                current={data?.items?.length}
                onChange={changePage}
                total={data?.meta?.totalItems}
                size="small"
              />
            </div>
          </div>
        </Col>
        <Col span={16}>
          <div className="bg-white p-20px ">
            {selectedId ? (
              <ContentAnswerSurvey results={results as SurveyResult[]} questions={questions as QuestionEntity[]} />
            ) : (
              <div className="text-center">Chọn người dùng đã trả lời</div>
            )}
          </div>
        </Col>
      </Row>
    </Spin>
  );
});
