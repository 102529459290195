import React from 'react';

import { AppRoutes } from '../../helpers';
import { MasterForm } from '../../components';
import { CategoryTypeEnum } from '../../graphql/type.interface';

export default function FormCancelReasonsByPartner() {
  return (
    <MasterForm
      title="Lý do hủy YCSC của đối tác"
      backTo={AppRoutes.cancelRequestByPartnerManagement}
      typeCategory={CategoryTypeEnum.CANCEL_REASON_BY_PARTNER}
    />
  );
}
