import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminGetPartnersSubmitSurveyQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Array<Types.Scalars['JSONObject']> | Types.Scalars['JSONObject']>;
  isActive?: Types.InputMaybe<Types.StatusEnum>;
  isApproved?: Types.InputMaybe<Types.Scalars['Boolean']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  search?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.SortInput>;
  surveyId: Types.Scalars['String'];
}>;

export type AdminGetPartnersSubmitSurveyQueryResponse = { __typename?: 'Query' } & {
  adminGetPartnersSubmitSurvey: { __typename?: 'PartnerConnection' } & {
    items?: Types.Maybe<Array<{ __typename?: 'PartnerEntity' } & Pick<Types.PartnerEntity, 'fullname' | 'id'>>>;
    meta: { __typename?: 'BasePaginationMeta' } & Pick<
      Types.BasePaginationMeta,
      'currentPage' | 'itemCount' | 'itemsPerPage' | 'totalItems' | 'totalPages'
    >;
  };
};

export const AdminGetPartnersSubmitSurveyDocument = gql`
  query adminGetPartnersSubmitSurvey(
    $filters: [JSONObject!]
    $isActive: StatusEnum
    $isApproved: Boolean
    $limit: Int
    $page: Int
    $search: String
    $sort: SortInput
    $surveyId: String!
  ) {
    adminGetPartnersSubmitSurvey(
      filters: $filters
      isActive: $isActive
      isApproved: $isApproved
      limit: $limit
      page: $page
      search: $search
      sort: $sort
      surveyId: $surveyId
    ) {
      items {
        fullname
        id
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;
export function useAdminGetPartnersSubmitSurveyQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetPartnersSubmitSurveyQueryResponse,
    AdminGetPartnersSubmitSurveyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetPartnersSubmitSurveyQueryResponse, AdminGetPartnersSubmitSurveyQueryVariables>(
    AdminGetPartnersSubmitSurveyDocument,
    options,
  );
}
export function useAdminGetPartnersSubmitSurveyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetPartnersSubmitSurveyQueryResponse,
    AdminGetPartnersSubmitSurveyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetPartnersSubmitSurveyQueryResponse, AdminGetPartnersSubmitSurveyQueryVariables>(
    AdminGetPartnersSubmitSurveyDocument,
    options,
  );
}
export type AdminGetPartnersSubmitSurveyQueryHookResult = ReturnType<typeof useAdminGetPartnersSubmitSurveyQuery>;
export type AdminGetPartnersSubmitSurveyLazyQueryHookResult = ReturnType<
  typeof useAdminGetPartnersSubmitSurveyLazyQuery
>;
export type AdminGetPartnersSubmitSurveyQueryResult = Apollo.QueryResult<
  AdminGetPartnersSubmitSurveyQueryResponse,
  AdminGetPartnersSubmitSurveyQueryVariables
>;
