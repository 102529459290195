import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminGetInstructionsQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Array<Types.Scalars['JSONObject']> | Types.Scalars['JSONObject']>;
  guideId?: Types.InputMaybe<Types.Scalars['String']>;
  isActive?: Types.InputMaybe<Types.StatusEnum>;
  isApproved?: Types.InputMaybe<Types.Scalars['Boolean']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  search?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;

export type AdminGetInstructionsQueryResponse = { __typename?: 'Query' } & {
  adminGetInstructions: { __typename?: 'InstructionConnection' } & {
    items?: Types.Maybe<
      Array<
        { __typename?: 'InstructionEntity' } & Pick<Types.InstructionEntity, 'id' | 'isActive' | 'name'> & {
            guide: { __typename?: 'GuideEntity' } & Pick<Types.GuideEntity, 'name'>;
          }
      >
    >;
    meta: { __typename?: 'BasePaginationMeta' } & Pick<
      Types.BasePaginationMeta,
      'currentPage' | 'itemCount' | 'itemsPerPage' | 'totalItems' | 'totalPages'
    >;
  };
};

export const AdminGetInstructionsDocument = gql`
  query adminGetInstructions(
    $filters: [JSONObject!]
    $guideId: String
    $isActive: StatusEnum
    $isApproved: Boolean
    $limit: Int
    $page: Int
    $search: String
    $sort: SortInput
  ) {
    adminGetInstructions(
      filters: $filters
      guideId: $guideId
      isActive: $isActive
      isApproved: $isApproved
      limit: $limit
      page: $page
      search: $search
      sort: $sort
    ) {
      items {
        guide {
          name
        }
        id
        isActive
        name
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;
export function useAdminGetInstructionsQuery(
  baseOptions?: Apollo.QueryHookOptions<AdminGetInstructionsQueryResponse, AdminGetInstructionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetInstructionsQueryResponse, AdminGetInstructionsQueryVariables>(
    AdminGetInstructionsDocument,
    options,
  );
}
export function useAdminGetInstructionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminGetInstructionsQueryResponse, AdminGetInstructionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetInstructionsQueryResponse, AdminGetInstructionsQueryVariables>(
    AdminGetInstructionsDocument,
    options,
  );
}
export type AdminGetInstructionsQueryHookResult = ReturnType<typeof useAdminGetInstructionsQuery>;
export type AdminGetInstructionsLazyQueryHookResult = ReturnType<typeof useAdminGetInstructionsLazyQuery>;
export type AdminGetInstructionsQueryResult = Apollo.QueryResult<
  AdminGetInstructionsQueryResponse,
  AdminGetInstructionsQueryVariables
>;
