import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
import { MediaFragmentFragmentDoc } from '../fragments/media.fragment.generated';
import { CategoryFragmentFragmentDoc } from '../fragments/category.fragment.generated';

const defaultOptions = {} as const;
export type AdminProductsQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Array<Types.Scalars['JSONObject']> | Types.Scalars['JSONObject']>;
  isActive?: Types.InputMaybe<Types.StatusEnum>;
  isApproved?: Types.InputMaybe<Types.Scalars['Boolean']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  manufacturerId?: Types.InputMaybe<Types.Scalars['String']>;
  modelId?: Types.InputMaybe<Types.Scalars['String']>;
  originId?: Types.InputMaybe<Types.Scalars['String']>;
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  search?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.SortInput>;
  type?: Types.InputMaybe<Types.ProductTypeEnum>;
  vehicleTypeId?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type AdminProductsQueryResponse = { __typename?: 'Query' } & {
  adminProducts: { __typename?: 'ProductConnection' } & {
    items?: Types.Maybe<
      Array<
        { __typename?: 'ProductEntity' } & Pick<
          Types.ProductEntity,
          | 'createdAt'
          | 'deletedAt'
          | 'descriptionImageIds'
          | 'detail'
          | 'id'
          | 'isActive'
          | 'isFixedCost'
          | 'isNew'
          | 'name'
          | 'operatingNumber'
          | 'operatingUnit'
          | 'ordinalNumber'
          | 'partNumber'
          | 'partnerId'
          | 'quantity'
          | 'serialNumber'
          | 'type'
          | 'unitPrice'
          | 'updatedAt'
          | 'vehicleRegistrationPlate'
          | 'vinNumber'
          | 'yearOfManufacture'
          | 'numberSold'
        > & {
            avatar?: Types.Maybe<
              { __typename?: 'Media' } & Pick<
                Types.Media,
                | 'id'
                | 'fullOriginalUrl'
                | 'fullThumbUrl'
                | 'createdAt'
                | 'fileSize'
                | 'name'
                | 'originalUrl'
                | 'ownerId'
                | 'thumbUrl'
                | 'type'
              >
            >;
            descriptionImages: Array<
              { __typename?: 'Media' } & Pick<
                Types.Media,
                | 'id'
                | 'fullOriginalUrl'
                | 'fullThumbUrl'
                | 'createdAt'
                | 'fileSize'
                | 'name'
                | 'originalUrl'
                | 'ownerId'
                | 'thumbUrl'
                | 'type'
              >
            >;
            manufacturer?: Types.Maybe<
              { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>
            >;
            model?: Types.Maybe<
              { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>
            >;
            origin?: Types.Maybe<
              { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>
            >;
            partner?: Types.Maybe<{ __typename?: 'PartnerEntity' } & Pick<Types.PartnerEntity, 'fullname'>>;
            productType?: Types.Maybe<
              { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>
            >;
            productUnit?: Types.Maybe<
              { __typename?: 'CategoryEntity' } & Pick<Types.CategoryEntity, 'id' | 'name' | 'type' | 'isActive'>
            >;
          }
      >
    >;
    meta: { __typename?: 'BasePaginationMeta' } & Pick<
      Types.BasePaginationMeta,
      'currentPage' | 'itemCount' | 'itemsPerPage' | 'totalItems' | 'totalPages'
    >;
  };
};

export const AdminProductsDocument = gql`
  query adminProducts(
    $filters: [JSONObject!]
    $isActive: StatusEnum
    $isApproved: Boolean
    $limit: Int
    $manufacturerId: String
    $modelId: String
    $originId: String
    $page: Int
    $search: String
    $sort: SortInput
    $type: ProductTypeEnum
    $vehicleTypeId: String
  ) {
    adminProducts(
      filters: $filters
      isActive: $isActive
      isApproved: $isApproved
      limit: $limit
      manufacturerId: $manufacturerId
      modelId: $modelId
      originId: $originId
      page: $page
      search: $search
      sort: $sort
      type: $type
      vehicleTypeId: $vehicleTypeId
    ) {
      items {
        avatar {
          ...MediaFragment
        }
        createdAt
        deletedAt
        descriptionImageIds
        descriptionImages {
          ...MediaFragment
        }
        detail
        id
        isActive
        isFixedCost
        isNew
        manufacturer {
          ...CategoryFragment
        }
        model {
          ...CategoryFragment
        }
        name
        operatingNumber
        operatingUnit
        ordinalNumber
        origin {
          ...CategoryFragment
        }
        partNumber
        partner {
          fullname
        }
        partnerId
        productType {
          ...CategoryFragment
        }
        productUnit {
          ...CategoryFragment
        }
        quantity
        serialNumber
        type
        unitPrice
        updatedAt
        vehicleRegistrationPlate
        vinNumber
        yearOfManufacture
        numberSold
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
  ${MediaFragmentFragmentDoc}
  ${CategoryFragmentFragmentDoc}
`;
export function useAdminProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<AdminProductsQueryResponse, AdminProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminProductsQueryResponse, AdminProductsQueryVariables>(AdminProductsDocument, options);
}
export function useAdminProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminProductsQueryResponse, AdminProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminProductsQueryResponse, AdminProductsQueryVariables>(AdminProductsDocument, options);
}
export type AdminProductsQueryHookResult = ReturnType<typeof useAdminProductsQuery>;
export type AdminProductsLazyQueryHookResult = ReturnType<typeof useAdminProductsLazyQuery>;
export type AdminProductsQueryResult = Apollo.QueryResult<AdminProductsQueryResponse, AdminProductsQueryVariables>;
