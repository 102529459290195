import { Button, Form, Input } from 'antd';
import { useCallback, useState } from 'react';

import { validationMessages } from '../../helpers';
import { RegexConstant } from '../../helpers/regex';
import { useAdminForgotPasswordMutation } from '../../graphql/mutations/adminForgotPassword.generated';
import { AdminForgotPasswordInput } from '../../graphql/type.interface';
import { ResponseMessage } from '../../components/response-message';

export default function ForgotPassword() {
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const [forgotPassword] = useAdminForgotPasswordMutation({
    onError: (error) => {
      setErrorMessage(error.message);
      setSuccessMessage(null);
    },
    onCompleted: () => {
      setSuccessMessage('Vui lòng check email để nhận mật khẩu mới!');
      setErrorMessage(null);
    },
  });

  const onFinish = useCallback((values: AdminForgotPasswordInput) => {
    forgotPassword({
      variables: {
        input: {
          email: values.email,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="text-center my-6">
        <span className="text-3xl block mb-2">Quên mật khẩu</span>
        <p className="font-light">Vui lòng nhập email đăng ký để được cấp lại mật khẩu</p>
      </div>
      {errorMessage && (
        <div className="my-16px">
          <ResponseMessage error>{errorMessage}</ResponseMessage>
        </div>
      )}
      <Form
        className="w-[400px]"
        name="login"
        layout="vertical"
        initialValues={{ remember: true }}
        form={form}
        autoComplete="off"
        onFinish={onFinish}>
        {successMessage && (
          <div className="my-16px">
            <ResponseMessage success>{successMessage}</ResponseMessage>
          </div>
        )}
        <Form.Item
          className="mb-4"
          label="Email"
          name="email"
          normalize={(e) => e.trimStart()}
          rules={[
            { required: true, message: validationMessages.email.required2 },
            { pattern: RegexConstant.EMAIL, message: validationMessages.email.notValid },
          ]}>
          <Input placeholder="Vui lòng nhập email" />
        </Form.Item>
        <Form.Item className="mt-10 mb-0">
          <Button type="primary" htmlType="submit" block>
            Xác nhận
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
