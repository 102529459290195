import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminDeleteGuideMutationVariables = Types.Exact<{
  input: Types.DeleteGuideInput;
}>;

export type AdminDeleteGuideMutationResponse = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'adminDeleteGuide'>;

export const AdminDeleteGuideDocument = gql`
  mutation adminDeleteGuide($input: DeleteGuideInput!) {
    adminDeleteGuide(input: $input)
  }
`;
export function useAdminDeleteGuideMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminDeleteGuideMutationResponse, AdminDeleteGuideMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminDeleteGuideMutationResponse, AdminDeleteGuideMutationVariables>(
    AdminDeleteGuideDocument,
    options,
  );
}
export type AdminDeleteGuideMutationHookResult = ReturnType<typeof useAdminDeleteGuideMutation>;
export type AdminDeleteGuideMutationResult = Apollo.MutationResult<AdminDeleteGuideMutationResponse>;
export type AdminDeleteGuideMutationOptions = Apollo.BaseMutationOptions<
  AdminDeleteGuideMutationResponse,
  AdminDeleteGuideMutationVariables
>;
