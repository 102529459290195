import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminDeleteInstructionMutationVariables = Types.Exact<{
  input: Types.DeleteInstructionInput;
}>;

export type AdminDeleteInstructionMutationResponse = { __typename?: 'Mutation' } & Pick<
  Types.Mutation,
  'adminDeleteInstruction'
>;

export const AdminDeleteInstructionDocument = gql`
  mutation adminDeleteInstruction($input: DeleteInstructionInput!) {
    adminDeleteInstruction(input: $input)
  }
`;
export function useAdminDeleteInstructionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminDeleteInstructionMutationResponse,
    AdminDeleteInstructionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminDeleteInstructionMutationResponse, AdminDeleteInstructionMutationVariables>(
    AdminDeleteInstructionDocument,
    options,
  );
}
export type AdminDeleteInstructionMutationHookResult = ReturnType<typeof useAdminDeleteInstructionMutation>;
export type AdminDeleteInstructionMutationResult = Apollo.MutationResult<AdminDeleteInstructionMutationResponse>;
export type AdminDeleteInstructionMutationOptions = Apollo.BaseMutationOptions<
  AdminDeleteInstructionMutationResponse,
  AdminDeleteInstructionMutationVariables
>;
