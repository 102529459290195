import { notification } from 'antd';

import { BookingStatusEnum, CategoryEntity, InputMaybe } from '../graphql/type.interface';
import { LIST_PARAMS } from '../constants';

export const convertBookingStatus = (status: BookingStatusEnum) => {
  switch (status) {
    case BookingStatusEnum.ASSIGNED_TECHNICIAN:
      return 'Đã giao cho KTV';
    case BookingStatusEnum.CANCEL:
      return 'YCSC đã bị hủy';
    case BookingStatusEnum.QUOTATION_REJECTED:
      return ' Khách hàng đã từ chối báo giá';
    case BookingStatusEnum.QUOTATION_ACCEPTED:
      return 'Khách hàng đã chấp nhận báo giá';
    case BookingStatusEnum.COMPLETE:
      return 'YCSC đã hoàn thành';
    case BookingStatusEnum.QUOTATION_REQUESTED:
      return 'KTV đã gửi báo giá';
    case BookingStatusEnum.RESCHEDULED:
      return 'KTV đã hẹn ngày đến';
    case BookingStatusEnum.SETTLEMENT_ACCEPTED:
      return 'Khách hàng đã chấp nhận quyết toán';
    case BookingStatusEnum.SETTLEMENT_REJECTED:
      return 'Khách hàng đã từ chối quyết toán';
    case BookingStatusEnum.SETTLEMENT_REQUESTED:
      return 'KTV đã gửi quyết toán';
    case BookingStatusEnum.TECHNICIAN_ARRIVED:
      return 'KTV đã đến nơi';
    case BookingStatusEnum.TECHNICIAN_ARRIVING:
      return 'KTV đang di chuyển';
    case BookingStatusEnum.WAIT_FOR_CONFIRM:
      return 'Chờ xác nhận YCSC';
    default:
      return undefined;
  }
};

export function numberWithDots(num: number | string | undefined | bigint, currencyFormat?: string) {
  if (!num) return 0;
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + `${currencyFormat ? currencyFormat : ' đ'}`;
}

export const getNameCategoriesEntity = (categories?: Array<Pick<CategoryEntity, 'name'>>) => {
  if (!categories || (categories && categories?.length === 0)) return '';
  return categories
    ?.map((qualification, idx: number) => {
      if (idx + 1 === categories.length) return qualification.name;
      return (qualification?.name as string) + ', ';
    })
    .join(' ');
};

export const serialColumnTable = (
  index: number,
  params: { page: InputMaybe<number>; limit?: InputMaybe<number> },
): number => {
  const page = params.page ?? LIST_PARAMS.page;
  const limit = params.limit ?? LIST_PARAMS.limit;
  return page === 1 ? index + 1 : (page - 1) * limit + (index + 1);
};

export const reverArray = (array: any = []) => {
  return array.slice().reverse();
};

type NotificationType = 'success' | 'info' | 'warning' | 'error';
export const showNotification = (type: NotificationType = 'info', message = '', description = '') => {
  notification[type]({
    message,
    description,
  });
};

export const joinStringArray = (arr: any[], key: string, char: string): string =>
  arr.reduce((a, c) => (a === '' ? a + c[key] : a + char + c[key]), '');

export function trimObj(obj: any) {
  const values = { ...obj };
  return Object.keys(values).reduce((a: any, c) => {
    a[c] = typeof values[c] === 'string' ? values[c].replace(/\s+/g, ' ').trim() : values[c];
    return a;
  }, {});
}

export const downloadExcel = (data: string, fileName: string) => {
  const linkSource = `data:application/vnd.ms-excel;base64,${data}`;
  const downloadLink = document.createElement('a');
  const fileNameXLSX = `${fileName}.xlsx`;

  downloadLink.href = linkSource;
  downloadLink.download = fileNameXLSX;
  downloadLink.click();
};
