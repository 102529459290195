// NOTE: Be careful, if you want to use this, make sure the tester doesn't catch you
export const notificationMessages = {
  create: {
    success: 'Thêm mới thành công',
    fail: 'Thêm mới thất bại',
  },

  update: {
    success: 'Chỉnh sửa thành công',
    fail: 'Chỉnh sửa thất bại',
  },

  delete: {
    success: 'Xóa thành công',
    fail: 'Xóa thất bại',
  },
};
