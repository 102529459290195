import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
const defaultOptions = {} as const;
export type AdminGetNewestMaintenanceByVehicleIdQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type AdminGetNewestMaintenanceByVehicleIdQueryResponse = { __typename?: 'Query' } & {
  adminGetNewestMaintenanceByVehicleId: { __typename?: 'MaintenanceEntity' } & Pick<
    Types.MaintenanceEntity,
    | 'createdAt'
    | 'id'
    | 'isActive'
    | 'maintenanceLevel'
    | 'mapAddress'
    | 'note'
    | 'startDate'
    | 'status'
    | 'updatedAt'
    | 'vehicleTypeCategoryId'
  > & {
      statusDetail?: Types.Maybe<
        { __typename?: 'MaintenanceStatusEntity' } & Pick<
          Types.MaintenanceStatusEntity,
          'createdAt' | 'id' | 'maintenanceId' | 'note' | 'status' | 'userId'
        > & {
            reasons?: Types.Maybe<
              Array<
                { __typename?: 'MaintenanceStatusCategoryEntity' } & Pick<
                  Types.MaintenanceStatusCategoryEntity,
                  'createdAt' | 'id' | 'maintenanceStatusId' | 'name' | 'type'
                >
              >
            >;
          }
      >;
      user?: Types.Maybe<
        { __typename?: 'UserEntity' } & Pick<
          Types.UserEntity,
          'address' | 'avatarId' | 'email' | 'fullname' | 'id' | 'isActive'
        > & {
            avatar?: Types.Maybe<
              { __typename?: 'Media' } & Pick<
                Types.Media,
                | 'createdAt'
                | 'fileSize'
                | 'fullOriginalUrl'
                | 'fullThumbUrl'
                | 'id'
                | 'isDeleted'
                | 'mimeType'
                | 'name'
                | 'originalUrl'
                | 'ownerId'
                | 'thumbUrl'
                | 'type'
                | 'updatedAt'
                | 'videoUrl'
              >
            >;
          }
      >;
    };
};

export const AdminGetNewestMaintenanceByVehicleIdDocument = gql`
  query adminGetNewestMaintenanceByVehicleId($id: String!) {
    adminGetNewestMaintenanceByVehicleId(id: $id) {
      createdAt
      id
      isActive
      maintenanceLevel
      mapAddress
      note
      startDate
      status
      statusDetail {
        createdAt
        id
        maintenanceId
        note
        reasons {
          createdAt
          id
          maintenanceStatusId
          name
          type
        }
        status
        userId
      }
      updatedAt
      user {
        address
        avatar {
          createdAt
          fileSize
          fullOriginalUrl
          fullThumbUrl
          id
          isDeleted
          mimeType
          name
          originalUrl
          ownerId
          thumbUrl
          type
          updatedAt
          videoUrl
        }
        avatarId
        email
        fullname
        id
        isActive
      }
      vehicleTypeCategoryId
    }
  }
`;
export function useAdminGetNewestMaintenanceByVehicleIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetNewestMaintenanceByVehicleIdQueryResponse,
    AdminGetNewestMaintenanceByVehicleIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminGetNewestMaintenanceByVehicleIdQueryResponse,
    AdminGetNewestMaintenanceByVehicleIdQueryVariables
  >(AdminGetNewestMaintenanceByVehicleIdDocument, options);
}
export function useAdminGetNewestMaintenanceByVehicleIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetNewestMaintenanceByVehicleIdQueryResponse,
    AdminGetNewestMaintenanceByVehicleIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminGetNewestMaintenanceByVehicleIdQueryResponse,
    AdminGetNewestMaintenanceByVehicleIdQueryVariables
  >(AdminGetNewestMaintenanceByVehicleIdDocument, options);
}
export type AdminGetNewestMaintenanceByVehicleIdQueryHookResult = ReturnType<
  typeof useAdminGetNewestMaintenanceByVehicleIdQuery
>;
export type AdminGetNewestMaintenanceByVehicleIdLazyQueryHookResult = ReturnType<
  typeof useAdminGetNewestMaintenanceByVehicleIdLazyQuery
>;
export type AdminGetNewestMaintenanceByVehicleIdQueryResult = Apollo.QueryResult<
  AdminGetNewestMaintenanceByVehicleIdQueryResponse,
  AdminGetNewestMaintenanceByVehicleIdQueryVariables
>;
