import React from 'react';

import { AppRoutes } from '../../helpers';
import { MasterForm } from '../../components';
import { CategoryTypeEnum } from '../../graphql/type.interface';

export default function FormCancelReasonsByUser() {
  return (
    <MasterForm
      title="Lý do hủy YCSC của người dùng"
      backTo={AppRoutes.cancelRequestByUserManagement}
      typeCategory={CategoryTypeEnum.CANCEL_REASON}
    />
  );
}
