import { memo } from 'react';
import { Button, Col, Form, Input, Popconfirm, Row, Select, Switch } from 'antd';
import { Link } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';

import { MasterTable, PageTitle } from '../../../components';
import { serialColumnTable } from '../../../utils';
import ActionGroup from '../../../components/group-action';
import { AppRoutes } from '../../../helpers';
import { useInstruction } from '../hooks/use-instruction-list';
import { StatusEnum } from '../../../graphql/type.interface';

export const InstructionList = memo(() => {
  const {
    params,
    tableData,
    instructCates,
    totalItems,
    fetching,
    updating,
    setParams,
    handleFilter,
    handleDelete,
    onEditStatus,
  } = useInstruction();
  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'index',
      align: 'center',
      render: (_, __, index) => serialColumnTable(index, params),
      width: 80,
    },
    {
      title: `Tên danh mục`,
      dataIndex: 'guide',
      ellipsis: true,
      render: (v) => v?.name,
    },
    {
      title: `Tên hướng dẫn`,
      dataIndex: 'name',
      ellipsis: true,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'isActive',
      render: (isActive: boolean, row) => (
        <Popconfirm
          title={`Bạn có chắc muốn thay đổi trạng thái không?`}
          okText="Đồng ý"
          cancelText="Huỷ bỏ"
          placement="topLeft"
          okButtonProps={{ loading: updating }}
          onConfirm={() => onEditStatus(row)}>
          <Switch checked={isActive} size="default" />
        </Popconfirm>
      ),
      align: 'center',
      width: 90,
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'id',
      render: (id: string) => (
        <ActionGroup
          urlDetail={AppRoutes.instructionDetailId}
          urlEdit={AppRoutes.instructionEditId}
          id={id}
          onDelete={handleDelete}
        />
      ),
      align: 'center',
      width: 170,
    },
  ];

  return (
    <div>
      <PageTitle title={`Danh sách hướng dẫn`} />
      <div>
        <Form size="small" onFinish={handleFilter}>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item name="search" normalize={(e) => e.trimStart()}>
                <Input placeholder="Tìm kiếm theo tên..." addonBefore={<SearchOutlined />} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="guideId">
                <Select
                  allowClear
                  showSearch
                  placeholder="Danh mục hướng dẫn"
                  options={instructCates.map((el) => ({ label: el.name, value: el.id }))}
                  filterOption={(input, option) => option?.label?.toLowerCase().includes(input.toLowerCase()) ?? false}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="isActive">
                <Select
                  defaultActiveFirstOption
                  placeholder="Trạng thái"
                  options={[
                    { label: 'Tất cả', value: null },
                    { label: 'Kích hoạt', value: StatusEnum.ACTIVE },
                    { label: 'Vô hiệu', value: StatusEnum.INACTIVE },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Áp dụng
                </Button>
              </Form.Item>
            </Col>
            <Col offset={2} span={2} className="flex space-x-4">
              <Link to={AppRoutes.instructionCreate}>
                <Button type="primary">Tạo mới</Button>
              </Link>
            </Col>
          </Row>
        </Form>
      </div>
      <div>
        <MasterTable
          filter={params}
          setFilter={setParams}
          loading={fetching}
          columns={columns}
          data={tableData}
          total={totalItems}
        />
      </div>
    </div>
  );
});
