import { useMemo } from 'react';

import { CategoryTypeEnum, StatusEnum } from '../../../graphql/type.interface';
import { useAdminAgenciesQuery } from '../../../graphql/queries/adminAgencies.generated';
import { useCategoriesQuery } from '../../../graphql/queries/categories.generated';

interface ProdDropdownProps {
  agencies?: boolean;
  vehicleTypes?: boolean;
  manufacturers?: boolean;
  models?: boolean;
  origins?: boolean;
  productUnits?: boolean;
  partOfNumbers?: boolean;
}

export function useProdDropdown({
  agencies = false,
  vehicleTypes = false,
  manufacturers = false,
  models = false,
  origins = false,
  productUnits = false,
  partOfNumbers = false,
}: ProdDropdownProps) {
  const getAllParams = (type: CategoryTypeEnum) => ({
    limit: 1000,
    page: 1,
    isActive: StatusEnum.ACTIVE,
    type,
  });

  const vehicleTypeParams = useMemo(() => getAllParams(CategoryTypeEnum.VEHICLE_TYPE), []);

  const manufacturerParams = useMemo(() => getAllParams(CategoryTypeEnum.MANUFACTURER), []);

  const modelParams = useMemo(() => getAllParams(CategoryTypeEnum.MODEL), []);

  const originParams = useMemo(() => getAllParams(CategoryTypeEnum.ORIGIN), []);

  const productUnitParams = useMemo(() => getAllParams(CategoryTypeEnum.PRODUCT_UNIT), []);

  const partOfNumberParams = useMemo(() => getAllParams(CategoryTypeEnum.PART_OF_PRODUCT), []);

  const { data: agenciesData, loading: agenciesLoading } = useAdminAgenciesQuery({
    skip: !agencies,
    variables: {
      limit: 1000,
      page: 1,
      isActive: StatusEnum.ACTIVE,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data: vehicleTypesData, loading: vehicleTypesLoading } = useCategoriesQuery({
    skip: !vehicleTypes,
    variables: vehicleTypeParams,
    fetchPolicy: 'cache-and-network',
  });

  const { data: manufacturersData, loading: manufacturersLoading } = useCategoriesQuery({
    skip: !manufacturers,
    variables: manufacturerParams,
    fetchPolicy: 'cache-and-network',
  });

  const { data: modelsData, loading: modelsLoading } = useCategoriesQuery({
    skip: !models,
    variables: modelParams,
    fetchPolicy: 'cache-and-network',
  });

  const { data: originsData, loading: originsLoading } = useCategoriesQuery({
    skip: !origins,
    variables: originParams,
    fetchPolicy: 'cache-and-network',
  });

  const { data: productUnitsData, loading: productUnitsLoading } = useCategoriesQuery({
    skip: !productUnits,
    variables: productUnitParams,
    fetchPolicy: 'cache-and-network',
  });

  const { data: partOfNumberData, loading: partOfNumberLoading } = useCategoriesQuery({
    skip: !partOfNumbers,
    variables: partOfNumberParams,
    fetchPolicy: 'cache-and-network',
  });

  return {
    data: {
      agencies: agenciesData?.adminAgencies?.items ?? [],
      vehicleTypes: vehicleTypesData?.categories?.items ?? [],
      manufacturers: manufacturersData?.categories?.items ?? [],
      models: modelsData?.categories?.items ?? [],
      origins: originsData?.categories?.items ?? [],
      productUnits: productUnitsData?.categories?.items ?? [],
      partOfNumbers: partOfNumberData?.categories.items ?? [],
    },
    loading:
      agenciesLoading ||
      vehicleTypesLoading ||
      manufacturersLoading ||
      modelsLoading ||
      originsLoading ||
      productUnitsLoading ||
      partOfNumberLoading,
  };
}
