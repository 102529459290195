import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '../type.interface';
import { AdminFragmentFragmentDoc } from '../fragments/admin.fragment.generated';

const defaultOptions = {} as const;
export type MeAdminQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MeAdminQueryResponse = { __typename?: 'Query' } & {
  meAdmin: { __typename?: 'AdminEntity' } & Pick<
    Types.AdminEntity,
    | 'address'
    | 'addressMoreInfo'
    | 'avatarId'
    | 'birthday'
    | 'createdAt'
    | 'deletedAt'
    | 'email'
    | 'fullname'
    | 'id'
    | 'isActive'
    | 'type'
    | 'updatedAt'
  > & {
      avatar?: Types.Maybe<
        { __typename?: 'Media' } & Pick<
          Types.Media,
          | 'id'
          | 'fullOriginalUrl'
          | 'fullThumbUrl'
          | 'createdAt'
          | 'fileSize'
          | 'name'
          | 'originalUrl'
          | 'ownerId'
          | 'thumbUrl'
          | 'type'
        >
      >;
    };
};

export const MeAdminDocument = gql`
  query meAdmin {
    meAdmin {
      ...AdminFragment
    }
  }
  ${AdminFragmentFragmentDoc}
`;
export function useMeAdminQuery(baseOptions?: Apollo.QueryHookOptions<MeAdminQueryResponse, MeAdminQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeAdminQueryResponse, MeAdminQueryVariables>(MeAdminDocument, options);
}
export function useMeAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeAdminQueryResponse, MeAdminQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeAdminQueryResponse, MeAdminQueryVariables>(MeAdminDocument, options);
}
export type MeAdminQueryHookResult = ReturnType<typeof useMeAdminQuery>;
export type MeAdminLazyQueryHookResult = ReturnType<typeof useMeAdminLazyQuery>;
export type MeAdminQueryResult = Apollo.QueryResult<MeAdminQueryResponse, MeAdminQueryVariables>;
